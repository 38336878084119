import { FC } from 'react';
import ChangePasswordForm from '../../../../components/Settings/UserProfile/ChangePasswordForm';
import UserProfileForm from '../../../../components/Settings/UserProfile/UserProfileForm';
import { TabHeader } from '../../../../components/ui/components/Header';

const Profile: FC = () => {
  return (
    <div className='h-full'>
      <div className=''>
        <TabHeader heading='Profile Settings' />
        <h4 className='text-gray-8 mb-4 mt-6 text-base font-medium'>Personal Information</h4>
        <UserProfileForm />
      </div>
      <hr className='my-8' />
      <div className='mt-6'>
        <h4 className='text-gray-8 mb-4 text-base font-medium'>Change Password</h4>
        <ChangePasswordForm />
      </div>
    </div>
  );
};

export default Profile;
