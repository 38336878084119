import { UseQueryOptions, useQuery } from 'react-query';
import { Api } from '../api';

export class HealthCheckApi extends Api {
  readonly baseUrl: string = '/api/v4/health-check';

  readonly getHealthCheck = () => {
    return this.http.get<IHealthCheckResponse>(this.route('/'));
  };
}

export const healthCheckApi = new HealthCheckApi();

export const useHealthCheckGet = (options: UseQueryOptions) => {
  const key = ['HealthCheckApi', 'getHealthCheck'];
  return useQuery(key, () => healthCheckApi.getHealthCheck(), options as unknown);
};

export interface IHealthCheckResponse {
  aggregate_status: number;
  aggregate_status_verbose: string;
  statuses_by_blockchain: IHealthCheckStatus[];
}

export interface IHealthCheckStatus {
  blockchain: number;
  blockchain_verbose: string;
  status: number;
  status_verbose: string;
  last_synced_block: number;
  last_synced_block_timestamp?: string;
  last_updated_at: string;
}
