import { FC } from 'react';
import { GoogleCharts } from 'google-charts';

import './pieWidget.scss';
import DashboardWidget from './DashboardWidget';
import { createTooltip } from './WidgetGraphTooltip';
import Skeleton from 'react-loading-skeleton';
import EmptyState from '../../ui/components/States/Empty';
import React from 'react';
import { RiskLevel } from '../../utils/constants/constants';

interface IPieWidget2Props {
  title: string;
  data: (string | number)[][];
  isLoading?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
  isPdf?: boolean;
  isRisk?: boolean;
}

const PieWidget2: FC<IPieWidget2Props> = ({
  title,
  data,
  isLoading,
  tooltipTitle,
  tooltipDescription,
  isPdf,
  isRisk,
}) => {
  data = isRisk
    ? data.map((row) => [RiskLevel.find((r) => r.value === Number(row[0])).label, row[1] as number])
    : data;
  const total = data.reduce((sum, row) => sum + (row[1] as number), 0);
  const percentages = data.map((row) => {
    const percentage = ((row[1] as number) / total) * 100;
    return [row[0], total > 0 ? percentage.toFixed(2) : '0.00'];
  });

  const colors = isRisk
    ? ['#ccc', '#fde68a', '#fbbf24', '#f87171', '#ef4444', '#b91c1c']
    : ['#0891B2', '#06B6D4', '#67E8F9'];
  const options = {
    legend: 'none',
    fontName:
      'Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    pieSliceText: 'value',
    pieSliceTextStyle: {
      fontSize: 12,
    },
    tooltip: {
      isHtml: true,
    },
    colors,
    width: 180,
    height: 180,
    slices: isRisk ? [{ textStyle: { color: 'black' } }] : null,
  };

  const chartData = () => {
    const chartData = [];
    if (chartData) {
      data.map((p, i) => {
        chartData.push([p[0], p[1], createTooltip(p[0].toString(), Number(p[1]), total, colors[i])]);
      });
    }

    const headers: (string | { type?: string; role: string; p?: { html: boolean } })[] = [
      'Label',
      'Value',
      { type: 'string', role: 'tooltip', p: { html: true } },
    ];

    return [headers, ...chartData];
  };
  const noData = data?.every((row) => row[1] === 0);
  if (!noData) GoogleCharts.load(drawChart);
  const chartRef = React.useRef(null);
  function drawChart() {
    const data = GoogleCharts.api.visualization.arrayToDataTable(chartData());
    if (chartRef?.current) {
      const chart = new GoogleCharts.api.visualization.PieChart(chartRef.current);
      chart.draw(data, options);
    }
  }

  return (
    <DashboardWidget
      title={title}
      subtitle='In Selected Period'
      className='py-0'
      containerClassName={!isPdf ? 'col-span-4' : 'col-span-6'}
      tooltipTitle={tooltipTitle}
      tooltipDescription={tooltipDescription}>
      {!isLoading && noData ? (
        <EmptyState />
      ) : (
        <div className='flex'>
          {isLoading ? <Skeleton width={140} height={140} className='m-2.5' /> : <div ref={chartRef} />}
          <div className='my-auto w-full'>
            {isLoading ? (
              <Skeleton count={4} width={'100%'} />
            ) : (
              percentages.map((percentage, i) => (
                <div key={i} className='mb-4 mr-2 flex'>
                  <div
                    className='my-auto mr-1 size-3 rounded-full border-2'
                    style={{
                      borderColor: colors[i],
                    }}
                  />
                  <span className='text-xs text-slate-500'>
                    {percentage[0]} {percentage[1]}%
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </DashboardWidget>
  );
};

export default PieWidget2;
