import { Button } from '@ui/index';
import { DownloadSimple } from '@phosphor-icons/react';
import { FC, useState } from 'react';
import { BasicModal } from '@ui/index';
interface ExportProps {
  onExport: () => void;
  type: string;
}

const ExportBtn: FC<ExportProps> = ({ onExport, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button
        variant='tertiary'
        onClick={() => setIsModalOpen(true)}
        iconStart={<DownloadSimple size={16} />}>
        Export
      </Button>
      <BasicModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalTitle='Export'
        modalBody={
          <p className='text-sm'>
            You have selected to download {type} list as a CSV file . Would you like to proceed?
          </p>
        }
        modalFooter={
          <div className='flex flex-row justify-end gap-4'>
            <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                onExport();
                setIsModalOpen(false);
              }}>
              Export
            </Button>
          </div>
        }
      />
    </>
  );
};

export default ExportBtn;
