import { useQuery, UseQueryOptions } from 'react-query';

import { Api } from './api';
import {
  ITransactionNonTokenRequest,
  ITransactionNonTokenResponse,
  ITransactionResponse,
  ITransactionTokenRequest,
  ITransactionTokenResponse,
} from './dtos/transaction';
import { IAggregatedResponse } from './dtos/address';

export class TransactionLiteApi extends Api {
  readonly baseUrl: string = '/risk/api/v4';

  readonly getTransactionLite = (id: string, currency: number) => {
    return this.http.get<ITransactionResponse>(this.route(`${currency}/transaction-preview/${id}/`));
  };

  readonly getAggregatedInputsLite = (id: string, currency: number) => {
    return this.http.get<IAggregatedResponse>(
      this.route(`${currency}/transaction-preview/${id}/aggregated-inputs/`)
    );
  };

  readonly getAggregatedOutputsLite = (id: string, currency: number) => {
    return this.http.get<IAggregatedResponse>(
      this.route(`${currency}/transaction-preview/${id}/aggregated-outputs/`)
    );
  };

  readonly getInputsLite = (params: ITransactionNonTokenRequest) => {
    const { id, offset = 0, limit = 100, filters, currency } = params;
    return this.http.get<ITransactionNonTokenResponse>(
      this.route(`${currency}/transaction-preview/${id}/inputs/`),
      {
        params: { offset, limit, ...filters },
      }
    );
  };

  readonly getOutputsLite = (params: ITransactionNonTokenRequest) => {
    const { id, offset = 0, limit = 100, filters, currency } = params;
    return this.http.get<ITransactionNonTokenResponse>(
      this.route(`${currency}/transaction-preview/${id}/outputs/`),
      {
        params: { offset, limit, ...filters },
      }
    );
  };

  readonly getInternalTransactionsLite = (params: ITransactionTokenRequest) => {
    const { id, offset, limit = 100, filters, currency } = params;
    return this.http.get<ITransactionTokenResponse>(
      this.route(`${currency}/transaction-preview/${id}/internal-transactions/`),
      {
        params: { offset, limit, ...filters },
      }
    );
  };

  readonly getTokenTransfersLite = (params: ITransactionTokenRequest) => {
    const { id, offset = 0, limit = 100, filters, currency } = params;
    return this.http.get<ITransactionTokenResponse>(
      this.route(`${currency}/transaction-preview/${id}/token-transfers/`),
      {
        params: { offset, limit, ...filters },
      }
    );
  };
}

export const transactionLiteApi = new TransactionLiteApi();

export const useTransactionGetTransactionLite = (id: string, currency: number, options?: UseQueryOptions) => {
  const key = ['transactionLiteApi.getTransactionLite', id];
  const result = useQuery(key, () => transactionLiteApi.getTransactionLite(id, currency), {
    ...options,
  } as unknown);

  return result;
};

export const useTransactionGetAggregatedInputsLite = (
  id: string,
  currency: number,
  options?: UseQueryOptions
) => {
  const key = ['transactionLiteApi.getAggregatedInputsLite', id];
  const result = useQuery(key, () => transactionLiteApi.getAggregatedInputsLite(id, currency), {
    ...options,
  } as unknown);

  return result;
};

export const useTransactionGetAggregatedOutputsLite = (
  id: string,
  currency: number,
  options?: UseQueryOptions
) => {
  const key = ['transactionLiteApi.getAggregatedOutputsLite', id];
  const result = useQuery(key, () => transactionLiteApi.getAggregatedOutputsLite(id, currency), {
    ...options,
  } as unknown);

  return result;
};
