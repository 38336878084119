import { FC } from 'react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { formatDateTime } from '../../../utils/helpers/date';
import ReportTable from '../ReportTable';
import { CaseStatusBadge, RiskBadge } from '../../../ui';
import { ICaseResponse } from '../../../api/dtos/case';

interface ICaseDetailsReportProps {
  caseData: ICaseResponse;
  part?: number;
}

const CaseDetailsReport: FC<ICaseDetailsReportProps> = ({ caseData, part }) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  const currentTime = formatDateTime(new Date().toLocaleString('en-US', { timeZone: timezone }));

  if (!caseData) return null;

  const tableData = [
    {
      label: 'Case Name',
      value: <div>{caseData.name}</div>,
    },
    {
      label: 'Case ID',
      value: <div>{caseData.case_id}</div>,
    },
    {
      label: 'Created',
      value: <div>{caseData.created_at}</div>,
    },
    {
      label: 'Last Updated',
      value: <div>{caseData.updated_at}</div>,
    },
    {
      label: 'Risk Level',
      value: (
        <div>
          <RiskBadge risk={caseData.risk_level} />
        </div>
      ),
    },
    {
      label: 'Status',
      value: (
        <div>
          <CaseStatusBadge key={caseData.case_id} status={caseData.status} />
        </div>
      ),
    },
    {
      label: 'Assigned',
      value: <div>{caseData.assigned_to_name}</div>,
    },
    { label: 'Alerts', value: <div>{caseData.open_alerts_count}</div> },
  ];

  return (
    <div>
      <h1 className='mb-1 mt-10 text-[26px] font-extrabold'>Case Report</h1>
      <code className='lead mb-3'>{caseData.id}</code>
      <hr className='my-2' />

      <div className='text-2xs'>
        <span className='mr-5 inline-block text-gray-500'>Report Exported On</span>
        {currentTime}
      </div>
      <hr className='my-2' />

      <ReportTable title={`Part ${part}: Case Details`} tableData={tableData} />
    </div>
  );
};

export default CaseDetailsReport;
