import { FC, useState } from 'react';
import { PlusCircle } from '@phosphor-icons/react';
import { BasicModal, Button, RadioButton } from '../../../ui';
import { useForm } from 'react-hook-form';
import { addressApi } from '../../../api/address';
import RadioButtonsController from '../../../ui/components/Form/RadioButtonsController';
import ExistingCustomerDropdown from './ExistingCustomerDropdownController';
import { useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from '../../../ui/components/Form/InputController';
import classNames from 'classnames';
import { useMutation } from 'react-query';
// import { useNavigate } from 'react-router-dom';
// TODO: Use from library directly
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';
import CustomersDisabled from './CustomersDisabled';
import DropdownController from '../../../ui/components/Form/DropdownController';
import { transactionApi } from '../../../api/transaction';
import { isCurrencyDeprecated } from '../../../utils/helpers/currency';
import { toast } from '../../../ui/components/Toast';
import CompassLoader from '../../ui/components/Loader/CompassLoader';

interface AddToCustomerProps {
  type: 'address' | 'transaction';
  identifier: string;
  currency: number;
  addresses?: string[];
}

export const initialData = {
  customer_type: 'existing',
  customer_id: '',
  type: '1',
  customer_address: '',
};

const AddToCustomer: FC<AddToCustomerProps> = (props) => {
  const { type, identifier, currency, addresses } = props;
  const [open, setOpen] = useState(false);
  const { state } = useAuth();
  const canAccessCustomers = state.userProfile.can_access_customers;
  const dropdownAddresses = addresses?.map((address) => ({ label: address, value: address }));

  const { navigate } = useRouter();
  // const navigate = useNavigate();

  const validations = Yup.object({
    customer_type: Yup.string().label('Customer type').required(),
    customer_id: Yup.string()
      .label('Customer id')
      .required('Customer id is required')
      .when('customer_type', {
        is: 'new',
        // then: Yup.string().min(5, 'Customer id should be of at least 5 characters'),
        then: (schema) =>
          schema.test('min', 'Customer id should be of at least 5 characters', (value) => value?.length >= 5),
      }),
    type: Yup.string().label('Type').required(),
    customer_address: Yup.mixed()
      .label('Customer address')
      .test('required', 'Customer address is required', (value: boolean) => {
        return type === 'address' || value;
      }),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const customerType = useWatch({ control, name: 'customer_type' });
  const { mutate: addAddress, isLoading: isAddressLoading } = useMutation(addressApi.screenAddress, {
    onSuccess: (data) => {
      navigate(`/addresses/${data.data.id}`);
      setOpen(false);
    },
  });

  const { mutate: addTransaction, isLoading: isTransactionLoading } = useMutation(
    transactionApi.screenTransaction,
    {
      onSuccess: (data) => {
        navigate(`/transactions/${data.data.id}`);
        setOpen(false);
      },
    }
  );
  const ModalBody: FC = () => {
    const customerId = useWatch({ control, name: 'customer_id' });

    const onSubmit = (data) => {
      const postData = { identifier, currency, customer_id: data.customer_id, type: Number(data.type) };
      type === 'address'
        ? addAddress(postData)
        : addTransaction({ ...postData, address: data.customer_address.value });
    };

    if (isAddressLoading || isTransactionLoading) return <CompassLoader />;

    return canAccessCustomers ? (
      <form onSubmit={handleSubmit(onSubmit)} className=''>
        <div className='mb-4'>
          <RadioButtonsController name='customer_type' control={control}>
            <RadioButton value='existing' labelText='Existing customer' />
            <RadioButton value='new' labelText='New customer' />
          </RadioButtonsController>
          <div className='mb-6' />
          <label className='mb-2 inline-block text-base font-medium text-gray-700'>Enter Customer Name</label>

          {customerType === 'existing' ? (
            <ExistingCustomerDropdown control={control} />
          ) : (
            <div className='flex flex-col'>
              <InputController
                control={control}
                name='customer_id'
                id='customer_id'
                type='text'
                placeholder='Customer name'
              />
              {!errors.customer_id && (
                <div className='mt-2 text-sm text-gray-600'>
                  Customer Id should be of at least 5 characters
                </div>
              )}
            </div>
          )}
          {customerId?.length > 4 && (
            <>
              <RadioButtonsController name='type' control={control} className={classNames('mt-4')}>
                <RadioButton value='1' labelText='Deposit' />
                <RadioButton value='2' labelText='Withdrawal' />
              </RadioButtonsController>
              {type === 'transaction' && (
                <DropdownController
                  control={control}
                  name='customer_address'
                  options={dropdownAddresses}
                  placeholder='Select an address'
                  customClass='mt-3'
                />
              )}
            </>
          )}
        </div>
        <div className='flex justify-end'>
          <Button variant='tertiary' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button type='submit' variant='primary' className='ml-2'>
            Add {type}
          </Button>
        </div>
      </form>
    ) : (
      <CustomersDisabled />
    );
  };

  const onClickCustomer = () => {
    if (isCurrencyDeprecated(currency)) {
      toast.error('This blockchain is no longer supported');
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div className='cursor-pointer text-sm text-blue-600' onClick={onClickCustomer}>
        <PlusCircle size={16} className='mr-1 inline-block' />
        Add to a customer
      </div>
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        modalTitle={canAccessCustomers ? 'Add to a customer' : 'Activate Customers'}
        size='md'
        modalBody={<ModalBody />}
        modalFooter={null}
        customFooterClass='hidden'
      />
    </>
  );
};

export default AddToCustomer;
