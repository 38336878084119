import { FC } from 'react';
import { ITransactionResponse } from '../../../../api/dtos/transaction';
import NonTokenTransaction from './NonTokenTransaction';
import { isTokenCurrency } from '../../../../utils/helpers/currency';
import TokenTransaction from './TokenTransaction';

interface TransactionSummaryProps {
  transaction: ITransactionResponse;
  setCustomerAddresses?: (addresses: string[]) => void;
  defaultEntities?: string[];
  defaultEntitySubTypes?: string[];
  defaultEntityName?: string;
  popover?: boolean;
}

const TransactionSummary: FC<TransactionSummaryProps> = (props) => {
  const {
    transaction,
    setCustomerAddresses,
    defaultEntities,
    defaultEntityName,
    defaultEntitySubTypes,
    popover,
  } = props;
  const isToken = isTokenCurrency(transaction.currency);
  return isToken ? (
    <TokenTransaction
      transaction={transaction}
      setCustomerAddresses={setCustomerAddresses}
      defaultEntities={defaultEntities}
      defaultEntitySubTypes={defaultEntitySubTypes}
      defaultEntityName={defaultEntityName}
      popover={popover}
    />
  ) : (
    <NonTokenTransaction
      transaction={transaction}
      setCustomerAddresses={setCustomerAddresses}
      defaultEntities={defaultEntities}
      defaultEntitySubTypes={defaultEntitySubTypes}
      defaultEntityName={defaultEntityName}
      popover={popover}
    />
  );
};

export default TransactionSummary;
