import { DotsThreeOutline } from '@phosphor-icons/react';
import { FC, useState } from 'react';
import { Button, DropdownOption } from '../../../ui';
import SimpleDropdown from '../../ui/components/Dropdown/SimpleDropdown';
// import { useRouter } from '../../../modules/router/RouterProvider';
import ExportReport from '../../common/ExportReport';
import CopyToClipboardBtn from '../../../ui/components/Copy/Copy';
import { ICaseResponse } from '../../../api/dtos/case';
import { ellipsis } from '../../../utils/helpers/helperFunctions';
import CaseAddEntitiesModal from '../CaseAddEntitiesModal';
import EditCaseNameModal from './EditCaseNameModal';
import CaseDeleteModal from './CaseDeleteModal';
import CaseAuditTrail from './CaseAuditTrail';
import { ArrowFatLinesRight } from '@phosphor-icons/react/dist/ssr';

interface CaseMetaDataHeaderProps {
  caseData: ICaseResponse;
}

const CaseMetaDataHeader: FC<CaseMetaDataHeaderProps> = ({ caseData }) => {
  // const { getQueryParams } = useRouter();

  const moreDropdownOptions = [
    { label: 'Edit Case Name', value: 'edit' },
    { label: 'Delete Case', value: 'delete' },
  ];

  const [addEntitiesModal, setAddEntitiesModal] = useState(false);

  const [archive, setArchive] = useState(false);
  const [editName, setEditName] = useState(false);

  const onChangeDropdown = (value: DropdownOption) => {
    if (value.value === 'delete') {
      setArchive(true);
    } else if (value.value === 'edit') {
      setEditName(true);
    }
  };
  return (
    <>
      <div className='flex flex-col justify-between'>
        <div className='flex break-all text-[21px] text-gray-800'>
          {caseData.is_auto_created_case && (
            <ArrowFatLinesRight size={17} className='my-auto mr-1 text-cyan-400' />
          )}
          <span title={caseData.name}>{ellipsis(caseData.name, 20)}</span>
          <CopyToClipboardBtn text={caseData.name} className='ml-1' />
        </div>
        <div id='address-full-id' className='flex text-wrap break-all text-xs text-gray-500'>
          {caseData.name}
        </div>
      </div>
      <div className='flex'>
        <Button className='my-auto' onClick={() => setAddEntitiesModal(true)}>
          Add Entities
        </Button>
        <ExportReport id={caseData.id} type='case' />
        <CaseAuditTrail id={caseData.id} />

        <SimpleDropdown
          value={null}
          onChange={onChangeDropdown}
          placeholder={
            <Button variant='tertiary' className='my-1 ml-2 mr-1 px-3'>
              <DotsThreeOutline size={16} weight='fill' />
            </Button>
          }
          options={moreDropdownOptions}
        />

        <CaseDeleteModal caseData={caseData} open={archive} setOpen={setArchive} />
        <EditCaseNameModal caseData={caseData} open={editName} setOpen={setEditName} />

        <CaseAddEntitiesModal
          caseId={caseData.id}
          isOpen={addEntitiesModal}
          onClose={() => setAddEntitiesModal(false)}
        />
      </div>
    </>
  );
};

export default CaseMetaDataHeader;
