import CustomSelect, { IOption } from '../../Filters/CustomSelect';

import { FC, useState } from 'react';
import { DigitalAsset } from '../../../api/dtos/address';

interface TokenSymbolProps {
  value: string[];
  tokens: DigitalAsset[];
  currency_short: string;
  setValue: (values: string[]) => void;
}

const TokenSymbolFilter: FC<TokenSymbolProps> = (props) => {
  const { value, setValue, tokens, currency_short } = props;

  const tokenOptions = tokens?.map((token) => ({
    value: token.token,
    label: token.token,
  })) || [{ value: currency_short, label: currency_short }];

  let selectedTokens = [];
  if (value && value.length) {
    // selectedRisks = riskOptions.filter((option) => value.includes(Number(option.value)));
    selectedTokens = tokenOptions.filter((option) => value.includes(option.value));
  }
  const [tokensState, setTokens] = useState<IOption[]>(selectedTokens);

  const handleChange = (values: IOption[]) => {
    setValue(values.map((r) => r.value));
    setTokens(values);
  };

  return (
    <>
      <CustomSelect
        label='Token Symbol'
        placeholder='Select Token Symbol'
        options={tokenOptions}
        handleChange={handleChange}
        value={tokensState}
      />
    </>
  );
};

export default TokenSymbolFilter;
