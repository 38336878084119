import CustomSelect, { IOption } from './CustomSelect';

import { FC, useState } from 'react';

interface RiskTypeFilterProps {
  value: number[];
  setValue: (riskTypes: number[]) => void;
}

export const riskTypesOptions = [
  { value: '1', label: 'Incoming' },
  { value: '2', label: 'Outgoing' },
];

const RiskTypeFilter: FC<RiskTypeFilterProps> = (props) => {
  const { value, setValue } = props;

  let selectedRiskTypes = [];
  if (value && value.length) {
    selectedRiskTypes = riskTypesOptions.filter((r) => value.includes(Number(r.value)));
  }
  const [riskTypes, setRiskTypes] = useState<IOption[]>(selectedRiskTypes);

  const handleChange = (riskTypes: IOption[]) => {
    setValue(riskTypes.map((r) => Number(r.value)));
    setRiskTypes(riskTypes);
  };

  return (
    <>
      <CustomSelect
        label='Risk Type'
        placeholder='Select Risk Type'
        options={riskTypesOptions}
        handleChange={handleChange}
        value={riskTypes}
      />
    </>
  );
};

export default RiskTypeFilter;
