import { FC, useMemo, useState } from 'react';
import {
  useBillingCreditNonUniqueUsageGet,
  useBillingCreditUsageGet,
  useBillingPlanGet,
} from '../../../../api/billing';
import { ProgressBar } from '../../../../components/ui/components/Chart';
import { TabHeader } from '../../../../components/ui/components/Header';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';
import { RadioButton, RadioButtons } from '../../../../ui';
import BillingPlans from '../../../../constants/billing-plans';
import { formatDateShort, formatMonthYear } from '../../../../utils/helpers/date';
import Table from '../../../../ui/components/Table/Table';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../../utils/helpers/react-query.helper';
import { format } from 'date-fns';
import { useAuth } from '../../../../modules/auth';
import Tab from '../../../../components/ui/components/Tab/Tab';

interface BillingUsageProps {}

const BillingUsage: FC<BillingUsageProps> = () => {
  const usageTabs = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Daily', value: 'daily' },
  ];
  const [usageTab, setUsageTab] = useState<'unique' | 'non-unique'>('unique');
  const [tab, setTab] = useState(0);
  // This is for month and year filter. (Currently hidden)

  // const months = listOfMonths().map((month, i) => {
  //   return {
  //     label: month,
  //     value: (i + 1).toString(),
  //   };
  // });
  // const [selectedMonth, setSelectedMonth] = useState<IOption>(months[0]);

  // const years = Array.from({ length: new Date().getFullYear() - 2019 + 1 }, (_, i) => {
  //   return {
  //     label: (new Date().getFullYear() - i).toString(),
  //     value: (new Date().getFullYear() - i).toString(),
  //   };
  // });
  // const [selectedYear, setSelectedYear] = useState<IOption>(years[0]);

  const { data: billingResponse, isLoading: isBillingLoading } = useBillingPlanGet();
  const creditUsageQuery = useBillingCreditUsageGet(
    {
      interval: usageTabs[tab].value as 'monthly' | 'daily',
    },
    {
      enabled: usageTab === 'unique',
    }
  );
  const creditUsageNonUniqueQuery = useBillingCreditNonUniqueUsageGet(
    {
      interval: usageTabs[tab].value as 'monthly' | 'daily',
    },
    {
      enabled: usageTab === 'non-unique',
    }
  );

  const creditUsageResponse = creditUsageQuery?.data;
  const creditUsageNonUniqueResponse = creditUsageNonUniqueQuery?.data;

  const billing = billingResponse?.data;
  const timezone = useAuth()?.state?.userProfile?.timezone;

  const billingParsed = useMemo(() => {
    if (billing == null) {
      return {};
    }

    return {
      billingFixedCost: billing?.fixed_cost ? parseFloat(billing?.fixed_cost?.toString()).toFixed(2) : '0',
      billingVariableCost: billing?.variable_cost
        ? parseFloat(billing?.variable_cost?.toString()).toFixed(2)
        : '0',
      billingFrequency:
        BillingPlans[billing.billing_frequency?.toString()] != null
          ? BillingPlans[billing.billing_frequency?.toString()].value
          : '',
      billingCurrency: billing?.currency || '',
    };
  }, [billing]);

  if (isBillingLoading) {
    return (
      <div>
        <CompassLoader />
      </div>
    );
  }

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsageTab(e.target.value as 'unique' | 'non-unique');
  };
  const headerData = [
    tab === 1 ? 'Day' : 'Month',
    'Address Screenings',
    'Transaction Screenings',
    'Total Screenings',
  ];
  const [, creditUsageData] = flattenInfiniteQueryResult(creditUsageResponse);
  const [, creditUsageNonUniqueData] = flattenInfiniteQueryResult(creditUsageNonUniqueResponse);
  const rowsData = usageTab === 'unique' ? creditUsageData : creditUsageNonUniqueData;
  const rows = rowsData?.map((item, i) => {
    const intervalFormatted =
      usageTabs[tab].value === 'monthly'
        ? formatMonthYear(item?.month)
        : format(new Date(item?.date_screened), 'PP');
    return {
      id: i,
      data: [
        intervalFormatted,
        item.addresses ?? 0,
        item.transactions ?? 0,
        item.addresses + (item.transactions ?? 0),
      ],
    };
  });

  const UsageWrapper = () => {
    return (
      <div className='ml-1'>
        <p className='py-4 text-xs text-gray-500'>
          Current billing cycle - {formatDateShort(billing.current_billing_period_start, timezone)} to{' '}
          {formatDateShort(billing.current_billing_period_end, timezone)}
        </p>
        <ProgressBar value={billing.usage} max={billing.credit_limit} barClassName='bg-blue-500' />
        <div className='flex justify-between'>
          <p className='py-4 text-sm font-semibold text-gray-500'>
            Credits Usage{' '}
            <span className='pl-2 pr-4'>
              {billing.usage}/{billing.credit_limit}
            </span>
          </p>
          <p className='py-4 text-xs text-gray-500'>
            1 Credit refers to 1 Merkle Science API call. Credits are reset every{' '}
            <span>{billingParsed.billingFrequency}</span> month(s)
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className='h-full max-w-6xl'>
      <TabHeader heading='Current Usage' />
      <UsageWrapper />
      <hr className='mx-1 py-3' />

      <TabHeader heading='API Credit Usage Report' />
      <div className='flex justify-between'>
        <RadioButtons className='mt-4' name='interval' selected={usageTab} onChange={onChangeRadio}>
          <RadioButton value='unique' labelText='Unique Screenings' />
          <RadioButton value='non-unique' labelText='Total Screenings' />
        </RadioButtons>
        {/* This is for month and year filter. (Currently hidden) */}
        {/* <div className='flex'>
          <SingleSelect
            value={selectedMonth}
            options={months}
            isCloseHidden
            handleChange={(value) => setSelectedMonth(value)}
            className='mr-2 w-28'
          />
          <SingleSelect
            value={selectedYear}
            options={years}
            isCloseHidden
            handleChange={(value) => setSelectedYear(value)}
          />
        </div> */}
      </div>

      <Table
        title='Usage'
        border
        className='mt-4'
        headerData={headerData}
        rows={rows}
        isLoading={usageTab === 'unique' ? creditUsageQuery.isLoading : creditUsageNonUniqueQuery.isLoading}
        heightOffset={370}
        {...buildInfiniteQueryTableProps(
          usageTab === 'unique' ? creditUsageQuery : creditUsageNonUniqueQuery
        )}
        headerActionsLeft={<Tab values={usageTabs} type='primary' listClassName='' onChange={setTab} />}
      />
    </div>
  );
};

export default BillingUsage;
