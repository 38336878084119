import { FC } from 'react';
import AppliedFilters from '../../AppliedFilters';
import { IFilters, defaultStateFilters } from './MonitoringTableFilter';
import { isEmpty, every } from 'lodash';
import CurrencyAppliedFilters from '../../AppliedFilters/CurrencyAppliedFilters';
import UserAppliedFilters from '../../AppliedFilters/UserAppliedFilter';

interface MonitoringTableAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

const MonitoringTableAppliedFilters: FC<MonitoringTableAppliedFiltersProps> = (props) => {
  const { filters, setFilters } = props;
  if (every(filters, (v) => isEmpty(v))) return null;

  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters(defaultStateFilters);
      }}>
      <CurrencyAppliedFilters
        currencies={filters.currency}
        setCurrencies={(currencies) => {
          setFilters({ ...filters, currency: currencies });
        }}
      />
      <UserAppliedFilters
        users={filters.added_by}
        setUsers={(users) => {
          setFilters({ ...filters, added_by: users });
        }}
      />
    </AppliedFilters>
  );
};

export default MonitoringTableAppliedFilters;
