import { FC } from 'react';
import AppliedFilters, { BasicAppliedFilter } from '../../AppliedFilters';
import { IFilters, defaultStateFilters } from './AddressTransactionsFilter';
import EntityAppliedFilters from '../../AppliedFilters/EntityAppliedFilters';
import DateAppliedFilter from '../../AppliedFilters/DateAppliedFilter';
import { transactionDirectionOptions } from '../../../utils/helpers/helperFunctions';
import { isEmpty, every, startCase } from 'lodash';
import { getEntityTypeBySubType } from '../../../utils/helpers/entity';

interface AddressTransactionsAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

const AddressTransactionsAppliedFilters: FC<AddressTransactionsAppliedFiltersProps> = (props) => {
  const { filters, setFilters } = props;
  if (every(filters, (v) => isEmpty(v))) return null;
  return (
    <AppliedFilters
      className='border-t'
      onResetAll={() => {
        setFilters(defaultStateFilters);
      }}>
      <EntityAppliedFilters
        entity={filters.tag_type_verboses}
        setEntity={(e) => {
          setFilters({ ...filters, tag_type_verboses: e });
        }}
      />
      <EntityAppliedFilters
        entity={filters.tag_subtype_verboses.map((e) => `${getEntityTypeBySubType(e)} -> ${e}`)}
        setEntity={(e) => {
          setFilters({ ...filters, tag_subtype_verboses: e });
        }}
      />
      <BasicAppliedFilter
        label='Address'
        value={filters.addresses}
        setValue={(v) => {
          setFilters({ ...filters, addresses: v });
        }}
      />
      <BasicAppliedFilter
        label='Entity Name'
        single
        value={[filters.tag_name_verbose]}
        setValue={(v) => {
          setFilters({ ...filters, tag_name_verbose: v[0] });
        }}
      />
      <DateAppliedFilter
        label='Transaction Date'
        from={filters.transaction_start_date}
        to={filters.transaction_end_date}
        onRemove={() => setFilters({ ...filters, transaction_start_date: '', transaction_end_date: '' })}
      />
      <BasicAppliedFilter
        label='Transaction Type'
        single
        value={[startCase(filters.type)]}
        setValue={(v) => {
          setFilters({ ...filters, type: v[0] });
        }}
      />
      <BasicAppliedFilter
        label='Token Symbol'
        value={filters.token_symbol}
        setValue={(v) => {
          setFilters({ ...filters, token_symbol: v });
        }}
      />
      <BasicAppliedFilter
        label='Transaction Direction'
        single
        value={[transactionDirectionOptions[Number(filters.transaction_direction) - 1]?.label]}
        setValue={(v) => {
          setFilters({ ...filters, transaction_direction: v[0] });
        }}
      />
    </AppliedFilters>
  );
};

export default AddressTransactionsAppliedFilters;
