import { FC } from 'react';
import BasicBadge from '../../../../ui/components/Badges/BasicBadge/BasicBadge';
import { CaseStatus } from '../../../../utils/constants/constants';

export interface CaseStatusBadgeProps {
  status: number;
  className?: string;
}

const CaseStatusBadge: FC<CaseStatusBadgeProps> = ({ status, className }) => {
  const currentCaseStatus = CaseStatus.find((c) => c.value === status);
  if (!currentCaseStatus) return null;
  return (
    <BasicBadge className={`${currentCaseStatus.bgColor} ${currentCaseStatus.textColor} p-0.5 ${className}`}>
      <div className='mx-1'> {currentCaseStatus.label} </div>
    </BasicBadge>
  );
};

export default CaseStatusBadge;
