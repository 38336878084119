import classNames from 'classnames';
import { FC, HTMLProps, ReactNode } from 'react';

export interface CheckboxProps extends Partial<HTMLProps<HTMLInputElement>> {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  labelText?: ReactNode;
  labelNode?: ReactNode;
}
const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    checked,
    className,
    inputClassName,
    labelClassName,
    labelText,
    name,
    labelNode,
    disabled,
    ...inputProps
  } = props;

  return (
    <label htmlFor={name} className={classNames('m-0 flex cursor-pointer items-center', className)}>
      <input
        type='checkbox'
        id={name}
        className={classNames('mb-auto cursor-pointer rounded border border-gray-300', inputClassName, {
          'cursor-not-allowed opacity-50': disabled,
          'bg-gray-500': !checked && disabled,
          'bg-gray-50': !checked && !disabled,
          'bg-blue-500': checked,
        })}
        checked={!!checked}
        disabled={disabled}
        {...inputProps}
      />
      {labelNode == null ? (
        <span
          className={classNames('ml-2 text-xs font-medium leading-default text-gray-800', labelClassName)}>
          {labelText}
        </span>
      ) : (
        labelNode
      )}
    </label>
  );
};

export default Checkbox;
