import * as React from 'react';
import classNames from 'classnames';
import { Download, Trash } from '@phosphor-icons/react';

import { formatDateShort } from '../../../utils/helpers/date';
import { CaseStatus, RiskLevel } from '../../../utils/constants/constants';

import { Button, DropdownOption } from '../../../ui';
import AppliedFilters from '../../../ui/components/Badges/AppliedFiltersBadge/AppliedFiltersBadge';

import { IFilters, defaultCaseListFilters } from './CaseListFilter';

interface Props {
  selectedText: string;
  count: number;
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  selectedCases: number[];
  setSelectedCases: (cases: number[]) => void;
  isAllCaseSelected: boolean;
  setIsAllCaseSelected: (boolean) => void;

  selectAllCases: (boolean) => void;

  downloadSelectedCases: () => void;
  deleteSelectedCases: () => void;

  caseUsers: DropdownOption[];
}

const CaseListAppliedFilter: React.FC<Props> = ({
  selectedText,

  count,
  filters,
  setFilters,
  selectedCases,
  setSelectedCases,
  isAllCaseSelected,
  setIsAllCaseSelected,

  selectAllCases,

  downloadSelectedCases,
  deleteSelectedCases,

  caseUsers,
}) => {
  const isFilterApplied = React.useMemo(() => {
    if (filters.address) return true;
    if (filters.customer_id) return true;
    if (filters.risk_level?.length) return true;
    if (filters.start_date) return true;
    if (filters.end_date) return true;
    if (filters.status?.length) return true;
    if (filters.assigned_to?.length) return true;
  }, [filters]);

  const resetFilter = () => {
    setFilters(defaultCaseListFilters);
  };

  return (
    <div
      className={classNames(
        'flex justify-stretch border-t-[1px] bg-gray-50',
        isFilterApplied || selectedCases?.length ? 'px-2 py-2.5' : ''
      )}>
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-wrap items-center'>
          {filters.address && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  address: '',
                });
              }}>
              Address: {filters.address}
            </AppliedFilters>
          )}
          {filters.customer_id && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  customer_id: '',
                });
              }}>
              Customer: {filters.customer_id}
            </AppliedFilters>
          )}
          {filters.start_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  start_date: null,
                  end_date: null,
                });
              }}>
              Date Updated: {formatDateShort(filters.start_date)} - {formatDateShort(filters.end_date)}
            </AppliedFilters>
          )}
          {filters.risk_level?.map((risk, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  risk_level: filters.risk_level.filter((r) => r !== risk),
                });
              }}>
              Risk: {RiskLevel.find((item) => item.value === risk).label}
            </AppliedFilters>
          ))}
          {filters.status?.map((sta, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  status: filters.status.filter((s) => s !== sta),
                });
              }}>
              Case Status: {CaseStatus.find((item) => item.value === sta).label}
            </AppliedFilters>
          ))}
          {(filters.assigned_to as number[])?.map((assignedTo, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  assigned_to: (filters.assigned_to as number[]).filter((s) => s !== assignedTo),
                });
              }}>
              Assigned To: {caseUsers.find((item) => item.value === `${assignedTo}`).label}
            </AppliedFilters>
          ))}
        </div>
        {isFilterApplied && (
          <div className='flex items-center'>
            <button className='mr-2 text-2xs text-blue-600' onClick={() => resetFilter()}>
              Reset Filters
            </button>
          </div>
        )}
      </div>
      {selectedCases?.length > 0 && (
        <div className='ml-auto flex min-w-[900px] items-center justify-end gap-3 pr-4 text-2xs'>
          <div></div>
          <div>{selectedText}</div>
          {!isAllCaseSelected && (
            <button
              className='text-blue-500'
              onClick={() => {
                selectAllCases(true);
                setIsAllCaseSelected(true);
              }}>
              Select all {count} cases
            </button>
          )}
          <button
            className='text-blue-500'
            onClick={() => {
              setSelectedCases([]);
              setIsAllCaseSelected(false);
            }}>
            Clear selection
          </button>
          <Button variant='tertiary' onClick={() => downloadSelectedCases()}>
            <Download size={17} />
          </Button>
          <Button variant='tertiary' onClick={() => deleteSelectedCases()}>
            <Trash size={17} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CaseListAppliedFilter;
