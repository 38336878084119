import { FC } from 'react';
import { useAuth } from '../../../modules/auth';
import SingleSelect, { IOption } from '../../ui/components/Select/SingleSelect';
import { CaseStatusBadge } from '../../../ui';
import { useMutation, useQueryClient } from 'react-query';
import { caseApi } from '../../../api/case';
import { toast } from 'react-toastify';
import { ICaseResponse } from '../../../api/dtos/case';

interface ICaseStatusDropdownProps {
  caseData: ICaseResponse;
}

const CaseStatusDropdown: FC<ICaseStatusDropdownProps> = ({ caseData }) => {
  const { state } = useAuth();
  const queryClient = useQueryClient();

  const status = caseData?.status;
  const currentStatusOptions = [
    {
      id: 1,
      status: 1,
      verbose: 'Open',
    },
    {
      id: 2,
      status: 1,
      verbose: 'Reopen',
    },
    {
      id: 3,
      status: 2,
      verbose: 'Close',
    },
    {
      id: 4,
      status: 3,
      verbose: 'Escalate',
    },
    {
      id: 5,
      status: 2,
      verbose: 'Accept',
    },
    {
      id: 6,
      status: 1,
      verbose: 'Reject',
    },
  ];

  const { mutate, isLoading } = useMutation(caseApi.updateCase, {
    onSuccess: () => {
      queryClient.invalidateQueries(['caseApi', caseData.id, 'getCase']);
      toast.success('Case status has been updated.');
    },
  });

  const caseStatuses = () => {
    const caseRole = state?.userProfile?.case_role;

    switch (status) {
      case 1:
        return currentStatusOptions.filter((status) => status.id === 3 || status.id === 4);
      case 2:
        return currentStatusOptions.filter((status) => status.id === 2);
      case 3:
        return currentStatusOptions.filter((status) => status.id === 3);
      case 4:
        if (caseRole === 1) {
          return currentStatusOptions.filter((status) => status.id === 5 || status.id === 6);
        } else {
          return currentStatusOptions.filter((status) => status.id === 1 || status.id === 4);
        }
      default:
        return currentStatusOptions;
    }
  };

  const caseStatusOptions = caseStatuses().map((status) => {
    return {
      label: status.verbose,
      value: status.status.toString(),
    };
  });

  const handleChange = (option: IOption) => {
    mutate({ caseId: caseData.id, status: Number(option.value), caseName: caseData.name });
  };

  const getCustomLabel = (option: IOption) => {
    return <CaseStatusBadge status={Number(option.value)} />;
  };

  return (
    <div>
      <SingleSelect
        value={{ label: status.toString(), value: status.toString() }}
        options={caseStatusOptions}
        getCustomLabel={getCustomLabel}
        handleChange={handleChange}
        className={isLoading ? 'pointer-events-none opacity-50' : ''}
        isCloseHidden
      />
    </div>
  );
};

export default CaseStatusDropdown;
