import { FC } from 'react';
import { DotBadge } from '../../../../ui';
import { startCase } from 'lodash';

interface MonitoringBadgeProps {
  isEnabled: boolean;
  inactiveText?: 'inactive' | 'expired';
}

const MonitoringBadge: FC<MonitoringBadgeProps> = ({ isEnabled, inactiveText = 'expired' }) => {
  const badge = isEnabled
    ? { content: 'Active', dotColor: 'bg-green-500', bgColor: 'bg-green-100', txtColor: 'text-green-800' }
    : {
        content: startCase(inactiveText),
        dotColor: 'bg-red-500',
        bgColor: 'bg-red-100',
        txtColor: 'text-red-800',
      };

  return <DotBadge {...badge} />;
};

export default MonitoringBadge;
