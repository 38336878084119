import { Api } from './api';
import {
  IChangePasswordRequest,
  IFindOrgRequest,
  ILogoutRequest,
  ILoginResponse,
  IPermissionsResponse,
} from './dtos/auth';

export class AuthApi extends Api {
  readonly baseUrl: string = 'auth0/api';

  readonly findOrg = (request: IFindOrgRequest) => {
    return this.http.post<ILoginResponse>(this.route('organization/'), request);
  };

  readonly logout = (request: ILogoutRequest) => {
    return this.http.post(this.route('logout/'), request);
  };

  readonly changePassword = (request: IChangePasswordRequest) => {
    return this.http.post(this.route('change-password/'), request);
  };

  readonly getPermissions = () => {
    return this.http.get<IPermissionsResponse>(this.route('permissions/'));
  };
}

export const authApi = new AuthApi();
