import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { IPolicyAlert } from '../../../api/dtos/policies';

import RuleAnalyticsAlertChart from './RuleAnalyticsAlertChart';

interface Props {
  isLoading: boolean;
  flaggedCount: number;
  hitRate: string;
  totalCount: number;
  ruleAlerts: IPolicyAlert[];
  type: string;
}

const RuleAnalytics: React.FC<Props> = ({
  isLoading,
  flaggedCount,
  hitRate,
  totalCount,
  ruleAlerts,
  type,
}) => {
  return (
    <div className='flex gap-3 p-4'>
      <div className='flex h-[350px] w-[450px] flex-col gap-3'>
        {isLoading ? (
          <>
            <Skeleton height={150} />
            <Skeleton height={150} />
            <Skeleton height={150} />
          </>
        ) : (
          <>
            <div className='h-1/3 border p-3.5'>
              <p className='mb-1 text-sm text-gray-500'>{type} Created</p>
              <p className='text-lg font-bold'>{totalCount}</p>
            </div>
            <div className='h-1/3 border p-3.5'>
              <p className='mb-1 text-sm text-gray-500'>{type} Flagged</p>
              <p className='text-lg font-bold'>{flaggedCount}</p>
            </div>
            <div className='h-1/3 border p-3.5'>
              <p className='mb-1 text-sm text-gray-500'>Hit Rate</p>
              <p className='text-lg font-bold'>{hitRate}</p>
            </div>
          </>
        )}
      </div>
      <div className='h-[350px] flex-1 border'>
        <div className='border-b px-3 py-2 text-sm text-gray-500'>
          <p className='font-medium'>Alerts Triggered</p>
          <p>Over Last Week</p>
        </div>
        {isLoading ? <Skeleton height={350} /> : <RuleAnalyticsAlertChart ruleAlerts={ruleAlerts} />}
      </div>
    </div>
  );
};

export default RuleAnalytics;
