import { FC } from 'react';
import { useLottie } from 'lottie-react';
import compassLoader from '../../../../assets/lottie/compass-loader.json';

interface CompassLoaderProps {
  size?: number;
  position?: 'left' | 'center' | 'right';
}

const CompassLoader: FC<CompassLoaderProps> = ({ size, position = 'center' }) => {
  const style = {
    height: size || 250,
    width: size || 250,
    margin: position === 'center' ? 'auto' : position === 'left' ? '0 0 0 0' : '0 0 0 auto',
  };
  const options = {
    animationData: compassLoader,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return <>{View}</>;
};

export default CompassLoader;
