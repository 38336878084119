import { ArrowsLeftRight, Cube, FolderOpen, UserCircle } from '@phosphor-icons/react';
import { capitalize } from 'lodash';
import { FC } from 'react';

interface TypeBadgeProps {
  type: 'address' | 'transaction' | 'customer' | 'case';
}

const TypeBadge: FC<TypeBadgeProps> = ({ type }) => {
  const typeMap = {
    address: {
      icon: <Cube size={12} weight='fill' />,
      bgColor: 'bg-indigo-100',
      textColor: 'text-indigo-800',
    },
    transaction: {
      icon: <ArrowsLeftRight size={12} weight='fill' />,
      bgColor: 'bg-pink-100',
      textColor: 'text-pink-800',
    },
    customer: {
      icon: <UserCircle size={12} weight='fill' />,
      bgColor: 'bg-yellow-100',
      textColor: 'text-yellow-800',
    },
    case: {
      icon: <FolderOpen size={12} weight='fill' />,
      bgColor: 'bg-purple-100',
      textColor: 'text-purple-800',
    },
  };
  const { icon, bgColor, textColor } = typeMap[type];

  return (
    <div className={`flex items-center gap-1 rounded-full px-2 py-[2px] ${bgColor} ${textColor}`}>
      {icon}
      <div>{capitalize(type)}</div>
    </div>
  );
};

export default TypeBadge;
