import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import Button from '../../Button/Button';
import BasicModal from '../BasicModal/BasicModal';

export interface ConfirmationModalProps<T = unknown> {
  onCancel?: (data: T) => void;
  onConfirm: (data: T) => void;
  open: boolean;
  data: T;
  title: string;
  body: ReactNode;
  showCancelButton?: boolean;
  confirmButton?: ReactNode;
  cancelButton?: ReactNode;
  disableFooterButtons?: boolean;
  confirmButtonClass?: string;
  cancelButtonClass?: string;
}

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const {
    data,
    title,
    body,
    onConfirm,
    onCancel,
    showCancelButton = true,
    disableFooterButtons = false,
    confirmButton = 'Yes',
    cancelButton = 'Cancel',
    confirmButtonClass,
    cancelButtonClass,
  } = props;

  return (
    <BasicModal
      onPressEnter={() => onConfirm?.(data)}
      open={data != null}
      onClose={() => onCancel?.(data)}
      modalTitle={title}
      modalBody={<p className='text-sm font-normal leading-default'>{body}</p>}
      modalFooter={
        <div className='flex flex-row justify-end gap-4'>
          {showCancelButton && (
            <Button
              variant='tertiary'
              onClick={() => {
                onCancel?.(data);
              }}
              disabled={disableFooterButtons}
              className={classNames(cancelButtonClass)}>
              {cancelButton}
            </Button>
          )}
          <Button
            variant='primary'
            className={classNames(confirmButtonClass)}
            disabled={disableFooterButtons}
            onClick={() => {
              onConfirm?.(data);
            }}>
            {confirmButton}
          </Button>
        </div>
      }
    />
  );
};

export default ConfirmationModal;
