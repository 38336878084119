import { addressApi } from '../../api/address';
import { transactionApi } from '../../api/transaction';
import { useMutation } from 'react-query';
import { useRouter } from '../../modules/router/RouterProvider';
import { useAuth } from '../../modules/auth';
import { toast } from 'react-toastify';
import { tokenAccountError } from '../constants/errors';
import { getErrorMessage } from './helperFunctions';

interface screenApiProps {
  entityType: 'addresses' | 'transactions';
  identifier: string;
  currency: number;
  customer_id?: string;
  type?: number;
  address?: string;
  isCreateCaseDisabled?: boolean;
}

export const useScreenApi = () => {
  const { mutateAsync: addressMutate } = useMutation(addressApi.screenAddress);
  const { mutateAsync: transactionMutate } = useMutation(transactionApi.screenTransaction);
  const { navigate } = useRouter();
  const { setScreeningLoader } = useAuth();

  const screenApi = async (
    props: screenApiProps,
    redirect = true
  ): Promise<{ id: number; error?: string; account_owner?: string }> => {
    const { entityType, identifier, currency, customer_id, type, address, isCreateCaseDisabled } = props;
    setScreeningLoader({ screeningLoader: true });
    let result = null;
    try {
      result =
        entityType === 'addresses'
          ? await addressMutate({
              identifier,
              currency,
              create_case: !isCreateCaseDisabled,
              ...(customer_id && { customer_id, type }),
            })
          : await transactionMutate({
              identifier,
              currency,
              create_case: !isCreateCaseDisabled,
              ...(customer_id && { customer_id, type, address }),
            });
      if (redirect) {
        navigate(`/${entityType}/${result.data.id}`);
      }
    } catch (e) {
      result = e.response;
      if (result?.data?.error !== tokenAccountError)
        toast.error(`Failed to screen the ${identifier}. Please try again after some time.`);
    }
    setScreeningLoader({ screeningLoader: false });

    return result?.data;
  };

  return screenApi;
};

type apiType = 'address' | 'transaction' | 'customer' | 'case' | 'alert';

export const getTypeApi = (type: apiType) => {
  switch (type) {
    case 'address':
      return 'addresses';
    case 'transaction':
      return 'transactions';
    case 'customer':
      return 'customers';
    case 'case':
      return 'cases';
    case 'alert':
      return 'alerts';
    default:
      return '';
  }
};

export const getApiBaseUrl = (type: apiType) => {
  switch (type) {
    case 'address':
      return '/risk/api/v4/addresses';
    case 'transaction':
      return '/risk/api/v4/transactions';
    case 'customer':
      return '/risk/api/v4/customers';
    case 'case':
      return '/resolutioncenter/api/v4/cases';
  }
};

export const getDetailApiKey = (type: apiType, id: string | number) => {
  switch (type) {
    case 'address':
      return ['addressApi.getAddress', id];
    case 'transaction':
      return ['transactionApi.getTransaction', id];
    case 'customer':
      return ['customerApi', id, 'customerApi.getCustomer'];
    default:
      return '';
  }
};

export const convertBooleanToPy = (value: boolean) => {
  return value == null ? null : value ? 'True' : 'False';
};

export const convertPyBooleanToBoolean = (value: string) => {
  return value == null ? null : value === 'True';
};

export const onError = (error) => {
  toast.error(getErrorMessage(error));
};
