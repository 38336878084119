import { FC, useState } from 'react';
import Tab from '../ui/components/Tab/Tab';
// import { flattenInfiniteQueryResultMeta } from '../../utils/helpers/react-query.helper';
import Pagination from '../../containers/Pagination';
import { IFilters, defaultStateFilters } from './AuditTrail/FilterAuditTrail';
import { UseInfiniteQueryResult, useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';
import FilterAuditTrail from './AuditTrail/FilterAuditTrail';
import AuditTrailAppliedFilters from './AuditTrail/AuditTrailAppliedFilters';
import Comment from './AuditTrail/Comment';
import { commentApi, useCommentGetComments } from '../../api/comment';
import Quill from '../ui/components/Quill';
import { IPostCommentsUser } from '../../api/dtos/comment';
import { AxiosResponse } from 'axios';
import { IPaginatedResponse } from '../../api/dtos/common';
import { toast } from 'react-toastify';

interface AuditLogsProps {
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
}

export interface IComment {
  id: number;
  type: number;
  body: string;
  comment_files: { file_key: string; file_name: string }[];
  commenter: string;
  created_at: string;
}

export interface ICommentGetResponse extends IPaginatedResponse<IComment> {}

const AuditLogs: FC<AuditLogsProps> = ({ type, id }) => {
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const [tab, setTab] = useState<number>(0);
  const getCommentsAll = useCommentGetComments({
    id,
    filters,
    type,
  });
  const getCommentsNotes = useCommentGetComments({
    id,
    filters,
    type,
    tab_type: 6,
  });
  const getCommentsLineage = useCommentGetComments({
    id,
    filters,
    type,
    tab_type: 16,
  });

  const isLoading = getCommentsAll.isLoading && getCommentsLineage && getCommentsNotes.isLoading;

  const onChange = (tab: number) => {
    setTab(tab);
  };

  const onApplyFilter = (filters: IFilters) => {
    setFilters(filters);
  };

  const tabs = [
    { label: 'All' },
    {
      label: 'Notes',
    },
    {
      label: 'Lineage',
    },
  ];

  const queryClient = useQueryClient();

  const { mutate } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      toast.success('Added Comment succesfully');
      queryClient.invalidateQueries(['commentApi.getComments']);
    },
  });

  const onSubmit = (value: string, files: FileList, users: IPostCommentsUser[]) => {
    mutate({
      id,
      type,
      body: value,
      files,
      context: {
        users,
      },
    });
  };

  const renderPagination = (getComments: UseInfiniteQueryResult<AxiosResponse<ICommentGetResponse>>) => (
    <>
      <Pagination query={getComments} className='flex max-h-screen flex-col-reverse' loader='compass'>
        {(data) => <Comment comment={data} key={data.id} />}
      </Pagination>
      {tab !== 2 && <Quill id={id} onSubmit={onSubmit} type={type} />}
    </>
  );

  return (
    <div className={classNames('rounded-md border border-gray-200')}>
      <Tab
        values={tabs}
        type='primary'
        onChange={onChange}
        listClassName='border-b-2 p-4 rounded-t-md'
        inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
        panelClassName=''
        headerActions={
          tab === 0 && <FilterAuditTrail filters={filters} onApply={onApplyFilter} type={type} />
        }
        isLoading={isLoading}
        subHeader={tab === 0 && <AuditTrailAppliedFilters filters={filters} setFilters={setFilters} />}>
        {renderPagination(getCommentsAll)}
        {renderPagination(getCommentsNotes)}
        {renderPagination(getCommentsLineage)}
      </Tab>
    </div>
  );
};

export default AuditLogs;
