import { useState } from 'react';
import { usePolicyGetList } from '../../api/policies';
import Pagination from '../../containers/Pagination.js';
import PolicyStatusBadge from '../../ui/components/Badges/StatusBadge/PolicyStatusBadge.js';
import { BasicBadge } from '../../ui';
import { useRouter } from '../../modules/router/RouterProvider';
import PolicyListAppliedFilters from './PolicyListAppliedFilters';
import { IFilters, defaultStateFilters } from './PolicyListFilter';
import PolicyListFilter from './PolicyListFilter';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';
import ExportRules from './ExportRules';
import AddPolicy from './AddPolicy';
import { calculateVh } from '../../utils/helpers/common';
import { Search } from '../ui/components/Search';
import SortPanel from '../SortPanel/SortPanel';

interface IPolicyListProps {}

const PolicyList: React.FC<IPolicyListProps> = () => {
  const [filters, setFilters] = useState(defaultStateFilters as IFilters);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState('');

  const isEnable =
    filters.policyStatus === 'live' ? true : filters.policyStatus === 'deactivated' ? false : null;

  const policyQuery = usePolicyGetList({
    is_enabled: isEnable,
    type: filters.policyType || null,
    q: search,
    ordering,
    category: filters.category ? Number(filters.category) : null,
  });

  const sortingOptions = [
    {
      sort_by: 'name',
      label: ['A->Z', 'Z->A'],
    },
    {
      sort_by: 'created_at',
      label: ['Latest', 'Oldest'],
    },
    {
      sort_by: 'updated_at',
      label: ['Latest', 'Oldest'],
    },
  ];

  const { navigate } = useRouter();
  const [count, results] = flattenInfiniteQueryResult(policyQuery?.data);
  const onClickPolicy = (id: number) => {
    navigate(`/settings/workspace/policies/${id}`);
  };

  return (
    <div>
      <div className='flex flex-col'>
        <div className='my-4 flex justify-between'>
          <div className='flex flex-row items-center gap-0.5'>
            <div className='my-auto mr-3 text-xs font-semibold uppercase text-gray-500'>Risk Policies</div>
            <div className='mr-2 rounded-xl bg-gray-100 px-[8px] py-[2px] text-xs font-medium text-gray-800'>
              Showing {results?.length} of {count ?? 0} results
            </div>
          </div>
          <div className='mr-3 flex flex-row items-center gap-2'>
            <Search value={search} setValue={setSearch} placeholder='Search policies' />
            <div className='flex items-center text-xs'>
              <SortPanel
                onApply={setOrdering}
                onReset={() => setOrdering('')}
                value={ordering}
                options={sortingOptions}>
                {/* <div className='mt-2 flex justify-between'>
                  <RadioButtons
                    name='ordering'
                    selected={sort}
                    className={'flex-col'}
                    onChange={(e) => setSort((e.target as HTMLInputElement).value)}>
                    <RadioButton value='name' labelText='Name' />
                    <RadioButton value='created_at' labelText='Created At' />
                    <RadioButton value='updated_at' labelText='Updated At' />
                  </RadioButtons>
                  <div>
                    {['name', 'created_at', 'updated_at'].map((key) => (
                      <div
                        key={key}
                        className='mb-4 flex cursor-pointer text-sm font-medium leading-default text-gray-800'>
                        <div
                          className={classNames('mr-2 hover:text-blue-500', {
                            'text-blue-600': sort === key && sortOrder === 'asc',
                          })}
                          onClick={() => {
                            setSort(key);
                            setSortOrder('asc');
                          }}>
                          Asc
                        </div>
                        <div
                          className={classNames('hover:text-blue-500', {
                            'text-blue-600': sort === key && sortOrder === 'desc',
                          })}
                          onClick={() => {
                            setSort(key);
                            setSortOrder('desc');
                          }}>
                          Desc
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </SortPanel>
            </div>
            <PolicyListFilter filters={filters} onApply={setFilters} />
            <ExportRules />
            <AddPolicy />
          </div>
        </div>
        <PolicyListAppliedFilters
          ordering={ordering}
          setOrdering={setOrdering}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <Pagination
        query={policyQuery}
        className='flex flex-col'
        style={{
          maxHeight: calculateVh(192),
        }}>
        {(policy) => (
          <div
            key={policy.id}
            onClick={() => onClickPolicy(policy.id)}
            className='my-2.5 cursor-pointer rounded-lg border p-5 text-xs text-gray-500 first:mt-0 hover:bg-gray-50'>
            <div className='mb-3.5'>
              <div className=''>{policy.name}</div>
              <div className='text-sm'>{policy.description}</div>
            </div>
            <div className='grid grid-cols-4'>
              <div>
                <div className='text-sm'>Status</div>
                <PolicyStatusBadge className='mt-1' isActive={policy.is_enabled} />
              </div>
              <div>
                <div className='text-sm'>Policy Type</div>
                <BasicBadge className='mt-1 bg-gray-200 text-gray-900'>{policy.type_verbose}</BasicBadge>
              </div>
              <div>
                <div className='text-sm'>Policy Category</div>
                <BasicBadge className='mt-1 bg-gray-200 text-gray-900'>{policy.category_verbose}</BasicBadge>
              </div>
              <div>
                <div className='text-sm'>Number of Rules</div>
                <div className='mt-1 text-gray-900'>{policy.rule_count}</div>
              </div>
            </div>
          </div>
        )}
      </Pagination>
    </div>
  );
};

export default PolicyList;
