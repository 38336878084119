import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../FilterPanel';
import CurrencyFilter from '../../Filters/CurrencyFilter';
interface DigitalAssetsFilterProps {
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
}

export interface IFilters {
  blockchains: number[];
}

export const defaultStateFilters: IFilters = {
  blockchains: [],
};

const DigitalAssetsFilter: FC<DigitalAssetsFilterProps> = ({ filters, onApply }) => {
  const [blockchains, setBlockchains] = useState<number[]>([]);

  const handleApply = () => {
    const newFilters = {
      blockchains,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setBlockchains(defaultStateFilters.blockchains);
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    if (filters) {
      setBlockchains(filters.blockchains);
    }
  }, [filters]);

  return (
    <FilterPanel onApply={handleApply} onReset={onReset}>
      <CurrencyFilter value={blockchains} setValue={setBlockchains} />
    </FilterPanel>
  );
};

export default DigitalAssetsFilter;
