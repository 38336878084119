export const caseTypes = [
  'Case Status Updated',
  'Alert Status Updated',
  'Risk Level Updated',
  'New Alerts Created',
  'Monitoring Status Update',
  'Address Screened',
  'User Comment Created',
  'Case Created',
  'Transaction Screened',
  'Alert Monitoring Status Updated',
];

// "0": "New Alerts Created",
// "1": "Alert Status Updated",
// "2": "Case Status Updated",
// "3": "Risk Level Updated",
// "4": "Case Entity Updated"

export const caseTypesCase = [
  { value: '0', label: 'New Alerts Created' },
  { value: '1', label: 'Alert Status Updated' },
  { value: '2', label: 'Case Status Updated' },
  { value: '3', label: 'Risk Level Updated' },
  { value: '4', label: 'Case Entity Updated' },
];
