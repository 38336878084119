/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from '../../modules/router/RouterProvider';
import { useSearch } from '../../api/search';
import { useEffect, useState } from 'react';
import { sanitizeAndEncode } from '../../utils/helpers/helperFunctions';
import { isLiteCurrency } from '../../utils/helpers/currency';
import EmptyState from '../../ui/components/States/Empty';
import { Button, SearchBadge } from '../../ui';
import TransactionPopover from '../../components/AddressTransaction/TransactionPopover';
import AddressPreview from '../../components/Address/AddressPreview';
import CustomDisclosure from '../../ui/components/Disclosure/CustomDisclosure';
import ListPreview from './ListPreview';
import { getTypeApi } from '../../utils/helpers/apiHelpers';
import Skeleton from 'react-loading-skeleton';
import { useAddressGetAddressList } from '../../api/address';
import { defaultAddressListFilters } from '../../components/Address/AddressList/AddressListFilter';
import { useTransactionGetTransactionList } from '../../api/transaction';
import { defaultTransactionListFilters } from '../../components/Transaction/TransactionList/TransactionListFilter';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';

export interface ICurrentEntityType {
  id: string;
  currency: number;
  type: 'address' | 'transaction';
  open_type: 'list_preview' | 'preview';
}

const SearchPage = () => {
  const { getQueryParams, navigate } = useRouter();
  const { q, search_entity_type } = getQueryParams();
  const [currentEntity, setCurrentEntity] = useState<ICurrentEntityType>();
  const { isLoading, data: searchData } = useSearch(
    {
      query: sanitizeAndEncode(q?.trim())?.replace(/%3A/g, ':'),
      entity_type: getTypeApi(
        search_entity_type as 'address' | 'transaction' | 'customer' | 'case' | 'alert'
      ),
    },
    {
      enabled: ['all', 'address', 'transaction', 'customer', 'case'].includes(search_entity_type),
    }
  );

  const combinedResult = [
    ...(searchData?.data?.un_screened_addresses || []).map((item) => ({
      ...item,
      item_type: 'address',
      is_screened: false,
    })),
    ...(searchData?.data?.un_screened_transactions || []).map((item) => ({
      ...item,
      item_type: 'transaction',
      is_screened: false,
    })),
    ...(searchData?.data?.addresses?.results || []).map((item) => ({ ...item, item_type: 'address' })),
    ...(searchData?.data?.transactions?.results || []).map((item) => ({
      ...item,
      item_type: 'transaction',
    })),
    ...(searchData?.data?.customers?.results || []).map((item) => ({ ...item, item_type: 'customer' })),
    ...(searchData?.data?.cases?.results || []).map((item) => ({ ...item, item_type: 'case' })),
  ];

  const { fullCoverageResults, sanctionScreeningResults } = combinedResult.reduce(
    (acc, item: any) => {
      if (!isLiteCurrency(item.currency)) {
        acc.fullCoverageResults.push(item);
      } else {
        const updatedItem = { ...item, sanctioned: true };
        acc.sanctionScreeningResults.push(updatedItem);
      }
      return acc;
    },
    { fullCoverageResults: [], sanctionScreeningResults: [] }
  );

  const addressListPreviewQuery = useAddressGetAddressList(
    {
      offset: 0,
      filters: { ...defaultAddressListFilters, currency: [currentEntity?.currency] },
      q: currentEntity?.id,
    },
    {
      enabled: currentEntity?.open_type === 'list_preview' && currentEntity?.type === 'address',
    }
  );
  const transactionListPreviewQuery = useTransactionGetTransactionList(
    {
      offset: 0,
      filters: { ...defaultTransactionListFilters, currency: [currentEntity?.currency] },
      q: currentEntity?.id,
    },
    {
      enabled: currentEntity?.open_type === 'list_preview' && currentEntity?.type === 'transaction',
    }
  );
  const [addressesCount, addresses] = flattenInfiniteQueryResult(addressListPreviewQuery?.data);
  const [transactionsCount, transactions] = flattenInfiniteQueryResult(transactionListPreviewQuery?.data);

  useEffect(() => {
    if (!q || !['all', 'address', 'transaction', 'customer', 'case'].includes(search_entity_type)) {
      navigate('/dashboard');
    }
  }, [navigate, q, search_entity_type]);

  return (
    <div className='flex h-full flex-col justify-between'>
      <div className='flex flex-col overflow-y-scroll'>
        <div className='mt-4 text-center text-gray-500'>
          Search Results for &quot;<span className='text-gray-400'>{q}</span>&quot;
        </div>
        {isLoading && (
          <div className='flex flex-col gap-4 p-6'>
            <Skeleton height={45} className='rounded-lg' />
            <Skeleton height={45} className='rounded-lg' />
          </div>
        )}
        {fullCoverageResults?.length > 0 && (
          <CustomDisclosure
            title={`Full Coverage - (${fullCoverageResults?.length})`}
            defaultOpen
            className='bg-blue-50'
            maxHeightClass='max-h-[50vh]'>
            {fullCoverageResults?.map((item, index) => {
              return (
                <SearchBadge
                  q={q}
                  type={item.item_type}
                  entity={item}
                  key={index}
                  setCurrentEntity={setCurrentEntity}
                />
              );
            })}
          </CustomDisclosure>
        )}
        {sanctionScreeningResults?.length > 0 && (
          <CustomDisclosure
            title={`Sanction Screening Only - (${sanctionScreeningResults?.length})`}
            className='bg-yellow-50'
            maxHeightClass='max-h-[25vh]'
            defaultOpen={fullCoverageResults?.length === 0}>
            {sanctionScreeningResults?.map((item, index) => (
              <SearchBadge
                q={q}
                type={item.item_type}
                entity={item}
                key={index}
                setCurrentEntity={setCurrentEntity}
              />
            ))}
          </CustomDisclosure>
        )}
        {!isLoading && combinedResult.length === 0 && (
          <div className='flex flex-col items-center'>
            <EmptyState />
            <Button onClick={() => navigate('/dashboard')} variant='primary'>
              Go Home
            </Button>
          </div>
        )}
        <TransactionPopover
          id={currentEntity?.id}
          currency={currentEntity?.currency}
          open={currentEntity?.type === 'transaction' && currentEntity?.open_type === 'preview'}
          setOpen={() => setCurrentEntity(null)}
        />
        <AddressPreview
          id={currentEntity?.id}
          currency={currentEntity?.currency}
          open={currentEntity?.type === 'address' && currentEntity?.open_type === 'preview'}
          setOpen={() => setCurrentEntity(null)}
        />
        <ListPreview
          open={currentEntity?.open_type === 'list_preview'}
          count={currentEntity?.type === 'address' ? addressesCount : transactionsCount}
          entitiesQuery={
            currentEntity?.type === 'address' ? addressListPreviewQuery : transactionListPreviewQuery
          }
          addresses={currentEntity?.type === 'address' && addresses}
          transactions={currentEntity?.type === 'transaction' && transactions}
          type={currentEntity?.type as 'address' | 'transaction'}
          onClose={() => {
            setCurrentEntity(null);
          }}
        />
      </div>
      {combinedResult.length > 0 && (
        <div className='bg-gray-600 py-1 text-center text-xs text-gray-50'>
          These are the most relevant results based on your search for &quot;{q}&quot;. Couldn&apos;t find
          what you need? Try expanding your search.
        </div>
      )}
    </div>
  );
};

export default SearchPage;
