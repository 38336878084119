import { FC, Fragment, ReactNode } from 'react';
import { DropdownOption } from '../../../../ui';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import classNames from 'classnames';

export interface SimpleDropdownProps {
  placeholder?: ReactNode;
  value?: DropdownOption;
  onChange: (value: DropdownOption) => void;
  disabled?: boolean | undefined;
  horizontal?: boolean | undefined;
  name?: string | undefined;
  multiple?: boolean | undefined;

  options: DropdownOption[];
}

const SimpleDropdown: FC<SimpleDropdownProps> = ({
  value,
  options,
  onChange,
  disabled,
  placeholder,
  ...rest
}) => {
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled} {...rest}>
      {({ open }) => (
        <>
          <div className='relative inline-flex'>
            <ListboxButton>
              {!value ? (
                <span className='block truncate'>{placeholder || 'Select...'}</span>
              ) : (
                <span className='block truncate'>{value?.label}</span>
              )}
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'></span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <ListboxOptions
                className={
                  'ring-1/5 absolute right-0 top-full z-40 mt-1 max-h-60 min-w-full list-none overflow-auto rounded-md bg-white p-0 py-1 text-sm font-medium leading-default shadow-lg ring-black focus:outline-none'
                }>
                {options?.map(
                  (option) =>
                    !option.hidden && (
                      <ListboxOption
                        key={option.value}
                        className={({ focus }) =>
                          classNames(
                            focus ? 'bg-blue-500 text-white' : 'text-gray-800',
                            'relative cursor-pointer select-none py-2 pr-4 text-xs leading-default'
                          )
                        }
                        value={option}>
                        {({ selected }) => (
                          <div className='flex items-center justify-between px-2 pr-0'>
                            <div className='flex items-center'>
                              {option?.iconStart}
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate px-2'
                                )}>
                                {option.label}
                              </span>
                            </div>
                            {option?.iconEnd}
                          </div>
                        )}
                      </ListboxOption>
                    )
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SimpleDropdown;
