import { CommentResult } from '../../api/dtos/comment';
import sortBy from 'lodash/sortBy';

export const convertCommentToHTML = (comment: CommentResult): string => {
  if (!comment.context) return comment.body;
  const body: string = comment.body;
  const bodyArray: string[] = [];

  const alertBadgeClasses =
    'whitespace-nowrap rounded-md border border-gray-300 p-1 text-sm leading-7 text-gray-900 shadow-inner mr-2';

  // Add attached alerts and alert items to bodyArray
  comment.context.alerts?.forEach((alert) => {
    bodyArray.push(`<span class="${alertBadgeClasses}">rule:${alert.rule_name}</span>`);
  });
  comment.context.alert_items?.forEach((item) => {
    bodyArray.push(`<span class="${alertBadgeClasses}">alert:${item.id}</span>`);
  });

  // Highlight tagged users in body
  const users = sortBy(comment.context.users, 'start_position');
  let lastIndex = 0;
  users.forEach((user) => {
    const { start_position: startIndex, length } = user;
    if (startIndex > lastIndex) {
      bodyArray.push(body.substring(lastIndex, startIndex));
    }
    bodyArray.push(`<span class="text-blue-600">${body.substring(startIndex, startIndex + length)}</span>`);
    lastIndex = startIndex + length;
  });
  if (lastIndex < body.length) {
    bodyArray.push(body.substring(lastIndex));
  }

  return bodyArray.join('');
};
