import * as React from 'react';

import { formatOnlyDate } from '../../../utils/helpers/date';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { BasicBadge, CaseStatusBadge, Checkbox, RiskBadge } from '../../../ui';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import { ICaseResponse } from '../../../../src/api/dtos/case';
import CopyToClipboardBtn from '../../../ui/components/Copy/Copy';

interface ICasesData {
  cases: ICaseResponse[];
  selectAllCases?: (force?: boolean) => void;
  selectedCases?: number[];
  selectCase?: (id: number) => void;
  type?: string;
}

export const useGetCaseData = ({
  cases,
  selectAllCases,
  selectedCases,
  selectCase,
  type = 'page',
}: ICasesData) => {
  const { state } = useAuth();
  const { navigate } = useRouter();
  return {
    headerData: [
      ...(type !== 'search'
        ? [
            <Checkbox
              key={0}
              className='w-0 rounded'
              onChange={() => selectAllCases()}
              checked={selectedCases.length === cases.length && cases.length !== 0}
            />,
          ]
        : []),
      'Case ID',
      'Case Name',
      'Risks Level',
      'Created At',
      'Last Updated At',
      'Assigned to',
      'Status',
      <div key={8} id='open-alerts' className='flex justify-center'>
        Open Alerts
      </div>,
    ],
    rowData: cases
      ? cases.map((caseItem) => {
          return {
            id: caseItem.id,
            data: [
              ...(type !== 'search'
                ? [
                    <Checkbox
                      key={caseItem.id}
                      className='w-0 rounded'
                      onChange={(e) => {
                        e.stopPropagation();
                        selectCase(caseItem.id);
                      }}
                      checked={selectedCases.includes(caseItem.id)}
                    />,
                    <div key={caseItem.id} className='flex'>
                      {caseItem.case_id}
                      <CopyToClipboardBtn text={caseItem.case_id} className='ml-1' />
                    </div>,
                  ]
                : [
                    <div key={caseItem.id} className='flex'>
                      {caseItem.case_id}
                    </div>,
                  ]),
              <IdentifierEllipsis
                key={caseItem.id}
                identifier={caseItem.name}
                limit={20}
                className='text-blue-500'
                clickable
                copyable
              />,
              <RiskBadge key={caseItem.id} risk={caseItem.risk_level} />,
              caseItem.created_at ? formatOnlyDate(caseItem.created_at, state.userProfile.timezone) : '',
              caseItem.updated_at ? formatOnlyDate(caseItem.updated_at, state.userProfile.timezone) : '',
              <div key={caseItem.id}>
                {caseItem.assigned_to_name ? (
                  caseItem.assigned_to_name
                ) : (
                  <BasicBadge className='bg-orange-50'>Unassigned</BasicBadge>
                )}
              </div>,
              <div key={caseItem.id} className='flex'>
                <CaseStatusBadge status={caseItem.status} />
              </div>,
              <div key={caseItem.id} id='open-alerts' className='flex justify-center'>
                {caseItem.open_alerts_count}
              </div>,
            ],
            onClick: () => {
              navigate(`/cases/${caseItem.id}`);
            },
          };
        })
      : [],
  };
};
