import React from 'react';
import { ArrowRight } from '@phosphor-icons/react';
import { ITransactionEntity, ITransactionResponse } from '../../../api/dtos/transaction';
import TransactionEntity from '../../Transaction/Overview/TransactionSummary/TransactionEntity';

interface TransactionEntityProps {
  input: ITransactionEntity;
  output: ITransactionEntity;
  transaction: ITransactionResponse;
}

const TransactionEntityReport: React.FC<TransactionEntityProps> = (props) => {
  const { input, output, transaction } = props;
  if (!transaction) return null;
  return (
    <div className='border-x border-b p-2'>
      <div className='grid grid-cols-12 text-3xs'>
        <div className='col-span-5'>
          {input && <TransactionEntity entity={input} transaction={transaction} isReport />}
        </div>
        <div className='col-span-2 mx-auto'>
          <ArrowRight size={32} weight='bold' color='#6B7280' />
        </div>
        <div className='col-span-5'>
          {output && <TransactionEntity entity={output} transaction={transaction} isReport />}
        </div>
      </div>
    </div>
  );
};

export default TransactionEntityReport;
