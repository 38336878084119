import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { useAuth } from '../../modules/auth';
import { useRouter } from '../../modules/router/RouterProvider';

import { getDigitalAssets } from '../../utils/helpers/currency';
import { useTransactionGetTransactionLite } from '../../api/transactionLite';

import Sidebar from '../ui/components/Sidebar';
import Tab from '../ui/components/Tab/Tab';
import DigitalAssets from '../ui/components/Currency/DigitalAssets';
import TransactionValue from '../Transaction/TransactionValue';
import TransactionSummary from '../Transaction/Overview/TransactionSummary/TransactionSummary';
import CounterpartySummary from './CounterpartySummary';
import InvestigationTree from './InvestigationTree/InvestigationTree';
import Card from '../ui/components/Card/Card';
import CompassLoader from '../ui/components/Loader/CompassLoader';
import IdentifierEllipsis from '../ui/components/Currency/IdentifierEllipsis';
import EmptyState from '../../ui/components/States/Empty';
import { Fee, TransactionTime } from '../Transaction/TransactionMetadata/TransactionUtils';
import { Button } from '../../ui';
import { useScreenApi } from '../../utils/helpers/apiHelpers';

interface ITransactionPopoverProps {
  id: string;
  currency: number;
  open: boolean;
  setOpen: (val: boolean) => void;
}

const TransactionPopover: FC<ITransactionPopoverProps> = ({ id, currency, open, setOpen }) => {
  const { state } = useAuth();
  const screenApi = useScreenApi();
  const { navigate, showErrorPage } = useRouter();
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    { label: 'Overview' },
    {
      label: 'Transfers',
    },
    {
      label: 'Counterparty Summary',
    },
    {
      label: 'Investigation Mode',
    },
  ];
  const { data: response, isLoading: detailLoading } = useTransactionGetTransactionLite(id, currency, {
    onError: (error: AxiosError) => {
      if (error.response.status === 404) {
        showErrorPage && showErrorPage({ status: 404 });
      }
    },
    enabled: open,
  });
  const transaction = response?.data;
  const metaDetails =
    !detailLoading && transaction && open
      ? [
          {
            label: 'Digital Asset',
            value: <DigitalAssets digitalAssets={getDigitalAssets(transaction)} noBadge />,
          },
          {
            label: 'Transaction Time',
            value: <TransactionTime transaction={transaction} state={state} />,
          },
          {
            label: 'Value',
            value: <TransactionValue transaction={transaction} />,
          },
          {
            label: 'Fee',
            value: <Fee transaction={transaction} />,
          },
          {
            label: 'Customer ID',
            value: (
              <div
                className='cursor-pointer break-all text-blue-600 underline'
                onClick={() => navigate(`/customers/${transaction?.customer_id}`)}>
                {transaction?.customer_id}
              </div>
              // TODO: Use react-router Link
            ),
            isHidden: !transaction?.customer_id,
          },
          {
            label: 'Type',
            value: transaction?.type === 1 ? 'Deposit' : 'Withdrawal',
            isHidden: !transaction?.type,
          },
        ]
      : [];

  useEffect(() => {
    if (state.screeningLoader) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.screeningLoader]);

  const handleScreen = () => {
    screenApi({
      identifier: transaction.identifier,
      currency: transaction.currency,
      entityType: 'transactions',
    });
  };

  const TabChildrenWrapper = ({ children, wrapperClass = '', showIdentifier = false }) => {
    return (
      <div className={classNames('h-full bg-gray-50', wrapperClass)}>
        {showIdentifier && (
          <div className='flex flex-col pl-2'>
            <div className='flex items-center gap-1'>
              <p className='text-sm text-gray-600'>Transaction Hash: </p>
              <IdentifierEllipsis
                identifier={transaction?.identifier}
                limit={75}
                className='py-2 text-sm text-gray-800'
                copyable
              />
            </div>
            <div className='flex items-center gap-1'>
              <p className='mb-2 text-sm text-gray-600'>Digital Asset: </p>
              <DigitalAssets digitalAssets={getDigitalAssets(transaction)} noBadge />
            </div>
          </div>
        )}
        {children}
      </div>
    );
  };

  return (
    <Sidebar
      title='Transaction Preview'
      isOpen={open}
      onClose={() => setOpen(false)}
      titleClass='!py-2'
      childrenClass='!my-2 !py-0'
      footer={
        !detailLoading &&
        transaction &&
        selectedTab === 0 && (
          <div className='flex flex-col bg-gray-100 p-4 text-sm'>
            <span className='text-xs text-gray-600'>
              Note: Basic screening for risk can flag false positive alerts
              <br />
              We recommend Advanced Screening (Workflow). Please refer to{' '}
              <a href='/docs/guide' rel='noreferrer' target='_blank' className='text-blue-600 underline'>
                Product Guide
              </a>
            </span>
            <Button
              type='submit'
              variant='primary'
              className='mt-5 w-full text-center'
              onClick={() => handleScreen()}>
              <span className='w-full text-center text-sm'>Screen Transaction (Basic)</span>
            </Button>
          </div>
        )
      }
      maxWidthClass='max-w-5xl'>
      {detailLoading ? (
        <CompassLoader />
      ) : transaction ? (
        <Tab
          values={tabs}
          type='secondary'
          onChange={setSelectedTab}
          listClassName={classNames('border-b border-gray-200 pb-2 sticky z-30 top-0 !mt-0')}
          panelStyle={{ height: selectedTab === 0 ? 'calc(100vh - 230px)' : 'calc(100vh - 110px)' }}
          panelClassName='pt-3'>
          <TabChildrenWrapper>
            <div className='justify-between gap-2 bg-gray-50 px-4 py-2'>
              <div className='flex flex-col justify-between'>
                <IdentifierEllipsis
                  identifier={transaction?.identifier}
                  limit={16}
                  className='text-xl text-gray-800'
                  copyable
                />
                <div className='flex text-wrap break-all text-sm text-gray-500'>
                  {transaction?.identifier}
                </div>
              </div>
              <hr className='mt-4' />
              {metaDetails?.map((metaDetail, i) =>
                metaDetail.isHidden ? null : (
                  <div key={i} className='my-5 grid grid-cols-3'>
                    <span className='my-auto text-2xs uppercase text-gray-500'>{metaDetail.label}</span>
                    <span className='col-span-2 my-auto text-xs font-semibold'>{metaDetail.value}</span>
                  </div>
                )
              )}
            </div>
          </TabChildrenWrapper>
          <TabChildrenWrapper showIdentifier>
            <TransactionSummary transaction={transaction} popover={true} />
          </TabChildrenWrapper>
          <TabChildrenWrapper showIdentifier>
            <CounterpartySummary
              transaction={transaction}
              type='transaction'
              popover={true}
              showModes={false}
              maxHeightClass='max-h-[450px]'
            />
          </TabChildrenWrapper>
          <TabChildrenWrapper showIdentifier>
            <Card title='INVESTIGATION MODE' containerClassName='border border-gray-200'>
              <InvestigationTree
                originType='transaction'
                identifier={transaction?.identifier}
                transaction={transaction}
                popover={true}
              />
            </Card>
          </TabChildrenWrapper>
        </Tab>
      ) : (
        <EmptyState />
      )}
    </Sidebar>
  );
};

export default TransactionPopover;
