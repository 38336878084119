import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { Api } from '../api';
import {
  IMonitoringAddAddressRequest,
  IMonitoringAddressesRequest,
  IMonitoringAddressesResponse,
  IMonitoringArchiveAddressRequest,
} from '../dtos/settings/monitoringAddresses';
import { getNextPageParam } from '../../utils/helpers/helperFunctions';

export class MonitoringAddressesApi extends Api {
  readonly baseUrl = '/risk/api/v4/custom-monitored-addresses/';

  readonly getMonitoredAddresses = (params: IMonitoringAddressesRequest) => {
    return this.http.get<IMonitoringAddressesResponse>(this.route(''), { params });
  };

  readonly addMonitoredAddress = (params: IMonitoringAddAddressRequest) => {
    return this.http.post(this.route(''), params);
  };

  readonly archiveMonitoredAddress = (params: IMonitoringArchiveAddressRequest) => {
    return this.http.post(this.route('archive/'), params);
  };
}

export class TokenMonitoringAddressesApi extends Api {
  readonly baseUrl = '/risk/api/v4/token-monitoring-addresses/';

  readonly getMonitoredAddresses = (params: IMonitoringAddressesRequest) => {
    return this.http.get<IMonitoringAddressesResponse>(this.route(''), {
      params,
    });
  };

  readonly addMonitoredAddress = (params: IMonitoringAddAddressRequest) => {
    return this.http.post(this.route(''), params);
  };

  readonly archiveMonitoredAddress = (params: IMonitoringArchiveAddressRequest) => {
    return this.http.post(this.route('archive/'), params);
  };
}

export const monitoringAddressesApi = new MonitoringAddressesApi();
export const tokenMonitoringAddressesApi = new TokenMonitoringAddressesApi();

export const useMonitoredAddressesGet = (
  params: IMonitoringAddressesRequest,
  options?: UseInfiniteQueryOptions
) => {
  const key = ['MonitoringAddressesApi.getMonitoredAddresses', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => monitoringAddressesApi.getMonitoredAddresses({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return {
    key,
    ...result,
  };
};

export const useTokenMonitoredAddressesGet = (
  params: IMonitoringAddressesRequest,
  options?: UseInfiniteQueryOptions
) => {
  const key = ['TokenMonitoringAddressesApi.getMonitoredAddresses', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => tokenMonitoringAddressesApi.getMonitoredAddresses({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return {
    key,
    ...result,
  };
};
