import { ReactNode } from 'react';
import { FC } from 'react';

interface TabHeaderProps {
  heading: ReactNode;
  subheading?: ReactNode;
}

const TabHeader: FC<TabHeaderProps> = ({ heading, subheading }) => {
  return (
    <>
      <h3 className='text-gray-8 mb-2 text-lg font-medium'>{heading}</h3>
      {subheading && <p className='font-regular mb-2 text-sm text-gray-500'>{subheading}</p>}
    </>
  );
};

export default TabHeader;
