import { useMutation, useQueryClient } from 'react-query';
import { Info, PlusCircle } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import * as React from 'react';

import { useAuth } from '../../../../modules/auth';

import { formatDateTime } from '../../../../utils/helpers/date';
import { onError } from '../../../../utils/helpers/apiHelpers';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../../utils/helpers/react-query.helper';

import { customTagsApi, useCustomListGetCustomList } from '../../../../api/settings/customTags';

import EditCustomList from '../../../../components/Settings/CustomLists/EditCustomList';
import RiskBadge from '../../../../ui/components/Badges/RiskBadge/RiskBadge';
import { TabHeader } from '../../../../components/ui/components/Header';
import { Search } from '../../../../components/ui/components/Search';
import Popover from '../../../../ui/components/Popover/Popover';
import Table from '../../../../ui/components/Table/Table';
import { Button } from '../../../../ui/components/Button';
import { Toggle } from '../../../../ui';

interface CustomListProps {}

const CustomLists: React.FC<CustomListProps> = () => {
  const { state } = useAuth();
  const queryClient = useQueryClient();
  const [search, setSearch] = React.useState('');
  const [pageKey, setPageKey] = React.useState('home');
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const customListQuery = useCustomListGetCustomList(
    { limit: 20, offset: 0, is_in_custom_list: true, q: search.length > 2 ? search : '' },
    { onError }
  );
  const [, customListData] = flattenInfiniteQueryResult(customListQuery?.data);
  const { mutate: toggleCustomList, isLoading: isLoadingToggleList } = useMutation(
    customTagsApi.toggleCustomTag,
    {
      onSuccess: () => {
        toast.success('Custom List has been updated');

        queryClient.invalidateQueries(['customTagsApi.getCustomLists']);
      },
      onError,
    }
  );

  const onToggleList = (id: number, isActive: boolean) => {
    toggleCustomList({ id, isActive, is_in_custom_list: true });
  };

  const headerData = [
    'List Name',
    'Description',
    'Added By',
    'Date Created',
    'Date Last Updated',
    'Risk Level',
    'Status',
    'Action',
  ];
  const rows = customListData?.map((row, i) => {
    return {
      id: row.name,
      data: [
        row.name,
        row.description,
        row.added_by,
        formatDateTime(row.created_at, state.userProfile.timezone),
        formatDateTime(row.updated_at, state.userProfile.timezone),
        <RiskBadge key={row.name} risk={row.risk_level} />,
        ![1, 2].includes(row.type) ? (
          <Toggle
            noPadding
            key={row.id}
            enabled={row.is_active}
            setEnabled={() => {
              onToggleList(row.id, !row.is_active);
            }}
            labelText={''}
          />
        ) : (
          ''
        ),
        <Button
          key={row.name}
          variant='tertiary'
          buttonSize='sm'
          className='!px-2 !py-1'
          onClick={() => {
            setSelectedIndex(i);
            setPageKey('edit');
          }}>
          Edit
        </Button>,
      ],
    };
  });

  if (pageKey !== 'home') {
    return (
      <EditCustomList
        customListData={pageKey === 'edit' ? customListData[selectedIndex] : undefined}
        isEdit={pageKey === 'edit'}
        setPageKey={setPageKey}
      />
    );
  }

  return (
    <div>
      <TabHeader
        heading={
          <div className='flex items-center'>
            Custom Lists
            <Popover
              referenceContent={<Info size={20} className='ml-2 flex text-gray-500' />}
              popoverContent='Custom lists can be used to assign a specific risk levels to addresses. If an address is added to a list, its risk level and the risk level of addresses with direct exposure to it will be assigned the risk level of the list.'
              isBasic
              className='w-80 text-sm'
              placement='bottom'
            />
          </div>
        }
      />
      <Table
        className={`${isLoadingToggleList ? 'pointer-events-none' : ''}`}
        headerData={headerData}
        rows={rows}
        isLoading={customListQuery.isLoading}
        headerWidths={[15, 20, 15, 12, 12, 10, 5, 5]}
        height={500}
        border
        headerActions={
          <Button iconStart={<PlusCircle size={20} />} onClick={() => setPageKey('create')}>
            Create Custom List
          </Button>
        }
        headerActionsLeft={
          <Search
            className='ml-3'
            minChars={3}
            value={search}
            setValue={setSearch}
            placeholder='Search for Custom Lists'
            autoComplete='off'
          />
        }
        {...buildInfiniteQueryTableProps(customListQuery)}
      />
    </div>
  );
};

export default CustomLists;
