import BasicBadge from '../../../../ui/components/Badges/BasicBadge/BasicBadge';
import { Currencies } from '../../../../utils/constants/constants';
import CurrencyBadge from '../Badge/CurrencyBadge';
import SingleSelect, { IOption } from './SingleSelect';

interface CurrencyDropdownProps {
  currency: IOption;
  setCurrency: (currency: IOption) => void;
  addAllChainsOption?: boolean;
}

const CurrencyDropdown: React.FC<CurrencyDropdownProps> = ({
  currency,
  setCurrency,
  addAllChainsOption = false,
}) => {
  const currencyCustomLabel = (option: IOption) => {
    if (option.label === 'All chains') {
      return <BasicBadge className='px-1 text-xs font-medium'>All chains</BasicBadge>;
    }
    return <CurrencyBadge currency={Number(option.value)} label={option.label} className='text-gray-900' />;
  };
  const options = Currencies.map((c) => ({
    label: c.currency_short,
    value: c.value.toString(),
  }));
  if (addAllChainsOption) {
    options.unshift({
      label: 'All chains',
      value: '-1',
    });
  }
  return (
    <SingleSelect
      options={options}
      value={currency || options[0]}
      handleChange={setCurrency}
      getCustomLabel={currencyCustomLabel}
      getCustomOptionLabel={currencyCustomLabel}
      className='!mt-0 w-[120px]'
      isCloseHidden
      isSearchable
    />
  );
};

export default CurrencyDropdown;
