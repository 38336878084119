import * as React from 'react';

import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { caseApi } from '../../../api/case';

import { BasicModal, Button } from '../../../ui';

import { ICaseBulkActionFilters } from '../../../views/dashboard/cases/CaseList';

import { toast } from '../../../ui/components/Toast';

interface Props {
  isOpen: boolean;
  onClose: () => void;

  selectedCases: number[];
  isAllCaseSelected: boolean;

  bulkActionFilter: ICaseBulkActionFilters;

  refetchList: () => void;
}

const DeleteCasesModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedCases,
  isAllCaseSelected,
  bulkActionFilter,
  refetchList,
}) => {
  const deleteSelectedCases = async () => {
    try {
      const res = await caseApi.deleteCases(isAllCaseSelected ? [] : selectedCases, bulkActionFilter);

      if (res.status === 204) {
        toast.success('Cases Deleted Successfully');
      }
    } catch (error) {
      if (getErrorMessage(error)?.length) {
        getErrorMessage(error).forEach((err) => toast.error(err));
      } else {
        toast.error(getErrorMessage(error));
      }
    }

    refetchList();
    onClose();
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Delete Cases'
      modalBody={
        <div className='z-[100000]'>
          <p className='text-sm'>Are you sure you want to delete {selectedCases.length} selected cases?</p>
          <div className='mt-4 flex justify-end gap-2'>
            <Button variant='tertiary' onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteSelectedCases();
              }}>
              Yes
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default DeleteCasesModal;
