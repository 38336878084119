import { FC } from 'react';
import MetaData from '../../../layouts/MetaData';
import { IAddressResponse } from '../../../api/dtos/address';
import { useAuth } from '../../../modules/auth';
import { getDigitalAssets, isLiteCurrency } from '../../../utils/helpers/currency';
import { useWorkspace } from '../../../utils/helpers/common';
import { RiskBadge } from '../../../ui';
import DigitalAssets from '../../ui/components/Currency/DigitalAssets';
import AddToCustomer from '../../AddressTransaction/AddToCustomer';
import EntityWithSubtypeBadge from '../../ui/components/EntityTypeBadge';
// import { useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../ui/components/BreadCrumb';
import { useRouter } from '../../../modules/router/RouterProvider';
import AddressCustomTags from './AddressCustomTags';
import AddressMetadataHeader from './AddressMetadataHeader';
import { MonitoringLabel, MonitoringValue } from './AddressMonitoring';
import AddressBalanceWrapper from './AddressBalanceWrapper';
import { formatDateTimeHour } from '../../../utils/helpers/date';
interface AddressDetailsMetadataProps {
  address: IAddressResponse;
  balance: { balance: number; balance_usd: number };
  attribution_timestamp: string;
  alerts?: number;
}

const AddressDetailsMetadata: FC<AddressDetailsMetadataProps> = (props) => {
  const workspace = useWorkspace();

  const { address, balance, attribution_timestamp, alerts } = props;
  const { state } = useAuth();

  // const [searchParams] = useSearchParams();
  // const caseId = searchParams.get('caseId');
  // const entityId = searchParams.get('entityId');
  // const refCase = searchParams.get('refCase');
  // TODO: Use from library directly

  const { navigate, getQueryParams } = useRouter();

  const { caseId, entityId, refCase } = getQueryParams();

  const isCaseReferenced = caseId && refCase && entityId;

  const metaDetails = [
    { label: 'Risk Level', value: <RiskBadge risk={address.risk_level} /> },
    { label: 'Open Alerts', value: alerts || 0 },
    {
      label: <MonitoringLabel />,
      value: <MonitoringValue address={address} />,
    },
    {
      label: 'Digital Asset',
      value: <DigitalAssets digitalAssets={getDigitalAssets(address)} noBadge />,
    },
    ...(!isLiteCurrency(address.currency)
      ? [
          {
            label: 'Balance',
            value: (
              <AddressBalanceWrapper
                balance={balance.balance}
                balance_usd={balance.balance_usd}
                currency_short={address.currency_short}
              />
            ),
          },
        ]
      : []),
    {
      label: 'Owner',
      value: address.tag_name_verbose_owner,
      isHidden: !address.tag_name_verbose_owner,
    },
    {
      label: 'Owner Type',
      value: (
        <EntityWithSubtypeBadge
          type={address.tag_type_verbose_owner}
          subtype={address.tag_subtype_verbose_owner}
        />
      ),
      isHidden: !address.tag_type_verbose_owner,
    },
    {
      label: 'User',
      value: (
        <div title={address.tag_name_verbose} className='truncate'>
          {address.tag_name_verbose}
        </div>
      ),
      isHidden: !address.tag_name_verbose,
    },
    {
      label: 'User Type',
      value: <EntityWithSubtypeBadge type={address.tag_type_verbose} subtype={address.tag_subtype_verbose} />,
      isHidden: !address.tag_name_verbose,
    },
    {
      label: 'Customer ID',
      value: (
        <div
          className='cursor-pointer break-all text-blue-600 underline'
          onClick={() => navigate(`/customers/${address.customer_id}`)}>
          {address.customer_id}
        </div>
        // TODO: Use react-router Link
      ),
      isHidden: !address.customer_id,
    },
    {
      label: 'Type',
      value: address.type_verbose,
      isHidden: !address.type_verbose,
    },
    {
      label: 'Tags',
      value: <AddressCustomTags address={address} />,
      isHidden: !state.userProfile.is_custom_tags_enabled,
    },
    {
      label: 'Attribution Timestamp',
      value: `${formatDateTimeHour(attribution_timestamp)} (GMT+0)`,
      isHidden: !attribution_timestamp,
    },
  ];

  return (
    <MetaData
      title='ADDRESS'
      preHeader={
        isCaseReferenced && (
          <Breadcrumb
            items={[{ label: caseId, to: `/cases/${refCase}` }, { label: address.identifier }]}
            className='mb-2'
          />
        )
      }
      header={<AddressMetadataHeader address={address} />}
      metaDetails={metaDetails}
      footer={
        !address.customer_id &&
        !workspace.is_address_screening_type_any_enabled &&
        !state.userProfile?.skip_alert_creation_modes?.includes(1) && (
          <AddToCustomer type='address' identifier={address.identifier} currency={address.currency} />
        )
      }
    />
  );
};

export default AddressDetailsMetadata;
