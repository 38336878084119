import { FC } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { customerApi } from '../../../api/customer';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BasicModal, Button } from '../../../ui';
import InputController from '../../../ui/components/Form/InputController';
import { useRouter } from '../../../modules/router/RouterProvider';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { AxiosError } from 'axios';

interface EditCustomerModalProps {
  customer_id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EditCustomerModal: FC<EditCustomerModalProps> = ({ customer_id, open, setOpen }) => {
  const { navigate } = useRouter();

  const { mutate: editCustomer } = useMutation(customerApi.editCustomerId, {
    onSuccess: (data) => {
      toast.success('Customer ID edited successfully.');
      navigate(`/customers/${data?.data?.customer_id}`);
      setOpen(false);
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });

  const validations = Yup.object({
    customer_id: Yup.string().label('Customer ID').required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      customer_id,
    },
  });

  const onClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    handleSubmit((data: { customer_id: string }) => {
      editCustomer({ customer_id, new_customer_id: data.customer_id });
    })();
  };

  const newCustomerId = useWatch({ control, name: 'customer_id' });

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      modalTitle={`Edit Customer ID`}
      modalBody={
        <InputController
          control={control}
          name='customer_id'
          label='Customer ID'
          error={errors.customer_id?.message}
          id={'rename-customer'}
          type={'text'}
        />
      }
      modalFooter={
        <div className='flex flex-row justify-end gap-4'>
          <Button variant='tertiary' onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={onConfirm}
            disabled={!newCustomerId || newCustomerId === customer_id}>
            Edit Customer ID
          </Button>
        </div>
      }
    />
  );
};

export default EditCustomerModal;
