import React, { FC, Fragment, ReactNode } from 'react';
import { Funnel } from '@phosphor-icons/react';
import { Button } from '@ui/index';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import classNames from 'classnames';

// import styles from '../styles.module.scss';
// TODO: use react-hook-form, use different filter components for different modules https://github.com/merklescience/compass-frontend/pull/5/files/c4034fcced218eaed074a508b346083642e0aed6#r1024791915
interface FilterBtnProps {
  disabled?: boolean;
}
interface FilterPanelProps {
  onReset?: () => void;
  onApply: (any) => void;
  disabled?: boolean;
  children?: ReactNode;
  panelClass?: string;
  tab?: number;
}

const FilterBtn: FC<FilterBtnProps> = ({ disabled }) => {
  return (
    <Button
      className='py-2.5'
      variant='tertiary'
      iconStart={<Funnel size={16} />}
      disabled={disabled}></Button>
  );
};

const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { onReset, onApply, disabled, children, panelClass, tab } = props;

  return (
    <>
      <Popover className='relative mx-1'>
        {({ open }) => (
          <>
            <PopoverButton as='div' className={`${open ? '' : 'text-opacity-90'}`}>
              <FilterBtn disabled={disabled} />
            </PopoverButton>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'>
              <PopoverPanel
                className={classNames(
                  'lg:max-w-l absolute right-1 z-30 mt-3 w-screen max-w-md px-4 pb-3 sm:px-0',
                  panelClass
                )}>
                <div className='rounded bg-white shadow-xl'>
                  <div className='relative bg-white lg:grid-cols-2'>
                    <div className='flex size-full flex-col items-start justify-between gap-3'>
                      <div className='flex w-full flex-col'>
                        <div className='relative h-auto rounded-lg bg-white'>
                          <div className='w-full items-center bg-gray-50 px-4 py-3 pt-[0.70rem] text-base font-medium text-gray-500'>
                            Filter
                          </div>
                          <div className='px-4'>
                            {React.Children.map(
                              children,
                              (child, i) =>
                                child && (
                                  <div className={`${tab === 2 && i === 2 ? '' : 'pb-4'}`}>{child}</div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='bottom-0 flex w-full flex-row items-center justify-between gap-2 px-4 py-2 font-medium'>
                        <PopoverButton
                          as={Button}
                          // type='tertiary'
                          variant='tertiary'
                          className='px-[11px] py-[7px] text-sm leading-4'
                          onClick={onReset}>
                          Reset Filters
                        </PopoverButton>
                        <PopoverButton
                          as={Button}
                          // type='primary'
                          className='px-[11px] py-[7px] text-sm leading-4'
                          // disabled={minMaxError || false}
                          onClick={onApply}>
                          Apply Filters
                        </PopoverButton>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default FilterPanel;
