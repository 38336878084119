import { useRouter } from '../../../modules/router/RouterProvider';
import { Button, RiskBadge } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import CustomerAddress from '../../ui/components/Table/CustomerAddress';
import { Plus } from '@phosphor-icons/react';
import CaseAddEntitiesModal from '../CaseAddEntitiesModal';
import { useState } from 'react';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import { ICaseAddressResponse, ICaseResponse } from '../../../api/dtos/case';
import DeleteCaseEntity from './DeleteCaseEntity';
import { AxiosResponse } from 'axios';
import { UseInfiniteQueryResult } from 'react-query';

interface EntityTableProps {
  caseData: ICaseResponse;
  addressesQuery?: UseInfiniteQueryResult<AxiosResponse<ICaseAddressResponse>>;
}

const AddressesTable: React.FC<EntityTableProps> = ({ caseData, addressesQuery }) => {
  const { navigate } = useRouter();

  const screenApi = useScreenApi();

  const [modal, setModal] = useState(false);

  const [count, addresses] = flattenInfiniteQueryResult(addressesQuery?.data);

  if (!addresses?.length && !addressesQuery.isFetching) return null;

  const headerData = ['Risk', 'Currency', 'Customer ID', 'Address', 'Alerts', 'Balance', 'Added By', ''];

  const rows = addresses?.map((address) => {
    return {
      id: address.id,
      data: [
        <RiskBadge key={address.id} risk={address.risk_level} />,
        <CurrencyBadge transparent key={address.id} currency={address.currency} />,
        <CustomerAddress key={address.id} customer_id={address.customer_id} type={address.type} />,
        <IdentifierEllipsis key={address.id} identifier={address.identifier} limit={6} copyable />,
        address.alerts,
        <CurrencyValue
          key={address.id}
          value={address.balance}
          currency={address.currency}
          dualTone
          showIcon
        />,
        address.added_by_name,
        <DeleteCaseEntity
          key={address.id}
          type='address'
          caseId={caseData.id}
          entityId={address.id}
          entityLabel={address.identifier}
        />,
      ],
      onClick: async () => {
        const result = await screenApi(
          {
            identifier: address.identifier,
            currency: address.currency,
            customer_id: address.customer_id,
            type: address.type,
            entityType: 'addresses',
          },
          false
        );
        navigate(`/addresses/${result.id}`, {
          caseId: caseData.case_id.toString(),
          entityId: address.id.toString(),
          refCase: caseData.id.toString(),
        });
      },
    };
  });

  return (
    <div className='mb-5'>
      <Table
        title='Addresses'
        headerActions={
          <Button variant='tertiary' onClick={() => setModal(true)} iconStart={<Plus size={16} />}>
            Add
          </Button>
        }
        count={count}
        headerData={headerData}
        rows={rows}
        border
        height={200}
        {...buildInfiniteQueryTableProps(addressesQuery)}
      />
      <CaseAddEntitiesModal
        caseId={caseData.id}
        type='address'
        isOpen={modal}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

export default AddressesTable;
