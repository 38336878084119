import { FC, useRef } from 'react';
import { ILevels } from '../../api/dtos/dashboard';
import DashboardWidget from './DashboardWidget';
import { GoogleCharts } from 'google-charts';
import { RiskLevel } from '../../utils/constants/constants';
import { createTooltip } from './WidgetGraphTooltip';

interface IRiskLevelWidgetProps {
  risksData: ILevels;
  isLoading?: boolean;
  isPdf?: boolean;
}

const RiskLevelWidget: FC<IRiskLevelWidgetProps> = ({ risksData = {}, isLoading, isPdf }) => {
  const risksDataArray: [key: string, value: number][] = Object.entries(risksData).reverse();
  const totalCount = risksDataArray.reduce((sum, row) => sum + row[1], 0);
  const percentagesData = risksDataArray.map(([key, value]) => {
    return [Number(key), Math.round((value / totalCount) * 100 * 1e2) / 1e4];
  });

  const riskLevelDistributionChart = () => {
    const data = [];

    if (risksData) {
      percentagesData.forEach((p, i) => {
        const risk = RiskLevel.find((r) => r.value === p[0]);
        data.push([
          risk?.label,
          p[1],
          risk?.color,
          `${(p[1] * 100).toFixed(2)}%`,
          createTooltip(risk.label, risksDataArray[i][1], totalCount, risk.color),
        ]);
      });
    }

    const headers: (string | { type?: string; role: string; p?: { html: boolean } })[] = [
      'Risk Type',
      'Percentage',
      { role: 'style' },
      { role: 'annotation' },
      { type: 'string', role: 'tooltip', p: { html: true } },
    ];

    return [headers, ...data];
  };

  const options = {
    fontName:
      'Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    titleTextStyle: {
      bold: false,
      color: '#111827',
      fontSize: 16,
    },
    annotations: {
      textStyle: {
        fontSize: 12,
        color: '#fff',
      },
    },
    hAxis: {
      title: '',
      titleTextStyle: { color: '#6B7280' },
      textStyle: {
        color: '#6B7280',
        fontSize: 12,
      },
      gridlines: { color: '#E5E7EB', count: 5 },
    },
    vAxis: {
      gridlines: { color: '#E5E7EB' },
      format: 'percent',
    },
    legend: { position: 'none' },
    chartArea: {
      width: '78%',
      top: '10%',
      height: '80%',
    },
    tooltip: { isHtml: true },
    width: isPdf ? 450 : null,
  };
  const chartRef = useRef(null);
  const noData = !(risksDataArray?.length > 0);

  if (!noData) GoogleCharts.load(drawChart);
  function drawChart() {
    const data = GoogleCharts.api.visualization.arrayToDataTable(riskLevelDistributionChart());
    if (chartRef?.current) {
      const chart = new GoogleCharts.api.visualization.ColumnChart(chartRef.current);
      chart.draw(data, options);
    }
  }

  return (
    <DashboardWidget
      title='Risk Level of Alerts'
      subtitle='In selected period'
      className='pb-0'
      containerClassName={!isPdf ? 'col-span-4' : 'col-span-6'}
      isLoading={isLoading}
      noData={noData}
      tooltipTitle='Risk Level Of Alerts'
      tooltipDescription='Provides a breakdown of the risk level of alerts i.e., Critical, High, Medium, or Caution in the selected period of time.'>
      <div ref={chartRef} />
    </DashboardWidget>
  );
};

export default RiskLevelWidget;
