import { Info } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FC } from 'react';

interface ITooltipInfoBodyProps {
  title: string;
  description: string;
  className?: string;
}

const TooltipInfoBody: FC<ITooltipInfoBodyProps> = ({ title, description, className }) => {
  return (
    <div>
      <div className={classNames('mx-2 w-[350px] rounded-lg bg-white p-4 shadow-md', className)}>
        <div className='flex'>
          <div className='mb-auto mr-2 rounded-full bg-blue-300 p-1'>
            <Info size={20} className='my-auto rounded-full bg-blue-300 text-blue-600' />
          </div>
          <div className='text-sm'>
            <p className='font-bold text-gray-800'>{title}</p>
            <p className='text-xs text-gray-700'>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TooltipInfoBody;
