import { Trash } from '@phosphor-icons/react';
import { ConfirmationModal } from '../../../ui';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { caseApi } from '../../../api/case';

interface DeleteCaseEntityProps {
  type: 'customer' | 'address' | 'transaction';
  caseId: number;
  entityId: number;
  entityLabel: string;
}

const DeleteCaseEntity: React.FC<DeleteCaseEntityProps> = (props) => {
  const { type, caseId, entityId, entityLabel } = props;

  const queryClient = useQueryClient();

  const { mutate } = useMutation(caseApi.archiveEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['caseApi', caseId]);
    },
  });

  const [modal, setModal] = useState(false);

  const deleteEntity = async () => {
    mutate({ caseId, entityId, type });
    setModal(false);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <Trash className='cursor-pointer hover:text-red-400' size={16} onClick={() => setModal(true)} />
      <div>
        <ConfirmationModal
          title={`Delete Entities`}
          confirmButton='Confirm'
          onConfirm={deleteEntity}
          onCancel={() => setModal(false)}
          open={modal}
          data={modal || null}
          body={
            <div>
              <p>
                This action is irreversible and will remove {type} <strong>{entityLabel}</strong> from case.
              </p>
              <p>Are you sure you want to proceed?</p>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default DeleteCaseEntity;
