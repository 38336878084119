import { FC } from 'react';
import { Card } from '../../../../ui/components/Card';

interface IEmptyAlertDetailSectionProps {
  title: string;
}

const EmptyAlertDetailSection: FC<IEmptyAlertDetailSectionProps> = ({ title }) => {
  return (
    <Card
      title={title.toUpperCase()}
      containerClassName='border border-gray-200'
      className='text-sm text-gray-500'>
      <div className='flex h-60 items-center justify-center'>No relevant information available</div>
    </Card>
  );
};

export default EmptyAlertDetailSection;
