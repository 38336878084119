export const entityOptions = [
  { value: 'Law Enforcement', label: 'Law Enforcement', selected: false },
  { value: 'Donation', label: 'Donation', selected: false },
  { value: 'Defi', label: 'Defi', selected: false },
  {
    value: 'Smart Contract Platform',
    label: 'Smart Contract Platform',
    selected: false,
  },
  { value: 'Service', label: 'Service', selected: false },
  { value: 'Exchange', label: 'Exchange', selected: false },
  { value: 'Coin Mixer', label: 'Coin Mixer', selected: false },
  { value: 'Gambling', label: 'Gambling', selected: false },
  { value: 'Mining Pool', label: 'Mining Pool', selected: false },
  { value: 'Sanctions', label: 'Sanctions', selected: false },
  { value: 'Darknet', label: 'Darknet', selected: false },
  { value: 'Scam', label: 'Scam', selected: false },
  { value: 'Extortion', label: 'Extortion', selected: false },
  {
    value: 'High Risk Organization',
    label: 'High Risk Organization',
    selected: false,
  },
  { value: 'Malware', label: 'Malware', selected: false },
  { value: 'Theft', label: 'Theft', selected: false },
  { value: 'Others', label: 'Others', selected: false },
];

export const sentiments = [
  {
    value: -1,
    content: 'Negative',
    bgColor: 'bg-red-100',
    dotColor: 'bg-red-500',
    txtColor: 'text-red-800',
  },
  {
    value: 0,
    content: 'Neutral',
    bgColor: 'bg-indigo-100',
    dotColor: 'bg-indigo-500',
    txtColor: 'text-indigo-800',
  },
  {
    value: 1,
    content: 'Positive',
    bgColor: 'bg-green-100',
    dotColor: 'bg-green-500',
    txtColor: 'text-green-800',
  },
  {
    value: 2,
    content: 'Mixed',
    bgColor: 'bg-amber-100',
    dotColor: 'bg-amber-500',
    txtColor: 'text-amber-800',
  },
  {
    value: 3,
    content: 'Not Available',
    bgColor: 'bg-gray-100',
    dotColor: 'bg-gray-500',
    txtColor: 'text-gray-800',
  },
];

export type Role = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type RoleVerbose =
  | 'Admin'
  | 'Agent'
  | 'Service Account'
  | 'L1 Agent'
  | 'L2 Agent'
  | 'L3 Agent'
  | 'L4 Agent';

export const Roles: { label: RoleVerbose; value: Role; className: string; border: string }[] = [
  {
    value: 1,
    label: 'Admin',
    className: 'text-blue-800 bg-blue-100',
    border: 'bg-blue-500',
  },
  {
    value: 2,
    label: 'Agent',
    className: 'text-yellow-800 bg-yellow-100',
    border: 'bg-yellow-500',
  },
  {
    value: 3,
    label: 'Service Account',
    className: 'text-pink-800 bg-pink-100',
    border: 'bg-pink-500',
  },
  {
    value: 4,
    label: 'L1 Agent',
    className: 'text-indigo-800 bg-indigo-100',
    border: 'bg-indigo-500',
  },
  {
    value: 5,
    label: 'L2 Agent',
    className: 'text-[#075985] bg-[#E0F2FE]',
    border: 'bg-[#075985]',
  },
  {
    value: 6,
    label: 'L3 Agent',
    className: 'text-teal-800 bg-teal-100',
    border: 'bg-teal-500',
  },
  {
    value: 7,
    label: 'L4 Agent',
    className: 'text-orange-800 bg-orange-100',
    border: 'bg-orange-500',
  },
];

export type Stage = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const Stages: { value: Stage; className: string }[] = [
  {
    value: 0,
    className: 'text-gray-800 bg-orange-100',
  },
  {
    value: 1,
    className: 'text-gray-800 bg-orange-200',
  },
  {
    value: 2,
    className: 'text-gray-800 bg-orange-300',
  },
  {
    value: 3,
    className: 'text-gray-800 bg-orange-400',
  },
  {
    value: 4,
    className: 'text-white bg-orange-500',
  },
  {
    value: 5,
    className: 'text-white bg-orange-600',
  },
  {
    value: 6,
    className: 'text-white bg-orange-700',
  },
  {
    value: 7,
    className: 'text-white bg-orange-800',
  },
  {
    value: 8,
    className: 'text-gray-800 bg-gray-100',
  },
];

export const workspaceColors = ['bg-green-400', 'bg-sky-400', 'bg-purple-400', 'bg-red-400', 'bg-yellow-400'];
