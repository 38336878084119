import classNames from 'classnames';
import { HTMLProps, ReactNode } from 'react';
import { FC } from 'react';

interface PageHeaderProps extends Omit<HTMLProps<HTMLDivElement>, 'action'> {
  heading: ReactNode;
  children?: ReactNode;
}

const PageHeader: FC<PageHeaderProps> = ({ heading, children, className, ...props }) => {
  return (
    <div className={classNames('flex pb-2', className)} {...props}>
      <div>
        <h3 className='text-gray-8 text-xl font-medium'>{heading}</h3>
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};

export default PageHeader;
