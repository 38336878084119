import { FC, useState } from 'react';
import { CaretDown, DownloadSimple } from '@phosphor-icons/react';
import SimpleDropdown from '../../ui/components/Dropdown/SimpleDropdown';
import Button from '../../../ui/components/Button/Button';
import { DropdownOption } from '../../../ui';
import { StyledDropzone } from './DropSection';

interface IDropZone {
  file_type: number;
  onSuccess: () => void;
}

const DropZone: FC<IDropZone> = ({ file_type, onSuccess }) => {
  const [dropdownValue, setDropdownValue] = useState({ label: 'CSV', value: 'CSV', id: 'csv' });
  const onChangeDropdown = (value) => {
    setDropdownValue(value);
  };
  const moreDropdownOptions: DropdownOption[] = [
    { label: 'CSV', value: 'CSV', id: 'csv' },
    { label: 'XLS', value: 'XLS', id: 'xls' },
  ];
  return (
    <div className='m-4'>
      <StyledDropzone file_type={file_type} onSuccess={onSuccess} />
      <div className='mt-5 flex justify-between'>
        <p className='mt-2 text-xs leading-4 text-gray-500'>Choose a format to download template:</p>
        <div className='flex'>
          <SimpleDropdown
            value={null}
            onChange={onChangeDropdown}
            placeholder={
              <Button variant='tertiary' className='px-3'>
                {dropdownValue.value}
                <CaretDown size={12} color='#D1D5DB' className='ml-1' />
              </Button>
            }
            options={moreDropdownOptions}
          />
          {/* TODO: Update Link when moved to compass */}
          <a
            id='download'
            href={`/samples/samples_${file_type === 0 ? 'addresses' : 'transactions'}_fileupload.${
              dropdownValue.id
            }`}
            download>
            <Button variant='secondary' className='ml-4 focus:outline-0'>
              <DownloadSimple size={16} />
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DropZone;
