import { FC } from 'react';
import { formatDateTime } from '../../../utils/helpers/date';
import classNames from 'classnames';
import CopyToClipboardBtn from '../Copy/Copy';
import { useAuth } from '../../../modules/auth';

export const TableDateTimeCell: FC<{ key: string | number; value: string; className?: string }> = ({
  value,
  className,
}) => {
  const timezone = useAuth()?.state?.userProfile?.timezone;
  return <span className={classNames('text-gray-400', className)}>{formatDateTime(value, timezone)}</span>;
};

export const TableCopyCell: FC<{ key: string | number; value: string; className?: string }> = ({
  value,
  className,
}) => {
  return (
    <span className={classNames('flex select-all items-center gap-2')}>
      <span className={classNames('text-blue-600', className)}>{value}</span>
      <CopyToClipboardBtn text={value} />
    </span>
  );
};
