export interface Props {
  bgColor?: string;
  content: string;
  dotColor?: string;
  txtColor?: string;
  fixedWidth?: boolean;
}

const DotBadge = (props: Props) => {
  return (
    <div
      className={`px-2 py-1 pr-2.5 ${
        props.bgColor ? props.bgColor : 'bg-gray-100'
      } flex w-fit flex-row items-center gap-1.5 rounded-xl`}>
      <div className={`size-1.5 rounded-full p-0.5 ${props.dotColor ? props.dotColor : 'bg-gray-200'}`}></div>
      <div
        className={`text-3xs font-medium leading-default ${
          props.txtColor ? props.txtColor : 'text-gray-800'
        } ${props.fixedWidth ? 'max-w-[70px] truncate' : ''}`}>
        {props.content || 'Unknown'}
      </div>
    </div>
  );
};

export default DotBadge;
