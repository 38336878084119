import SlackImg from '@/assets/integrations/slack.svg';
import WebhookImg from '@/assets/integrations/webhook.svg';
import FireblocksImg from '@/assets/integrations/fireblocks.svg';
import { IIntegrationResult } from '../../api/dtos/integration';

export interface IIntegration {
  provider: string;
  label: string;
  isActive: boolean | undefined;
  description: string;
  descriptionVerbose: string;
  image: string;
  setup: string[];
  setupAdvanced?: string[];
  isHidden?: boolean;
}

const integrations = [
  {
    provider: 'slack',
    label: 'Slack',
    description: 'Connect Slack to Merkle Science to keep your team efficient and coordinated.',
    descriptionVerbose:
      'Connect Slack to Merkle Science to keep your team efficient and coordinated. Get notified in Slack with updates about addresses, transactions, customers and cases. Also receive important notifications like usage and billing alerts right from your workspace.',
    setup: [
      "Click on the 'Add Integration' button on the top right of the webpage.",
      "You will be redirected to a new page, Where they need to enter the workplace’s Slack URL, then click on 'Continue'.",
      'Then you will be redirected to the authorization Webpage, where you need to log in with Merkle Science credentials.',
      "After Login, you will be redirected to a Permission webpage; as the last step, you need to enter the name of the slack channel they want to receive updates from Merkle Science and click on 'Allow'.",
      'Upon completing the above steps, you would successfully integrate Slack to Merkle Science.',
    ],
    image: SlackImg,
  },
  {
    provider: 'webhook',
    label: 'Webhook',
    description:
      'Like email notifications, our webhook notifications allow you to get notified of events that happen on Block Monitor.',
    descriptionVerbose:
      'Like email notifications, our webhook notifications allow you to get notified of events that happen on Block Monitor.<br/><br/>Using webhooks, you can update your internal risk admin dashboard with latest risk level as and when they are updated on the Merkle Science platform. Webhooks can also help in placing restrictions or holds on specific accounts when a risk previously not identified is detected due to continous monitoring.',
    setup: [
      'Click on “Add Integration” button.',
      'Enter the URL at which you would like to receive webhooks.',
      'Enable the webhook.',
      'Send test data to validate if your webhook is live and receiving requests.',
      'Once you have a received a message verifying that we have received a HTTP 200 response from the webhook, you can then save the webhook.',
    ],
    image: WebhookImg,
  },
  {
    provider: 'fireblocks',
    label: 'Fireblocks',
    description: 'Automatically sync transactions from your Fireblocks account.',
    descriptionVerbose:
      'Our advanced integration allows Compass to effectively freeze risky deposits on Fireblocks or prevent risky withdrawals from being processed on the blockchain. If you are interested in enabling this functionality, please contact our support team at support@merklescience.com to initiate the process for enabling the advanced integration.',
    setup: [
      'Click on the “<b>Add Integration</b>” button.',
      'Copy the webhook URL generated.',
      'Login to your Fireblocks account (if required select the Fireblocks Workspace you want to connect Compass to.)',
      'Click on the Settings icon located at the top of the Fireblocks screen',
      'Navigate towards section named <b>Configure Webhook URL</b> and click on <b>Manage URLs</b>',
      'Paste the webhook URL you copied in Step 2 and save the webhook.',
      'Your integration is now complete, and an automatic sync between your Fireblocks workspace and Compass has been established. Do note that it could upto 1 minute for transactions to appear on Compass after they have been confirmed on the blockchain.',
    ],
    setupAdvanced: [
      'Login to your Fireblocks account (if required select the Fireblocks Workspace you want to connect Compass to.)',
      'Click on the Settings icon located at the top of the Fireblocks screen',
      'Navigate towards section named Users',
      'Add a new User and set the following properties <ol class="list-[revert-layer] ml-4" type="a"><li>Type: API user</li><li>Role: Non Signing Admin</li><li>CSR Certificate: The CSR certificate will be shared with you securely by support team	</br>No other fields are necessary to fill</li></ol>',
      'After the previous step, a new API user with the status `Pending Setup` will appear on your Users Page. Navigate to the settings for this user and add the static IP address for the Compass Application. This Static IP address will be shared with you securely by support team',
      'This is the Static IP address from where Compass will execute its freeze and rejection API calls to Fireblocks',
      'Once the IP address has been whitelisted, you can retrieve your API key by clicking on the button / status label `Pending Setup`',
      'Ensure that this API key is securely shared with Merkle Science',
    ],
    image: FireblocksImg,
  },
];

export const getIntegrationData = (
  integrationData: IIntegrationResult[],
  isFireblocksEnabled: boolean
): IIntegration[] => {
  if (!integrationData) {
    return [];
  }
  return integrations.map((integration) => {
    const integrationDataItem = integrationData.find((item) => item.provider === integration.provider);

    return {
      ...integration,
      isActive: integrationDataItem?.is_active,
      // config: integrationDataItem?.config,
      isHidden: integration.provider === 'fireblocks' && !isFireblocksEnabled,
    };
  });
};
