import { FC } from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
export { toast } from 'react-toastify';
import './Toast.scss';

type ToastProps = Partial<ToastContainerProps>;

const Toast: FC = (props: ToastProps) => {
  return (
    <ToastContainer
      autoClose={3000}
      position='top-right'
      bodyClassName='break-words'
      className={`w-[32rem] text-lg font-semibold leading-6`}
      hideProgressBar={true}
      {...props}
    />
  );
};

export default Toast;
