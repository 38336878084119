import { useState } from 'react';
import { Button } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { formatDateTime } from '../../../utils/helpers/date';

import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import { Search } from '../../ui/components/Search';
import AddMonitoringAddressModal from './AddMonitoringAddressModal';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { getCurrencyFromCode } from '../../../utils/helpers/currency';
import { useMonitoredAddressesGet } from '../../../api/settings/monitoringAddresses';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import MonitoringTableFilter, { IFilters, defaultStateFilters } from './MonitoringTableFilter';
import MonitoringTableAppliedFilters from './MonitoringTableAppliedFilter';
import { useAuth } from '../../../modules/auth';
import { useCustomMonitoredAddressesGet } from '../../../api/settings/customMonitoringEntities';

interface IMonitoringTableProps {
  monitoringLevel: number;
}

export interface IMonitoringAddress {
  is_active?: boolean;
  identifier?: string;
  currency?: number;
  remaining_days?: number;
}

const MonitoringTable: React.FC<IMonitoringTableProps> = ({ monitoringLevel }) => {
  const [search, setSearch] = useState('');
  const [modalAddress, setModalAddress] = useState<IMonitoringAddress>(null);
  const [modal, setModal] = useState(false);

  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const timezone = useAuth()?.state?.userProfile?.timezone;

  const headerData = [
    ...(monitoringLevel === 3
      ? ['Addresses']
      : monitoringLevel === 1
        ? ['Deactivated Addresses']
        : ['Monitored Addresses']),
    ...(monitoringLevel !== 3 ? ['Added By'] : []),
    ...(monitoringLevel !== 3 ? ['Date Added'] : []),
    'Blockchain',
    ...(monitoringLevel !== 3 ? ['Action'] : []),
  ];

  const monitoringAddressesQuery = useMonitoredAddressesGet(
    {
      q: search,
      ...filters,
      is_ignored: monitoringLevel === 1,
    },
    {
      enabled: monitoringLevel !== 3,
    }
  );
  const customMonitoringAddresses = useCustomMonitoredAddressesGet(
    { limit: 20, offset: 0, q: search },
    { enabled: monitoringLevel === 3 }
  );
  const [count, data] = flattenInfiniteQueryResult(
    monitoringLevel === 3 ? customMonitoringAddresses?.data : monitoringAddressesQuery?.data
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rows = data.map((row: any, i) => {
    return {
      id: monitoringLevel === 3 ? i : row.id,
      data: [
        <IdentifierEllipsis
          key={monitoringLevel === 3 ? i : row.id}
          identifier={row.identifier}
          currency={row.currency}
          type='addresses'
          copyable
        />,
        ...(monitoringLevel !== 3 ? [row.added_by] : []),
        ...(monitoringLevel !== 3 ? [formatDateTime(row.created_at, timezone)] : []),
        // <DigitalAssets key={row.id} digitalAssets={row.blockchains} />,
        <CurrencyBadge
          key={monitoringLevel === 3 ? i : row.id}
          currency={row.currency}
          label={getCurrencyFromCode(row.currency).label}
        />,
        ...(monitoringLevel !== 3
          ? [
              <Button
                key={row.id}
                className='mr-2'
                variant='tertiary'
                onClick={() => {
                  setModal(true);
                  setModalAddress(row);
                }}>
                Remove
              </Button>,
            ]
          : []),
      ],
    };
  });

  return (
    <div>
      <Table
        className={`mt-6 ${monitoringLevel === 3 ? 'w-4/6' : ''}`}
        headerData={headerData}
        rows={rows}
        count={
          monitoringLevel === 3 &&
          !customMonitoringAddresses.isLoading &&
          !isNaN(count) &&
          `Showing ${data.length} of ${count} results`
        }
        title={
          monitoringLevel === 3
            ? 'Screened Associated Addresses'
            : monitoringLevel === 1
              ? 'Monitoring deactivated addresses'
              : 'Monitored Addresses'
        }
        headerActions={
          monitoringLevel !== 3 ? (
            <>
              <MonitoringTableFilter filters={filters} onApply={setFilters} />
              <Button
                variant='primary'
                onClick={() => {
                  setModal(true);
                  setModalAddress(null);
                }}>
                Add Address
              </Button>
            </>
          ) : (
            <Search
              className='ml-4 w-56'
              placeholder='Search for Address'
              value={search}
              setValue={setSearch}
            />
          )
        }
        border
        height={400}
        appliedFilters={<MonitoringTableAppliedFilters filters={filters} setFilters={setFilters} />}
        {...buildInfiniteQueryTableProps(
          monitoringLevel === 3 ? customMonitoringAddresses : monitoringAddressesQuery
        )}
      />
      {monitoringLevel !== 3 && (
        <AddMonitoringAddressModal
          type={modalAddress ? 'remove' : 'add'}
          show={!!modal}
          onClose={() => setModal(false)}
          isIgnored={monitoringLevel === 1}
          {...modalAddress}
        />
      )}
    </div>
  );
};

export default MonitoringTable;
