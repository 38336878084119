import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '../Checkbox';

type CheckboxControllerProps<TFieldValues, TName extends FieldPath<TFieldValues>> = CheckboxProps &
  Omit<ControllerProps<TFieldValues, TName>, 'render'> & { checked?: boolean };

const CheckboxController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  ...props
}: CheckboxControllerProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, value, ...field } }) => {
        // return <Input {...field} {...inputProps} error={fieldState?.error?.message} />;

        return <Checkbox checked={value} {...field} {...props} name={name} />;
      }}></Controller>
  );
};

export default CheckboxController;
