import React from 'react';
import { GoogleCharts } from 'google-charts';
import { startOfDay, subDays, isSameDay } from 'date-fns';

interface DataItem {
  date: string;
  count: number;
}

const RuleAnalyticsAlertChart: React.FC<{ ruleAlerts: DataItem[] }> = ({ ruleAlerts }) => {
  const chartRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const drawChart = () => {
      const data = new GoogleCharts.api.visualization.DataTable();
      data.addColumn('date', 'Date');
      data.addColumn('number', 'Count');

      const sevenDayData = [];

      for (let i = 0; i < 7; i++) {
        const day = startOfDay(subDays(new Date(), i));
        const matchingAlert = ruleAlerts.find((alert) => isSameDay(day, new Date(alert.date)));
        const count = matchingAlert ? matchingAlert.count : 0;
        sevenDayData.push({ date: day, count });
      }

      sevenDayData.reverse().forEach((dayData) => {
        data.addRow([dayData.date, dayData.count]);
      });

      const maxCount = Math.max(...sevenDayData.map((day) => day.count));
      const graphMaxPoint = maxCount > 0 ? Number(maxCount * 1.1) : 1;

      const options = {
        colors: ['#10B981'],
        legend: { position: 'none' },
        width: '200px',
        height: '100%',
        chartArea: {
          left: '4%',
          right: '4%',
          height: '80%',
        },
        vAxis: {
          textPosition: 'none',
          gridlines: { count: 0 },
          viewWindow: { min: 0, max: graphMaxPoint },
        },
        hAxis: {
          textPosition: 'bottom',
          format: 'd MMM',
          minorGridlines: { count: 0 },
          gridlines: { count: 7 },
        },
      };

      if (chartRef.current) {
        const chart = new GoogleCharts.api.visualization.LineChart(chartRef.current);
        chart.draw(data, options);
      }
    };

    GoogleCharts.load(drawChart);
  }, [ruleAlerts]);

  return <div ref={chartRef} className='h-[289px]' />;
};

export default RuleAnalyticsAlertChart;
