import { IOption } from './CustomSelect';

import { FC, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import SingleSelect from '../ui/components/Select/SingleSelect';
import { AxiosResponse } from 'axios';
import { IClassifierResponse } from '../../api/dtos/classifier';
import { UseInfiniteQueryResult } from 'react-query';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../utils/helpers/react-query.helper';

interface RuleIdFilterProps {
  value: string;
  ruleName: string;
  setValue: (rules: string) => void;
  setName: (rules: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ruleNamesQuery: UseInfiniteQueryResult<AxiosResponse<IClassifierResponse, any>, unknown>;
}

const RuleIdFilter: FC<RuleIdFilterProps> = (props) => {
  const { value, setValue, setName, ruleNamesQuery, ruleName } = props;
  const [, ruleNamesData] = flattenInfiniteQueryResult(ruleNamesQuery?.data);
  const isLoadingRuleClassifiers = ruleNamesQuery?.isLoading;
  const [ruleNames, setRuleNames] = useState<IOption[]>([]);

  useEffect(() => {
    if (ruleNamesData) {
      const ruleNamesList = uniq(ruleNamesData);
      setRuleNames(
        ruleNamesList.map((r) => {
          return {
            value: r.uuid,
            label: r.rule_name + ' (' + r.policy_type_verbose + ')',
          };
        })
      );
    }
  }, [ruleNamesQuery.data]);

  const [rules, setRules] = useState<IOption>(value && ruleName ? { label: ruleName, value: value } : null);

  const handleChange = (rules: IOption) => {
    setValue(rules?.value ?? null);
    setName(rules?.label ?? null);
    setRules(rules);
  };

  return (
    <SingleSelect
      label='Rule Name'
      placeholder='Select Rule Name'
      options={ruleNames}
      isLoading={isLoadingRuleClassifiers}
      value={rules ? rules : null}
      handleChange={(rule) => handleChange(rule)}
      isSearchable
      asyncPaginate
      {...buildInfiniteQueryTableProps(ruleNamesQuery)}
    />
  );
};

export default RuleIdFilter;
