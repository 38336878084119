import { FC } from 'react';
import { ICaseAuditTrailResult } from '../../../api/dtos/case';
import Expandable from '../../ui/components/Expandable/Expandable';
import { formatDateTime } from '../../../utils/helpers/date';
import { useAuth } from '../../../modules/auth';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { CaseStatusBadge, RiskBadge } from '../../../ui';
import { ArrowRight } from '@phosphor-icons/react';
import { getAlertType } from '../../../utils/helpers/alerts';
import AlertBadge from '../../../ui/components/Badges/AlertBadge/AlertBadge';
import { getCurrencyById } from '../../../utils/helpers/currency';

interface ICaseAuditTrailEntityProps {
  entity: ICaseAuditTrailResult;
}

const EntityKeyValue = ({
  entityKey,
  entityValue,
}: {
  entityKey: React.ReactNode;
  entityValue: React.ReactNode;
}) => {
  return (
    <div className='my-2 flex text-sm'>
      <div className='font-bold text-gray-700'>{entityKey}</div>
      <div className='mx-3'>:</div>
      <div className='text-gray-500'>{entityValue}</div>
    </div>
  );
};

const CaseAuditTrailEntity: FC<ICaseAuditTrailEntityProps> = ({ entity }) => {
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();

  const contextType = Object.keys(entity.context)[1];

  const getTitle = () => {
    let user = '';
    let action = '';
    if (entity.type === 0) {
      action = 'New alert created';
    } else if (entity.type === 1) {
      action = 'Alert updated';
    } else if (entity.type === 2) {
      user = entity.by_user_name;
      action = 'updated case status';
    } else if (entity.type === 3) {
      action = 'Risk level updated';
    } else if (entity.type === 4) {
      user = entity.by_user_name;
      action =
        entity.context.action + (entity.context.action === 'added' ? ' a new' : '') + ' ' + contextType;
    }
    return (
      <div className='flex w-full justify-between'>
        <div className='flex text-base font-semibold text-gray-700'>
          <div className='text-gray-500'>{user}</div>
          <div className='ml-1'>{action}</div>
        </div>
        <div className='mr-1 text-xs text-gray-500'>{formatDateTime(entity.created_at, timezone)}</div>
      </div>
    );
  };

  const currencyVerbose = getCurrencyById(entity.context?.[contextType]?.currency)?.label;

  return (
    <div className='border-b py-4'>
      <Expandable
        title={getTitle()}
        content={
          <div>
            {contextType === 'address' && (
              <>
                <EntityKeyValue entityKey={'Address'} entityValue={entity.context.address.identifier} />
                <EntityKeyValue
                  entityKey={'Currency'}
                  entityValue={
                    <CurrencyBadge
                      currency={entity.context.address.currency}
                      label={currencyVerbose}
                      transparent
                    />
                  }
                />
              </>
            )}
            {contextType === 'transaction' && (
              <>
                <EntityKeyValue
                  entityKey={'Transaction'}
                  entityValue={entity.context.transaction.identifier}
                />
                <EntityKeyValue
                  entityKey={'Amount'}
                  entityValue={
                    <CurrencyBadge
                      currency={entity.context.transaction.currency}
                      label={currencyVerbose}
                      transparent
                    />
                  }
                />
              </>
            )}
            {contextType === 'customer' && (
              <EntityKeyValue entityKey={'Customer ID'} entityValue={entity.context.customer.customer_id} />
            )}
            {entity.type === 3 && (
              <div className='flex'>
                <RiskBadge risk={entity.context.old_risk_level} className='line-through' />
                <ArrowRight size={14} className='mx-2 my-auto text-blue-300' />
                <RiskBadge risk={entity.context.new_risk_level} />
              </div>
            )}
            {entity.type === 2 && (
              <div className='flex'>
                <CaseStatusBadge status={entity.context.old_case_status} className='line-through' />
                <ArrowRight size={14} className='mx-2 my-auto text-blue-300' />
                <CaseStatusBadge status={entity.context.new_case_status} />
              </div>
            )}
            {entity.type === 0 &&
              entity.context.alerts.map((alert, i) => (
                <div className='mb-2 flex' key={i}>
                  <RiskBadge risk={alert.level} />
                  <span className='ml-2 text-2xs'>
                    {getAlertType(alert.risk_types)} - {alert.rule_name}
                  </span>
                </div>
              ))}
            {entity.type === 1 && (
              <>
                <div className='flex'>
                  <RiskBadge risk={entity.context.alerts[0].level} />
                  <span className='ml-2 text-2xs'>
                    {getAlertType(entity.context.alerts[0].risk_types)} - {entity.context.alerts[0].rule_name}
                  </span>
                </div>
                <div className='mt-4 flex'>
                  <AlertBadge type={entity.context.old_alert_status} className='line-through' />
                  <ArrowRight size={14} className='mx-2 my-auto text-blue-300' />
                  <AlertBadge type={entity.context.new_alert_status} />
                </div>
              </>
            )}
          </div>
        }
        isExpanded
      />
    </div>
  );
};

export default CaseAuditTrailEntity;
