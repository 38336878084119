import { useEffect, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useRouter } from '../../modules/router/RouterProvider';
import { IAuthUser } from '../../modules/auth/types';
import MerkleLogoBlue from '@/assets/images/dashboard/merkle-logo-blue.svg';

interface ReportHeaderProps {
  isInvoices: boolean;
  invoiceReport: boolean;
  taxInvoice: string;
  orgLogo: string;
  orgName: string;
}

const ReportHeader: React.FC<ReportHeaderProps> = ({
  isInvoices,
  invoiceReport,
  taxInvoice,
  orgLogo,
  orgName,
}) => {
  const { navigate } = useRouter();
  const decodedToken = useMemo(() => ({}) as IAuthUser, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const decoded = (token ? jwtDecode(token) : {}) as IAuthUser;

    if (isInvoices) {
      decoded.organization_brand_logo = '';
      decoded.organization = 'Merkle Science';
    }

    decodedToken.organization_brand_logo = decoded.organization_brand_logo;
    decodedToken.organization = decoded.organization;
  }, [decodedToken, isInvoices]);

  return (
    <header id='header' className='flex items-center justify-between border-b-2 pb-4'>
      <div className='flex justify-between'>
        <div className='flex'>
          <div
            onClick={() => navigate('dashboard')}
            id='brand-logo'
            className='size-9 flex-row whitespace-pre rounded-md bg-transparent p-1.5 text-base font-medium text-black'>
            <img
              src={orgLogo || decodedToken.organization_brand_logo || MerkleLogoBlue}
              alt='Organization Logo'
              width={invoiceReport ? '48' : '24'}
              height={invoiceReport ? '48' : '24'}
            />
          </div>
          <button
            id='brand-text'
            className='size-9 flex-row whitespace-pre rounded-md bg-transparent p-1.5 text-base font-medium text-black'>
            <span
              style={{
                marginTop: invoiceReport ? '2px' : '2px',
                fontSize: invoiceReport ? '32px' : '',
              }}>
              {orgLogo || decodedToken.organization_brand_logo
                ? orgName || decodedToken.organization
                : 'Merkle Science'}
            </span>
          </button>
        </div>
      </div>
      <div className='flex flex-col items-end'>
        <span id='notice' className='text-3xs text-gray-700'>
          Private and Confidential
        </span>
        {taxInvoice && <h1 className='title mb-1'>{`Tax Invoice #${taxInvoice}`}</h1>}
      </div>
    </header>
  );
};

export default ReportHeader;
