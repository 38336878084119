import { useSearchParams } from 'react-router-dom';
import CompassLoader from '../../components/ui/components/Loader/CompassLoader';
import { errors } from '../../utils/constants/errors';
import { removeOrganizationInfo } from '../../utils/helpers/cookies';
import { toast } from 'react-toastify';
import { useRouter } from '../../modules/router/RouterProvider';
import AppLogo from '@/assets/compass-full.svg';
import Logo from '@/assets/logo.color.svg';

const LoginComplete = () => {
  const [searchParams] = useSearchParams();
  const { navigate } = useRouter();
  const error = searchParams.get('error');
  if (error) {
    const errorDescription = searchParams.get('error_description');
    const errorTitle = errors[errorDescription] || errorDescription;
    removeOrganizationInfo();
    toast.error(errorTitle);
    setTimeout(() => {
      navigate('/accounts/login');
    }, 2500);
  } else {
    navigate('/dashboard');
  }
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-8 bg-[url('/pattern-bottom-right.svg'),_url('/pattern-bottom-left.svg')] bg-[position:bottom_right,bottom_left] bg-no-repeat p-16">
      <img src={AppLogo} alt='app-logo' width={200} />
      <div className='h-max w-4/12 min-w-60 max-w-96 rounded-lg border border-gray-200 bg-white p-6 shadow-md'>
        <div className='text-xl mb-6 text-center font-medium uppercase text-gray-700'>Logging in</div>
        <CompassLoader />
      </div>
      <div className='flex w-3/12 items-center justify-center border-b-2 border-gray-200 pb-8 text-2xs text-blue-600'>
        <a className='' href='https://www.merklescience.com/privacy-policy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>
        <div className='mx-2'>・</div>
        <a className='' href='https://www.merklescience.com/terms' target='_blank' rel='noreferrer'>
          Terms of Service
        </a>
      </div>
      <img src={Logo} alt='merklescience-logo' width={200} />
    </div>
  );
};

export default LoginComplete;
