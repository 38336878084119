import { useState } from 'react';
import { Button, ConfirmationModal } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { formatDateTime } from '../../../utils/helpers/date';

import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import { Search } from '../../ui/components/Search';
import AddMonitoringAddressModal from './AddMonitoringAddressModal';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { getCurrencyFromCode } from '../../../utils/helpers/currency';
import { useTokenMonitoredAddressesGet } from '../../../api/settings/monitoringAddresses';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import MonitoringTableFilter, { IFilters, defaultStateFilters } from './MonitoringTableFilter';
import MonitoringTableAppliedFilters from './MonitoringTableAppliedFilter';
import { useAuth } from '../../../modules/auth';
import { TabHeader } from '../../ui/components/Header';
import Popover from '../../../ui/components/Popover/Popover';
import { Info } from '@phosphor-icons/react';
import { useWorkspace } from '../../../utils/helpers/common';

interface ISmartContractWatchlistProps {}

export interface IMonitoringAddress {
  is_active?: boolean;
  identifier?: string;
  currency?: number;
  remaining_days?: number;
}

const SmartContractWatchlist: React.FC<ISmartContractWatchlistProps> = () => {
  const [search, setSearch] = useState('');
  const [modalAddress, setModalAddress] = useState<IMonitoringAddress>(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [modal, setModal] = useState(false);
  const workspace = useWorkspace();
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const timezone = useAuth()?.state?.userProfile?.timezone;

  const headerData = [
    'Monitored Addresses',
    'Added By',
    'Date Added',
    'Blockchain',
    <div key='action' className='mr-6 text-right'>
      Action
    </div>,
  ];

  const monitoringAddressesQuery = useTokenMonitoredAddressesGet({ q: search, ...filters });
  const [, data] = flattenInfiniteQueryResult(monitoringAddressesQuery?.data);
  const rows = data.map((row) => {
    return {
      id: row.id,
      data: [
        <IdentifierEllipsis
          key={row.id}
          identifier={row.identifier}
          currency={row.currency}
          type='addresses'
          copyable
          clickable
        />,
        row.added_by,
        formatDateTime(row.created_at, timezone),
        <CurrencyBadge
          key={row.id}
          currency={row.currency}
          label={getCurrencyFromCode(row.currency).label}
        />,
        <div key={row.id} className='text-right'>
          <Button
            className='mr-2'
            variant='tertiary'
            onClick={() => {
              setModal(true);
              setModalAddress(row);
            }}>
            Remove
          </Button>
        </div>,
      ],
    };
  });

  const onClickAddAddress = (confirmation = false) => {
    if (!data.length && !confirmation) {
      setConfirmationModal(true);
    } else {
      setConfirmationModal(false);
      setModal(true);
      setModalAddress(null);
    }
  };

  return (
    <div>
      <TabHeader
        heading={
          <div className='flex items-center'>
            Smart Contract Watchlist
            <Popover
              referenceContent={<Info size={20} className='ml-2 flex text-gray-500' />}
              popoverContent={
                'Enable monitoring for all transactions on the addresses added to this watchlist. This will screen all transactions associated with this address, and you should add addresses to this list only if you wish to screen these transactions separately'
              }
              isBasic
              className='w-80 text-sm'
              placement='bottom'
            />
          </div>
        }
      />
      {workspace.token_monitoring_status ? (
        <Table
          className='mt-6'
          headerData={headerData}
          rows={rows}
          headerActionsLeft={
            <Search
              className='ml-4 w-96'
              placeholder='Search for Address'
              value={search}
              setValue={setSearch}
            />
          }
          headerActions={
            <>
              <MonitoringTableFilter filters={filters} onApply={setFilters} />
              <Button
                variant='primary'
                onClick={() => onClickAddAddress()}
                disabled={monitoringAddressesQuery.isLoading}>
                Add Address
              </Button>
            </>
          }
          border
          height={400}
          appliedFilters={<MonitoringTableAppliedFilters filters={filters} setFilters={setFilters} />}
          {...buildInfiniteQueryTableProps(monitoringAddressesQuery)}
        />
      ) : (
        <div className='mt-6 font-medium text-gray-500'>
          Smart Contract Watchlist is not available as part of your current plan.
          <br />
          Please reach to your Account Managers or mail us at{' '}
          <a href='mailto:support@merklescience.com' className='text-blue-500'>
            support@merklescience.com
          </a>{' '}
          to get it activated
        </div>
      )}
      <ConfirmationModal
        onConfirm={() => onClickAddAddress(true)}
        open={!!confirmationModal}
        data={confirmationModal || null}
        onCancel={() => setConfirmationModal(false)}
        title={'Confirm Add Address'}
        body={'This will screen all transactions associated with this address. Do you want to proceed?'}
        cancelButton='No'
      />
      <AddMonitoringAddressModal
        type={modalAddress ? 'remove' : 'add'}
        show={!!modal}
        onClose={() => setModal(false)}
        isToken
        {...modalAddress}
      />
    </div>
  );
};

export default SmartContractWatchlist;
