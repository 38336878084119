import { FC } from 'react';
import { isCurrencyDeprecated } from '../../utils/helpers/currency';

const CurrencyDeprecationAlert: FC<{ currency: number }> = ({ currency }) => {
  if (!isCurrencyDeprecated(currency)) return null;
  return (
    <div className='-m-4 mb-2 bg-amber-50 p-2 py-4 text-xs text-amber-800'>
      This blockchain is no longer supported
    </div>
  );
};

export default CurrencyDeprecationAlert;
