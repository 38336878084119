import { FC } from 'react';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import { formatDateTime } from '../../../utils/helpers/date';
import ReportTable from '../ReportTable';
import { ICustomerTokensResult } from '../../../api/dtos/customer';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { useRouter } from '../../../modules/router/RouterProvider';

interface IDigitalAssetSummaryReportProps {
  tokenSummary: ICustomerTokensResult[];
  part?: number;
}

const DigitalAssetSummaryReport: FC<IDigitalAssetSummaryReportProps> = ({ tokenSummary, part }) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;

  if (!tokenSummary) return null;

  const tableData = tokenSummary.map((token) => ({
    label: <CurrencyBadge currency={token.currency} label={token.currency_short} transparent />,
    value: [
      {
        label: 'Balance',
        value: <CurrencyValue value={token.balance} currency={token.currency_short} />,
      },
      {
        label: 'Deposit Amount',
        value: <CurrencyValue value={token.total_incoming_value} currency={token.currency_short} />,
      },
      {
        label: 'Withdrawal Amount',
        value: <CurrencyValue value={token.total_outgoing_value} currency={token.currency_short} />,
      },
      {
        label: 'Earliest Transaction',
        value: formatDateTime(token.earliest_transaction_time, timezone),
      },
      {
        label: 'Latest Transaction',
        value: formatDateTime(token.latest_transaction_time, timezone),
      },
      {
        label: 'Deposits',
        value: token.incoming_transaction_count,
      },
      {
        label: 'Withdrawals',
        value: token.outgoing_transaction_count,
      },
    ],
  }));

  return <ReportTable tableData={tableData} title={`Part ${part}: Digital Asset Summary`} />;
};

export default DigitalAssetSummaryReport;
