import { FC, useEffect, useState } from 'react';
import { BasicModal, Button, RoleBadge } from '../../../ui';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from '../../../ui/components/Toast';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { teamMembersApi } from '../../../api/settings/teamMembers';
import CustomSelectController from '../../ui/components/Select/CustomSelectController';
import { useWorkspaceLiteGet, workspacesApi } from '../../../api/workspaces';
import SingleSelectController from '../../ui/components/Select/SingleSelectController';
import TeamMemberEmailController from './TeamMemberEmailController';
import { IOption } from '../../Filters/CustomSelect';
import { useWorkspace } from '../../../utils/helpers/common';
import WorkspaceBadge from '../../ui/components/Badge/WorkspaceBadge';
import { Roles, Role } from '../../../ui/utils/constants';

export interface TeamMemberInviteModalProps {
  onClose?: (refetch?: boolean) => void;
  open: boolean;
  inviteType: 'single' | 'bulk';
  type: 'organisation' | 'workspace';
  emails?: string[];
}

const validations = Yup.object({
  // email is array of object with value and label
  emails: Yup.array().required().min(1, 'Please enter email address to invite'),
  role: Yup.object().label('Role').required(),
  workspace: Yup.array().label('Workspace').required().min(1, 'Please enter workspace to invite'),
});

const initialData = {
  emails: [],
  role: Roles[1],
  workspace: [],
};

const TeamMemberInviteModal: FC<TeamMemberInviteModalProps> = (props) => {
  const { open, onClose, inviteType, emails, type } = props;
  const [currentWorkspace, setCurrentWorkspace] = useState<IOption>();

  const [error, setError] = useState<string>(null);
  const [workspacesOptions, setWorkspacesOptions] = useState<IOption[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, dirtyFields },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    if (emails?.length) {
      setValue(
        'emails',
        emails.map((email) => ({ value: email, label: email }))
      );
    }
  }, [emails, setValue]);

  const isFormDisable = isSubmitting;
  const { mutateAsync: addTeamMember } = useMutation(teamMembersApi.addTeamMember);
  const { mutateAsync: addWorkspaceMember } = useMutation(workspacesApi.inviteWorkspaceMembers);
  const { mutateAsync: bulkInviteTeamMembers } = useMutation(teamMembersApi.bulkInviteTeamMembers);
  const { data: workspaces, isLoading } = useWorkspaceLiteGet({ enabled: open && type === 'organisation' });

  useEffect(() => {
    setWorkspacesOptions(
      workspaces?.data?.results?.map((w, i) => ({
        label: w.name,
        value: w.slug,
        index: i,
      })) || []
    );
  }, [workspaces]);
  const workspace = useWorkspace();
  useEffect(() => {
    if (type === 'workspace') {
      setWorkspacesOptions([{ label: workspace.name, value: workspace.slug }]);
    }
  }, [workspace.name, type, workspace.slug]);

  const isWorkspaceSelectDisabled =
    inviteType === 'bulk' || type === 'workspace' || workspacesOptions?.length === 1;

  useEffect(() => {
    if (!isLoading) {
      if (inviteType === 'bulk' || type === 'workspace') {
        setCurrentWorkspace(workspacesOptions.find((w) => w.value === workspace.slug));
        setValue('workspace', [workspacesOptions.find((w) => w.value === workspace.slug)]);
      }
      if (workspacesOptions.length === 1) {
        setCurrentWorkspace(workspacesOptions[0]);
        setValue('workspace', workspacesOptions);
      }
    }
  }, [open, inviteType, type, setValue, workspacesOptions, workspace, isLoading]);

  const onSubmit = async (req: typeof initialData) => {
    const onSuccess = () => {
      toast.success(
        inviteType === 'single'
          ? `Team member invited to ${type} successfully!`
          : 'Team members invited to workspace successfully!'
      );
      handleClose(true);
      setError(null);
    };

    const onError = (e: AxiosError) => {
      const errorMessage = getErrorMessage(e);
      if (errorMessage) {
        setError(errorMessage);
        reset({}, { keepValues: true });
      } else {
        toast.error('Failed to invite team members. Please try again.');
      }
    };

    const request = {
      emails: req.emails.map((e) => e.value),
      role: req.role.value,
      workspaces: req.workspace.map((w) => w.value),
    };

    try {
      if (inviteType === 'bulk') {
        const requestBulk = {
          emails: request.emails,
          role: request.role,
          workspace: request.workspaces[0],
        };
        await bulkInviteTeamMembers(requestBulk);
        onSuccess();
      } else if (type === 'workspace') {
        await addWorkspaceMember(request);
        onSuccess();
      } else {
        await addTeamMember(request);
        onSuccess();
      }
    } catch (error) {
      onError(error as AxiosError);
    }
  };
  const handleClose = (refetch?: boolean) => {
    reset();
    onClose?.(refetch);
  };

  const getCustomRoleLabel = (option) => <RoleBadge role={Number(option.value) as Role} />;

  const getCustomWorkspaceLabel = (option) => (
    <WorkspaceBadge key={option.label} i={option.index} label={option.label} />
  );

  // TODO: show loading when user detail is loading

  // isDirty was wrongly getting true
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  return (
    <BasicModal
      open={open}
      onClose={handleClose}
      modalTitle={inviteType === 'single' ? 'Add Member' : 'Bulk Invite to Workspace'}
      modalBody={
        <div className='pb-0 text-sm font-normal leading-default'>
          {
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}>
              <div className=''>
                <div className='mb-1'>Email Address</div>
                <TeamMemberEmailController control={control} inviteType={inviteType} type={type} />
              </div>
              {error?.length > 1 && !isDirtyAlt && (
                <p className='mb-2 mt-1 text-xs text-red-600' id='email-error'>
                  {error}
                </p>
              )}
              <div className='mt-4 grid grid-cols-2 gap-2 gap-y-0'>
                <div>Role</div>
                <div>Workspace</div>
                <SingleSelectController
                  name='role'
                  options={Roles.filter((r, index) => ![2].includes(index) && index <= 4).map((r) => ({
                    label: r.label,
                    value: r.value.toString(),
                  }))}
                  getCustomLabel={getCustomRoleLabel}
                  getCustomOptionLabel={getCustomRoleLabel}
                  control={control}
                  isCloseHidden
                />
                {isWorkspaceSelectDisabled ? (
                  <div className='mt-1 rounded-md border border-gray-200 bg-gray-50 p-2'>
                    <WorkspaceBadge i={0} label={currentWorkspace?.label} />
                  </div>
                ) : (
                  <CustomSelectController
                    name={'workspace'}
                    control={control}
                    getCustomLabel={getCustomWorkspaceLabel}
                    getCustomOptionLabel={getCustomWorkspaceLabel}
                    options={workspacesOptions}
                    isClearable={type === 'organisation'}
                  />
                )}
              </div>
            </form>
          }
        </div>
      }
      modalFooter={
        <div className='flex justify-end gap-4'>
          <Button variant='tertiary' disabled={isFormDisable} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button disabled={isFormDisable} onClick={() => handleSubmit(onSubmit)()}>
            {'Invite Team Member'}
          </Button>
        </div>
      }
    />
  );
};

export default TeamMemberInviteModal;

// Enable this User
// If you disable the user, the user access will be revoked and they will be unable to sign in.
