import * as React from 'react';
import CompassLoader from '../../../components/ui/components/Loader/CompassLoader';
import DetailPage from '../../../layouts/DetailPage';

import { useRouter } from '../../../modules/router/RouterProvider';
import { useCaseGetCase } from '../../../api/case';
import CaseMetadata from '../../../components/Case/CaseMetadata/CaseMetadata';
import CaseEntities from '../../../components/Case/CaseEntities/CaseEntities';
import CaseComments from '../../../components/Case/CaseComments/CaseComments';

interface CaseDetailsProps {
  id?: string;
}

const CaseDetails: React.FC<CaseDetailsProps> = () => {
  const { getParams } = useRouter();
  const caseId = encodeURIComponent(getParams().caseid || getParams().caseId);
  const { data: response, isLoading: detailLoading } = useCaseGetCase(Number(caseId), {
    useErrorBoundary: true,
  });

  if (detailLoading) {
    return <CompassLoader />;
  }

  return (
    <DetailPage>
      <CaseMetadata case={response?.data} />
      <div className='flex'>
        <CaseEntities caseData={response?.data} />
        <CaseComments id={response?.data?.id} />
      </div>
    </DetailPage>
  );
};

export default CaseDetails;
