import CustomSelect, { IOption } from './CustomSelect';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { profileApi } from '../../api/profile';
import { BasicBadge } from '../../ui';

interface UserFilterProps {
  value: number[];
  setValue: (risks: number[]) => void;
}

const UserFilter: FC<UserFilterProps> = (props) => {
  const { value, setValue } = props;

  const getLabel = (option: IOption) => {
    return <BasicBadge className='bg-gray-100'>{option.label}</BasicBadge>;
  };

  const getOptionLabel = (option: IOption) => {
    return option.label;
  };

  const { data: usersData } = useQuery(['userFilter.getUsers'], () => profileApi.getCaseUsers());

  const userOptions = usersData?.data?.results?.map((user) => {
    return {
      value: user.id.toString(),
      label: user.full_name,
    };
  });

  let selectedUsers = [];
  if (value && value.length) {
    selectedUsers = userOptions.filter((c) => value.includes(Number(c.value)));
  }
  const [users, setUsers] = useState<IOption[]>(selectedUsers);

  const handleChange = (users: IOption[]) => {
    setValue(users.map((r) => Number(r.value)));
    setUsers(users);
  };

  return (
    <>
      <CustomSelect
        label='Added by'
        placeholder='Select User'
        options={userOptions}
        getCustomLabel={getLabel}
        getCustomOptionLabel={getOptionLabel}
        handleChange={handleChange}
        value={users}
      />
    </>
  );
};

export default UserFilter;
