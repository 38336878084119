import React, { HTMLProps, useEffect, useRef } from 'react';
import { FC, ReactNode } from 'react';
// import { useAuth } from '../modules/auth';
import Page from './Page';
import { debounce } from 'lodash';

interface PageProps extends Omit<HTMLProps<HTMLDivElement>, 'action'> {
  title?: string;
  action?: ReactNode;
  containerClassName?: string;
  children: [ReactNode, ReactNode];
  alertDetails?: number;
}

const DetailPage: FC<PageProps> = (props) => {
  // const { setTabState } = useAuth();
  const rightSection = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (rightSection.current) {
      rightSection.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [props.alertDetails]);
  //TODO: fix tab not closing in detail page
  // useEffect(() => {
  //   setTabState({ isTabVisible: false });
  // }, []);

  const onWheel = debounce((e: React.WheelEvent<HTMLDivElement>) => {
    if (rightSection.current) {
      const scrollY = Math.abs(e.deltaY) === 1 ? e.deltaY * 100 : e.deltaY;
      rightSection.current.scrollBy({ top: scrollY, behavior: 'smooth' });
    }
  }, 200);

  return (
    <Page containerClassName={'!p-0'} className='flex' {...props}>
      <div
        style={{
          height: 'calc(100vh - 62px - var(--dashboard-tabs-height, 0px) - var(--screen-alert-height, 0px))',
        }}
        className='w-1/4 flex-none overflow-auto border-r border-gray-200 bg-gray-50'
        onWheel={onWheel}>
        {props.children[0]}
      </div>
      <div
        className='details-section w-full overflow-auto'
        ref={rightSection}
        style={{
          height: 'calc(100vh - 62px - var(--dashboard-tabs-height, 0px) - var(--screen-alert-height, 0px))',
        }}>
        {props.children[1]}
      </div>
    </Page>
  );
};

export default DetailPage;
