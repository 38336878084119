import * as React from 'react';

import { Checkbox } from '../../../ui';
import CalendarFilter, { IDateRangeString } from '../../Filters/CalendarFilter';
import RiskFilter from '../../Filters/RiskFilter';
import CurrencyFilter from '../../Filters/CurrencyFilter';
import RuleNameFilter from '../../Filters/RuleNameFilter';
import StageNameFilter from '../../Filters/StageFilter';
import { FilterPanel } from '../../FilterPanel';

export interface IOption {
  value: string;
  label: string;
}

export interface IFilters {
  risk_level: number[];
  currency: number[];
  created_start_date: string;
  created_end_date: string;
  start_date: string;
  end_date: string;
  transaction_start_date: string;
  transaction_end_date: string;
  is_included_in_case: boolean;
  rule_name: string[];
  workflow_stage?: (number | string)[];
}

export const defaultTransactionListFilters: IFilters = {
  risk_level: [],
  currency: [],
  created_start_date: null,
  created_end_date: null,
  start_date: null,
  end_date: null,
  transaction_start_date: null,
  transaction_end_date: null,
  is_included_in_case: null,
  rule_name: [],
  workflow_stage: [],
};

interface Props {
  filters: IFilters;
  onApply: (any) => void;
  onReset: () => void;
  disabled?: boolean;
  tab: number;
}

const TransactionListFilter: React.FC<Props> = ({ filters, onApply, disabled, tab }) => {
  const [riskLevel, setRiskLevel] = React.useState<number[]>([]);
  const [dateAdded, setDateAdded] = React.useState<IDateRangeString>();
  const [dateUpdated, setDateUpdated] = React.useState<IDateRangeString>();
  const [transactionDate, setTransactionDate] = React.useState<IDateRangeString>();
  const [currency, setCurrency] = React.useState<number[]>([]);
  const [includedInCase, setIncludedInCase] = React.useState<boolean>(null);
  const [ruleName, setRuleName] = React.useState<string[]>([]);
  const [workflow_stage, setStage] = React.useState<(number | string)[]>([]);

  const handleApply = () => {
    const newFilters: IFilters = {
      risk_level: riskLevel,
      currency: currency,
      created_start_date: dateAdded.from,
      created_end_date: dateAdded.to,
      start_date: dateUpdated.from,
      end_date: dateUpdated.to,
      transaction_start_date: transactionDate.from,
      transaction_end_date: transactionDate.to,
      is_included_in_case: includedInCase,
      rule_name: ruleName,
      workflow_stage: workflow_stage,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setRiskLevel([]);
    setDateAdded(null);
    setDateUpdated(null);
    setTransactionDate(null);
    setCurrency([]);
    setIncludedInCase(null);
    setRuleName([]);
    setStage([]);
    onApply(defaultTransactionListFilters);
  };

  React.useEffect(() => {
    setRiskLevel(filters.risk_level);
    setCurrency(filters.currency);
    setDateAdded({
      from: filters.created_start_date,
      to: filters.created_end_date,
    });
    setDateUpdated({
      from: filters.start_date,
      to: filters.end_date,
    });
    setTransactionDate({
      from: filters.transaction_start_date,
      to: filters.transaction_end_date,
    });
    setIncludedInCase(filters.is_included_in_case);
    setRuleName(filters.rule_name);
    setStage(filters.workflow_stage);
  }, [filters]);

  return (
    <FilterPanel tab={tab} onApply={handleApply} onReset={onReset} disabled={disabled}>
      <div />
      <RiskFilter value={riskLevel} setValue={setRiskLevel} />
      {tab !== 2 ? (
        <StageNameFilter is_status_stage={false} value={workflow_stage} setValue={setStage} />
      ) : (
        <></>
      )}
      <CurrencyFilter value={currency} setValue={setCurrency} />
      <CalendarFilter value={dateAdded} setValue={setDateAdded} label='Date Added' />
      <CalendarFilter value={dateUpdated} setValue={setDateUpdated} label='Date Updated' />
      <CalendarFilter value={transactionDate} setValue={setTransactionDate} label='Transaction Date' />
      <Checkbox
        checked={includedInCase}
        labelText={<span className='text-sm font-medium leading-5 text-gray-700'>Included in Case</span>}
        onChange={(e) => setIncludedInCase((e.target as HTMLInputElement).checked)}
      />
      <RuleNameFilter value={ruleName} setValue={setRuleName} type={'transaction'} />
    </FilterPanel>
  );
};

export default TransactionListFilter;
