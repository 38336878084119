import { FC } from 'react';
import MetaData from '../../../layouts/MetaData';
import { RiskBadge } from '../../../ui';
import CaseMetadataHeader from './CaseMetadataHeader';
import { formatOnlyDate } from '../../../utils/helpers/date';
import { ICaseResponse } from '../../../api/dtos/case';
import CopyToClipboardBtn from '../../../ui/components/Copy/Copy';
import CaseStatusDropdown from './CaseStatusDropdown';
import AssociatedCases from '../../AddressTransaction/AssociatedCases';
import AssignedToDropdown from './AssignedToDropdown';

interface CaseMetadataProps {
  case: ICaseResponse;
}

const CaseMetadata: FC<CaseMetadataProps> = (props) => {
  const { case: caseData } = props;

  const metaDetails = [
    {
      label: 'Case ID',
      value: (
        <div className='flex'>
          {caseData.case_id}
          <CopyToClipboardBtn text={caseData.case_id} className='ml-1' />
        </div>
      ),
    },
    {
      label: 'Status',
      value: <CaseStatusDropdown caseData={caseData} />,
    },
    {
      label: 'Assigned To',
      value: <AssignedToDropdown caseData={caseData} />,
    },
    { label: 'Risk Level', value: <RiskBadge risk={caseData.risk_level} /> },
    {
      label: 'Created',
      value: formatOnlyDate(caseData.created_at),
    },
    {
      label: 'Last Updated',
      value: formatOnlyDate(caseData.updated_at),
    },
    {
      label: 'Alerts',
      value: caseData?.open_alerts_count,
    },
  ];

  return (
    <>
      <MetaData title='CASE' header={<CaseMetadataHeader caseData={caseData} />} metaDetails={metaDetails} />
      <AssociatedCases associatedCases={caseData.associated_cases} isCase />
    </>
  );
};

export default CaseMetadata;
