import { FC } from 'react';
import { Button } from '../../../ui/components/Button';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import BasicModal from '../../../ui/components/Modals/BasicModal/BasicModal';

export interface TokenModalType {
  identifier: string;
  currency: number;
}

interface TokenAccountModalProps {
  tokenModal: { identifier: string; currency: number };
  setTokenModal: (val: TokenModalType) => void;
}

const TokenAccountModal: FC<TokenAccountModalProps> = ({ tokenModal, setTokenModal }) => {
  return (
    <BasicModal
      modalTitle='Token account'
      modalBody={
        <div className='text-sm text-gray-900'>
          <div>Current address is a token account. Please screen owner account address.</div>
          <div className='mt-2 flex'>
            Owner Address:
            <IdentifierEllipsis
              identifier={tokenModal?.identifier}
              currency={tokenModal?.currency}
              limit={50}
              type='addresses'
              className='ml-2 text-blue-500'
              clickable
              copyable
              onClick={() => setTokenModal(null)}
            />
          </div>
        </div>
      }
      modalFooter={
        <Button className='ml-auto flex' variant='primary' onClick={() => setTokenModal(null)}>
          OK
        </Button>
      }
      open={!!tokenModal}
      onClose={() => setTokenModal(null)}
    />
  );
};

export default TokenAccountModal;
