import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../FilterPanel';
import SingleSelect from '../ui/components/Select/SingleSelect';
import { policyCategoryOptions, policyTypeOptions } from '../../utils/constants/rules';
interface PolicyListFilterProps {
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
}

export interface IFilters {
  policyStatus: string;
  policyType: string;
  category: string;
}

export const defaultStateFilters: IFilters = {
  policyStatus: 'live',
  policyType: 'all',
  category: null,
};
const policyStatusOptions = [
  { label: 'Live', value: 'live' },
  { label: 'Deactivated', value: 'deactivated' },
  { label: 'All', value: 'all' },
];

const PolicyListFilter: FC<PolicyListFilterProps> = ({ filters, onApply }) => {
  const [policyStatus, setPolicyStatus] = useState<string>('');
  const [policyType, setPolicyType] = useState<string>('');
  const [category, setCategory] = useState<string>(null);

  const handleApply = () => {
    const newFilters = {
      policyStatus,
      policyType,
      category,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setPolicyStatus('');
    setPolicyType('');
    setCategory(null);
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    setPolicyStatus(filters.policyStatus);
    setPolicyType(filters.policyType);
    setCategory(filters.category);
  }, [filters]);

  return (
    <FilterPanel onApply={handleApply} onReset={onReset}>
      <SingleSelect
        options={policyStatusOptions}
        handleChange={(value) => setPolicyStatus(value?.value)}
        value={policyStatusOptions.find((option) => option.value === policyStatus)}
        label='Policy Status'
        labelClassName='text-sm'
      />
      <SingleSelect
        options={policyTypeOptions}
        handleChange={(value) => setPolicyType(value?.value)}
        value={policyTypeOptions.find((option) => option.value === policyType)}
        label='Policy Type'
        labelClassName='text-sm'
      />
      <SingleSelect
        options={policyCategoryOptions}
        handleChange={(value) => setCategory(value?.value)}
        value={policyCategoryOptions.find((option) => option.value === category)}
        label='Category'
        labelClassName='text-sm'
      />
    </FilterPanel>
  );
};

export default PolicyListFilter;
