import { useMutation } from 'react-query';
import { integrationApi } from '../../../../api/integration';
import { useEffectOnce } from 'react-use';
import { useRouter } from '../../../../modules/router/RouterProvider';
import { toast } from 'react-toastify';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';

const SlackConnect = () => {
  const { getQueryParams, navigate } = useRouter();
  const { mutate } = useMutation(integrationApi.slackAuthRedirect, {
    onSuccess: () => {
      toast.success('Slack connected');
      navigate('/settings/workspace/integrations/slack');
    },
    onError: () => {
      toast.error('Error connecting to slack');
      navigate('/dashboard');
    },
  });
  useEffectOnce(() => {
    const { code } = getQueryParams();
    if (!code) {
      navigate('/dashboard');
    } else {
      mutate({ code });
    }
  });
  return (
    <div className='flex flex-col'>
      <CompassLoader />
      <div className='text-center'>Connecting to Slack</div>
    </div>
  );
};

export default SlackConnect;
