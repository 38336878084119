import * as React from 'react';

import { CaretDown, CaretUp } from '@phosphor-icons/react';
import EntityWithSubtypeBadge from '../../ui/components/EntityTypeBadge';
import EmptyState from '../../../ui/components/States/Empty';

const formatUSD = (value) => {
  const nf = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return value ? `$${nf.format(value)}` : '$0.00';
};

interface TAG_AGGREGATED_VALUE {
  tag_name_verbose: string;
  tag_type_verbose: string;
  tag_subtype_verbose: string;
  exposure_type: string;
  total_value_usd: string;
}

interface Props {
  inputs: TAG_AGGREGATED_VALUE[];
  outputs: TAG_AGGREGATED_VALUE[];
  inputsType?: 'deposit' | 'withdrawal';
  maxHeightClass?: string;
}

interface ROW_TYPE {
  tag_name_verbose: string;
  tag_type_verbose: string;
  tag_subtype_verbose: string;
  incoming_direct: string;
  incoming_indirect: number;
  outgoing_direct: number;
  outgoing_indirect: number;
}

const AssociationRiskTable: React.FC<Props> = ({
  inputs,
  outputs,
  inputsType,
  maxHeightClass = 'max-h-80',
}) => {
  const [isAsc, setIsAsc] = React.useState(false);
  const [sortField, setSortField] = React.useState('incoming_direct');
  const isInputVisible = !inputsType || inputsType === 'deposit';
  const isOutputVisible = !inputsType || inputsType === 'withdrawal';
  const rows = React.useMemo<ROW_TYPE[]>(() => {
    const data = {};

    isInputVisible &&
      inputs.forEach((input) => {
        const key = input.tag_name_verbose;
        if (!key) return;

        if (data[key]) {
          if (input.exposure_type === 'direct') {
            data[key] = {
              ...data[key],
              incoming_direct: input.total_value_usd,
            };
          } else {
            data[key] = {
              ...data[key],
              incoming_indirect: input.total_value_usd,
            };
          }
        } else {
          if (input.exposure_type === 'direct') {
            data[key] = {
              tag_name_verbose: key,
              tag_type_verbose: input.tag_type_verbose,
              tag_subtype_verbose: input.tag_subtype_verbose,
              incoming_direct: input.total_value_usd,
              incoming_indirect: 0,
              outgoing_direct: 0,
              outgoing_indirect: 0,
            };
          } else {
            data[key] = {
              tag_name_verbose: key,
              tag_type_verbose: input.tag_type_verbose,
              tag_subtype_verbose: input.tag_subtype_verbose,
              incoming_direct: 0,
              incoming_indirect: input.total_value_usd,
              outgoing_direct: 0,
              outgoing_indirect: 0,
            };
          }
        }
      });

    isOutputVisible &&
      outputs.forEach((output) => {
        const key = output.tag_name_verbose;
        if (!key) return;

        if (data[key]) {
          if (output.exposure_type === 'direct') {
            data[key] = {
              ...data[key],
              outgoing_direct: output.total_value_usd,
            };
          } else {
            data[key] = {
              ...data[key],
              outgoing_indirect: output.total_value_usd,
            };
          }
        } else {
          if (output.exposure_type === 'direct') {
            data[key] = {
              tag_name_verbose: key,
              tag_type_verbose: output.tag_type_verbose,
              tag_subtype_verbose: output.tag_subtype_verbose,
              outgoing_direct: output.total_value_usd,
              outgoing_indirect: 0,
              incoming_direct: 0,
              incoming_indirect: 0,
            };
          } else {
            data[key] = {
              tag_name_verbose: key,
              tag_type_verbose: output.tag_type_verbose,
              tag_subtype_verbose: output.tag_subtype_verbose,
              outgoing_direct: 0,
              outgoing_indirect: output.total_value_usd,
              incoming_direct: 0,
              incoming_indirect: 0,
            };
          }
        }
      });

    return Object.values(data);
  }, [inputs, isInputVisible, isOutputVisible, outputs]);

  const CaretIcon = isAsc ? (
    <CaretDown color='#000' weight='bold' size={14} />
  ) : (
    <CaretUp color='#000' weight='bold' size={14} />
  );

  return (
    <div className={`${maxHeightClass} overflow-auto border-t`}>
      {inputsType && (
        <div className='border-t p-3 text-2xs uppercase text-gray-600'>
          Entities {inputsType === 'deposit' ? 'Depositing' : 'Withdrawing'} Funds
        </div>
      )}
      <table className='w-full table-auto text-sm text-gray-800'>
        <thead className='sticky top-0 z-20 h-7'>
          <tr className='bg-gray-100 text-gray-500'>
            <th></th>
            <th></th>
            {isInputVisible && (
              <th colSpan={2} className='py-1 pr-4 text-end text-2xs font-medium leading-5'>
                Incoming Exposure
              </th>
            )}
            {isOutputVisible && (
              <th colSpan={2} className='py-1 pr-4 text-end text-2xs font-medium leading-5'>
                Outgoing Exposure
              </th>
            )}
          </tr>
        </thead>
        <thead className='sticky top-7 z-20 bg-gray-100'>
          <tr className='sticky top-0 bg-gray-100 text-gray-500'>
            <th className='p-2.5 pl-3 text-left text-2xs font-medium'>
              <div
                className='flex cursor-pointer items-center gap-1'
                onClick={() => {
                  setSortField('tag_name_verbose');
                  setIsAsc(!isAsc);
                }}>
                <span>Entity Name</span>
                {sortField === 'tag_name_verbose' && CaretIcon}
              </div>
            </th>
            <th className='p-2.5 pl-3 text-left text-2xs font-medium'>
              <div
                className='flex cursor-pointer items-center gap-1'
                onClick={() => {
                  setSortField('tag_type_verbose');
                  setIsAsc(!isAsc);
                }}>
                <span>Entity Type</span>
                {sortField === 'tag_type_verbose' && CaretIcon}
              </div>
            </th>
            {isInputVisible && (
              <>
                <th className='p-2.5 pl-3 text-left text-2xs font-medium'>
                  <div
                    className='flex cursor-pointer items-center justify-end gap-1'
                    onClick={() => {
                      setSortField('incoming_direct');
                      setIsAsc(!isAsc);
                    }}>
                    <span>Direct</span>
                    {sortField === 'incoming_direct' && CaretIcon}
                  </div>
                </th>
                <th className='p-2.5 pl-3 text-2xs font-medium'>
                  <div
                    className='flex cursor-pointer items-center justify-end gap-1'
                    onClick={() => {
                      setSortField('incoming_indirect');
                      setIsAsc(!isAsc);
                    }}>
                    <span>Indirect</span>
                    {sortField === 'incoming_indirect' && CaretIcon}
                  </div>
                </th>
              </>
            )}
            {isOutputVisible && (
              <>
                <th className='p-2.5 pl-3 text-2xs font-medium'>
                  <div
                    className='flex cursor-pointer items-center justify-end gap-1'
                    onClick={() => {
                      setSortField('outgoing_direct');
                      setIsAsc(!isAsc);
                    }}>
                    <span>Direct</span>
                    {sortField === 'outgoing_direct' && CaretIcon}
                  </div>
                </th>
                <th className='p-2.5 pl-3 pr-4 text-2xs font-medium'>
                  <div
                    className='flex cursor-pointer items-center justify-end gap-1'
                    onClick={() => {
                      setSortField('outgoing_indirect');
                      setIsAsc(!isAsc);
                    }}>
                    <span>Indirect</span>
                    {sortField === 'outgoing_indirect' && CaretIcon}
                  </div>
                </th>
              </>
            )}
          </tr>
        </thead>
        {rows.length !== 0 ? (
          <tbody className='max-h-80 overflow-y-auto'>
            {rows
              .sort((r1, r2) => {
                if (r1[sortField] > r2[sortField]) {
                  return isAsc ? 1 : -1;
                } else if (r1[sortField] < r2[sortField]) {
                  return isAsc ? -1 : 1;
                } else {
                  return 0;
                }
              })
              .map((row, index) => (
                <tr key={index} className='text-2xs font-medium odd:bg-white even:bg-gray-50'>
                  <td className='p-2 py-2.5 pl-3 text-left'>{row.tag_name_verbose}</td>
                  <td className='p-2 py-2.5 pl-3 text-left'>
                    <EntityWithSubtypeBadge type={row.tag_type_verbose} subtype={row.tag_subtype_verbose} />
                  </td>
                  {isInputVisible && (
                    <>
                      <td className='p-2 py-2.5 pl-3 text-right'>{formatUSD(row.incoming_direct)}</td>
                      <td className='p-2 py-2.5 pl-3 text-right'>{formatUSD(row.incoming_indirect)}</td>
                    </>
                  )}
                  {isOutputVisible && (
                    <>
                      <td className='p-2 py-2.5 pl-3 text-right'>{formatUSD(row.outgoing_direct)}</td>
                      <td className='p-2 py-2.5 pl-3 pr-4 text-right'>{formatUSD(row.outgoing_indirect)}</td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody className='max-h-80 overflow-y-auto'>
            <tr>
              <td colSpan={6}>
                <EmptyState />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AssociationRiskTable;
