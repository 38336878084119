import { useQuery, UseQueryOptions } from 'react-query';
import { Api } from './api';
import { IAnalyticsResponse } from './dtos/analytics';

export class AnalyticsApi extends Api {
  readonly baseUrl: string = 'account-permissions';

  readonly getAnalytics = () => {
    return this.http.get<IAnalyticsResponse>('account-permissions');
  };
}

export const analyticsApi = new AnalyticsApi();

export const useAnalyticsGet = (options?: UseQueryOptions) => {
  const key = 'AnalyticsApi.getAnalytics';
  const result = useQuery(key, analyticsApi.getAnalytics, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};
