import { FC, useEffect } from 'react';
import { BasicModal, Button } from '../../../ui';
import { useMutation, useQueryClient } from 'react-query';
import { customTagsApi } from '../../../api/settings/customTags';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputController from '../../../ui/components/Form/InputController';
import { toast } from '../../../ui/components/Toast';
import TextareaController from '../../../ui/components/Form/TextareaController';
import { ICustomTagResult } from '../../../api/dtos/settings/customTags';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { AxiosError } from 'axios';

export interface CustomTagsModalProps {
  type: 'add' | 'edit' | null;
  onClose: () => void;
  customTag?: ICustomTagResult;
}

const validations = Yup.object({
  name: Yup.string().required('Tag name required'),
  description: Yup.string().required('Tag description required'),
});

const initialData = {
  name: '',
  description: '',
};

const CustomTagsModal: FC<CustomTagsModalProps> = (props) => {
  const { type, customTag, onClose } = props;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    setValue('name', customTag?.name);
    setValue('description', customTag?.description);
  }, [customTag?.description, customTag?.name, setValue]);

  const isFormDisable = isSubmitting;

  const queryClient = useQueryClient();

  const { mutate: createTag } = useMutation(customTagsApi.createCustomTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(['customTagsApi.getAddresses']);
      toast.success(
        'Custom Tag has been created, Please navigate to the risk policies to create custom rules for this tag'
      );
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });

  const { mutate: updateTag } = useMutation(customTagsApi.updateCustomTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(['customTagsApi.getAddresses']);
      toast.success('Custom Tag has been updated');
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });

  const modalTitle = type === 'add' ? 'Create Custom Tag' : 'Edit Custom Tag';

  const onSubmit = (req: typeof initialData) => {
    const request = {
      name: req.name,
      description: req.description,
      is_in_custom_list: false,
    };

    type === 'add' ? createTag(request) : updateTag({ id: customTag?.id, payload: request });

    onClose();
  };

  return (
    <BasicModal
      open={type != null}
      onClose={() => onClose()}
      modalTitle={modalTitle}
      modalBody={
        <div className='pb-0 text-sm font-normal leading-default'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4'>
              <InputController
                id='tagName'
                type='text'
                name='name'
                labelText='Tag Name'
                placeholder='Enter Tag Name'
                required={true}
                control={control}
                disabled={isFormDisable}
              />
            </div>
            <div>
              <TextareaController
                id='tagDescription'
                name='description'
                type='text'
                labelText='Tag Description'
                placeholder='Enter Tag Description'
                required={true}
                control={control}
                height={100}
                disabled={isFormDisable}
              />
            </div>
          </form>
        </div>
      }
      modalFooter={
        <div className='flex justify-end gap-4'>
          <Button variant='tertiary' disabled={isFormDisable} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button disabled={isFormDisable} onClick={() => handleSubmit(onSubmit)()}>
            {type === 'add' ? 'Create' : 'Update'} Tag
          </Button>
        </div>
      }
    />
  );
};

export default CustomTagsModal;
