import CustomSelect, { IOption } from './CustomSelect';

import { FC, useState } from 'react';

interface RuleConditionFilterProps {
  value: string[];
  setValue: (ruleConditions: string[]) => void;
}

const RuleConditionFilter: FC<RuleConditionFilterProps> = (props) => {
  const { value, setValue } = props;

  const ruleConditionsOptions = [
    { value: 'direct', label: 'Direct' },
    { value: 'indirect', label: 'Indirect' },
  ];

  let selectedRuleConditions = [];
  if (value && value.length) {
    selectedRuleConditions = ruleConditionsOptions.filter((r) => value.includes(r.value));
  }
  const [ruleConditions, setRuleConditions] = useState<IOption[]>(selectedRuleConditions);

  const handleChange = (ruleConditions: IOption[]) => {
    setValue(ruleConditions.map((r) => r.value));
    setRuleConditions(ruleConditions);
  };

  return (
    <>
      <CustomSelect
        label='Exposure Type'
        placeholder='Select Exposure Type'
        options={ruleConditionsOptions}
        handleChange={handleChange}
        value={ruleConditions}
      />
    </>
  );
};

export default RuleConditionFilter;
