import { FC } from 'react';
import NotificationsForm from '../../../../components/Settings/Notifications/NotificationsForm';
import { TabHeader } from '../../../../components/ui/components/Header';

const EmailNotifications: FC = () => {
  return (
    <div className='h-full'>
      <TabHeader
        heading='Email Notifications Settings'
        subheading='Choose what notifications you want to receive via Email.'
      />
      <div className='max-w-3xl'>
        <NotificationsForm />
      </div>
    </div>
  );
};

export default EmailNotifications;
