import { FC } from 'react';
import MetaData from '../../../layouts/MetaData';
import { useAuth } from '../../../modules/auth';
import { getDigitalAssets } from '../../../utils/helpers/currency';
import { RiskBadge } from '../../../ui';
import DigitalAssets from '../../ui/components/Currency/DigitalAssets';
import AddToCustomer from '../../AddressTransaction/AddToCustomer';
// import { useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../ui/components/BreadCrumb';
import { useRouter } from '../../../modules/router/RouterProvider';
import { ITransactionResponse } from '../../../api/dtos/transaction';
import TransactionMetadataHeader from './TransactionMetadataHeader';
import TransactionValue from '../TransactionValue';
import { Fee, TransactionTime } from './TransactionUtils';

interface TransactionDetailsMetadataProps {
  transaction: ITransactionResponse;
  alerts?: number;
  customerAddresses?: string[];
}

const TransactionDetailsMetadata: FC<TransactionDetailsMetadataProps> = (props) => {
  const { transaction, alerts, customerAddresses } = props;
  const { state } = useAuth();

  // const [searchParams] = useSearchParams();
  // const caseId = searchParams.get('caseId');
  // const entityId = searchParams.get('entityId');
  // const refCase = searchParams.get('refCase');
  // TODO: Use from library directly

  const { navigate, getQueryParams } = useRouter();

  const { caseId, entityId, refCase } = getQueryParams();

  const isCaseReferenced = caseId && refCase && entityId;

  const metaDetails = [
    { label: 'Risk Level', value: <RiskBadge risk={transaction.risk_level} /> },
    { label: 'Open Alerts', value: alerts || 0 },
    {
      label: 'Digital Asset',
      value: <DigitalAssets digitalAssets={getDigitalAssets(transaction)} noBadge />,
    },
    {
      label: 'Transaction Time',
      value: <TransactionTime state={state} transaction={transaction} />,
    },
    {
      label: 'Value',
      value: <TransactionValue transaction={transaction} />,
    },
    {
      label: 'Fee',
      value: <Fee transaction={transaction} />,
    },
    {
      label: 'Customer ID',
      value: (
        <div
          className='cursor-pointer break-all text-blue-600 underline'
          onClick={() => navigate(`/customers/${transaction.customer_id}`)}>
          {transaction.customer_id}
        </div>
        // TODO: Use react-router Link
      ),
      isHidden: !transaction.customer_id,
    },
    {
      label: 'Type',
      value: transaction.type === 1 ? 'Deposit' : 'Withdrawal',
      isHidden: !transaction.type,
    },
  ];

  return (
    <MetaData
      title='TRANSACTION'
      preHeader={
        isCaseReferenced && (
          <Breadcrumb
            items={[{ label: caseId, to: `/cases/${refCase}` }, { label: transaction.identifier }]}
            className='mb-2'
          />
        )
      }
      header={<TransactionMetadataHeader transaction={transaction} />}
      metaDetails={metaDetails}
      footer={
        !transaction.customer_id &&
        !state.userProfile?.skip_alert_creation_modes?.includes(1) && (
          <AddToCustomer
            type='transaction'
            identifier={transaction.identifier}
            currency={transaction.currency}
            addresses={customerAddresses}
          />
        )
      }
    />
  );
};

export default TransactionDetailsMetadata;
