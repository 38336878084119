import { FC, useEffect, useMemo, useState } from 'react';
import Page from '../../layouts/Page';
import PagePdf from '../../components/pdf/Page';
import { useAuth, withAuthUser } from '../../modules/auth';
import { withAuthProps } from '../../modules/auth/components/withAuthUser';
import CounterWidget from '../../components/Dashboard/CountWidget';
import {
  dashboardApi,
  useDashboardAlerts,
  useDashboardAlertsResolutionRecord,
  useDashboardCases,
  useDashboardEntities,
  useDashboardRules,
} from '../../api/dashboard';
import CaseDistributionWidget from '../../components/Dashboard/CaseDistributionWidget';
import RiskLevelWidget from '../../components/Dashboard/RiskLevelWidget';
import AlertResolutionWidget from '../../components/Dashboard/AlertResolutionWidget';
import PieWidget2 from '../../components/Dashboard/PieWidget2';
import RulesTriggeredWidget from '../../components/Dashboard/RulesTriggeredWidget';
import CurrencyDistributionWidget from '../../components/Dashboard/CurrencyDistributionWidget';
import CalendarFilter, { IDateRangeString } from '../../components/Filters/CalendarFilter';
import { format, subDays } from 'date-fns';
import { useRouter } from '../../modules/router/RouterProvider';
import { Button, DropdownOption } from '../../ui';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ArrowsClockwise, CalendarSlash, DownloadSimple } from '@phosphor-icons/react';
import classNames from 'classnames';
import Popover from '../../ui/components/Popover/Popover';
import { formatDateTime } from '../../utils/helpers/date';
import { useWorkspace } from '../../utils/helpers/common';
import { startCase } from 'lodash';
import PieWidget3 from '../../components/Dashboard/PieWidget3';
import WidgetTooltip from '../../components/Dashboard/WidgetTooltip';

interface DashboardProps extends withAuthProps {
  isPdf?: boolean;
  firstName?: string;
}

interface ActionsProps {
  showCalender?: boolean;
  showDownload?: boolean;
  showTooltip?: boolean;
  downloadIsLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadOnClick?: any;
  tooltipTitle?: string;
  tooltipDescription?: string;
}

const Wrapper = ({ isPdf, children, workspace }) => {
  const content = <div className='mb-4 grid grid-cols-12 gap-4'>{children}</div>;
  return isPdf ? <PagePdf footer={`Dashboard Report (Workspace: ${workspace})`}>{content}</PagePdf> : content;
};

const Dashboard: FC<DashboardProps> = (props) => {
  const { isPdf, authUser, firstName } = props;
  const { navigate, getQueryParams } = useRouter();
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();
  const { start_date, end_date } = getQueryParams();

  const date = {
    from: start_date || format(subDays(new Date(), 6), 'yyyy-MM-dd'),
    to: end_date || format(new Date(), 'yyyy-MM-dd'),
  };
  const [dateRange, setDateRange] = useState<IDateRangeString>(date);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefetch, setIsRefetch] = useState(0);
  const [dropdownValue, setDropdownValue] = useState<DropdownOption>();
  const workspace = useWorkspace();

  const apiParam = useMemo(
    () => ({
      start_date: dateRange?.from,
      end_date: dateRange?.to,
      refresh: isRefresh,
      refetch: isRefetch,
    }),
    [dateRange?.from, dateRange?.to, isRefresh, isRefetch]
  );

  useEffect(() => {
    if (dateRange?.from && dateRange?.to && (dateRange?.from !== date.from || dateRange?.to !== date.to)) {
      navigate(`/dashboard`, {
        start_date: dateRange.from,
        end_date: dateRange.to,
      });
    }
  });

  const { data: entitiesData, isFetching: entitiesLoading } = useDashboardEntities(apiParam);
  const { data: casesData, isFetching: casesLoading } = useDashboardCases(apiParam);
  const { data: rulesData, isFetching: rulesLoading } = useDashboardRules(apiParam);
  const { data: alertsData, isFetching: alertsLoading } = useDashboardAlerts(apiParam);
  // const { data: alertsEntityData, isFetching: alertsEntityLoading } = useDashboardAlertsEntity(apiParam);
  const { data: alertsResolutionRecordData, isFetching: alertsResolutionRecordLoading } =
    useDashboardAlertsResolutionRecord(apiParam);

  const caseOverviewPieData = [
    ['Automatic', casesData?.data?.cases_summary?.auto_created_cases ?? 0],
    [
      'Manual',
      casesData?.data?.cases_summary?.total_cases_created -
        casesData?.data?.cases_summary?.auto_created_cases || 0,
    ],
  ];

  const alertStatusPieData = [
    ['Open', alertsData?.data?.alerts_summary?.alerts_status_data['0'] ?? 0],
    ['In Progress', alertsData?.data?.alerts_summary?.alerts_status_data['1'] ?? 0],
    ['Closed', alertsData?.data?.alerts_summary?.alerts_status_data['2'] ?? 0],
  ];

  const addressesRiskLevelPieData = [
    ['1', entitiesData?.data?.entities_summary?.address_risk_level_count?.['1'] ?? 0],
    ['2', entitiesData?.data?.entities_summary?.address_risk_level_count?.['2'] ?? 0],
    ['3', entitiesData?.data?.entities_summary?.address_risk_level_count?.['3'] ?? 0],
    ['4', entitiesData?.data?.entities_summary?.address_risk_level_count?.['4'] ?? 0],
    ['5', entitiesData?.data?.entities_summary?.address_risk_level_count?.['5'] ?? 0],
  ];

  const transactionRiskLevelPieData = [
    ['1', entitiesData?.data?.entities_summary?.transaction_risk_level_count?.['1'] ?? 0],
    ['2', entitiesData?.data?.entities_summary?.transaction_risk_level_count?.['2'] ?? 0],
    ['3', entitiesData?.data?.entities_summary?.transaction_risk_level_count?.['3'] ?? 0],
    ['4', entitiesData?.data?.entities_summary?.transaction_risk_level_count?.['4'] ?? 0],
    ['5', entitiesData?.data?.entities_summary?.transaction_risk_level_count?.['5'] ?? 0],
  ];

  const customerRiskLevelPieData = [
    ['1', entitiesData?.data?.entities_summary?.customer_risk_level_count?.['1'] ?? 0],
    ['2', entitiesData?.data?.entities_summary?.customer_risk_level_count?.['2'] ?? 0],
    ['3', entitiesData?.data?.entities_summary?.customer_risk_level_count?.['3'] ?? 0],
    ['4', entitiesData?.data?.entities_summary?.customer_risk_level_count?.['4'] ?? 0],
    ['5', entitiesData?.data?.entities_summary?.customer_risk_level_count?.['5'] ?? 0],
  ];

  const tooltips = {
    casesCreated: {
      title: 'Cases Created',
      description:
        'Cases created counts the total number of cases automatically or manually created in the selected period of time as well as the percentage change since the last period based on the date range selected.',
    },
    casesResolved: {
      title: 'Cases Resolved',
      description:
        'Cases resolved counts the total number of cases automatically or manually resolved in the selected period of time as well as the percentage change since the last period based on the date range selected.',
    },
    casesOpened: {
      title: 'Cases Pending',
      description: 'Number of cases whose status is OPEN as of now & pending for resolution',
    },
    alertsCreated: {
      title: 'Alerts Created',
      description:
        'Alerts created counts the total number of alerts created in the selected period of time as well as the percentage change since the last period based on the date range selected.',
    },
    alertsOpened: {
      title: 'Alerts Pending',
      description:
        'Alerts that are not closed but open or work-in-progress as of now. Time period is not applicable here.',
    },
    alertsClosed: {
      title: 'Alerts Closed',
      description:
        'Alerts closed indicates the total number of alerts that are marked as <No Review Required> or <Resolved> or <Inactive> in the selected time period. Percentage change indicates the change compared to previous period.',
    },
    caseCreationBreakdown: {
      title: 'Case Creation Breakdown',
      description:
        'Case Creation Breakdown provides the number of cases created manually by an analyst vs. automatically based on the product settings in the selected period.',
    },
    alertStatus: {
      title: 'Alert Status',
      description:
        'Alert Status provides a breakdown of Open vs. Resolved vs. Closed cases in the selected period.',
    },
    currencyDistributions: {
      title: 'Currency Distributions',
      description:
        'Currency Distribution provides a blockchain breakdown of all screenings, transactions, or addresses searched in Compass during the selected period.',
    },
    pendingAddresses: {
      title: 'Addresses pending',
      tooltipTitle: 'Addresses pending',
      tooltipDescription:
        'Number of Addresses with associated open alerts for review and escalation/resolution',
    },
    pendingTransactions: {
      title: 'Transactions pending',
      tooltipTitle: 'Transactions pending',
      tooltipDescription:
        'Number of Transactions with associated open alerts for review and escalation/resolution',
    },
    pendingCustomers: {
      title: 'Customers pending',
      tooltipTitle: 'Customers pending',
      tooltipDescription:
        'Number of Customers with associated open alerts for review and escalation/resolution',
    },
  };

  const { mutate, isLoading } = useMutation(dashboardApi.getDashboardReport, {
    onSuccess: (data) => {
      toast.success(data.data.message);
    },
  });

  const onDownload = () => {
    mutate(apiParam);
  };

  const { mutate: mutateResolvedAlertReport, isLoading: isResolvedAlertReportLoading } = useMutation(
    dashboardApi.getDashboardResolvedAlertReport,
    {
      onSuccess: (data) => {
        toast.success(data.data.message);
      },
    }
  );

  const { mutate: mutateOpenedAlertReport, isLoading: isOpenedAlertReportLoading } = useMutation(
    dashboardApi.getDashboardOpenedAlertReport,
    {
      onSuccess: (data) => {
        toast.success(data.data.message);
      },
    }
  );

  const { mutate: mutateCreatedAlertReport, isLoading: isCreatedAlertReportLoading } = useMutation(
    dashboardApi.getDashboardCreatedAlertReport,
    {
      onSuccess: (data) => {
        toast.success(data.data.message);
      },
    }
  );

  const onDownloadResolveAlert = () => {
    mutateResolvedAlertReport(apiParam);
  };

  const onDownloadOpenAlert = () => {
    mutateOpenedAlertReport(apiParam);
  };

  const onRefresh = () => {
    setIsRefresh(true);
    setIsRefetch((prev) => prev + 1);
  };

  const isRefreshVisible = useMemo(() => {
    return ['Last 7 Days', 'Last 30 Days', 'This Month'].includes(dropdownValue?.label.toString());
  }, [dropdownValue?.label]);

  const Actions = ({
    showCalender,
    showDownload,
    showTooltip,
    downloadIsLoading,
    downloadOnClick,
    tooltipTitle,
    tooltipDescription,
  }: ActionsProps) => {
    return (
      <div className='flex'>
        {showCalender && (
          <Popover
            referenceContent={
              <CalendarSlash size={16} className={classNames('mr-2 mt-0.5 cursor-pointer line-through')} />
            }
            className='mt-2 rounded-md border bg-white p-2 text-xs text-gray-800'
            popoverContent={'The time period filter is not applied to this widget'}
          />
        )}
        {showDownload && (
          <Popover
            referenceContent={
              <DownloadSimple
                size={16}
                className={classNames('mr-2 mt-0.5', {
                  'cursor-pointer hover:text-blue-600': !downloadIsLoading,
                })}
                onClick={downloadOnClick}
              />
            }
            className='mt-2 rounded-md border bg-white p-2 text-xs text-gray-800'
            popoverContent={'Maximum records: 10000'}
          />
        )}
        {showTooltip && <WidgetTooltip title={tooltipTitle} description={tooltipDescription} />}
      </div>
    );
  };

  const PieWidget3Wrapper = ({ toolTipData, pieData, isLoading, link, isRisk, isPdf = false }) => {
    return (
      <PieWidget3
        title={toolTipData.title}
        tooltipTitle={toolTipData.tooltipTitle}
        tooltipDescription={toolTipData.tooltipDescription}
        data={pieData}
        isLoading={isLoading}
        widgetHeader={
          <Actions
            showCalender
            showTooltip
            tooltipTitle={toolTipData.tooltipTitle}
            tooltipDescription={toolTipData.tooltipDescription}
          />
        }
        link={link}
        isRisk={isRisk}
        isPdf={isPdf}
      />
    );
  };

  const PieWidget3All = ({ isPdf = false }) => {
    return (
      <>
        <PieWidget3Wrapper
          toolTipData={tooltips.pendingAddresses}
          pieData={addressesRiskLevelPieData}
          isLoading={entitiesLoading}
          link={{ to: '/addresses', params: { risk_level: '5,4,3,2,1', tab: 'all_addresses' } }}
          isRisk
          isPdf={isPdf}
        />
        <PieWidget3Wrapper
          toolTipData={tooltips.pendingTransactions}
          pieData={transactionRiskLevelPieData}
          isLoading={entitiesLoading}
          link={{ to: '/transactions', params: { risk_level: '5,4,3,2,1', tab: 'all_transactions' } }}
          isRisk
          isPdf={isPdf}
        />
        <PieWidget3Wrapper
          toolTipData={tooltips.pendingCustomers}
          pieData={customerRiskLevelPieData}
          isLoading={entitiesLoading}
          link={{ to: '/customers', params: { risk_level: '5,4,3,2,1', tab: 'all_customers' } }}
          isRisk
          isPdf={isPdf}
        />
      </>
    );
  };

  if (!isPdf)
    return (
      <Page containerClassName='bg-gray-50'>
        <div className='my-3.5 flex justify-between'>
          <div className='text-[24px] font-semibold'>{`Welcome ${
            authUser?.first_name ?? firstName ?? ''
          }!`}</div>
          <div className='flex items-center'>
            {isRefreshVisible && (
              <div className='mr-1 flex items-center text-sm'>
                <span className='mr-2 text-gray-600'>Last refreshed on</span>
                {formatDateTime(alertsData?.data?.last_refreshed_time, timezone)}
                {alertsData?.data?.last_refreshed_time && ` (${timezone})`}
                <Popover
                  referenceContent={
                    <ArrowsClockwise
                      onClick={onRefresh}
                      size={20}
                      weight='bold'
                      className='mx-2 cursor-pointer transition hover:rotate-180 hover:text-blue-600'
                    />
                  }
                  isBasic
                  popoverContent={'Refresh now'}
                />
              </div>
            )}
            <Button className='mr-6' onClick={onDownload} disabled={isLoading}>
              <DownloadSimple size={16} className='mr-2' />
              Download
            </Button>
            <CalendarFilter
              isOutsideFilter
              value={dateRange}
              setValue={setDateRange}
              onDropdownChange={setDropdownValue}
            />
          </div>
        </div>
        <Wrapper isPdf={isPdf} workspace={startCase(workspace.slug)}>
          <>
            <div className='col-span-8'>
              <div className='grid grid-cols-3 gap-4'>
                <CounterWidget
                  title={tooltips.alertsCreated.title}
                  action={
                    <Actions
                      showDownload
                      downloadOnClick={
                        !isCreatedAlertReportLoading ? () => mutateCreatedAlertReport(apiParam) : null
                      }
                      downloadIsLoading={isCreatedAlertReportLoading}
                    />
                  }
                  value={alertsData?.data?.alerts_summary?.total_alerts_created}
                  oldValue={alertsData?.data?.alerts_summary?.previous_period_total_alerts_created}
                  tooltipTitle={tooltips.alertsCreated.title}
                  tooltipDescription={tooltips.alertsCreated.description}
                  isLoading={alertsLoading}
                  link={{ to: '/alerts', params: { tab: '4' } }}
                />
                <CounterWidget
                  title={tooltips.alertsClosed.title}
                  action={
                    <Actions
                      showDownload
                      downloadOnClick={!isResolvedAlertReportLoading ? onDownloadResolveAlert : null}
                      downloadIsLoading={isResolvedAlertReportLoading}
                    />
                  }
                  value={alertsData?.data?.alerts_summary?.total_alerts_resolved}
                  oldValue={alertsData?.data?.alerts_summary?.previous_period_total_alerts_resolved}
                  tooltipTitle={tooltips.alertsClosed.title}
                  tooltipDescription={tooltips.alertsClosed.description}
                  isLoading={alertsLoading}
                  link={{ to: '/alerts', params: { tab: '3' } }}
                />
                <CounterWidget
                  title={tooltips.alertsOpened.title}
                  action={
                    <Actions
                      showCalender
                      showDownload
                      downloadOnClick={!isOpenedAlertReportLoading ? onDownloadOpenAlert : null}
                      downloadIsLoading={isOpenedAlertReportLoading}
                    />
                  }
                  value={alertsData?.data?.alerts_summary?.total_alerts_opened}
                  tooltipTitle={tooltips.alertsOpened.title}
                  tooltipDescription={tooltips.alertsOpened.description}
                  isLoading={alertsLoading}
                  link={{ to: '/alerts', params: { tab: '0' } }}
                />
                <PieWidget3All />

                <CounterWidget
                  title={tooltips.casesCreated.title}
                  value={casesData?.data?.cases_summary?.total_cases_created}
                  oldValue={casesData?.data?.cases_summary?.previous_period_total_cases_created}
                  tooltipTitle={tooltips.casesCreated.title}
                  tooltipDescription={tooltips.casesCreated.description}
                  isLoading={casesLoading}
                />
                <CounterWidget
                  title={tooltips.casesResolved.title}
                  value={casesData?.data?.cases_summary?.total_cases_resolved}
                  oldValue={casesData?.data?.cases_summary?.previous_period_total_cases_resolved}
                  tooltipTitle={tooltips.casesResolved.title}
                  tooltipDescription={tooltips.casesResolved.description}
                  isLoading={casesLoading}
                />
                <CounterWidget
                  title={tooltips.casesOpened.title}
                  value={casesData?.data?.cases_summary?.total_cases_opened}
                  tooltipTitle={tooltips.casesOpened.title}
                  tooltipDescription={tooltips.casesOpened.description}
                  isLoading={casesLoading}
                  action={<Actions showCalender />}
                />
              </div>
            </div>
            <CurrencyDistributionWidget
              addressData={entitiesData?.data?.entities_summary?.address_currency_count}
              transactionData={entitiesData?.data?.entities_summary?.transaction_currency_count}
              isLoading={entitiesLoading}
            />
            <PieWidget2
              title='Alert Status'
              data={alertStatusPieData}
              isLoading={alertsLoading}
              tooltipTitle={tooltips.alertStatus.title}
              tooltipDescription={tooltips.alertStatus.description}
            />
            <RiskLevelWidget
              risksData={alertsData?.data?.alerts_summary?.alerts_risk_data}
              isLoading={alertsLoading}
            />
            <AlertResolutionWidget
              resolutionData={alertsResolutionRecordData?.data?.alerts_resolution_record}
              isLoading={alertsResolutionRecordLoading}
            />
          </>
        </Wrapper>
        <Wrapper isPdf={isPdf} workspace={startCase(workspace.slug)}>
          <>
            <RulesTriggeredWidget rules={rulesData?.data?.rules_summary} isLoading={rulesLoading} />
            <PieWidget2
              title='Case Overview'
              data={caseOverviewPieData}
              isLoading={casesLoading}
              tooltipTitle={tooltips.caseCreationBreakdown.title}
              tooltipDescription={tooltips.caseCreationBreakdown.description}
            />
            <CaseDistributionWidget
              casesCount={casesData?.data?.cases_summary?.total_cases_created}
              previousCasesCount={casesData?.data?.cases_summary?.previous_period_total_cases_created}
              timeSeriesData={casesData?.data?.cases_summary?.time_series_by_risk_level}
              isLoading={casesLoading}
            />
          </>
        </Wrapper>
      </Page>
    );

  return (
    <Page containerClassName='bg-gray-50'>
      <Wrapper isPdf={isPdf} workspace={startCase(workspace.slug)}>
        <>
          <CounterWidget
            isPdf
            title={tooltips.alertsCreated.title}
            action={
              <Actions
                showDownload
                downloadOnClick={
                  !isCreatedAlertReportLoading ? () => mutateCreatedAlertReport(apiParam) : null
                }
                downloadIsLoading={isCreatedAlertReportLoading}
              />
            }
            value={alertsData?.data?.alerts_summary?.total_alerts_created}
            oldValue={alertsData?.data?.alerts_summary?.previous_period_total_alerts_created}
            tooltipTitle={tooltips.alertsCreated.title}
            tooltipDescription={tooltips.alertsCreated.description}
            isLoading={alertsLoading}
          />
          <CounterWidget
            isPdf
            title={tooltips.alertsClosed.title}
            action={
              <Actions
                showDownload
                downloadOnClick={!isResolvedAlertReportLoading ? onDownloadResolveAlert : null}
                downloadIsLoading={isResolvedAlertReportLoading}
              />
            }
            value={alertsData?.data?.alerts_summary?.total_alerts_resolved}
            oldValue={alertsData?.data?.alerts_summary?.previous_period_total_alerts_resolved}
            tooltipTitle={tooltips.alertsClosed.title}
            tooltipDescription={tooltips.alertsClosed.description}
            isLoading={alertsLoading}
          />
          <CounterWidget
            isPdf
            title={tooltips.alertsOpened.title}
            action={
              <Actions
                showDownload
                downloadOnClick={!isOpenedAlertReportLoading ? onDownloadOpenAlert : null}
                downloadIsLoading={isOpenedAlertReportLoading}
              />
            }
            value={alertsData?.data?.alerts_summary?.total_alerts_opened}
            tooltipTitle={tooltips.alertsOpened.title}
            tooltipDescription={tooltips.alertsOpened.description}
            isLoading={alertsLoading}
          />

          <CounterWidget
            isPdf
            title={tooltips.casesCreated.title}
            value={casesData?.data?.cases_summary?.total_cases_created}
            oldValue={casesData?.data?.cases_summary?.previous_period_total_cases_created}
            tooltipTitle={tooltips.casesCreated.title}
            tooltipDescription={tooltips.casesCreated.description}
            isLoading={casesLoading}
          />
          <CounterWidget
            isPdf
            title={tooltips.casesResolved.title}
            value={casesData?.data?.cases_summary?.total_cases_resolved}
            oldValue={casesData?.data?.cases_summary?.previous_period_total_cases_resolved}
            tooltipTitle={tooltips.casesResolved.title}
            tooltipDescription={tooltips.casesResolved.description}
            isLoading={casesLoading}
          />
          <CounterWidget
            isPdf
            title={tooltips.casesOpened.title}
            value={casesData?.data?.cases_summary?.total_cases_opened}
            tooltipTitle={tooltips.casesOpened.title}
            tooltipDescription={tooltips.casesOpened.description}
            isLoading={casesLoading}
          />
          <CurrencyDistributionWidget
            isPdf
            addressData={entitiesData?.data?.entities_summary?.address_currency_count}
            transactionData={entitiesData?.data?.entities_summary?.transaction_currency_count}
            isLoading={entitiesLoading}
          />
        </>
      </Wrapper>
      <Wrapper isPdf={isPdf} workspace={startCase(workspace.slug)}>
        <PieWidget3All isPdf />
      </Wrapper>
      <Wrapper isPdf={isPdf} workspace={startCase(workspace.slug)}>
        <>
          <PieWidget2
            isPdf
            title='Alert Status'
            data={alertStatusPieData}
            isLoading={alertsLoading}
            tooltipTitle={tooltips.alertStatus.title}
            tooltipDescription={tooltips.alertStatus.description}
          />
          <RiskLevelWidget
            isPdf
            risksData={alertsData?.data?.alerts_summary?.alerts_risk_data}
            isLoading={alertsLoading}
          />
          <AlertResolutionWidget
            isPdf
            resolutionData={alertsResolutionRecordData?.data?.alerts_resolution_record}
            isLoading={alertsResolutionRecordLoading}
          />
        </>
      </Wrapper>
      <Wrapper isPdf={isPdf} workspace={startCase(workspace.slug)}>
        <>
          <RulesTriggeredWidget isPdf rules={rulesData?.data?.rules_summary} isLoading={rulesLoading} />
          <PieWidget2
            isPdf
            title='Case Overview'
            data={caseOverviewPieData}
            isLoading={casesLoading}
            tooltipTitle={tooltips.caseCreationBreakdown.title}
            tooltipDescription={tooltips.caseCreationBreakdown.description}
          />
          <CaseDistributionWidget
            isPdf
            casesCount={casesData?.data?.cases_summary?.total_cases_created}
            previousCasesCount={casesData?.data?.cases_summary?.previous_period_total_cases_created}
            timeSeriesData={casesData?.data?.cases_summary?.time_series_by_risk_level}
            isLoading={casesLoading}
          />
        </>
      </Wrapper>
    </Page>
  );
};

export default withAuthUser(Dashboard);
