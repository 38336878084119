import { FC } from 'react';
import CopyToClipboardBtn from '../../../../ui/components/Copy/Copy';
import { cx } from '../../../../utils/helpers/common';
import { ellipsis } from '../../../../utils/helpers/helperFunctions';
import { isAddressScreeningDisabled } from '../../../../utils/helpers/currency';
import { useScreenApi } from '../../../../utils/helpers/apiHelpers';
import { Folder } from '@phosphor-icons/react';

interface IdentifierEllipsisProps {
  identifier: string;
  limit?: number;
  copyable?: boolean;
  className?: string;
  type?: 'addresses' | 'transactions';
  currency?: number;
  clickable?: boolean;
  includedInCase?: boolean;
  isCreateCaseDisabled?: boolean;
  customerId?: string;
  customerType?: number;
  onClick?: () => void;
}

const IdentifierEllipsis: FC<IdentifierEllipsisProps> = ({
  identifier,
  limit = 12,
  currency,
  type,
  copyable,
  className,
  clickable,
  includedInCase = false,
  customerId,
  isCreateCaseDisabled = false,
  customerType,
  onClick: onClickProp,
}) => {
  const screenApi = useScreenApi();

  if (!identifier) {
    return null;
  }

  const formatIdentifier = (val: string) => {
    if (type === 'addresses') {
      // Format address for `0xDate` to `0x` for Stellar
      const isValidDate = !isNaN(new Date(val.replace(/^0x/, '')).getTime());
      val = isValidDate ? '0x' : val;

      // If address contains _ show string before _
      val = val.includes('_') ? val.split('_')[0] : val;
    }
    return val;
  };

  const formattedIdentifier = formatIdentifier(identifier);

  const isScreeningDisabled = type === 'addresses' && isAddressScreeningDisabled(identifier, currency);

  const isClickable = clickable && !isScreeningDisabled;

  const onClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!isClickable) return;
    onClickProp?.();
    if (currency == null || !type) return;
    e.stopPropagation();
    screenApi?.({
      identifier,
      currency,
      isCreateCaseDisabled,
      entityType: type,
      customer_id: customerId,
      type: customerType,
    });
  };

  return (
    <div
      className={cx(`flex ${className} ${isClickable ? 'cursor-pointer text-blue-600' : 'text-gray-600'}`)}
      onClick={onClick}>
      <span className='font-mono' title={identifier}>
        {ellipsis(formattedIdentifier, limit)}
      </span>
      {copyable && <CopyToClipboardBtn text={formattedIdentifier} size={18} className='ml-1' />}
      {includedInCase && <Folder size={18} className='ml-1' />}
    </div>
  );
};

export default IdentifierEllipsis;
