import { FC } from 'react';
import { StatusBadge } from '../../../ui';
import { useRouter } from '../../../modules/router/RouterProvider';
import { IIntegration } from '../../../utils/helpers/integrations';

interface IntegrationProps {
  integration: IIntegration;
}

const Integration: FC<IntegrationProps> = ({ integration }) => {
  const { navigate } = useRouter();
  const { provider, label, isActive, description, image, isHidden } = integration;
  const onClickIntegration = () => {
    navigate(`/settings/workspace/integrations/${provider}`);
  };
  if (isHidden) return null;
  return (
    <div
      onClick={onClickIntegration}
      className='flex w-72 cursor-pointer flex-col rounded-md border bg-gray-50'>
      <div className='flex-0 flex items-center justify-center bg-white'>
        <img src={image} width='108' className='my-4' alt={label} />
      </div>
      <div className='m-0 flex-auto px-3 py-4'>
        <p className='flex justify-between pb-2 font-bold'>
          <span className='leading-7'>{label}</span>
          <span className='text-right'>
            <StatusBadge isActive={isActive === true} />
          </span>
        </p>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Integration;
