import { FC } from 'react';
import { IAggregatedResult } from '../../../api/dtos/address';
import CustomerAssociatedEntitiesTableReport from './CustomerAssociatedEntitiesTableReport';

interface ICustomerAssociatedEntitiesReportProps {
  deposits: IAggregatedResult[];
  withdrawals: IAggregatedResult[];
  part: number;
}

const CustomerAssociatedEntitiesReport: FC<ICustomerAssociatedEntitiesReportProps> = ({
  deposits,
  withdrawals,
  part,
}) => {
  return (
    <div>
      <div className='rounded-t-sm border border-gray-200 bg-gray-200 px-3 py-2.5 text-2xs font-bold'>
        Part {part}: Associated Entities
        <div className='text-3xs font-normal text-gray-500'>
          Entities associated with customer David and the addresses with it
        </div>
      </div>
      <CustomerAssociatedEntitiesTableReport associatedEntities={deposits} type={'deposit'} />
      <CustomerAssociatedEntitiesTableReport associatedEntities={withdrawals} type={'withdrawal'} />
    </div>
  );
};

export default CustomerAssociatedEntitiesReport;
