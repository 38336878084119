import * as React from 'react';

import { CaseStatus } from '../../../utils/constants/constants';

import { RadioButtons, RadioButton } from '../../../ui';
import { Search } from '../../ui/components/Search';
import CustomSelect from '../../../components/Filters/CustomSelect';

import CalendarFilter, { IDateRangeString } from '../../Filters/CalendarFilter';
import RiskFilter from '../../Filters/RiskFilter';
import { FilterPanel } from '../../FilterPanel';

export interface IOption {
  value: string;
  label: string;
}

export interface IFilters {
  address: string;
  customer_id: string;
  status: number[];
  risk_level: number[];
  assigned_to: number[] | string;
  start_date: string;
  end_date: string;
}

export const defaultCaseListFilters: IFilters = {
  address: '',
  customer_id: '',
  status: [],
  risk_level: [],
  assigned_to: [],
  start_date: null,
  end_date: null,
};

interface Props {
  filters: IFilters;
  onApply: (any) => void;
  onReset: () => void;
  disabled?: boolean;
  caseUsers: IOption[];
  activeTab: number;
}

const getValueFromOptions = (optionValue, options: IOption[]): IOption => {
  let value: IOption;
  options.forEach((option) => {
    if (option.value === optionValue) {
      value = option;
    }
  });
  return value;
};

const caseStatusOptions: IOption[] = CaseStatus.map((cs) => ({ label: cs.label, value: `${cs.value}` }));

const CaseListFilter: React.FC<Props> = ({ filters, onApply, disabled, caseUsers, activeTab }) => {
  const [searchType, setSearchType] = React.useState('address');

  const [address, setAddress] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [status, setStatus] = React.useState<IOption[]>([]);
  const [riskLevel, setRiskLevel] = React.useState<number[]>([]);
  const [dateAdded, setDateAdded] = React.useState<IDateRangeString>();
  const [assignedTo, setAssignedTo] = React.useState<IOption[]>([]);

  const handleApply = () => {
    const newFilters: IFilters = {
      address: address,
      customer_id: customerId,
      status: status.map((option) => option.value).map(Number),
      risk_level: riskLevel,
      assigned_to: assignedTo.map((option) => option.value).map(Number),
      start_date: dateAdded.from,
      end_date: dateAdded.to,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setAddress('');
    setCustomerId('');
    setStatus([]);
    setRiskLevel([]);
    setAssignedTo([]);
    setDateAdded(null);
    onApply(defaultCaseListFilters);
  };

  React.useEffect(() => {
    setAddress(filters.address);
    setCustomerId(filters.customer_id);
    setStatus(filters.status.map((val) => getValueFromOptions(val.toString(), caseStatusOptions)));
    setRiskLevel(filters.risk_level);
    setAssignedTo((filters.assigned_to as number[]).map((val) => getValueFromOptions(val, caseUsers)));
    setDateAdded({
      from: filters.start_date,
      to: filters.end_date,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleSearchTypeChange = (e) => {
    const val = (e.target as HTMLInputElement).value;
    setCustomerId('');
    setAddress('');
    setSearchType(val);
  };

  return (
    <FilterPanel onApply={handleApply} onReset={onReset} disabled={disabled}>
      <div />
      <div className='relative'>
        <RadioButtons name='type' selected={searchType} onChange={handleSearchTypeChange} className='mb-3'>
          <RadioButton value='address' labelText='Search by Address' />
          <RadioButton value='customer_id' labelText='Search by Customer ID' />
        </RadioButtons>
        <Search
          placeholder={searchType === 'address' ? 'Search using address hash' : 'Search Customer ID'}
          setValue={searchType === 'address' ? setAddress : setCustomerId}
          value={searchType === 'address' ? address : customerId}
        />
      </div>
      {activeTab !== 2 && (
        <CustomSelect
          label='Case Status'
          value={status}
          handleChange={(val) => setStatus(val)}
          placeholder='Select Option'
          options={caseStatusOptions}
        />
      )}
      <RiskFilter value={riskLevel} setValue={setRiskLevel} />
      {activeTab !== 0 && activeTab !== 1 && (
        <CustomSelect
          label='Assigned To'
          value={assignedTo}
          handleChange={(val) => setAssignedTo(val)}
          placeholder='Choose a Case Agent'
          options={caseUsers}
        />
      )}
      <CalendarFilter value={dateAdded} setValue={setDateAdded} label='Creation Date Range' />
    </FilterPanel>
  );
};

export default CaseListFilter;
