import { FC } from 'react';
import { IAssociatedCase } from '../../api/dtos/address';
import Table from '../../ui/components/Table/Table';
import { TableCopyCell } from '../../ui/components/Table/Cells';
import { RiskBadge, CaseStatusBadge } from '../../ui';
import { useRouter } from '../../modules/router/RouterProvider';

interface IAssociatedCasesProps {
  associatedCases: IAssociatedCase[];
  isCase?: boolean;
}

const AssociatedCases: FC<IAssociatedCasesProps> = ({ associatedCases, isCase }) => {
  const { navigate } = useRouter();
  const headerData = ['Case ID', 'Risk Level', 'Status'];
  if (!isCase) {
    headerData.push('Created By');
  }
  const rows = associatedCases.map((item) => ({
    id: item.id,
    data: [
      <TableCopyCell key={item.case_id} value={item.case_id} />,
      <RiskBadge key={item.case_id} risk={item.risk_level} noLabel={isCase} />,
      <CaseStatusBadge key={item.case_id} status={item.status} className='-ml-4' />,
      ...(!isCase ? [<div key={item.case_id}>{item.created_by_name}</div>] : []),
    ],
  }));

  if (!rows.length && isCase) {
    return null;
  }
  return (
    <Table
      title='ASSOCIATED CASES'
      height={isCase ? 250 : null}
      rows={rows}
      headerData={headerData}
      count={associatedCases.length}
      onClickRow={(caseId) => navigate(`/cases/${caseId}`)}
      border
    />
  );
};

export default AssociatedCases;
