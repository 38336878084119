import { FC } from 'react';

interface AppliedFiltersProps {
  children: React.ReactNode;
  onResetAll: () => void;
  className?: string;
}

const AppliedFilters: FC<AppliedFiltersProps> = ({ children, onResetAll, className }) => {
  return (
    <div className={className}>
      <div className='mx-4 flex justify-between'>
        <div>{children}</div>
        <div className='my-auto cursor-pointer text-xs text-blue-600' onClick={onResetAll}>
          Reset Filters
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;
