import { FC } from 'react';
import AddToCase from '../../AddressTransaction/AddToCase';
import ArchiveButton from '../../common/ArchiveButton';
import ExportReport from '../../common/ExportReport';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import { ITransactionResponse } from '../../../api/dtos/transaction';
import { useRouter } from '../../../modules/router/RouterProvider';

interface TransactionMetadataHeaderProps {
  transaction: ITransactionResponse;
}

const TransactionMetadataHeader: FC<TransactionMetadataHeaderProps> = ({ transaction }) => {
  const { getQueryParams } = useRouter();

  const { entityId, refCase } = getQueryParams();

  return (
    <>
      <div className='flex flex-col justify-between'>
        <IdentifierEllipsis
          identifier={transaction.identifier}
          limit={16}
          className='text-xl text-gray-800'
          copyable
        />
        <div className='flex text-wrap break-all text-sm text-gray-500'>{transaction.identifier}</div>
      </div>
      <div className='mt-4 flex'>
        <AddToCase transaction={transaction} refCase={{ case: Number(refCase), entity: Number(entityId) }} />
        <ArchiveButton
          id={transaction.id}
          identifier={transaction.identifier}
          type={'transaction'}
          customerId={transaction.customer_id}
        />
        <ExportReport id={transaction.id} type='transaction' />
      </div>
    </>
  );
};

export default TransactionMetadataHeader;
