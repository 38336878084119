import { useAuth } from '../../modules/auth';

export const useTheme = () => {
  const { state } = useAuth();
  const isCustomHeader = () =>
    ['laserdigital-sandbox.com', 'crypto-sandbox.com'].includes(state?.userProfile?.domain);
  const themeColor = () =>
    isCustomHeader()
      ? { primary: '#FC8019', secondary: '#FEA357', focused: '#fff' }
      : { primary: '#2563eb', secondary: '#3b82f6', focused: '#fff' };

  return {
    isCustomHeader,
    themeColor,
  };
};
