import { Api } from './api';

export class DownloadApi extends Api {
  readonly baseUrl: string = '/download';

  readonly downloadFile = ({ key }) => {
    return this.http.post(this.route('/'), { key });
  };
}

export const downloadApi = new DownloadApi();
