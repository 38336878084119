import { FC } from 'react';
import ReportTable from '../ReportTable';
import { ICaseCustomerResult } from '../../../api/dtos/case';
import { RiskBadge } from '../../../ui';

interface ICaseListing {
  data: ICaseCustomerResult[];
  part: number;
}

const CustomerCaseList: FC<ICaseListing> = ({ data, part }) => {
  if (!data) return null;

  const tableData = data.map((caseItem: ICaseCustomerResult) => {
    const value = [
      {
        label: 'Customer ID',
        value: <div className='break-words font-normal'>{caseItem.customer_id}</div>,
      },
      {
        label: 'Added By',
        value: <div className='font-normal'>{caseItem.added_by_name}</div>,
      },
      {
        label: 'Alerts',
        value: <div className='font-normal'>{caseItem.alerts}</div>,
      },
      {
        label: 'Created',
        value: <div className='font-normal'>{caseItem.created_at}</div>,
      },
      {
        label: 'Deposit',
        value: <div className='font-normal'>{caseItem.deposit_amount}</div>,
      },
      {
        label: 'Risk Level',
        value: (
          <div>
            <RiskBadge risk={caseItem.risk_level} />
          </div>
        ),
      },
      {
        label: 'Last Updated',
        value: <div className='font-normal'>{caseItem.updated_at}</div>,
      },
    ];

    return {
      label: caseItem.id,
      value,
    };
  });
  return <ReportTable tableData={tableData} title={`Part ${part}: Customer Listing`} />;
};

export default CustomerCaseList;
