import classNames from 'classnames';
import { FC, HTMLProps, ReactNode } from 'react';

interface RadioButtonProps extends Partial<HTMLProps<HTMLInputElement>> {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  labelText?: string;
  labelNode?: ReactNode;
  description?: string;
  value?: string | number;
}
const styleClasses = 'rounded-full relative border block w-[18px] h-[18px]';

const RadioButton: FC<RadioButtonProps> = (props) => {
  const {
    className,
    inputClassName,
    labelClassName,
    labelText,
    name,
    labelNode,
    disabled,
    selected,
    value,
    description,
    ...inputProps
  } = props;

  return (
    <label
      className={classNames(
        'flex',
        { 'cursor-not-allowed opacity-50': disabled },
        { 'cursor-pointer': !disabled },
        labelClassName
      )}
      key={'radios-value-' + value}>
      <input
        type='radio'
        name={name}
        id={'radio-' + name}
        value={value}
        disabled={disabled}
        checked={selected}
        {...inputProps}
        className={classNames('hidden', className)}
      />
      <div className={classNames(inputClassName || 'mt-0')}>
        {selected ? (
          <span className={classNames(styleClasses, 'border-blue-600 bg-blue-600')}>
            <span
              className={classNames('absolute left-1/2 top-1/2 block h-[6px] w-[6px] rounded-full bg-white')}
              style={{
                transform: 'translate(-50%, -50%)',
              }}></span>
          </span>
        ) : (
          <span className={classNames(styleClasses, 'border-gray-200 bg-white')}></span>
        )}
      </div>
      {labelNode == null ? (
        <div className='ml-2'>
          <p className='text-sm font-medium leading-default text-gray-800'>{labelText}</p>
          <p className='text-sm font-normal leading-default text-gray-500'>{description}</p>
        </div>
      ) : (
        labelNode
      )}
    </label>
  );
};

export default RadioButton;
