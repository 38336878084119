import { BasicBadge } from '../../../../ui';
import classNames from 'classnames';
import { workspaceColors } from '../../../../ui/utils/constants';

interface WorkspaceBadgeProps {
  label: string;
  i: number;
  className?: string;
}

const WorkspaceBadge: React.FC<WorkspaceBadgeProps> = ({ label, i, className }) => {
  const bgClass = workspaceColors[i % workspaceColors.length];

  return <BasicBadge className={classNames('text-gray-800', bgClass, className)}>{label}</BasicBadge>;
};

export default WorkspaceBadge;
