import { Api } from './api';
import { IClassifierRequest, IClassifierResponse } from './dtos/classifier';
import { convertBooleanToPy } from '../utils/helpers/apiHelpers';

export class Classifier extends Api {
  readonly baseUrl: string = '/risk/api/v4/classifiers';

  readonly getClassifiers = (params: IClassifierRequest) => {
    const {
      rule_type,
      is_enabled,
      policy_enabled,
      policy_type,
      alert_level,
      offset = 0,
      limit = 20,
      q,
      ordering,
    } = params;
    return this.http.get<IClassifierResponse>(this.route('/'), {
      params: {
        rule_type,
        is_enabled: convertBooleanToPy(is_enabled),
        policy_enabled: convertBooleanToPy(policy_enabled),
        policy_type,
        alert_level,
        offset,
        limit,
        q,
        ordering,
      },
    });
  };
}

export const classifierApi = new Classifier();
