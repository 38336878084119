import ReportHeader from './ReportHeader';
import ReportFooter from './ReportFooter';
import WatermarkImage from '@/assets/images/watermark.svg';
import './page.scss';
import { usePermissionsGet } from '../../api/billing';
import classNames from 'classnames';

interface PageProps {
  isInvoices?: boolean;
  invoiceReport?: boolean;
  taxInvoice?: string;
  children: React.ReactNode;
  footer: React.ReactNode;
}

const Page: React.FC<PageProps> = (props) => {
  const { isInvoices = false, invoiceReport = false, taxInvoice, children, footer } = props;

  const { data } = usePermissionsGet();
  const brandLogo = data?.data?.organization_brand_report_logo;
  const orgLogo = data?.data.organization_brand_logo;
  const orgName = data?.data.organization;

  return (
    <div className='page group relative m-0 block' id='page'>
      <img
        src={brandLogo || WatermarkImage}
        id='page-bg'
        className={classNames('fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', {
          'opacity-5': brandLogo,
        })}
      />

      <div id='page-header' className='fixed inset-x-0 top-0 bg-white'>
        <ReportHeader
          isInvoices={isInvoices}
          invoiceReport={invoiceReport}
          taxInvoice={taxInvoice}
          orgLogo={orgLogo}
          orgName={orgName}
        />
      </div>

      <table className='w-full'>
        <thead className='print:table-header-group'>
          <tr>
            <td>
              <div id='page-header-space' className='print:h-[70px]'></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div id='content' className='py-6 print:py-0 group-last:print:pb-0'>
                {children}
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot className='print:table-footer-group'>
          <tr>
            <td>
              <div id='page-footer-space' className='print:h-[70px]'></div>
            </td>
          </tr>
        </tfoot>
      </table>

      <div id='page-footer' className='print:fixed print:inset-x-0 print:bottom-0 print:bg-white'>
        <ReportFooter invoiceReport={invoiceReport}>{footer}</ReportFooter>
      </div>
    </div>
  );
};

export default Page;
