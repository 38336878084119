import classNames from 'classnames';
import { FC } from 'react';
import WidgetTooltip from './WidgetTooltip';
import Skeleton from 'react-loading-skeleton';
import EmptyState from '../../ui/components/States/Empty';

export interface DashboardWidgetProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  tooltipTitle?: string;
  tooltipDescription?: string;
  className?: string;
  containerClassName?: string;
  isLoading?: boolean;
  widgetHeader?: React.ReactNode;
  noData?: boolean;
  showBorder?: boolean;
  headerClassName?: string;
  titleClassName?: string;
}

const DashboardWidget: FC<DashboardWidgetProps> = ({
  title,
  subtitle,
  children,
  tooltipTitle,
  tooltipDescription,
  className,
  containerClassName,
  isLoading,
  widgetHeader,
  noData,
  showBorder = true,
  headerClassName,
  titleClassName,
}) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-col rounded-2xl border bg-white text-gray-900 shadow-sm',
        containerClassName
      )}>
      <div
        className={classNames('flex w-full justify-between px-6 py-4', headerClassName, {
          'border-b': showBorder,
        })}>
        <div className=''>
          <p
            className={classNames('font-semibold uppercase tracking-wider', titleClassName, {
              'text-sm text-gray-800': !titleClassName,
            })}>
            {title}
          </p>
          {subtitle && (
            <p className='text-xs font-medium uppercase tracking-widest text-gray-400'>{subtitle}</p>
          )}
        </div>
        {widgetHeader
          ? widgetHeader
          : !!(tooltipTitle && tooltipDescription) && (
              <WidgetTooltip title={tooltipTitle} description={tooltipDescription} />
            )}
      </div>
      {/* {subHeader} */}
      {isLoading ? (
        <div className='p-6'>
          <Skeleton height={300} />
        </div>
      ) : noData ? (
        <EmptyState />
      ) : (
        <div className={classNames('flex-1 p-6', className)}>{children}</div>
      )}
    </div>
  );
};

export default DashboardWidget;
