import { FC, useState } from 'react';
import CustomSelect, { IOption } from './CustomSelect';
import EntityBadge from '../ui/components/Badge/EntityBadge';
import { entityTypes } from '../../utils/helpers/entity';
interface EntityFilterProps {
  value: string[];
  setValue: (entities: string[]) => void;
  noLabel?: boolean;
  limit?: number;
  noBorder?: boolean;
  className?: string;
}

const EntityFilter: FC<EntityFilterProps> = (props) => {
  const { value, setValue, noLabel = false, limit, noBorder, className } = props;
  const entityOptions = Object.keys(entityTypes).map((key) => {
    return {
      value: key,
      label: key,
    };
  });
  let selectedEntities = [];
  if (value) {
    selectedEntities = entityOptions.filter((option) => value.includes(option.value));
  }
  const [entities, setEntities] = useState<IOption[]>(selectedEntities);

  const getLabel = (option: IOption) => {
    return <EntityBadge entity={{ tag_type_verbose: option.value }} />;
  };
  const handleChange = (entities: IOption[]) => {
    setEntities(entities);
    const entityValues = entities.map((entity) => entity.value);
    setValue(entityValues);
  };

  return (
    <CustomSelect
      label={noLabel ? null : 'Entity types'}
      placeholder='Select Entity Types'
      options={entityOptions}
      getCustomLabel={getLabel}
      getCustomOptionLabel={getLabel}
      handleChange={handleChange}
      value={entities}
      limit={limit}
      className={className}
      noBorder={noBorder}
    />
  );
};

export default EntityFilter;
