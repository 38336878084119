import { FileArrowUp } from '@phosphor-icons/react';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import './dropzone.scss';
import { uploadApi } from '../../../api/upload';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';

const baseStyle = {
  flex: 1,
  display: 'flex',
  height: '128px',
  alignItems: 'center',
  padding: '20px',
  justifyContent: 'center',
  backgroundColor: '#e5e7eb',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#d3dde9',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const StyledDropzone = (props: { file_type: number; onSuccess: () => void }) => {
  const fileProto = {
    name: null,
    size: 0,
    uploaded: 0,
    failed: false,
    failedReason: null,
    completed: false,
    type: null,
    lastModified: null,
    file: null,
  };
  const [uploadProgress, setUploadProgress] = React.useState<{ [key: number]: number }>();
  const mutation = useMutation(uploadApi.uploadFile, {
    onSuccess: () => {
      toast.success('File Uploaded Successfully');
    },
    onError: (e: AxiosError) => {
      toast.error(getErrorMessage(e) ?? 'File Not Uploaded');
    },
  });
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { 'text/csv': ['.csv'], 'text/xls': ['.xls'] },
    onDrop: async (files) => {
      await Promise.all(
        files.map((file, index) =>
          mutation.mutateAsync({
            ...fileProto,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            file,
            file_type: props.file_type,
            index,
            setUploadProgress,
            length: files.length,
          })
        )
      );
      props.onSuccess();
    },
    onError: () => {
      toast.error('Unable to Upload File');
    },
  });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  React.useEffect(() => {
    if (uploadProgress) {
      if (Object.keys(uploadProgress).length > 0) {
        const minProgress = Math.min(...Object.values(uploadProgress));
        document.documentElement.style.setProperty('--uploaded', `${minProgress}%`);
      } else {
        document.documentElement.style.setProperty('--uploaded', '100%');
        setTimeout(() => {
          document.documentElement.style.setProperty('--uploaded', '0%');
        }, 500);
      }
    }
  }, [uploadProgress]);

  return (
    <div className='container cursor-pointer'>
      <div className='dropzone' {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className='flex flex-col items-center'>
          <FileArrowUp size={32} color='#D1D5DB' className='items-center' />
          <p className='z-10 text-xs font-semibold text-gray-500'>
            Drag your {props.file_type === 0 ? 'Address' : 'Transaction'} files here or click here to upload.
          </p>
        </div>
      </div>
    </div>
  );
};
