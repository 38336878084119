import classNames from 'classnames';
import { CopyBtn, Input, RadioButton } from '../../../ui';
import Expandable from '../../ui/components/Expandable/Expandable';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ToggleController from '../../../ui/components/Form/ToggleController';
import RadioButtonsController from '../../../ui/components/Form/RadioButtonsController';
import CheckboxController from '../../../ui/components/Form/CheckboxController';
import { IIntegrationProps } from '../../../views/dashboard/settings/Integrations/IntegrationDetails';
import { IRiskLevelObject } from '../../../api/dtos/profile';
import { SelectCreatable } from '../../ui/components/Select';
import { useAuth } from '../../../modules/auth';
import Popover from '../../../ui/components/Popover/Popover';

const initialData = {
  isAdvanced: false,
  freezingStatus: '0',
  freezingRiskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
  rejectionStatus: 'disabled',
  rejectionRiskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
  emailUsers: [],
  isFreezingNotificationEnabled: false,
  isRejectionNotificationEnabled: false,
  isAdvancedSettingsNotificationEnabled: false,
};

const FireblocksForm: React.FC<IIntegrationProps> = ({ apiProxy, data, onSubmit }) => {
  const webhookUrl = (apiProxy || import.meta.env.VITE_API_PROXY) + data.webhook_slug;
  const { state } = useAuth();
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: initialData,
  });

  const domain = state.userProfile?.domain;

  useEffect(() => {
    if (!data) return;
    const getRiskLevels = (levels: IRiskLevelObject, dataLevels: number[]) => {
      const result = {} as IRiskLevelObject;
      Object.keys(levels).forEach((key) => {
        result[key] = dataLevels?.map((l) => Number(l))?.includes(Number(key));
      });
      return result;
    };

    const freezingRiskLevels = getRiskLevels(
      initialData.freezingRiskLevels,
      data.config.fireblocks_freezing_risk_levels
    );
    const rejectionRiskLevels = getRiskLevels(
      initialData.rejectionRiskLevels,
      data.config.fireblocks_rejection_risk_levels
    );

    setValue('isAdvanced', data.config.is_advanced_integration_enabled);
    setValue('freezingStatus', data.config.fireblocks_freezing_status?.toString());
    setValue('freezingRiskLevels', freezingRiskLevels);
    setValue('rejectionStatus', data.config.fireblocks_rejection_status?.toString());
    setValue('rejectionRiskLevels', rejectionRiskLevels);
    setValue('emailUsers', data.config.fireblocks_notifications_emails);
    setValue('isFreezingNotificationEnabled', data.config.is_fireblocks_freezing_notification_enabled);
    setValue('isRejectionNotificationEnabled', data.config.is_fireblocks_rejection_notification_enabled);
    setValue(
      'isAdvancedSettingsNotificationEnabled',
      data.config.is_fireblocks_advanced_settings_notification_enabled
    );
  }, [data, setValue]);

  useEffect(() => {
    const onChange = (form: typeof initialData) => {
      const emailUsers = form.emailUsers?.map((email) =>
        email.includes('@') ? email : `${email}@${domain}`
      );
      const config = {
        ...data.config,
        is_advanced_integration_enabled: !!form.isAdvanced,
        fireblocks_freezing_status: parseInt(form?.freezingStatus) || 0,
        fireblocks_freezing_risk_levels: Object.keys(form.freezingRiskLevels)
          .filter((key) => form.freezingRiskLevels[key])
          .map((k) => Number(k)),

        fireblocks_rejection_status: parseInt(form.rejectionStatus) || 0,
        fireblocks_rejection_risk_levels: Object.keys(form.rejectionRiskLevels)
          .filter((key) => form.rejectionRiskLevels[key])
          .map((k) => Number(k)),
        fireblocks_notifications_emails: emailUsers || [],
        is_fireblocks_freezing_notification_enabled: !!form.isFreezingNotificationEnabled,
        is_fireblocks_rejection_notification_enabled: !!form.isRejectionNotificationEnabled,
        is_fireblocks_advanced_settings_notification_enabled: !!form.isAdvancedSettingsNotificationEnabled,
      };
      onSubmit({
        id: data.id,
        is_active: data.is_active,
        provider: 'fireblocks',
        config: config,
      });
    };
    const subscription = watch(() => handleSubmit(onChange)());
    return () => subscription.unsubscribe();
  }, [data, domain, handleSubmit, onSubmit, setValue, watch]);

  return (
    <div className='mt-8 bg-gray-50 px-10 py-7'>
      <div className='font-bold'>Configure Integration</div>
      {/* Basic Integration */}
      <Expandable
        isExpanded
        className='mb-2 mt-4'
        title={<span className='text-lg'>Basic Integration</span>}
        content={
          <Input
            disabled
            iconEnd={<CopyBtn text={webhookUrl} />}
            id={'url'}
            labelText='Webhook URL'
            type={'text'}
            className='mb-2 mt-4'
            value={webhookUrl}
          />
        }
      />
      {/* Advanced Integration */}
      <Expandable
        isExpanded
        className={classNames('my-2', {
          'pointer-events-none opacity-50': !data.config.is_advanced_integration_enabled,
        })}
        title={
          <div className='flex'>
            <div className='my-auto mr-2 text-lg'>Advanced Integration</div>
            <Popover
              referenceContent={
                <div onClick={(e) => e.stopPropagation()}>
                  <ToggleController
                    disabled
                    className='my-auto'
                    control={control}
                    name='isAdvanced'
                    labelText=' '
                  />
                </div>
              }
              className='rounded-md border bg-white p-2 text-xs text-gray-800'
              popoverContent={
                'Get in touch with support@merklescience.com to have the advanced integration enabled or disabled.'
              }
            />
          </div>
        }
        subtitle={
          !data.config.is_advanced_integration_enabled && (
            <div>
              Get in touch with <a className='border-b border-gray-500'>support@merklescience.com</a> to
              enable the advanced integration
            </div>
          )
        }
        content={
          <div>
            <div className='my-4'>
              <div className='font-semibold'>Automated Deposit Freezing</div>
              <RadioButtonsController
                control={control}
                name='freezingStatus'
                className='mt-1 grid grid-cols-3'>
                <RadioButton
                  labelText='Enabled'
                  value='1'
                  labelClassName='border rounded-lg p-2 bg-white'
                  description='You will receive email notifications indicating that funds have been frozen, and Compass will submit an API request to freeze the funds associated with the transaction.'
                />
                <RadioButton
                  labelText='Disabled'
                  value='0'
                  labelClassName='border rounded-lg p-2 bg-white'
                  description='Funds related to risky deposits will not be frozen. '
                />
                <RadioButton
                  labelText='Dry Run'
                  value='2'
                  labelClassName='border rounded-lg p-2 bg-white'
                  description='You will receive email notifications indicating that funds have been frozen, and Compass will submit an API request to freeze the funds associated with the transaction.'
                />
              </RadioButtonsController>
              <div className='ml-6 mt-4 text-sm text-gray-500'>
                Select the risk levels that a deposit transaction must meet in order for its funds to be
                automatically frozen on Fireblocks.
              </div>
              <div className={classNames('ml-6 flex gap-4 pt-3')}>
                <CheckboxController control={control} name='freezingRiskLevels.5' labelText='Critical' />
                <CheckboxController control={control} name='freezingRiskLevels.4' labelText='High' />
                <CheckboxController control={control} name='freezingRiskLevels.3' labelText='Medium' />
                <CheckboxController control={control} name='freezingRiskLevels.2' labelText='Caution' />
                <CheckboxController control={control} name='freezingRiskLevels.1' labelText='Info' />
              </div>
            </div>
            <div className='my-4'>
              <div className='font-semibold'>Automated Withdrawal Attempt Rejection</div>
              <RadioButtonsController
                control={control}
                name='rejectionStatus'
                className='mt-1 grid grid-cols-3'>
                <RadioButton
                  labelText='Enabled'
                  value='1'
                  labelClassName='border rounded-lg p-2 bg-white'
                  description='You will receive email notifications indicating that a withdrawal attempt was rejected, and Compass will submit an API request to cancel the withdrawal attempt.'
                />
                <RadioButton
                  labelText='Disabled'
                  value='0'
                  labelClassName='border rounded-lg p-2 bg-white'
                  description='Withdrawal attempts to risky addresses or initiated by risky customers will not be rejected.'
                />
                <RadioButton
                  labelText='Dry Run'
                  value='2'
                  labelClassName='border rounded-lg p-2 bg-white'
                  description='You will receive email notifications indicating that a withdrawal attempt was rejected, but Compass will not issue the API request to cancel the withdrawal.'
                />
              </RadioButtonsController>
              <div className='ml-6 mt-4 text-sm text-gray-500'>
                Select the risk levels that the customer associated with this withdrawal address must meet in
                order for a withdrawal attempt to be automatically rejected.
              </div>
              <div className={classNames('ml-6 flex gap-4 pt-3')}>
                <CheckboxController control={control} name='rejectionRiskLevels.5' labelText='Critical' />
                <CheckboxController control={control} name='rejectionRiskLevels.4' labelText='High' />
                <CheckboxController control={control} name='rejectionRiskLevels.3' labelText='Medium' />
                <CheckboxController control={control} name='rejectionRiskLevels.2' labelText='Caution' />
                <CheckboxController control={control} name='rejectionRiskLevels.1' labelText='Info' />
              </div>
            </div>
            <div className='my-4'>
              <div className='font-semibold'>Email Notifications</div>

              <div className='mb-1 text-sm font-medium'>
                Please provide a list of email addresses for users who will receive notifications related to
                the Compass and Fireblocks integration. Users who do not have access to Compass can also
                receive these notifications.
              </div>
              <Controller
                name='emailUsers'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectCreatable
                    formatCreateLabel={(input) => `Add ${input}@${domain}`}
                    placeholder='Add email address'
                    options={value}
                    setOptions={onChange}
                  />
                )}></Controller>

              <div className='mb-1 mt-3 text-sm text-gray-500'>These notifications include:</div>
              <div className={classNames('flex gap-4')}>
                <CheckboxController
                  control={control}
                  name='isFreezingNotificationEnabled'
                  labelText='Deposit Freezing'
                />
                <CheckboxController
                  control={control}
                  name='isRejectionNotificationEnabled'
                  labelText='Withdrawal Attempt Rejection performed by Compass'
                />
                <CheckboxController
                  control={control}
                  name='isAdvancedSettingsNotificationEnabled'
                  labelText='Changes to Advanced Fireblocks settings'
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default FireblocksForm;
