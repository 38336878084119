import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { Question } from '@phosphor-icons/react';
import CustomLink from '../ui/components/CustomLink';
import ChainSyncModal from './ChainSyncModal';
import SupportedDigitalAssets from './SupportedDigitalAssets';
import { useTheme } from '../../utils/helpers/theme';

interface HeaderMenuLinksProps {
  compassStatus?: string;
  // TODO: Handle zendesk support on compass
  onSupport?: () => void;
}

const HeaderMenuLinks: FC<HeaderMenuLinksProps> = ({ compassStatus, onSupport }) => {
  const [chainSyncModal, setChainSyncModal] = useState(false);
  const [supportedAssets, setSupportedAssets] = useState(false);
  const { themeColor } = useTheme();

  const compassStatusUrl = compassStatus || import.meta.env.VITE_EXTERNAL_API_STATUS_PAGE_URL || '';
  return (
    <div>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <MenuButton
            className={classNames(
              'my-px mr-3 rounded-lg px-3 py-2 text-sm font-medium text-white outline-1 hover:outline hover:outline-white focus:outline-none'
            )}
            style={{ backgroundColor: themeColor().secondary }}>
            <Question size={22} className='my-auto text-white' />
          </MenuButton>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <MenuItems className='absolute right-0 mt-2 min-w-[240px] rounded-md border bg-white text-sm text-gray-800 shadow-lg focus:outline-none'>
            <MenuItem as={'div'} onClick={() => setSupportedAssets(true)}>
              <div className='cursor-pointer rounded-md py-2 pl-4 pr-1 hover:bg-blue-500 hover:text-white'>
                Supported Digital Assets
              </div>
            </MenuItem>
            <MenuItem to='/docs/guide' target='_blank' as={CustomLink}>
              <div className='cursor-pointer rounded-md py-2 pl-4 pr-1 hover:bg-blue-500 hover:text-white'>
                Product Guide
              </div>
            </MenuItem>
            <MenuItem to='/docs/api' target='_blank' as={CustomLink}>
              <div className='cursor-pointer rounded-md py-2 pl-4 pr-1 hover:bg-blue-500 hover:text-white'>
                API Documentation
              </div>
            </MenuItem>
            <MenuItem as={'a'} href={compassStatusUrl} target='_blank'>
              <div className='cursor-pointer rounded-md py-2 pl-4 pr-1 hover:bg-blue-500 hover:text-white'>
                API Uptime
              </div>
            </MenuItem>
            <MenuItem as={'div'} onClick={() => setChainSyncModal(true)}>
              <div className='cursor-pointer border-b py-2 pl-4 pr-1 hover:rounded-md hover:bg-blue-500 hover:text-white'>
                Chain Synchronization
              </div>
            </MenuItem>
            <MenuItem as={'div'} onClick={onSupport}>
              <div className='cursor-pointer rounded-md py-3 pl-4 pr-1 hover:bg-blue-500 hover:text-white'>
                Support
              </div>
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
      <ChainSyncModal open={chainSyncModal} onClose={() => setChainSyncModal(false)} />
      <SupportedDigitalAssets isOpen={supportedAssets} onClose={() => setSupportedAssets(false)} />
    </div>
  );
};

export default HeaderMenuLinks;
