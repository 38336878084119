import { FC } from 'react';
import { IAlert, IAlertItem } from '../../../../../api/dtos/alert';
import AlertCommentButton from './AlertCommentsButton';
import { Button } from '../../../../../ui';
import { UserPlus } from '@phosphor-icons/react';
import { IOption } from '../../../../../components/ui/components/Select/SingleSelect';

export interface IStageOptions extends IOption {
  id?: number;
}
interface IAlertButtonPanelProps {
  alert: IAlert;
  alertItem?: IAlertItem;
  type: 'address' | 'transaction' | 'customer';
  setOpenModal: (val: string) => void;
  setIsBulkUpdate: (val: boolean) => void;
  setCurrentOption: (val: IStageOptions) => void;
  setSelectedAlertId: (val: string | number) => void;
  setNextStageOptions: (val: IStageOptions[]) => void;
}

const AlertButtonPanel: FC<IAlertButtonPanelProps> = ({
  alert,
  alertItem,
  type,
  setOpenModal,
  setIsBulkUpdate,
  setCurrentOption,
  setSelectedAlertId,
  setNextStageOptions,
}) => {
  return (
    <div className='flex'>
      <AlertCommentButton id={alert?.id} alert={alert} alertItem={alertItem} type={type} />
      <Button
        variant='secondary'
        buttonSize='md'
        className='ml-2'
        iconStart={<UserPlus weight='fill' size={15} />}
        onClick={() => {
          setIsBulkUpdate(false);
          setSelectedAlertId(alert.id);
          setCurrentOption({
            label: alert.assigned_to,
            value: alert.assigned_to,
          });
          setOpenModal('assignee');
        }}
      />
      <Button
        variant='secondary'
        buttonSize='md'
        className='ml-2'
        onClick={() => {
          setIsBulkUpdate(false);
          setNextStageOptions(
            alert?.workflow_stage?.next_stages?.map((r) => ({
              label: r.name,
              value: r.name,
              id: r.id,
            }))
          );
          setSelectedAlertId(alert.id);
          setCurrentOption({
            id: alert?.workflow_stage?.id,
            label: alert?.workflow_stage?.name,
            value: alert?.workflow_stage?.name,
          });
          setOpenModal('status');
        }}>
        Update Status
      </Button>
    </div>
  );
};

export default AlertButtonPanel;
