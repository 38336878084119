/* eslint-disable no-console */
import React from 'react';
import CurrencyBadge from '../Badge/CurrencyBadge';
import { BasicBadge } from '../../../../ui';
import classNames from 'classnames';
import Popover from '../../../../ui/components/Popover/Popover';
import { getCurrencyFromCode } from '../../../../utils/helpers/currency';

interface DigitalAsset {
  currency: number;
  token?: string;
}

interface DigitalAssetsProps {
  digitalAssets: DigitalAsset[];
  noBadge?: boolean;
  showAll?: boolean;
  AssetsDisplayed?: number;
}

const DigitalAssets: React.FC<DigitalAssetsProps> = ({
  digitalAssets,
  noBadge = false,
  showAll = false,
  AssetsDisplayed = 1,
}) => {
  const assets = digitalAssets?.map((asset) => ({
    ...asset,
    token: asset.token ?? getCurrencyFromCode(asset.currency).currency_short,
  }));

  const numberOfIterations = Math.min(assets.length, AssetsDisplayed);

  return (
    <div className='flex items-center'>
      {showAll ? (
        <div className='flex flex-wrap'>
          {assets.map((asset) => (
            <CurrencyBadge
              key={asset.token}
              currency={asset.currency}
              label={asset.token}
              transparent={noBadge}
              className='mr-2'
            />
          ))}
        </div>
      ) : (
        <div className='flex'>
          {assets &&
            assets
              .slice(0, numberOfIterations)
              .map((asset) => (
                <CurrencyBadge
                  key={asset.token}
                  currency={asset.currency}
                  label={asset.token}
                  className='mb-2 mr-2'
                />
              ))}
          {assets && assets.length - AssetsDisplayed > 0 && (
            <Popover
              placement='bottom'
              referenceContent={
                <BasicBadge className='bg-gray-100'>{`+${assets.length - AssetsDisplayed}`}</BasicBadge>
              }
              popoverContent={
                <div className={classNames('flex w-80 max-w-sm flex-wrap rounded-md bg-white p-2 shadow-lg')}>
                  {assets.slice(AssetsDisplayed).map((asset) => (
                    <CurrencyBadge
                      key={asset.token}
                      currency={asset.currency}
                      label={asset.token}
                      className='mb-2 mr-2'
                    />
                  ))}
                </div>
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DigitalAssets;
