import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export interface ErrorMessageProps {
  title?: string;
  titleIcons?: ReactNode;
  body: string | ReactNode;
  className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  const { title, body, className, titleIcons } = props;

  return (
    <div className={classNames('rounded-md border bg-white text-gray-500', className)}>
      <div className='flex items-center border-b px-3 py-1'>
        <h4 className='py-2 text-xs font-semibold uppercase text-gray-500'>{title}</h4>
        {titleIcons}
      </div>
      <div className='flex h-52 items-center justify-center text-xs'>{body}</div>
    </div>
  );
};

export default ErrorMessage;
