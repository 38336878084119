import CustomSelect, { IOption } from './CustomSelect';

import { FC, useState } from 'react';

interface CustomTagsFilterProps {
  value: (number | string)[];
  setValue: (stages: (number | string)[]) => void;
  customTagsOptions: IOption[];
}

const CustomTagsFilter: FC<CustomTagsFilterProps> = (props) => {
  const { value, setValue, customTagsOptions } = props;

  let selectedTags = [];
  if (value && value.length) {
    selectedTags = customTagsOptions?.filter((r) => value?.includes(r.value));
  }
  const [tags, setTags] = useState<IOption[]>(selectedTags);

  const handleChange = (tagsData: IOption[]) => {
    setValue(tagsData.map((r) => r.value));
    setTags(tagsData);
  };

  return (
    <CustomSelect
      label='Custom Tag'
      placeholder='Select Custom Tag'
      options={customTagsOptions}
      handleChange={handleChange}
      value={tags}
    />
  );
};

export default CustomTagsFilter;
