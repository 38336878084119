import { IStageListLite } from '../../api/dtos/stages';

export const getColorMappingStageOptions = (stages: IStageListLite[]) => {
  const combinations = {};

  stages?.forEach((stage, index) => {
    combinations[stage.id] = index;
  });
  return combinations;
};
