import { FC, useMemo } from 'react';
import { useBillingPlanGet } from '../../../../api/billing';
import { TabHeader } from '../../../../components/ui/components/Header';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';
import { Checkbox } from '../../../../ui';
import BillingPlans from '../../../../constants/billing-plans';
import { Currencies } from '../../../../utils/constants/constants';
import { formatOnlyDate } from '../../../../utils/helpers/date';

interface BillingCurrentPlanProps {}

const BillingCurrentPlan: FC<BillingCurrentPlanProps> = () => {
  const { data: billingResponse, isLoading: isBillingLoading } = useBillingPlanGet();
  // const { data: subscriptionResponse, isLoading: isSubscriptionLoading } = useSubscriptionGet(
  //   {
  //     organizationId: billingResponse?.data?.chargebee_organization_id,
  //   },
  //   {
  //     enabled: !isBillingLoading,
  //   }
  // );

  const billing = billingResponse?.data;
  // const subscription = subscriptionResponse?.data?.results?.[0];

  const billingParsed = useMemo(() => {
    if (billing == null) {
      return {};
    }

    return {
      billingFixedCost: billing?.fixed_cost ? parseFloat(billing?.fixed_cost?.toString()).toFixed(2) : '0',
      billingVariableCost: billing?.variable_cost
        ? parseFloat(billing?.variable_cost?.toString()).toFixed(2)
        : '0',
      billingFrequency:
        BillingPlans[billing.billing_frequency?.toString()] != null
          ? BillingPlans[billing.billing_frequency?.toString()].value
          : '',
      billingCurrency: billing?.currency || '',
    };
  }, [billing]);

  // if (isBillingLoading || isSubscriptionLoading) {
  if (isBillingLoading) {
    return (
      <div>
        <CompassLoader />
      </div>
    );
  }

  return (
    <div className='h-full max-w-6xl'>
      <TabHeader heading='Current Plan Details' />
      <div className='mt-2'>
        <p className='text-sm font-semibold leading-8 text-gray-500'>Subscription Period</p>
        <p className='text-sm'>
          {formatOnlyDate(billing.start_date)} – {formatOnlyDate(billing.end_date)}
        </p>
      </div>
      <div className='mt-2'>
        <p className='text-sm font-semibold leading-8 text-gray-500'>Pricing</p>
        <p className='text-sm font-medium text-gray-700'>
          Fixed Fee: {billingParsed.billingCurrency} {billingParsed.billingFixedCost} for{' '}
          {billing.credit_limit} credits every {billingParsed.billingFrequency} month(s)
        </p>
        <p className='text-sm'>
          Variable Fee: {billingParsed.billingCurrency} {billingParsed.billingVariableCost} per credit after{' '}
          {billing.credit_limit} credits
        </p>
      </div>
      <div className='mt-2'>
        <p className='text-sm font-semibold leading-8 text-gray-500'>Number of Users allowed</p>
        <p className='text-sm'>{billing.limit_invite_users}</p>
      </div>
      <div className='mb-8 mt-2'>
        <p className='text-sm font-semibold leading-8 text-gray-500'>Currencies Enabled in Current Plan</p>
        <div className='grid max-w-4xl grid-cols-4 gap-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'>
          {Currencies?.map((c) => {
            return (
              <Checkbox
                key={c.currency_short}
                checked
                // Display all enabled
                // https://merkle-science.atlassian.net/browse/COMPASS-847
                disabled
                labelText={<span className='text-sm font-medium leading-5 text-gray-700'>{c.label}</span>}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BillingCurrentPlan;
