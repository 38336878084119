/* eslint-disable no-console */
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation, createSearchParams } from 'react-router-dom';
import { useAuth } from '../modules/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { authApi } from '../api/auth';
import CompassLoader from '../components/ui/components/Loader/CompassLoader';
import { useQuery } from 'react-query';
import { useWorkspace } from '../utils/helpers/common';
import { useErrorBoundary } from 'react-error-boundary';
import { AxiosResponse } from 'axios';
import { useAnalyticsGet } from '../api/analytics';
import { IAnalyticsResponse } from '../api/dtos/analytics';
import { setIdentity } from '../utils/helpers/zendeskSetup';
import { initGTag, initHotjar, initLogRocket } from '../integrations';
import { IntercomProvider } from 'react-use-intercom';
import * as Sentry from '@sentry/browser';

type AppContainerProps = PropsWithChildren & {
  loginPath?: string;
};

export const AppContainer: FC<AppContainerProps> = ({ children, loginPath = '/accounts/login' }) => {
  const { isAuthenticated, setUser, state, loginSilently, setAccountPermissionsState } = useAuth();
  const [intercomAppId, setIntercomAppId] = useState('');
  const { isLoading } = useAuth0();
  const location = useLocation();
  const workspace = useWorkspace();
  const { showBoundary } = useErrorBoundary();
  const { data, isLoading: isLoadingPermissions } = useQuery(
    ['userProfile'],
    () => authApi.getPermissions(),
    {
      enabled: !!(state.access && isAuthenticated),
      staleTime: 24 * 60 * 60 * 1000,
      useErrorBoundary: true,
    }
  );

  useAnalyticsGet({
    onSuccess: (data: AxiosResponse<IAnalyticsResponse>) => {
      setAccountPermissionsState({ accountPermissions: data?.data });
      setIdentity(data.data.name, data.data.email, data.data.organization, data.data.zendesk_key);
      if (data.data.intercom_enabled) {
        setIntercomAppId(data.data.intercom_app_id);
      }
      initLogRocket(data.data, state);
      initGTag(data.data);
      initHotjar(data.data);
      Sentry.setUser({
        name: data.data.name,
        email: data.data.email,
        organization: data.data.organization,
        currentWorkspace: workspace.name,
      });
    },
    enabled: !!(state.access && isAuthenticated),
  });

  useEffect(() => {
    if (data?.data) {
      setUser({
        userProfile: data?.data,
      });
      if (!data.data.workspaces?.length) {
        showBoundary({ response: { data: ['No workspaces found for the user.'] } });
      } else if (data.data.workspaces.length && !localStorage.getItem('workspace')) {
        const workspace = new URLSearchParams(window.location.search).get('workspace');
        const currentWorkspace = workspace || data.data.workspaces[0].slug;
        if (currentWorkspace) localStorage.setItem('workspace', `"${currentWorkspace}"`);
      }
    }
  }, [data, setUser, showBoundary]);

  const onHide = () => console.log('Intercom did hide the Messenger');
  const onShow = () => console.log('Intercom did show the Messenger');

  if (isLoading || isLoadingPermissions) {
    return <CompassLoader />;
  }

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={`${loginPath}?${createSearchParams({ redirect: location.pathname })}`} />;
  }

  if (!state.access) {
    loginSilently();
    return <CompassLoader />;
  }

  if (!workspace.name) {
    return <CompassLoader />;
  }

  if (intercomAppId === '') {
    return <div>{children}</div>;
  }

  return (
    <IntercomProvider appId={intercomAppId} autoBoot onHide={onHide} onShow={onShow}>
      <div>{children}</div>
    </IntercomProvider>
  );
};

export function inAppContainer(Node: ReactNode) {
  return <AppContainer>{Node}</AppContainer>;
}

export default AppContainer;
