import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query';

import { getNextPageParam } from '../utils/helpers/helperFunctions';

import { Api } from './api';
import {
  ITransactionListParams,
  ITransactionListResponse,
  ITransactionExternalRequest,
  ITransactionExternalResponse,
  ITransactionTokenRequest,
  ITransactionTokenResponse,
  ITransactionNonTokenRequest,
  ITransactionNonTokenResponse,
  ITransactionReportRequest,
  ITransactionResponse,
  ITransactionScreenRequest,
  ITransactionExportList,
  ITransactionUpdateAlerts,
} from './dtos/transaction';
import { IAddressTransactionsExportResponse, IAggregatedResponse } from './dtos/address';

export class TransactionApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/transactions';

  readonly getTransactionList = (params: ITransactionListParams) => {
    const { offset, filters, assigned_to, workflow_stage, closed, q } = params;

    if (closed) {
      return this.http.get<ITransactionListResponse>(this.route(`/?offset=${offset}`), {
        params: { assigned_to, limit: 20, ...filters, workflow_stage, q },
      });
    }
    return this.http.get<ITransactionListResponse>(this.route(`/?offset=${offset}`), {
      params: { assigned_to, workflow_stage, limit: 20, ...filters, q },
    });
  };

  readonly getTransaction = (id: number) => {
    return this.http.get<ITransactionResponse>(this.route(`${id}/`));
  };

  readonly exportTransactionList = (params: ITransactionExportList) => {
    const { ids, filters, assigned_to, workflow_stage, closed } = params;

    if (closed) {
      return this.http.get<IAddressTransactionsExportResponse>(this.route(`/export/?ids=${ids.join(',')}`), {
        params: { assigned_to, ...filters, workflow_stage },
      });
    }
    return this.http.get<IAddressTransactionsExportResponse>(this.route(`/export/?ids=${ids.join(',')}`), {
      params: { assigned_to, workflow_stage, ...filters },
    });
  };

  readonly updateTransactionAlerts = (params: ITransactionUpdateAlerts) => {
    const { ids, filters, assigned_to, workflow_stage, closed, comment, updated_status } = params;

    if (closed) {
      return this.http.put<IAddressTransactionsExportResponse>(
        this.route(`/update-alerts/?ids=${ids.join(',')}`),
        {
          workflow_stage: updated_status,
          ...(comment && {
            comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } },
          }),
        },
        {
          params: {
            assigned_to,
            ...filters,
            workflow_stage,
          },
        }
      );
    }
    return this.http.put<IAddressTransactionsExportResponse>(
      this.route(`/update-alerts/?ids=${ids.join(',')}`),
      {
        workflow_stage: updated_status,
        ...(comment && { comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } } }),
      },
      {
        params: {
          assigned_to,
          workflow_stage,
          ...filters,
        },
      }
    );
  };

  readonly screenTransaction = (params: ITransactionScreenRequest) => {
    const {
      identifier,
      currency,
      customer_id = null,
      type = null,
      address = null,
      create_case = true,
    } = params;
    return this.http.post<ITransactionResponse>(this.route('/'), {
      create_case,
      identifier,
      currency,
      ...(!create_case && { create_case }),
      ...(customer_id && { customer_id, type, address }),
    });
  };

  readonly getTransactionReport = (params: ITransactionReportRequest) => {
    const { id, entities } = params;
    return this.http.get(this.route(`${id}/report/`), {
      params: { entities },
      responseType: 'blob',
    });
  };
  readonly archiveTransaction = (id: number) => {
    return this.http.delete(this.route(`${id}/`));
  };

  readonly getAggregatedInputs = (id: number) => {
    return this.http.get<IAggregatedResponse>(this.route(`${id}/aggregated-inputs/`));
  };

  readonly getAggregatedOutputs = (id: number) => {
    return this.http.get<IAggregatedResponse>(this.route(`${id}/aggregated-outputs/`));
  };

  readonly getInputs = (params: ITransactionNonTokenRequest) => {
    const { id, offset = 0, limit = 100, filters } = params;
    return this.http.get<ITransactionNonTokenResponse>(this.route(`${id}/inputs/`), {
      params: { offset, limit, ...filters },
    });
  };

  readonly getOutputs = (params: ITransactionNonTokenRequest) => {
    const { id, offset = 0, limit = 100, filters } = params;
    return this.http.get<ITransactionNonTokenResponse>(this.route(`${id}/outputs/`), {
      params: { offset, limit, ...filters },
    });
  };

  readonly getExternalTransaction = (params: ITransactionExternalRequest) => {
    const { id } = params;
    return this.http.get<ITransactionExternalResponse>(this.route(`${id}/external-transaction/`));
  };

  readonly getInternalTransactions = (params: ITransactionTokenRequest) => {
    const { id, offset, limit = 100, filters } = params;
    return this.http.get<ITransactionTokenResponse>(this.route(`${id}/internal-transactions/`), {
      params: { offset, limit, ...filters },
    });
  };

  readonly getTokenTransfers = (params: ITransactionTokenRequest) => {
    const { id, offset = 0, limit = 100, filters } = params;
    return this.http.get<ITransactionTokenResponse>(this.route(`${id}/token-transfers/`), {
      params: { offset, limit, ...filters },
    });
  };
}

export const transactionApi = new TransactionApi();

export const useTransactionGetTransactionList = (
  params: ITransactionListParams,
  options?: UseQueryOptions
) => {
  const key = [
    'transactionApi.getTransactionList',
    params.filters,
    params.assigned_to,
    params.workflow_stage,
    params?.q,
  ];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => transactionApi.getTransactionList({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useTransactionGetTransaction = (id: number, options?: UseQueryOptions) => {
  const key = ['transactionApi.getTransaction', id];
  const result = useQuery(key, () => transactionApi.getTransaction(id), { ...options } as unknown);

  return result;
};

export const useTransactionGetAggregatedInputs = (id: number, options?: UseQueryOptions) => {
  const key = ['transactionApi.getAggregatedInputs', id];
  const result = useQuery(key, () => transactionApi.getAggregatedInputs(id), {
    ...options,
  } as unknown);

  return result;
};

export const useTransactionGetAggregatedOutputs = (id: number, options?: UseQueryOptions) => {
  const key = ['transactionApi.getAggregatedOutputs', id];
  const result = useQuery(key, () => transactionApi.getAggregatedOutputs(id), {
    ...options,
  } as unknown);

  return result;
};
