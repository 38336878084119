import classNames from 'classnames';
import { ArrowDownRight, ArrowUpRight, UserCircle } from '@phosphor-icons/react';
import { ellipsis } from '../../../utils/helpers/helperFunctions';

interface ICustomerAddressList {
  address: {
    customer_id?: string;
    type?: number;
  };
}

const CustomerAddressList: React.FC<ICustomerAddressList> = ({ address }) => {
  if (!address.customer_id) return null;
  return (
    <div className='flex items-center gap-1' title={address.customer_id}>
      <span
        className={classNames(
          address.type === 0 ? 'text-gray-800' : address.type === 1 ? 'text-green-500' : 'text-red-500'
        )}>
        {address.type === 0 ? (
          <UserCircle aria-label='user' size={15} />
        ) : address.type === 1 ? (
          <ArrowDownRight aria-label='deposit' size={15} />
        ) : (
          <ArrowUpRight aria-label='withdrawal' size={15} />
        )}
      </span>
      <span>{ellipsis(address.customer_id, 16)}</span>
    </div>
  );
};

export default CustomerAddressList;
