import { renderToString } from 'react-dom/server';

const TooltipComponent = ({ label, count, total, color }) => (
  <div className='flex w-[150px] rounded-md p-3'>
    {color && <div className='flex rounded-sm border-l-4' style={{ borderColor: color }} />}
    <div className='ml-2'>
      <div className='font-semi-bold text-gray-500'>{label}</div>
      <div>
        <span className='mr-1 text-lg font-bold'>{count.toLocaleString()}</span>
        <span className='text-gray-500'>out of {total.toLocaleString()}</span>
      </div>
    </div>
  </div>
);

export const createTooltip = (label: string, count: number, total: number, color?: string) => {
  return renderToString(<TooltipComponent label={label} count={count} total={total} color={color} />);
};
