import { Api } from './api';
import {
  IUniversalSearchCustomersResponse,
  IUniversalSearchApiResponse,
  ISearchWithTypeParams,
  ISearchWithCustomersParams,
  ISearchRequestParams,
} from './dtos/search';
import { getNextPageParam } from '../utils/helpers/helperFunctions';
import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';
import { getNewOffsetForSearchPage } from '../utils/helpers/react-query.helper';

export class SearchApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/search';

  readonly getSearch = ({ offset = 0, q, entity_type }) => {
    return this.http.get<IUniversalSearchApiResponse>(this.route('/'), {
      params: { limit: 10, q, offset, entity_type },
    });
  };
  readonly getSearchWithCustomers = ({ offset = 0, q }) => {
    return this.http.get<IUniversalSearchCustomersResponse>(this.route(`customers/`), {
      params: { limit: 25, q, offset },
    });
  };
  readonly getSearchWithType = ({ offset = 0, q, search_entity_type }) => {
    return this.http.get<Partial<IUniversalSearchApiResponse>>(this.route('/'), {
      params: { limit: 25, q, offset, search_entity_type },
    });
  };
  readonly getUniversalSearch = ({ limit = 2, offset = 0, q }) => {
    return this.http.get<IUniversalSearchApiResponse>(this.route('suggest/'), {
      params: { limit, q, offset },
    });
  };
}

export const searchApi = new SearchApi();

export const useSearch = (params: ISearchRequestParams, options?: UseQueryOptions) => {
  const { query: q, entity_type } = params;
  const key = ['SearchApi.getSearch', q, entity_type];
  const result = useQuery(
    key,
    ({ pageParam }) => searchApi.getSearch({ offset: pageParam, q, entity_type }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return {
    key,
    ...result,
  };
};

export const useSearchWithCustomers = (params: ISearchWithCustomersParams, options?: UseQueryOptions) => {
  const key = ['SearchApi.searchCustomers', params.query];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => searchApi.getSearchWithCustomers({ offset: pageParam, q: params.query }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return result;
};

export const useSearchWithType = (params: ISearchWithTypeParams, options?: UseQueryOptions) => {
  const key = ['SearchApi.searchCases', params.query, params.search_entity_type];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) =>
      searchApi.getSearchWithType({
        offset: pageParam,
        q: params.query,
        search_entity_type: params.search_entity_type,
      }),
    {
      getNextPageParam: getNewOffsetForSearchPage,
      ...options,
    } as unknown
  );
  return result;
};

export const useUniversalSearch = ({ query }: { query: string }, options?: UseQueryOptions) => {
  const key = ['SearchApi.universalSearch', query];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => searchApi.getUniversalSearch({ offset: pageParam, q: query }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return result;
};
