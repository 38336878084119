import classNames from 'classnames';
import round from 'lodash/round';
import { FC, HTMLProps } from 'react';

interface ProgressBarProps extends Omit<HTMLProps<HTMLDivElement>, 'action'> {
  value: number;
  max?: number;
  barClassName?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({
  className,
  value,
  max = 100,
  barClassName,
  children,
  ...props
}) => {
  const percentage = Math.min(round((value / Math.max(max, 1)) * 100, 2), 100);

  return (
    <div className={classNames(className, 'relative h-5 rounded-[4px] bg-gray-200')} {...props}>
      <div
        className={classNames(
          'absolute h-full rounded-[4px] text-right align-middle text-gray-700',
          barClassName
        )}
        style={{
          width: `${percentage}%`,
        }}
        {...props}>
        {children}
      </div>
    </div>
  );
};

export default ProgressBar;
