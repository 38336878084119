import { VariantClasses } from '../../hooks/use-variant-classes';
import { FC, HTMLProps, useState } from 'react';
import { ReactNode } from 'react';
import { cx } from '../../../utils/helpers/common';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  children?: string | ReactNode;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  type?: 'button' | 'reset' | 'submit';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'irreversible' | 'danger';
  buttonSize?: 'sm' | 'md' | 'lg';
  iconClassName?: string;
}

const Button: FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = (props) => {
  // Using js hover for goldeneye style fix. TODO: use normal hover:

  const [isHovered, setIsHovered] = useState(false);

  const buttonVariant: VariantClasses<ButtonProps['variant']> = (variant) => {
    switch (variant) {
      case 'secondary':
        return `enabled:text-blue-700 border border-white-600 ${
          !isHovered ? 'enabled:bg-blue-100' : 'hover:bg-blue-200'
        } disabled:bg-blue-50 disabled:text-blue-400`;
      case 'tertiary':
        return `enabled:text-gray-700 border border-gray-300 ${
          !isHovered ? 'bg-white' : 'hover:bg-gray-50'
        } disabled:text-gray-300`;
      case 'irreversible':
        return `enabled:text-red-700 border border-red-100 ${
          !isHovered ? 'enabled:bg-red-100' : 'hover:bg-red-200'
        }  disabled:bg-red-50 disabled:text-red-400`;
      case 'danger':
        return `bg-red-600 text-white border border-red-600 hover:bg-red-800 disabled:bg-red-400 disabled:text-red-50`;
      // Added enabled:bg-blue-600 to make it work with goldeneye
      default:
        return `enabled:text-white border border-blue-600 ${
          !isHovered ? 'enabled:bg-blue-600' : 'hover:bg-blue-700'
        } disabled:bg-blue-400 disabled:text-gray-50`;
    }
  };

  const getButtonSize: VariantClasses<ButtonProps['buttonSize']> = (size) => {
    switch (size) {
      case 'sm':
        return 'px-2 py-1 text-xs';
      default:
        return 'px-4 py-2 text-xs';
    }
  };

  const {
    type = 'button',
    variant = 'primary',
    iconStart,
    iconEnd,
    iconClassName,
    className,
    children,
    buttonSize,
    ...btnProps
  } = props;

  const classes = buttonVariant(variant) + ' ' + getButtonSize(buttonSize);

  return (
    <button
      type={type}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={cx(
        `inline-flex items-center rounded-md shadow-sm outline-offset-2 focus:outline focus:outline-2 focus:outline-blue-500 disabled:hover:cursor-not-allowed ${classes} ${className}`
      )}
      {...btnProps}>
      {iconStart && <span className={cx(`${children ? 'mr-2' : ''} ${iconClassName}`)}>{iconStart}</span>}
      {children}
      {iconEnd && <span className={cx(`mr-2 ${iconClassName}`)}>{iconEnd}</span>}
    </button>
  );
};

export default Button;
