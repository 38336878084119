import { FC } from 'react';
import BasicBadge from '../../../../ui/components/Badges/BasicBadge/BasicBadge';

export interface AlertBadgeProps {
  type: number;
  className?: string;
  label?: string;
}

const AlertBadge: FC<AlertBadgeProps> = ({ type, className, label }) => {
  const alertData = {
    0: {
      bgColor: 'bg-white',
      label: 'Opened',
    },
    1: {
      bgColor: 'bg-yellow-500',
      label: 'Resolved',
    },
    2: {
      bgColor: 'bg-orange-500',
      label: 'Escalated',
    },
  };
  return (
    <BasicBadge
      className={`${alertData[type].bgColor} text-gray-500 ${className} px-2 py-px text-2xs leading-4`}>
      <div className='mx-1'>{label || alertData[type].label}</div>
    </BasicBadge>
  );
};

export default AlertBadge;
