import CustomSelect, { IOption } from './CustomSelect';
import { RiskBadge } from '@ui/index';

import { FC, useState } from 'react';
import { getRiskOptions } from '../../utils/helpers/common';

interface RiskFilterProps {
  value: number[];
  levels?: number[];
  setValue: (risks: number[]) => void;
}

const RiskFilter: FC<RiskFilterProps> = (props) => {
  const { value, setValue, levels } = props;

  const getLabel = (option: IOption) => {
    return <RiskBadge risk={Number(option.value)} />;
  };

  const riskOptions = getRiskOptions(levels);

  let selectedRisks = [];
  if (value && value.length) {
    selectedRisks = riskOptions.filter((option) => value.includes(Number(option.value)));
  }
  const [risks, setRisks] = useState<IOption[]>(selectedRisks);

  const handleChange = (risks: IOption[]) => {
    setValue(risks.map((r) => Number(r.value)));
    setRisks(risks);
  };

  return (
    <>
      <CustomSelect
        label='Risk Level'
        placeholder='Select Risk Level'
        options={riskOptions}
        getCustomLabel={getLabel}
        getCustomOptionLabel={getLabel}
        handleChange={handleChange}
        value={risks}
      />
    </>
  );
};

export default RiskFilter;
