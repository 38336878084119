import React from 'react';
import { IRuleCondition } from '../../../api/dtos/policies';
import { addressRules, conditionVariables, transactionRules } from '../../../utils/constants/rules';
import SingleSelect, { IOption } from '../../../components/ui/components/Select/SingleSelect';
import { entityTypes, getOptionsRuleConditions } from '../../../utils/helpers/entity';
import CustomSelect from '../../../components/Filters/CustomSelect';
import Select, { DropdownIndicatorProps, StylesConfig, components } from 'react-select';
import { Tooltip } from '../../../ui/components/Tooltip';
import { toast } from 'react-toastify';
import { reverseMapRuleConditions } from '../../../utils/helpers/rules';

interface Props {
  ruleCondition: IRuleCondition;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editedConditions: any[];
  setEditedConditions: (val) => void;
  index: number;
}

interface IRuleOption extends IOption {
  id?: string;
}

const RuleEditor: React.FC<Props> = ({
  ruleCondition: ruleConditionProp,
  type,
  editedConditions,
  setEditedConditions,
  index,
}) => {
  const ruleCondition = reverseMapRuleConditions(ruleConditionProp);
  const rulesList = type === 'transaction' ? transactionRules : addressRules;
  const [search, setSearch] = React.useState('');
  const [stateCondition, setStateCondition] = React.useState('');
  const [entityNameOptions, setEntityNameOptions] = React.useState([]);
  const styles: StylesConfig<unknown, false> = {
    control: (css) => ({
      ...css,
      borderRadius: '6px',
      border: '1px solid #D1D5DB',
      minHeight: '28px',
      '*': {
        boxShadow: 'none !important',
      },
    }),
    indicatorsContainer: (css) => ({ ...css, display: 'visible' }),
    input: (css) => ({
      ...css,
      color: 'blue',
      fontSize: '11px',
      paddingTop: '2px',
      cursor: 'text',
      display: 'flex',
    }),
    placeholder: (css) => ({ ...css, fontSize: '15px' }),
    menu: (css) => ({
      ...css,
      border: '1px solid #D1D5DB',
      borderRadius: '6px',
      padding: '2px 0px 2px 0px',
      boxShadow: 'none',
      zIndex: 2,
    }),
    option: (css, { isSelected }) => ({
      ...css,
      padding: '5px',
      backgroundColor: isSelected ? 'rgb(37,99,235)' : 'white',
      color: isSelected ? 'white' : 'black',
      cursor: 'pointer',
      border: 0,
      outline: 'none',
      ':hover': {
        color: 'black',
        backgroundColor: '#d6d2d2',
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      background: 'none',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#2563eb',
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      borderRadius: 0,
      fontSize: 11,
      padding: '1px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#2563eb',
      paddingLeft: '0px',
      marginLeft: -1,
      borderRadius: 0,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      ':hover': {
        backgroundColor: '#606d7c',
      },
    }),
  };
  const DropdownIndicator = (props: DropdownIndicatorProps<IRuleOption, true>) => {
    return (
      <components.DropdownIndicator {...props} className='!px-2 !py-1'>
        {customDropdownArrow(
          conditionVariables['entity_names']?.placeholder,
          conditionVariables['entity_names']?.tooltip
        )}
      </components.DropdownIndicator>
    );
  };
  const options = React.useMemo(() => {
    return getOptionsRuleConditions(ruleCondition.rule_condition_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const [multiValue, setMultiValue] = React.useState({});
  const [singleValue, setSingleValue] = React.useState({});
  const riskTypesKeys = [
    'risk_types',
    'risk_types_1',
    'risk_types_2',
    'indirect_risk_types',
    'inactivity_types',
  ];
  const [stateOptions, setStateOptions] = React.useState(options);
  const [inputValue, setInputValue] = React.useState('');
  const handleRuleChange = (option: IRuleOption, conditionType: string) => {
    if (conditionType === 'threshold_amount_usd') {
      if (
        !conditionVariables[conditionType].checkError(
          Number(option?.id),
          Number(singleValue['upper_threshold_amount_usd']?.id)
        )
      ) {
        toast.error(conditionVariables[conditionType].error);
        return;
      }
    }
    if (conditionType === 'upper_threshold_amount_usd') {
      if (
        !conditionVariables[conditionType].checkError(
          Number(option?.id),
          Number(singleValue['threshold_amount_usd']?.id || null)
        )
      ) {
        toast.error(conditionVariables[conditionType].error);
        return;
      }
    }
    if (conditionType === 'taint_percent_threshold') {
      if (
        !conditionVariables[conditionType].checkError(
          Number(option?.id),
          Number(singleValue['taint_percent_upper_threshold']?.id || null)
        )
      ) {
        toast.error(
          conditionVariables[conditionType].error.replace(
            '{val}',
            singleValue['taint_percent_upper_threshold']?.id || 100
          )
        );
        return;
      }
    }
    if (conditionType === 'taint_percent_upper_threshold') {
      if (
        !conditionVariables[conditionType].checkError(
          Number(option?.id),
          Number(singleValue['taint_percent_threshold']?.id || null)
        )
      ) {
        toast.error(
          conditionVariables[conditionType].error.replace(
            '{val}',
            singleValue['taint_percent_threshold']?.id || 0
          )
        );
        return;
      }
    }
    if (conditionType === 'lower_bound') {
      if (
        !conditionVariables[conditionType].checkError(
          Number(option?.id),
          Number(singleValue['upper_bound']?.id || null)
        )
      ) {
        toast.error(conditionVariables[conditionType].error);
        return;
      }
    }
    if (conditionType === 'upper_bound') {
      if (
        !conditionVariables[conditionType].checkError(
          Number(option?.id),
          Number(singleValue['lower_bound']?.id || null)
        )
      ) {
        toast.error(conditionVariables[conditionType].error);
        return;
      }
    }
    const newItem = editedConditions[index] || {};
    if (riskTypesKeys.includes(conditionType)) {
      if (conditionType === 'inactivity_types') {
        newItem['inactivity_types'] = option?.id === '1' ? [1] : option?.id === '2' ? [2] : [1, 2];
      } else {
        newItem['risk_types'] = option?.id === '1' ? [1] : option?.id === '2' ? [2] : [1, 2];
      }
    } else if (
      (conditionType === 'taint_percent_upper_threshold' || conditionType === 'upper_threshold_amount_usd') &&
      option?.id === '0'
    ) {
      newItem[conditionType] = null;
    } else if (
      conditionType === 'taint_percent_threshold' ||
      conditionType === 'threshold_amount_usd' ||
      conditionType === 'taint_percent_upper_threshold' ||
      conditionType === 'upper_threshold_amount_usd'
    ) {
      newItem[conditionType] = parseFloat(option?.id);
    } else if (
      conditionType === 'lower_transaction_count_threshold' ||
      conditionType === 'transaction_count_threshold_2'
    ) {
      newItem['transaction_count_threshold'] = parseInt(option?.id);
    } else if (conditionType === 'analysis_window_2') {
      newItem['analysis_window'] = parseInt(option?.id);
    } else {
      newItem[conditionType] = parseInt(option?.id);
    }
    const newItems = [...editedConditions.slice(0, index), newItem, ...editedConditions.slice(index + 1)];
    setEditedConditions(newItems);
    const oldItem = { ...singleValue };
    oldItem[conditionType] = option;
    setSingleValue(oldItem);
  };

  React.useEffect(() => {
    const oldItemSingle = {};
    const oldItemMulti = {};
    const matches = rulesList[ruleCondition.rule_condition_type]?.match(/\{([^}]+)\}/g);

    matches?.map((key) => {
      const cleanKey = key.slice(1, -1);
      if (riskTypesKeys.includes(cleanKey)) {
        if (cleanKey !== 'inactivity_types') {
          oldItemSingle[cleanKey] =
            stateOptions[cleanKey]?.find((item) => item.id === ruleCondition['risk_types']?.join('')) || null;
        } else {
          oldItemSingle[cleanKey] =
            stateOptions[cleanKey]?.find((item) => item.id === ruleCondition['inactivity_types']?.join('')) ||
            null;
        }
      } else if (['entity_names', 'entity_subtypes', 'entity_types', 'sentiments'].includes(cleanKey)) {
        if (!Array.isArray(oldItemMulti[cleanKey])) {
          oldItemMulti[cleanKey] = [];
        }
        if (cleanKey === 'entity_names') {
          ruleCondition[cleanKey]?.map((mapItem) =>
            oldItemMulti[cleanKey]?.push({
              label: mapItem?.toString(),
              value: mapItem?.toString(),
              id: mapItem?.toString(),
            })
          );
        } else {
          ruleCondition[cleanKey]?.map((mapItem) =>
            oldItemMulti[cleanKey]?.push(
              stateOptions[cleanKey]?.find((item) => item.id?.toString() === mapItem?.toString())
            )
          );
        }
      } else if (ruleCondition[cleanKey] !== undefined) {
        if (ruleCondition[cleanKey]?.toString() === '0' && conditionVariables[cleanKey].zero) {
          oldItemSingle[cleanKey] = {
            label: conditionVariables[cleanKey]?.zero,
            value: conditionVariables[cleanKey]?.zero,
            id: 0,
          };
        } else {
          oldItemSingle[cleanKey] = stateOptions[cleanKey]?.find(
            (item) => item.id?.toString() === ruleCondition[cleanKey]?.toString()
          ) || {
            label: conditionVariables[cleanKey].text.replace(
              /\{val\}/g,
              parseFloat(ruleCondition[cleanKey])?.toString()
            ),
            value: conditionVariables[cleanKey].text.replace(
              /\{val\}/g,
              parseFloat(ruleCondition[cleanKey])?.toString()
            ),
            id: parseFloat(ruleCondition[cleanKey])?.toString(),
          };
        }
      } else if (
        stateOptions[cleanKey]?.find((item) => item.label.includes('any')) &&
        cleanKey !== 'threshold_amount_usd'
      ) {
        oldItemSingle[cleanKey] = {
          label: conditionVariables[cleanKey]?.zero,
          value: conditionVariables[cleanKey]?.zero,
          id: 0,
        };
      } else {
        oldItemSingle[cleanKey] = null;
      }
    });
    setSingleValue(oldItemSingle);
    setMultiValue(oldItemMulti);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, ruleCondition]);

  const handleMultiChange = (stateOptions: IRuleOption[], conditionType: string) => {
    const oldItem = { ...multiValue };
    const newItem = editedConditions[index] || {};
    if (conditionType === 'entity_names') {
      if (stateOptions.find((item) => item.id === '')) {
        return;
      }
      newItem[conditionType] = stateOptions.map((item) => item.label);
    } else {
      newItem[conditionType] = stateOptions.map((item) => parseInt(item.id));
    }
    oldItem[conditionType] = stateOptions;
    setMultiValue(oldItem);
    const newItems = [...editedConditions.slice(0, index), newItem, ...editedConditions.slice(index + 1)];
    setEditedConditions(newItems);
  };

  React.useEffect(() => {
    if (search !== '' && !riskTypesKeys.includes(stateCondition)) {
      if (parseFloat(search)) {
        options[stateCondition].push({
          label: conditionVariables[stateCondition].text.replace(/\{val\}/g, parseFloat(search)?.toString()),
          value: conditionVariables[stateCondition].text.replace(/\{val\}/g, parseFloat(search)?.toString()),
          id: parseFloat(search)?.toString(),
        });
      } else {
        options[stateCondition].push({
          label: conditionVariables[stateCondition].text?.replace(/\{val\}/g, 'NaN'),
          value: conditionVariables[stateCondition].text?.replace(/\{val\}/g, 'NaN'),
          id: -1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    setStateOptions(options);
  }, [options]);

  const getCustomOptionLabel = (option: IRuleOption) => {
    return <div className='rounded-xl bg-blue-600 px-1.5 py-0 text-xs text-white'>{option.label}</div>;
  };

  const getCustomOptionLabelEntityNames = (option: IRuleOption, conditionType: string) => {
    if (conditionType === 'entity_types') {
      return (
        <div className={`rounded-xl ${entityTypes[option.label].bgColor} px-1.5 py-0 text-xs text-white`}>
          {option.label}
        </div>
      );
    }
    return <div className='rounded-xl bg-blue-600 px-1.5 py-0 text-xs text-white'>{option.label}</div>;
  };

  const noOptionsMessage = () => {
    return 'List is empty';
  };

  const handleKeyDown = (e, conditionType) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      if (!multiValue[conditionType].some((option) => option.value === inputValue)) {
        const oldItem = [...multiValue[conditionType]];
        oldItem.push({ value: inputValue, label: inputValue, id: inputValue });
        handleMultiChange(oldItem, 'entity_names');
        setInputValue('');
      }
    }
  };

  const onInputChange = (newText: string) => {
    if (newText !== '') {
      setEntityNameOptions([{ label: newText, value: newText, id: newText }]);
      setInputValue(newText);
    } else {
      setEntityNameOptions([]);
      setInputValue(newText);
    }
  };

  const customDropdownArrow = (title: string, body: string) => {
    return (
      <Tooltip
        className='inline-block bg-slate-300 align-middle leading-normal text-gray-900'
        contentTitle={title}
        content={body}
        label='?'
      />
    );
  };
  const replacedSentence = rulesList[ruleCondition.rule_condition_type]
    ?.split(/(\{.*?\})/)
    ?.map((part, index) => {
      if (part.startsWith('{') && part.endsWith('}') && part !== '{item_type}') {
        const conditionType = part.substring(1, part.length - 1);
        if (conditionType === 'entity_names') {
          return (
            <Select
              key={index}
              value={multiValue[conditionType] || []}
              components={{ DropdownIndicator }}
              isMulti
              options={entityNameOptions}
              isClearable={false}
              hideSelectedOptions={false}
              styles={styles}
              placeholder='Entity Names'
              noOptionsMessage={noOptionsMessage}
              onChange={(values) => handleMultiChange(values, conditionType)}
              onKeyDown={(e) => handleKeyDown(e, conditionType)}
              inputValue={inputValue}
              onInputChange={onInputChange}
            />
          );
        } else if (['entity_subtypes', 'entity_types', 'sentiments'].includes(conditionType)) {
          return (
            <CustomSelect
              placeholder={conditionVariables[conditionType]?.placeholder}
              key={index}
              options={stateOptions[conditionType] || []}
              handleChange={(values) => handleMultiChange(values, conditionType)}
              className='!mt-1'
              parentClassName={conditionType === 'entity_subtypes' ? 'min-w-[350px]' : 'min-w-[300px]'}
              value={multiValue[conditionType] || []}
              limit={conditionType === 'entity_subtypes' ? 1 : 2}
              getCustomLabel={(option: IRuleOption) => getCustomOptionLabelEntityNames(option, conditionType)}
              controlClassPaddingY='!py-1'
              customDropdownArrow={customDropdownArrow(
                conditionVariables[conditionType]?.placeholder,
                conditionVariables[conditionType]?.tooltip
              )}
            />
          );
        }
        return (
          <SingleSelect
            placeholder={conditionVariables[conditionType]?.placeholder}
            key={index}
            options={stateOptions[conditionType] || []}
            handleChange={(value) => handleRuleChange(value, conditionType)}
            className='!mt-0'
            isSearchable={true}
            value={singleValue[conditionType] || null}
            setSearch={setSearch}
            searchLabel={conditionVariables[conditionType]?.placeholder}
            stateCondition={conditionType}
            setStateCondition={setStateCondition}
            getCustomLabel={getCustomOptionLabel}
            controlClassPaddingY='!py-1'
            customDropdownArrow={customDropdownArrow(
              conditionVariables[conditionType]?.placeholder,
              conditionVariables[conditionType]?.tooltip
            )}
            isCloseHidden={true}
          />
        );
      } else {
        if (part === '{item_type}') {
          return type;
        }
        return part;
      }
    });
  return <div className='flex flex-wrap items-center gap-1 text-sm'>{replacedSentence}</div>;
};

export default RuleEditor;
