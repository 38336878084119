import { Lightning } from '@phosphor-icons/react';
import { BasicBadge, StatusBadge } from '../../../../ui';
import { FC } from 'react';
import classNames from 'classnames';

interface IntegrationBadgeProps {
  isEnabled: boolean;
  className?: string;
  isAdvanced?: boolean;
  type: string;
}

const IntegrationBadge: FC<IntegrationBadgeProps> = ({ isEnabled, isAdvanced, className, type }) => {
  const activeText = type === 'fireblocks' ? 'Basic Integration Active' : 'Active';
  if (!(isAdvanced && isEnabled))
    return <StatusBadge className={className} isActive={isEnabled} enableLabel={activeText} />;

  return (
    <BasicBadge
      className={classNames(className, 'bg-purple-100 text-purple-600')}
      iconStart={<Lightning size={16} weight='fill' className='mr-1' />}>
      Advanced Integration Active
    </BasicBadge>
  );
};

export default IntegrationBadge;
