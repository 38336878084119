import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  IPolicyResponse,
  IPolicyCryptoObjectsFlagged,
  IPolicyRulesTriggeredResponse,
  IPolicyAlert,
  IPolicyRulesTriggeredListResponse,
} from '../../../api/dtos/policies';
import PolicyStatusBadge from '../../../ui/components/Badges/StatusBadge/PolicyStatusBadge';
import { BasicBadge, RiskBadge } from '../../../ui';

import PolicyAlertChart from './PolicyAlertChart';
import Table from '../../../ui/components/Table/Table';
import { UseInfiniteQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { buildInfiniteQueryTableProps } from '../../../utils/helpers/react-query.helper';

interface Props {
  policyDetails: IPolicyResponse;
  isLoadingPolicyCryptoObjects: boolean;
  policyCryptoObjectsFlagged: IPolicyCryptoObjectsFlagged;
  isLoadingPolicyRulesList: boolean;
  policyRules: IPolicyRulesTriggeredResponse[];
  isLoadingPolicyAlert: boolean;
  policyAlerts: IPolicyAlert[];
  policyGetTriggeredRulesQuery: UseInfiniteQueryResult<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<IPolicyRulesTriggeredListResponse, any>,
    unknown
  >;
  policyRulesCount: number;
}

const PolicyOverview: React.FC<Props> = ({
  policyDetails,
  isLoadingPolicyCryptoObjects,
  policyCryptoObjectsFlagged,
  isLoadingPolicyRulesList,
  policyRules,
  isLoadingPolicyAlert,
  policyAlerts,
  policyGetTriggeredRulesQuery,
  policyRulesCount,
}) => {
  const headerData = ['RISK LEVEL', 'RULE NAME', 'TRIGGERS'];

  const rows = policyRules?.map((policyRule, index) => {
    return {
      id: index,
      data: [
        <RiskBadge key={index} risk={policyRule.risk_level} />,
        <div key={index}>{policyRule.rule_name}</div>,
        <div key={index}>{policyRule.count}</div>,
      ],
    };
  });

  return (
    <div className=''>
      <div className='border-b p-4 text-sm'>
        <p className='mb-1 mt-3 font-semibold text-gray-500'>{policyDetails.name}</p>
        <p className='mb-5 text-gray-500'>{policyDetails.description}</p>
        <div className='mb-1 grid grid-cols-4'>
          <div>
            <p className='mb-1 text-sm text-gray-500'>Status</p>
            <div>
              <PolicyStatusBadge isActive={policyDetails.is_enabled} />
            </div>
          </div>
          <div>
            <p className='mb-1 text-sm text-gray-500'>Policy Type</p>
            <div>
              <BasicBadge className='bg-gray-200'>{policyDetails.type_verbose}</BasicBadge>
            </div>
          </div>
          <div>
            <p className='mb-1 text-sm text-gray-500'>Policy Category</p>
            <div>
              <BasicBadge className='bg-gray-200'>{policyDetails.category_verbose}</BasicBadge>
            </div>
          </div>
          <div>
            <p className='mb-1 text-sm text-gray-500'>Number of Rules</p>
            <p>{policyDetails.rule_count}</p>
          </div>
        </div>
      </div>
      {isLoadingPolicyCryptoObjects ? (
        <div className='grid grid-cols-3 gap-3 p-4'>
          <Skeleton height={150} />
          <Skeleton height={150} />
          <Skeleton height={150} />
        </div>
      ) : (
        <div className='grid grid-cols-3 gap-3 p-4'>
          <div className='border p-3.5'>
            <p className='mb-1 text-sm text-gray-500'>{`${policyDetails.type_verbose} Created`}</p>
            <p className='text-lg font-bold'>{policyCryptoObjectsFlagged.total_count}</p>
          </div>
          <div className='border p-3.5'>
            <p className='mb-1 text-sm text-gray-500'>{`${policyDetails.type_verbose} Flagged`}</p>
            <p className='text-lg font-bold'>{policyCryptoObjectsFlagged.flagged_count}</p>
          </div>
          <div className='border p-3.5'>
            <p className='mb-1 text-sm text-gray-500'>Hit Rate</p>
            <p className='text-lg font-bold'>{policyCryptoObjectsFlagged.hit_rate}</p>
          </div>
        </div>
      )}
      <div className='flex gap-3 p-4'>
        <div className='h-fit w-[450px] border'>
          <div className='border-b px-3 py-2 text-sm text-gray-500'>
            <p className='font-medium'>Alerts Triggered</p>
            <p>Over Last Week</p>
          </div>
          <div>
            {isLoadingPolicyAlert ? (
              <Skeleton height={350} />
            ) : (
              <PolicyAlertChart policyAlerts={policyAlerts} />
            )}
          </div>
        </div>
        <div className='h-[261px] flex-1 border'>
          <Table
            title='Rules Triggered'
            count={policyRulesCount}
            isLoading={isLoadingPolicyRulesList}
            headerData={headerData}
            rows={rows}
            height={261}
            {...buildInfiniteQueryTableProps(policyGetTriggeredRulesQuery)}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyOverview;
