import { WarningCircle } from '@phosphor-icons/react';
import { FC, HTMLProps, ReactNode } from 'react';
import CopyToClipboardBtn from '../Copy/Copy';
import _ from './Input.module.scss';
import { cx } from '../../../utils/helpers/common';

export interface InputProps extends HTMLProps<HTMLInputElement> {
  id: string;
  type:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  error?: string | boolean;
  labelText?: string;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  value?: string | number;
  inputClassNames?: string;
  labelClassNames?: string;
  copyable?: boolean;
  showErrorMesg?: boolean;
  helpText?: string;
}

const Input: FC<InputProps> = (props) => {
  const {
    type,
    id,
    error,
    iconStart,
    iconEnd,
    labelText,
    placeholder,
    className,
    labelClassNames,
    inputClassNames,
    value,
    copyable,
    showErrorMesg = true,
    helpText,
    ...otherProps
  } = props;

  return (
    <div className={className}>
      {labelText && (
        <label
          htmlFor={id}
          className={cx('block text-sm font-medium leading-default text-gray-800', labelClassNames)}>
          {labelText}
        </label>
      )}
      <div className={cx('relative flex rounded-md shadow-sm', labelText ? 'mt-1' : '')}>
        {iconStart && (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            {iconStart}
          </div>
        )}
        <input
          type={type}
          id={id}
          className={cx(
            error
              ? 'border-red-400 pr-10 text-red-800 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
              : 'border-gray-300 text-gray-800 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500',
            'block w-full rounded-md border px-3 py-2 text-sm font-medium leading-default',
            {
              'pl-8': iconStart != null,
              'pr-8': iconEnd != null,
              'rounded-br-none rounded-tr-none': copyable,
            },
            _.inputField,
            inputClassNames
          )}
          placeholder={placeholder || 'Enter value'}
          value={value}
          {...otherProps}
        />
        {!error && iconEnd && (
          <div className='absolute inset-y-0 right-0 flex items-center pr-3'>{iconEnd}</div>
        )}
        {error && (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <WarningCircle className='size-5 text-red-500' aria-hidden='true' />
          </div>
        )}
        {copyable && (
          <div className='flex items-center rounded-lg rounded-l-none border border-gray-200 px-2 py-1'>
            <CopyToClipboardBtn text={value as string} />
          </div>
        )}
      </div>
      {error && typeof error === 'string' && showErrorMesg && (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {error}
        </p>
      )}
      {helpText && (
        <p className='mt-2 text-sm text-gray-500' id='help-text'>
          {helpText}
        </p>
      )}
    </div>
  );
};

export default Input;
