import { FC } from 'react';
import { getCurrencyImageById } from '../../../../utils/helpers/currency';
import classNames from 'classnames';

interface CurrencyValueProps {
  value: number | string | null;
  currency: string | number;
  className?: string;
  dualTone?: boolean;
  showIcon?: boolean;
}

const CurrencyValue: FC<CurrencyValueProps> = ({
  value,
  className,
  currency,
  dualTone = false,
  showIcon = false,
}) => {
  if ([null, undefined, '-', ''].includes(value as string)) return <span>-</span>;
  const isZero = Number(value) === 0;
  const nf = Intl.NumberFormat('en-US', {
    maximumFractionDigits: currency === 'USD' ? 2 : 4,
    minimumFractionDigits: currency === 'USD' ? 2 : 0,
  });
  const formatCurrency = isZero ? '0.00' : nf.format(Number(value));

  const [integral, fractional] = String(formatCurrency).split('.');

  return (
    <span className={classNames(className, 'flex')}>
      {currency === 'USD' && '$'}
      {showIcon && <img className='mr-1' width={16} src={getCurrencyImageById(currency as number)} />}
      <span title={formatCurrency}>
        {integral}
        {fractional && (
          <>
            {'.'}
            <span className={`${dualTone ? 'text-gray-400' : ''}`}>{fractional}</span>
          </>
        )}
      </span>
      {currency !== 'USD' && !showIcon && <span className='ml-1'>{currency}</span>}
    </span>
  );
};

export default CurrencyValue;
