import { FC } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { ICustomerAddressResponse, ICustomerResponse } from '../../api/dtos/customer';
import { AxiosResponse } from 'axios';
import CustomerSummary from './Overview/CustomerSummary';
import Alerts from '../common/Alerts';
import NonAlertStageAssignee from '../common/NonAlertStageAssignee';

interface ICustomerAddressesProps {
  customer: ICustomerResponse;
  depositAddressesQuery: UseInfiniteQueryResult<AxiosResponse<ICustomerAddressResponse>>;
  customerId: string;
  setAlerts: (alerts: number) => void;
}

const CustomerAddresses: FC<ICustomerAddressesProps> = ({
  customer,
  depositAddressesQuery,
  customerId,
  setAlerts,
}) => {
  return (
    <div className='flex flex-col gap-3'>
      <CustomerSummary
        customer={customer}
        depositAddressesQuery={depositAddressesQuery}
        showAddressSummary
        showDigitalAssetSummary={false}
      />
      <NonAlertStageAssignee
        type='customers'
        id={customerId}
        assigned_to_name={customer?.assigned_to}
        stage_name={customer?.workflow_stage?.name}
        next_stages={customer?.workflow_stage?.next_stages}
        stage_id={customer?.workflow_stage?.id}
        is_final_stage={customer?.workflow_stage?.is_final_stage}
      />
      <Alerts
        id={encodeURIComponent(customer.customer_id)}
        custId={customer.id}
        type='customer'
        setAlerts={setAlerts}
        entity_type='address'
        showLoading
      />
    </div>
  );
};

export default CustomerAddresses;
