import { DotsThreeOutline } from '@phosphor-icons/react';
import { FC, useState } from 'react';
import { IAddressResponse } from '../../../api/dtos/address';
import { BasicBadge, Button, DropdownOption } from '../../../ui';
import AddToCase from '../../AddressTransaction/AddToCase';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import SimpleDropdown from '../../ui/components/Dropdown/SimpleDropdown';
import IKnowThisAddress from './IKnowThisAddress';
import MonitoringModal from './MonitoringModal';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';
import ExportReport from '../../common/ExportReport';
import ArchiveButton from '../../common/ArchiveButton';
import { useCurrentRole, useWorkspace } from '../../../utils/helpers/common';

interface AddressMetadataHeaderProps {
  address: IAddressResponse;
}

const AddressMetadataHeader: FC<AddressMetadataHeaderProps> = ({ address }) => {
  const destinationTag = address.identifier.match(/:(\d){1,10}/g)?.[0]?.slice(1);
  const addressWithoutTag = address.identifier.replace(/:(\d){1,10}/g, '');
  const { isAdmin, isOrgAdmin } = useCurrentRole();

  const { getQueryParams } = useRouter();
  const { entityId, refCase } = getQueryParams();

  const { state } = useAuth();
  const workspace = useWorkspace();
  const isMonitoringActive = !address.is_muted && !!workspace.continuous_monitoring_level;

  const isMonitoringVisible = () => {
    if (
      !state.userProfile.can_enable_continuous_monitoring ||
      !workspace.continuous_monitoring_level ||
      workspace.continuous_monitoring_level === 3 ||
      !(isAdmin || isOrgAdmin)
    ) {
      return false;
    }
    if (address.is_muted && !address.can_unmute) {
      return false;
    }
    return true;
  };

  const moreDropdownOptions = [
    ...(isMonitoringVisible()
      ? [
          {
            label: `${!isMonitoringActive ? 'Enable' : 'Deactivate'} Monitoring`,
            value: 'monitoring',
          },
        ]
      : []),
    { label: 'Archive', value: 'archive' },
    { label: 'I know this address', value: 'know' },
  ];
  const [archive, setArchive] = useState(false);
  const [monitoringModal, SetMonitoringModal] = useState(false);
  const [knowAddress, setKnowAddress] = useState(false);
  const onChangeDropdown = (value: DropdownOption) => {
    if (value.value === 'archive') {
      setArchive(true);
    } else if (value.value === 'monitoring') {
      SetMonitoringModal(true);
    } else if (value.value === 'know') {
      setKnowAddress(true);
    }
  };
  return (
    <>
      <div className='flex flex-col justify-between'>
        <IdentifierEllipsis
          identifier={addressWithoutTag}
          limit={16}
          className='text-xl text-gray-800'
          copyable
        />
        <div id='address-full-id' className='flex text-wrap break-all text-xs text-gray-500'>
          {addressWithoutTag}
        </div>
      </div>
      {address?.is_megahub && <BasicBadge className='mt-2 bg-yellow-200'>Megahub</BasicBadge>}
      {destinationTag && (
        <>
          <div className='mt-2 text-xs font-medium uppercase text-gray-500'>Destination Tag</div>
          <div className='flex flex-col justify-between'>
            <IdentifierEllipsis
              identifier={destinationTag}
              limit={16}
              className='text-xl text-gray-800'
              copyable
            />
            <div id='destination-tag' className='flex text-wrap break-all text-sm text-gray-500'>
              {destinationTag}
            </div>
          </div>
        </>
      )}
      <div className='mt-2 flex'>
        <AddToCase address={address} refCase={{ case: Number(refCase), entity: Number(entityId) }} />
        <ExportReport id={address.id} type='address' />

        <SimpleDropdown
          value={null}
          onChange={onChangeDropdown}
          placeholder={
            <Button variant='tertiary' className='my-1 ml-2 px-3'>
              <DotsThreeOutline size={16} weight='fill' />
            </Button>
          }
          options={moreDropdownOptions}
        />
        <ArchiveButton
          id={address.id}
          open={archive}
          setOpen={setArchive}
          identifier={address.identifier}
          type='address'
        />
        <MonitoringModal open={monitoringModal} setOpen={SetMonitoringModal} address={address} />
        <IKnowThisAddress open={knowAddress} setOpen={setKnowAddress} address={address} />
      </div>
    </>
  );
};

export default AddressMetadataHeader;
