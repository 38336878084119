import { FC } from 'react';

interface HighlightedIdentifierProps {
  identifier: string;
  highlightedText: string;
  showEllipsis?: boolean;
  maxLength?: number;
}

const HighlightedIdentifier: FC<HighlightedIdentifierProps> = ({
  identifier,
  highlightedText,
  showEllipsis = false,
  maxLength = 20,
}) => {
  const truncatedIdentifier =
    showEllipsis && identifier.length > maxLength ? `${identifier.slice(0, maxLength)}...` : identifier;

  const parts = truncatedIdentifier.split(new RegExp(`(${highlightedText})`, 'gi'));

  return (
    <code className='text-[11.5px] text-blue-500'>
      {parts.map((part: string, index: number) =>
        part.toLowerCase() === highlightedText.toLowerCase() ? (
          <span
            key={index}
            className='bg-yellow-200'
            style={{
              display: 'inline',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>
            {part}
          </span>
        ) : (
          <span key={index} style={{ wordBreak: 'break-word' }}>
            {part}
          </span>
        )
      )}
    </code>
  );
};

export default HighlightedIdentifier;
