import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Textarea, TextareaProps } from '../Input';

type TextareaControllerProps<TFieldValues, TName extends FieldPath<TFieldValues>> = TextareaProps &
  Omit<ControllerProps<TFieldValues, TName>, 'render'>;

const TextareaController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  ...textareaProps
}: TextareaControllerProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState }) => {
        return <Textarea {...field} {...textareaProps} error={fieldState?.error?.message} />;
      }}></Controller>
  );
};

export default TextareaController;
