import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { RadioButtons, RadioButtonsProps } from '../RadioButtons';

type RadioButtonsControllerProps<TFieldValues, TName extends FieldPath<TFieldValues>> = RadioButtonsProps &
  Omit<ControllerProps<TFieldValues, TName>, 'render'> & { checked?: boolean };

const RadioButtonsController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  ...props
}: RadioButtonsControllerProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, name, onBlur, onChange, value } }) => {
        // return <Input {...field} {...inputProps} error={fieldState?.error?.message} />;

        return <RadioButtons {...props} name={name} selected={value} onChange={onChange} />;
      }}></Controller>
  );
};

export default RadioButtonsController;
