import { FC, useState } from 'react';
import CheckboxController from '../../../../ui/components/Form/CheckboxController';
import { TabHeader } from '../../../../components/ui/components/Header';
import { useForm } from 'react-hook-form';

import { useMutation, useQueryClient } from 'react-query';

import { AxiosError, AxiosResponse } from 'axios';
import { IErrorResponse } from '../../../../api/dtos/common';
import { toast } from 'react-toastify';
import {
  useUserContinuousMonitoringLevelGet,
  useUserMonitoringPreferenceGet,
  monitoringPreferencesApi,
} from '../../../../api/settings/monitoringPreferences';
import {
  IContinuousMonitoringUpdateRequest,
  IUserMonitoringPreferenceContinuousMonitoringResponse,
  IUserMonitoringPreferenceResponse,
} from '../../../../api/dtos/settings/monitoringPreferences';
import MonitoringTable from '../../../../components/Settings/Monitoring/MonitoringTable';
import RadioButtonsController from '../../../../ui/components/Form/RadioButtonsController';
import { BasicBadge, Button, RadioButton } from '../../../../ui';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';
import { getErrorMessage } from '../../../../utils/helpers/helperFunctions';
import SingleSelect, { IOption } from '../../../../components/ui/components/Select/SingleSelect';
import classNames from 'classnames';
import { useAuth } from '../../../../modules/auth';
import {
  customMonitoredEntitiesApi,
  useCustomMonitoredEntitiesGet,
} from '../../../../api/settings/customMonitoringEntities';
import { CircleNotch, X } from '@phosphor-icons/react';
import Select, { DropdownIndicatorProps, StylesConfig, components } from 'react-select';
import Popover from '../../../../ui/components/Popover/Popover';
import { formatDateTime } from '../../../../utils/helpers/date';

type ContinuousMonitoringType = 0 | 1 | 2 | 3;
interface MonitoringPreferenceProps {}
const initialData = {
  monitoring: 1 as ContinuousMonitoringType,
  riskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
};

interface IEntityOption extends IOption {
  added_by: string;
  created_at: string;
}

const MonitoringPreference: FC<MonitoringPreferenceProps> = () => {
  const queryClient = useQueryClient();
  const [currentMonitoringLevel, setCurrentMonitoringLevel] = useState<0 | 1 | 2 | 3>();
  const [isMonitoringTableLoading, setIsMonitoringTableLoading] = useState(false);
  const [updatedEntities, setUpdatedEntities] = useState<IEntityOption[]>([]);
  const [inputValue, setInputValue] = useState('');

  const [duration, setDuration] = useState<IOption>(null);

  const { control, setValue } = useForm<typeof initialData>({
    defaultValues: initialData,
  });

  const { state } = useAuth();
  const canEnableMonitoring = state?.userProfile?.can_enable_continuous_monitoring;

  const { data: autoResolveAlertsData, isFetching: isPreferenceLoading } = useUserMonitoringPreferenceGet();
  const autoResolveAlerts = autoResolveAlertsData?.data?.levels || [];
  const { isFetching: isMonitoringLoading } = useUserContinuousMonitoringLevelGet({
    onSuccess: (data: AxiosResponse<IUserMonitoringPreferenceContinuousMonitoringResponse>) => {
      setValue('monitoring', data?.data?.continuous_monitoring_level);
      const monitoringDays = data?.data?.continuous_monitoring_days;
      const days = !monitoringDays ? 'Indefinitely' : `${monitoringDays} Days`;
      setDuration({
        label: days,
        value: monitoringDays ? monitoringDays.toString() : null,
      });
      setCurrentMonitoringLevel(data.data.continuous_monitoring_level);
    },
  });

  useCustomMonitoredEntitiesGet({
    onSuccess: ({ data }) => {
      setUpdatedEntities(
        data.results.map((option) => ({
          label: option.entity_name,
          value: option.id,
          created_at: option.created_at,
          added_by: option.added_by,
        }))
      );
    },
  });

  const { mutate: addEntityName, isLoading: isAddEntityLoading } = useMutation(
    customMonitoredEntitiesApi.addCustomMonitoredEntities,
    {
      onSuccess: (data, variables) => {
        toast.success(`Successfully Added ${variables.entity_name}`);
        queryClient.invalidateQueries(['customMonitoredEntitiesApi.getCustomMonitoredEntities']);
        queryClient.invalidateQueries(['customMonitoredEntitiesApi.getCustomMonitoredAddresses']);
        const oldItem = [...updatedEntities];
        oldItem.push({
          value: data?.data?.id.toString(),
          label: data?.data?.entity_name,
          added_by: data?.data?.added_by,
          created_at: data?.data?.created_at,
        });
        setUpdatedEntities(oldItem);
        setInputValue('');
      },
      onError: (err, variables) => {
        toast.error(`Unable to add ${variables.entity_name}`);
      },
    }
  );
  const { mutate: deleteEntityName, isLoading: isDeleteEntityLoading } = useMutation(
    customMonitoredEntitiesApi.deleteCustomMonitoredEntities,
    {
      onSuccess: () => {
        toast.success('Successfully removed');
        queryClient.invalidateQueries(['customMonitoredEntitiesApi.getCustomMonitoredEntities']);
        queryClient.invalidateQueries(['customMonitoredEntitiesApi.getCustomMonitoredAddresses']);
      },
      onError: (err: AxiosError) => {
        toast.error(getErrorMessage(err));
      },
    }
  );

  const { mutate } = useMutation(monitoringPreferencesApi.updateAutoResolveAlerts, {
    onSettled: () => {
      queryClient.invalidateQueries(['ProfileApi.getMonitoringPreference']);
    },
    onSuccess: () => {
      toast.success('Auto resolve alerts preferences updated successfully');
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });
  const continuousMonitoring = useMutation(monitoringPreferencesApi.updateContinuousMonitoringSettings);

  const onChangeMonitoring = (e) => {
    const request: IContinuousMonitoringUpdateRequest = {
      continuous_monitoring_level: e.target.value,
    };

    setIsMonitoringTableLoading(true);

    continuousMonitoring.mutate(request, {
      onSuccess: (data) => {
        toast.success('Continuous monitoring preferences updated successfully');
        setCurrentMonitoringLevel(data.data.continuous_monitoring_level);
        setIsMonitoringTableLoading(false);
      },
      onError: (error: AxiosError<IErrorResponse>) => {
        toast.error(getErrorMessage(error) || 'Error updating continuous monitoring level');
        queryClient.refetchQueries(['ProfileApi.getMonitoringPreference']);
        setIsMonitoringTableLoading(false);
      },
    });
  };

  const toggleSelection = (data: number) => {
    const newAutoResolved = autoResolveAlerts.includes(data)
      ? autoResolveAlerts.filter((item: number) => item !== data)
      : [...(autoResolveAlerts || []), data];

    const request: IUserMonitoringPreferenceResponse = {
      levels: newAutoResolved,
      __typename: 'AutoResolve',
    };
    mutate(request);
  };

  const durationOptions = [
    {
      label: '30 Days',
      value: '30',
    },
    {
      label: '60 Days',
      value: '60',
    },
    {
      label: '90 Days',
      value: '90',
    },
    {
      label: 'Indefinitely',
      value: null,
    },
  ];

  const handleDurationChange = (value: IOption) => {
    setDuration(value);
    setIsMonitoringTableLoading(true);
    const request: IContinuousMonitoringUpdateRequest = {
      continuous_monitoring_days: Number(value.value) || null,
    };
    continuousMonitoring.mutate(request, {
      onSuccess: () => {
        toast.success('Continuous monitoring duration updated successfully');
        setIsMonitoringTableLoading(false);
      },
      onError: (error: AxiosError<IErrorResponse>) => {
        setIsMonitoringTableLoading(false);
        toast.error(getErrorMessage(error) || 'Error updating continuous monitoring duration');
      },
    });
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (inputValue.trim() !== '') {
        if (!updatedEntities.some((option) => option.label.toLowerCase() === inputValue.toLowerCase())) {
          addEntityName({ entity_name: inputValue });
        } else {
          toast.error('Entity already exists');
        }
      } else {
        toast.error('Please enter an Entity');
      }
    }
  };
  const styles: StylesConfig<unknown, false> = {
    control: (css) => ({
      ...css,
      borderRadius: '6px',
      border: '1px solid #D1D5DB',
      minHeight: '28px',
      '*': {
        boxShadow: 'none !important',
      },
      '& > div:first-of-type': {
        overflow: 'visible !important',
      },
    }),
    indicatorsContainer: (css) => ({ ...css, display: 'visible' }),
    input: (css) => ({
      ...css,
      color: 'blue',
      fontSize: '11px',
      paddingTop: '2px',
      cursor: 'text',
      display: 'flex',
    }),
    placeholder: (css) => ({ ...css, fontSize: '15px' }),
    menu: (css) => ({
      ...css,
      border: '1px solid #D1D5DB',
      borderRadius: '6px',
      padding: '2px 0px 2px 0px',
      boxShadow: 'none',
      zIndex: 2,
    }),
    option: (css, { isSelected }) => ({
      ...css,
      padding: '5px',
      backgroundColor: isSelected ? '#DBEAFE' : 'white',
      color: isSelected ? 'white' : 'black',
      cursor: 'pointer',
      border: 0,
      outline: 'none',
      ':hover': {
        color: 'black',
        backgroundColor: '#d6d2d2',
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      background: 'none',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#1E40AF',
      backgroundColor: '#DBEAFE',
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      borderRadius: 0,
      fontSize: 12,
      padding: '1px',
      paddingRight: '5px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: '1E40AF',
      backgroundColor: '#DBEAFE',
      paddingLeft: '0px',
      marginLeft: -1,
      borderRadius: 0,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      ':hover': {
        backgroundColor: '#606d7c',
      },
    }),
  };
  const DropdownIndicator = (props: DropdownIndicatorProps<IEntityOption, true>) => {
    return (
      <components.DropdownIndicator {...props} className='!px-2 !py-1'>
        {isAddEntityLoading || isDeleteEntityLoading ? (
          <CircleNotch size={30} className={'ml-1 animate-spin text-blue-200'} />
        ) : (
          <Button variant='secondary' className='py-1' onClick={() => handleKeyDown({ key: 'Enter' })}>
            Add
          </Button>
        )}
      </components.DropdownIndicator>
    );
  };
  const CustomMultiValue = (props) => {
    return (
      <BasicBadge className='mr-1 flex gap-2 bg-blue-100 text-blue-800'>
        <Popover
          referenceContent={<div className='text-xs text-gray-800'>{props.data.label}</div>}
          popoverContent={
            <div className=''>
              <p>Added by: {props.data.added_by}</p>
              <p>Created at: {formatDateTime(props.data.created_at, state.userProfile.timezone)}</p>
            </div>
          }
          className='text-sm text-gray-800'
          placement='top'
          isBasic
        />
        <X
          size={15}
          className='cursor-pointer'
          onClick={() => {
            deleteEntityName(Number(props.data.value));
          }}
        />
      </BasicBadge>
    );
  };

  return (
    <>
      <div className='h-full max-w-6xl'>
        <TabHeader heading='Monitoring Config' />
        {isPreferenceLoading || isMonitoringLoading ? (
          <CompassLoader />
        ) : (
          <>
            <div>
              <h4 className='mt-5'>Auto Resolve Alerts</h4>
              <TabHeader heading='' subheading='Alerts of these alert levels will be auto resolved.' />
              <div className='flex gap-4 pt-3'>
                <CheckboxController
                  control={control}
                  name='riskLevels.1'
                  checked={autoResolveAlerts.includes(5)}
                  onChange={() => toggleSelection(5)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Critical</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.2'
                  checked={autoResolveAlerts.includes(4)}
                  onChange={() => toggleSelection(4)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>High</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.3'
                  checked={autoResolveAlerts.includes(3)}
                  onChange={() => toggleSelection(3)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Medium</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.4'
                  checked={autoResolveAlerts.includes(2)}
                  onChange={() => toggleSelection(2)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Caution</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.5'
                  checked={autoResolveAlerts.includes(1)}
                  onChange={() => toggleSelection(1)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Info</div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className='mt-6 pt-3'>
              <hr />
            </div>
            <div className='mb-8'>
              <h4 className='mt-5'>Continuous Monitoring</h4>
              <div className='mb-5 w-1/2'>
                <TabHeader
                  heading=''
                  subheading='When Enabled, risk levels for previously searched addresses are continuously updated in real time as transactions are seen on the blockchain.'
                />
              </div>
              <div className='mb-6'>
                <RadioButtonsController
                  control={control}
                  name='monitoring'
                  onClick={onChangeMonitoring}
                  disabled={!canEnableMonitoring}>
                  <RadioButton labelText='Enable' value={1} />
                  <RadioButton labelText='Disable' value={0} />
                  <RadioButton labelText='Custom' value={2} />
                  <RadioButton labelText='Entity Based Monitoring' value={3} />
                </RadioButtonsController>
              </div>
              {canEnableMonitoring && (
                <>
                  <div className='mb-5 w-1/2'>
                    <TabHeader
                      heading=''
                      subheading={
                        currentMonitoringLevel === 3
                          ? 'Entities whose associated wallets needs be monitored continuously'
                          : 'Address will be monitored for the set period from the date of last screening of the address'
                      }
                    />
                  </div>
                  {currentMonitoringLevel !== 3 ? (
                    <div
                      className={classNames('w-72', currentMonitoringLevel, {
                        'pointer-events-none opacity-50': currentMonitoringLevel == 0,
                      })}>
                      <SingleSelect
                        placeholder='Select Duration'
                        name={'duration'}
                        options={durationOptions}
                        value={duration}
                        handleChange={handleDurationChange}
                        isCloseHidden
                      />
                    </div>
                  ) : (
                    <Select
                      value={updatedEntities}
                      components={{ MultiValue: CustomMultiValue, DropdownIndicator }}
                      className='w-4/6'
                      isMulti
                      options={[]}
                      isClearable={false}
                      hideSelectedOptions={false}
                      styles={styles}
                      menuIsOpen={false}
                      placeholder='No tags selected'
                      noOptionsMessage={() => null}
                      onKeyDown={(e) => handleKeyDown(e)}
                      inputValue={inputValue}
                      onInputChange={setInputValue}
                    />
                  )}
                </>
              )}
            </div>
            {isMonitoringTableLoading ? (
              <CompassLoader />
            ) : (
              !!currentMonitoringLevel &&
              canEnableMonitoring && <MonitoringTable monitoringLevel={currentMonitoringLevel} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MonitoringPreference;
