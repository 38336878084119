import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../FilterPanel';
import EntityFilter from '../../Filters/EntityFilter';
import CalendarFilter, { IDateRangeString } from '../../Filters/CalendarFilter';
import RiskFilter from '../../Filters/RiskFilter';
interface CustomerTransactionsFilterProps {
  filters: IFilters;
  onApply: (filters: IFilters) => void;
}

export interface IFilters {
  tag_type_verboses: string[];
  transaction_start_date: string;
  transaction_end_date: string;
  risk_level: number[];
}

export const defaultStateFilters: IFilters = {
  tag_type_verboses: [],
  transaction_start_date: '',
  transaction_end_date: '',
  risk_level: [],
};

const CustomerTransactionsFilter: FC<CustomerTransactionsFilterProps> = ({ filters, onApply }) => {
  const [riskLevel, setRiskLevel] = useState<number[]>([]);
  const [entities, setEntities] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<IDateRangeString>();

  const handleApply = () => {
    const newFilters = {
      tag_type_verboses: entities,
      transaction_start_date: dateRange?.from,
      transaction_end_date: dateRange?.to,
      risk_level: riskLevel,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setRiskLevel([]);
    setEntities([]);
    setDateRange({ from: null, to: null });
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    if (filters) {
      setRiskLevel(filters.risk_level);
      setEntities(filters.tag_type_verboses);
      setDateRange({
        from: filters.transaction_start_date,
        to: filters.transaction_end_date,
      });
    }
  }, [filters]);

  return (
    <FilterPanel onApply={handleApply} onReset={onReset}>
      <RiskFilter value={riskLevel} setValue={setRiskLevel} />
      <CalendarFilter value={dateRange} setValue={setDateRange} label='Transaction Date' />
      <EntityFilter value={entities} setValue={setEntities} />
    </FilterPanel>
  );
};

export default CustomerTransactionsFilter;
