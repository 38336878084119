import { FC } from 'react';
import CustomerTransactions from './CustomerTransactions/CustomerTransactions';
import { UseInfiniteQueryResult } from 'react-query';
import { ICustomerAddressResponse, ICustomerResponse } from '../../api/dtos/customer';
import { AxiosResponse } from 'axios';
import CounterpartySummary from '../AddressTransaction/CounterpartySummary';
import CustomerSummary from './Overview/CustomerSummary';
import Alerts from '../common/Alerts';

interface IRecordedTransactionsProps {
  customer: ICustomerResponse;
  depositAddressesQuery: UseInfiniteQueryResult<AxiosResponse<ICustomerAddressResponse>>;
  setAlerts: (alerts: number) => void;
}

const RecordedTransactions: FC<IRecordedTransactionsProps> = ({
  customer,
  depositAddressesQuery,
  setAlerts,
}) => {
  return (
    <div className='flex flex-col gap-3'>
      <CustomerSummary customer={customer} />
      <CustomerTransactions
        customer={customer}
        customerId={customer.customer_id}
        depositAddressesQuery={depositAddressesQuery}
      />
      <Alerts
        id={encodeURIComponent(customer?.customer_id)}
        type='customer'
        setAlerts={setAlerts}
        custId={customer.id}
        entity_type='transaction'
        showLoading
      />
      <CounterpartySummary customer={customer} type='customer' showModes={false} />
    </div>
  );
};

export default RecordedTransactions;
