import { Currencies } from '../constants/constants';

export function getCurrencyFromCode(currencyCode: number) {
  return Currencies.find((currency) => currency.value === currencyCode);
}

export const getCurrencyById = (id: number) => Currencies.find((currency) => currency.value === id);

export const getCurrencyImageById = (id: number): string => {
  return '/currency/' + getCurrencyById(id).currency_short + '.svg';
};

export const isCurrencyDeprecated = (currency: number): boolean =>
  !!getCurrencyFromCode(currency)?.deprecated;

export const getActiveCurrencies = () => {
  return Currencies.filter((currency) => !currency.deprecated);
};

export const getDigitalAssets = (entity) => {
  if (!(entity.digital_assets && entity.digital_assets.length)) {
    return [
      {
        currency: entity.currency,
        token: entity.currency_short,
      },
    ];
  }
  return entity.digital_assets;
};

export const getCurrencyShortValue = (currency: string): string => {
  if (['OP', 'ARB'].includes(currency)) {
    return 'ETH';
  }
  return currency;
};

export const isTokenCurrency = (currency: number): boolean =>
  [1, 7, 8, 9, 10, 13, 14, 15, 16, 17, 24, 25].includes(currency);

export const isLiteCurrency = (currency: number): boolean =>
  currency >= 18 && currency <= 123 && ![24, 25].includes(currency);

const addressScreeningDisabled = [
  { identifier: '0.0.98', currency: 14 },
  { identifier: '0.0.1459478', currency: 14 },
  { identifier: 'nonstandard', currency: 'all' },
];

export const isAddressScreeningDisabled = (
  address: string,
  currency: number,
  matchWhole = false
): boolean => {
  return addressScreeningDisabled.some((item) => {
    if (address.includes('_')) return true;
    return (
      (matchWhole ? item.identifier === address : address.startsWith(item.identifier)) &&
      (item.currency === 'all' || item.currency === currency)
    );
  });
};

export const getTokenVerboseByCurrencyId = (currency: number) => {
  if (currency === 7) {
    return 'ZRC-2 Transactions';
  } else if (currency === 8) {
    return 'BEP-20 Transactions';
  } else if (currency === 10) {
    return 'TRC-20 Transactions';
  } else if ([13, 14].includes(currency)) {
    return 'Token transfers';
  } else if (currency === 15) {
    return 'CRC-20 Transactions';
  }
  return 'ERC-20 Transactions';
};
