import { UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { Api } from '../api';
import {
  ICustomMonitoredEntitiesResponse,
  ICustomMonitoredEntitiesList,
  ICustomMonitoredListRequest,
  ICustomMonitoredAddresses,
} from '../dtos/settings/monitoringPreferences';
import { getNextPageParam } from '../../utils/helpers/helperFunctions';

export class CustomMonitoringEntitiesApi extends Api {
  readonly baseUrl = '/risk/api/v4/custom-monitored-entities/';
  readonly getCustomMonitoredEntities = () => {
    return this.http.get<ICustomMonitoredEntitiesResponse>(this.route('/'));
  };
  readonly addCustomMonitoredEntities = (request: { entity_name: string }) => {
    const { entity_name } = request;
    return this.http.post<ICustomMonitoredEntitiesList>(this.route('/'), {
      entity_name,
    });
  };
  readonly deleteCustomMonitoredEntities = (id: number) => {
    return this.http.delete(this.route(`${id}/`));
  };
  readonly getCustomMonitoredAddresses = (params: ICustomMonitoredListRequest) => {
    const { q, limit, offset } = params;
    return this.http.get<ICustomMonitoredAddresses>(this.route('addresses/'), {
      params: { q, limit, offset },
    });
  };
}

export const customMonitoredEntitiesApi = new CustomMonitoringEntitiesApi();

export const useCustomMonitoredEntitiesGet = (options?: UseQueryOptions) => {
  const key = ['customMonitoredEntitiesApi.getCustomMonitoredEntities'];
  const result = useQuery(key, () => customMonitoredEntitiesApi.getCustomMonitoredEntities(), {
    ...options,
    getNextPageParam,
  } as unknown);

  return {
    key,
    ...result,
  };
};
export const useCustomMonitoredAddressesGet = (
  params: ICustomMonitoredListRequest,
  options?: UseQueryOptions
) => {
  const key = ['customMonitoredEntitiesApi.getCustomMonitoredAddresses', params.q];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) =>
      customMonitoredEntitiesApi.getCustomMonitoredAddresses({
        offset: pageParam,
        limit: params.limit,
        q: params.q,
      }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return {
    key,
    ...result,
  };
};
