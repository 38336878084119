import { FC } from 'react';
import { getEntityColor } from '../../utils/helpers/entity';
import classNames from 'classnames';

interface IEntitiesLegendProps {
  entities: string[];
  className?: string;
}

const EntitiesLegend: FC<IEntitiesLegendProps> = ({ entities, className }) => {
  if (!entities || !entities.length) return null;
  return (
    <div className={classNames('items flex flex-wrap', className)}>
      {entities.map((entity, index) => (
        <div key={index} className='mr-2 flex items-center'>
          <div className={`mr-2 size-3 ${getEntityColor(entity).bgColor}`}></div>
          <div className='text-2xs'>{entity}</div>
        </div>
      ))}
    </div>
  );
};

export default EntitiesLegend;
