import { FC, useState } from 'react';
import { ArrowDownRight, ArrowUpRight } from '@phosphor-icons/react';
import classNames from 'classnames';

interface IncomingOutgoingSwitchProps {
  onChange: (value: 'incoming' | 'outgoing') => void;
}

const IncomingOutgoingSwitch: FC<IncomingOutgoingSwitchProps> = ({ onChange }) => {
  const [value, seValue] = useState<'incoming' | 'outgoing'>('incoming');

  const getProps = (type: 'incoming' | 'outgoing') => {
    const commonClasses = 'rounded-full p-px cursor-pointer';
    return type === value
      ? { className: classNames(commonClasses, 'bg-white rounded-full'), color: '#1F2937' }
      : { color: '#9CA3AF', className: commonClasses };
  };

  const onClickArrow = (type: 'incoming' | 'outgoing') => {
    seValue(type);
    onChange(type);
  };
  return (
    <div className='flex items-center rounded-full bg-gray-200 p-1'>
      <ArrowDownRight size={16} {...getProps('incoming')} onClick={() => onClickArrow('incoming')} />
      <ArrowUpRight size={16} {...getProps('outgoing')} onClick={() => onClickArrow('outgoing')} />
    </div>
  );
};

export default IncomingOutgoingSwitch;
