/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Logo from '@/assets/logo.color.svg';
import AppLogo from '@/assets/compass-full.svg';
import { useMutation } from 'react-query';
import { authApi } from '../../api/auth';
import { AxiosError } from 'axios';
import { toast } from '../../ui/components/Toast';
import { IErrorResponse } from '../../api/dtos/common';
import InputController from '../../ui/components/Form/InputController';
import { Button } from '../../ui';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRight } from '@phosphor-icons/react';
import CompassLoader from '../../components/ui/components/Loader/CompassLoader';
import {
  getCaptchaFailed,
  getOrganizationInfo,
  getUserEmail,
  setCaptchaFailed,
  setOrganizationInfo,
  setUserEmail,
} from '../../utils/helpers/cookies';
import { useEffectOnce } from 'react-use';

const initialData = {
  email: '',
};

const validations = Yup.object({
  email: Yup.string().email('Please enter a valid email address').required('Email Address is required'),
});

const RECAPTCHA_ERROR_MESSAGE = 'Error while verifying reCAPATCHA. Please try again after sometime';

const LoginPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { loginWithRedirect, isLoading } = useAuth0();
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const isCaptchaFailed = () => {
    const captchaFailed = getCaptchaFailed();
    if (captchaFailed) {
      return Math.abs(new Date(captchaFailed).getTime() - new Date().getTime()) / 1000 <= 15;
    }
    return false;
  };

  const initiateLogin = (organizationID, email) => {
    loginWithRedirect({
      appState: { returnTo: searchParams.get('redirect') || '' },
      authorizationParams: {
        organization: organizationID,
        login_hint: email || '',
      },
    });
  };

  const [captchaFail, setCaptchaFail] = useState(false);

  useEffectOnce(() => {
    setCaptchaFail(isCaptchaFailed());
    const organizationInfo = getOrganizationInfo();
    if (organizationInfo) {
      initiateLogin(organizationInfo, getUserEmail());
    }
  });

  useEffect(() => {
    if (captchaFail) {
      setError('email', {
        type: 'manual',
        message: 'Error while verifying reCAPATCHA. Please try again after {value} seconds',
      });
    }
  }, [captchaFail]);

  const { mutate } = useMutation(authApi.findOrg, {
    onSuccess: ({ data }, vars) => {
      const organizationID = data.auth0_organization;
      if (!organizationID && !data.is_token_valid && !data.is_score_valid) {
        toast.error(RECAPTCHA_ERROR_MESSAGE);
        setCaptchaFailed();
        setCaptchaFail(isCaptchaFailed());
        return;
      } else if (!organizationID) {
        toast.error('No organization found for given email address');
        return;
      }
      setOrganizationInfo(organizationID);
      setUserEmail(vars.email);
      initiateLogin(organizationID, vars.email);
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });

  const onSubmit = async (data: typeof initialData) => {
    let token = '';
    data.email = data.email.toLowerCase();
    if (executeRecaptcha) {
      try {
        token = await executeRecaptcha('login');
      } catch {
        setCaptchaFailed();
        setCaptchaFail(isCaptchaFailed());
        toast.error('Recaptcha failed, please refresh the page.');
      }
      mutate({ ...data, recaptcha_token: token });
    } else {
      toast.error('Loading recaptcha, please wait');
    }
  };

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-8 bg-[url('/pattern-bottom-right.svg'),_url('/pattern-bottom-left.svg')] bg-[position:bottom_right,bottom_left] bg-no-repeat p-16">
      <img src={AppLogo} alt='app-logo' width={200} />
      <div className='h-max w-4/12 min-w-60 max-w-96 rounded-lg border border-gray-200 bg-white p-6 shadow-md'>
        {isLoading ? (
          <CompassLoader />
        ) : (
          <form
            className='space-y-6 bg-merkle-logo-bg bg-contain bg-center bg-no-repeat'
            action='#'
            onSubmit={handleSubmit(onSubmit)}>
            <div className='text-center text-[24px] font-semibold text-gray-500'>Log In</div>
            <div>
              <InputController
                control={control}
                id='email'
                type='email'
                name='email'
                labelText='Email address'
                placeholder='Enter your email address'
                autoComplete='off'
              />
            </div>
            <div>
              <Button
                id='login'
                type='submit'
                className='w-full justify-center font-semibold'
                disabled={isSubmitting || captchaFail}>
                Continue
                <ArrowRight size={20} className='ml-2' />
              </Button>
            </div>
            {/* <div className='flex justify-end'>
            <Link to={'/forgot-password/'} className='text-xs text-blue-600 underline'>
              Forgot Password?
            </Link>
          </div> */}
          </form>
        )}
      </div>
      <div className='flex w-3/12 items-center justify-center border-b-2 border-gray-200 pb-8 text-2xs text-blue-600'>
        <a className='' href='https://www.merklescience.com/privacy-policy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>
        <div className='mx-2'>・</div>
        <a className='' href='https://www.merklescience.com/terms' target='_blank' rel='noreferrer'>
          Terms of Service
        </a>
      </div>
      <img src={Logo} alt='merklescience-logo' width={200} />
    </div>
  );
};

export default LoginPage;
