import React, { FC } from 'react';
import { useWorkSpaceGetWorkFlowMembers } from '../../api/workspaces';
import SingleSelect, { IOption } from '../ui/components/Select/SingleSelect';
import UpdateStageorAssigneeModal from './UpdateStageorAssigneeModal';
import { IWorkSpaceMembers } from '../../api/dtos/workspaces';
import { toast } from 'react-toastify';
import { Stage } from '../../ui/utils/constants';
import StageBadge from '../../ui/components/Badges/StageBadge/StageBadge';
import UserBadge from '../../ui/components/Badges/UserBadge/UserBadge';
import { UserCircle } from '@phosphor-icons/react';
import { useStagesListLiteGet } from '../../api/stages';
import { getColorMappingStageOptions } from '../../utils/helpers/stageOptionsColor';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../utils/helpers/react-query.helper';
import { useDebounce } from 'use-debounce';
import { alertApi } from '../../api/alert';
import { useMutation, useQueryClient } from 'react-query';
import { getErrorMessage } from '../../utils/helpers/helperFunctions';
import { AxiosError } from 'axios';
import { IStageOptions } from './Alerts/Alert/AlertButtonPanel/AlertButtonPanel';

interface StageAndAssignedProps {
  type: 'address' | 'transaction' | 'customer';
  id: number | string;
  assigned_to_name: string;
  stage_name: string;
  stage_id: Stage;
  selectedAlertID: string;
  next_stage_options: IStageOptions[];
  is_final_stage: boolean;
}

const StageAndAssignedWorkSpace: FC<StageAndAssignedProps> = ({
  type,
  id,
  assigned_to_name,
  stage_name,
  stage_id,
  selectedAlertID,
  next_stage_options,
  is_final_stage,
}) => {
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = React.useState<string>('');
  interface IStageOptions extends IOption {
    id?: Stage;
  }
  const stage = stage_id
    ? {
        id: stage_id,
        label: stage_name,
        value: stage_name,
      }
    : {
        id: 7,
        label: '',
        value: '',
      };
  const assigned_to = assigned_to_name
    ? {
        label: assigned_to_name,
        value: assigned_to_name,
      }
    : {
        id: null,
        label: '',
        value: '',
      };
  const [selectedOption, setSelectedOption] = React.useState<IOption>();
  const [selectedOptionId, setSelectedOptionId] = React.useState<number>();
  const [comment, setComment] = React.useState('');
  const [search, setSearch] = React.useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);
  const assignedToOptionsQuery = useWorkSpaceGetWorkFlowMembers({
    q: debouncedSearch,
    is_active: true,
  });
  const { mutate: mutateAlert, isLoading: isLoadingUpdateStageorAssignee } = useMutation(
    alertApi.updateAlert,
    {
      onSuccess: () => {
        setOpenModal('');
        const key = ['alertApi.getAlert'];
        queryClient.invalidateQueries(key);
        toast.success('Alerts Updated Successfully');
        setComment('');
        setSelectedOption(null);
        setSelectedOptionId(null);
      },
      onError(err: AxiosError<{ error: string }>) {
        toast.error(getErrorMessage(err));
      },
    }
  );
  const stagesListLiteQuery = useStagesListLiteGet(true);
  const [, assignedToOptions]: [number, IWorkSpaceMembers[]] = flattenInfiniteQueryResult(
    assignedToOptionsQuery?.data
  );

  const getAssignedToOptions = (assignedToData: IWorkSpaceMembers[]) => {
    return assignedToData?.map((r) => {
      return {
        value: r.email,
        label: r.email,
      };
    });
  };

  const assignedToOptionsData = getAssignedToOptions(assignedToOptions);

  const handleChangeStage = (stage: IOption) => {
    if (stage) {
      setSelectedOption(stage);
      const foundStage = next_stage_options.find((r) => r.label === stage?.label);
      setSelectedOptionId(foundStage.id);
      setOpenModal('status');
    }
  };
  const handleChangeAssignee = (assignee: IOption) => {
    if (assignee) {
      setSelectedOption(assignee);
      const foundAssignee = assignedToOptions.find((r) => r.email === assignee?.label);
      setSelectedOptionId(foundAssignee.user_id);
      setOpenModal('assignee');
    }
  };

  const handleSubmit = () => {
    if (comment) {
      mutateAlert({
        type,
        id,
        status: openModal === 'status' ? selectedOptionId : undefined,
        assigned_to: openModal === 'assignee' ? selectedOptionId : undefined,
        alertId: selectedAlertID,
        comment: comment,
      });
    } else {
      toast.error('Enter a comment');
    }
  };

  const stageColorMap = getColorMappingStageOptions(stagesListLiteQuery?.data?.data?.results);

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (option === null || option?.id === 7) {
      return <StageBadge role={8} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  const getCustomOptionLabelAssignee = (option: IOption) => {
    return (
      <UserBadge
        label={option?.label ? option?.label : 'Unassigned'}
        className='bg-blue-100 text-sm text-blue-800'
        iconStart={<UserCircle size={17} weight='fill' />}
      />
    );
  };

  const getCustomLabelAssignee = (option: IOption) => {
    return (
      <UserBadge className='bg-gray-200 text-gray-800' label={option?.label ? option?.label : 'Unassigned'} />
    );
  };

  const customFilterOptions = () => {
    return true;
  };

  return (
    <div
      className={`mt-4 flex gap-8 rounded-md border border-blue-200 bg-blue-50 ${is_final_stage ? 'grayscale' : ''} p-4`}>
      <div className='flex w-1/2 items-center gap-4'>
        <p className='mt-1 text-xs uppercase leading-3 text-gray-500'>Status</p>
        <SingleSelect
          placeholder={<div className='text-xs'>Select Status</div>}
          options={next_stage_options}
          handleChange={handleChangeStage}
          value={stage}
          isCloseHidden={true}
          showRadioButton={true}
          parentClassName='grow'
          getCustomLabel={getCustomOptionLabel}
          getCustomOptionLabel={getCustomOptionLabel}
        />
      </div>
      <div className='flex w-1/2 items-center gap-4'>
        <p className='mt-1 whitespace-nowrap text-xs uppercase leading-3 text-gray-500'>Assigned To</p>
        <SingleSelect
          placeholder={<div className='text-xs'>Select Assignee</div>}
          options={assignedToOptionsData}
          handleChange={handleChangeAssignee}
          isLoading={assignedToOptionsQuery.isLoading}
          value={assigned_to}
          isCloseHidden={true}
          parentClassName='grow'
          isSearchable={true}
          getCustomLabel={getCustomOptionLabelAssignee}
          {...buildInfiniteQueryTableProps(assignedToOptionsQuery)}
          setSearch={setSearch}
          filterOptions={customFilterOptions}
          asyncPaginate={true}
        />
      </div>
      <UpdateStageorAssigneeModal
        open={openModal !== ''}
        setOpen={setOpenModal}
        optionsData={openModal === 'status' ? next_stage_options : assignedToOptionsData}
        currentOption={openModal === 'status' ? stage : assigned_to}
        selectedOption={selectedOption}
        setSelectedOption={openModal === 'status' ? handleChangeStage : handleChangeAssignee}
        handleSubmit={handleSubmit}
        setComment={setComment}
        isStageorAssignee={openModal === 'status'}
        comment={comment}
        getCustomOptionLabel={openModal === 'status' ? getCustomOptionLabel : getCustomLabelAssignee}
        setSearch={setSearch}
        isLoading={isLoadingUpdateStageorAssignee}
        assignedToOptionsQuery={assignedToOptionsQuery}
      />
    </div>
  );
};

export default StageAndAssignedWorkSpace;
