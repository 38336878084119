export const addressPolicyId = {
  'Source of Funds': [1, 2, 3, 4, 5, 6, 18, 19, 20, 21],
  Behaviour: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
  'Source of Funds Advanced': [22, 23, 24],
};

export const policyCategoryOptions = [
  { label: 'Source of Funds', value: '1' },
  { label: 'Behaviour', value: '2' },
  { label: 'Source of Funds Advanced', value: '3' },
];

export const policyTypeOptions = [
  { label: 'All', value: '' },
  { label: 'Address', value: '1' },
  { label: 'Transaction', value: '0' },
  { label: 'Customer', value: '2' },
];

export const addressRules = {
  1: 'If this {item_type} has directly {risk_types} any of the actors with type in {entity_types} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  2: 'If this {item_type} has directly {risk_types} any of the actors with sub-type in {entity_subtypes} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  3: 'If this {item_type} has directly {risk_types} any of the actors with name in {entity_names} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  4: 'If this {item_type} is owned by any actor of type in {entity_types}',
  5: 'If this {item_type} is owned by any actor of sub-type in {entity_subtypes}',
  6: 'If this {item_type} is owned by any actor of name in {entity_names}',
  7: 'If this {item_type} has {risk_types_1} in previous {analysis_window} for at least {multiplier_threshold} times the usual US$ amount it normally does over a {analysis_window} period',
  8: 'If this {item_type} has received a payment in the previous {transit_window} but has withdrawn {payment_amount_percent} since then',
  9: 'If this {item_type} has {risk_types} {counterparty_threshold} counterparty addresses',
  10: 'If this {item_type} has {risk_types_1} after not having {inactivity_types} for previous {dormancy_window}',
  11: 'If this {item_type} has {risk_types_1} of {transaction_amount_threshold} in a single transaction',
  12: 'If this {item_type} has {risk_types} an address created in the {address_age}',
  13: 'If this {item_type} has {risk_types_1} in a transaction which paid an abnormally large transaction fee',
  14: 'If this {item_type} has {risk_types_1} with transaction amounts between {lower_bound} and {upper_bound}',
  15: 'If this {item_type} has {risk_types_1} more than {transaction_count_threshold} in the previous {analysis_window_2}',
  16: 'If this {item_type} has {risk_types_1} in total of {transaction_amount_threshold} in the previous {analysis_window_2}',
  17: 'If this {item_type} has {risk_types_2} at least {transaction_count_threshold_2} transactions of {transaction_amount_threshold} each in the previous {analysis_window_2}',
  18: 'If this {item_type} has indirectly {indirect_risk_types} any of the actors with type in {entity_types} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  19: 'If this {item_type} has indirectly {indirect_risk_types} any of the actors with sub-type in {entity_subtypes} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  20: 'If this {item_type} has indirectly {indirect_risk_types} any of the actors with name in {entity_names} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  21: 'If this {item_type} has {sentiments} web sentiment',
  22: 'If this {item_type} has directly {risk_types} any of the actors with type in {entity_types} for {threshold_amount_usd} and {upper_threshold_amount_usd} with a transaction count of {lower_transaction_count_threshold} and {transaction_count_upper_threshold} in any calendar month',
  23: 'If this {item_type} has directly {risk_types} any of the actors with sub-type in {entity_subtypes} for {threshold_amount_usd} and {upper_threshold_amount_usd} with a transaction count of {lower_transaction_count_threshold} and {transaction_count_upper_threshold} in any calendar month',
  24: 'If this {item_type} has directly {risk_types} any of the actors with name in {entity_names} for {threshold_amount_usd} and {upper_threshold_amount_usd} with a transaction count of {lower_transaction_count_threshold} and {transaction_count_upper_threshold} in any calendar month',
};

export const transactionRules = {
  1: 'If this transaction has directly {risk_types} any of the actors with type in {entity_types} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  2: 'If this transaction has directly {risk_types} any of the actors with sub-type in {entity_subtypes} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  3: 'If this transaction has directly {risk_types} any of the actors with name in {entity_names} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  18: 'If this transaction has indirectly {indirect_risk_types} any of the actors with type in {entity_types} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  19: 'If this transaction has indirectly {indirect_risk_types} any of the actors with sub-type in {entity_subtypes} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
  20: 'If this transaction has indirectly {indirect_risk_types} any of the actors with name in {entity_names} {threshold_amount_usd} and {upper_threshold_amount_usd} and {taint_percent_threshold} and {taint_percent_upper_threshold}',
};

function interpolateTemplate(
  template: string,
  setLabel: boolean,
  data: Record<string, string>,
  id: number
): string {
  const regex = /{(\w+)}/g;
  const style =
    'background-color: #dbeafe; padding: 2px 8px; border-radius: 8px; word-break: break-word; white-space: wrap; display: inline-block; line-height: 140%;';
  if (setLabel) {
    return template.replace(
      regex,
      (_, key) =>
        `<span style="${style}">${
          key === 'item_type' ? data[key] : conditionVariables[key]?.placeholder || key
        }</span>`
    );
  }
  let updated_template = template;
  if ([22, 23, 24].includes(id) && !data['transaction_count_upper_threshold']) {
    updated_template = template.replace('and {transaction_count_upper_threshold}', '');
  }
  return updated_template?.replace(regex, (_, key) => {
    return `<span style="${style}">${data[key] || key}</span>`;
  });
}

export const getRule = (id: number, setLabel: boolean, data: Record<string, string>): string =>
  interpolateTemplate(
    data['item_type'] === 'Transaction' ? transactionRules[id] : addressRules[id],
    setLabel,
    data,
    id
  );

export interface IRuleVariableTypes {
  item_type?: string;
  risk_types?: string;
  entity_types?: string;
  threshold_amount_usd?: string;
  upper_threshold_amount_usd?: string;
  taint_percent_threshold?: string;
  taint_percent_upper_threshold?: string;
  entity_subtypes?: string;
  entity_names?: string;
  risk_types_1?: string;
  analysis_window?: string;
  multiplier_threshold?: string;
  transit_window?: string;
  payment_amount_percent?: string;
  counterparty_threshold?: string;
  inactivity_types?: string;
  dormancy_window?: string;
  transaction_amount_threshold?: string;
  address_age?: string;
  lower_bound?: string;
  upper_bound?: string;
  transaction_count_threshold?: string;
  analysis_window_2?: string;
  risk_types_2?: string;
  transaction_count_threshold_2?: string;
  indirect_risk_types?: string;
  sentiments?: string;
  lower_transaction_count_threshold?: string;
  transaction_count_upper_threshold?: string;
}

export interface IRuleVariableTypesApi {
  item_type?: string;
  risk_types?: number[];
  entity_types?: number[];
  threshold_amount_usd?: string;
  upper_threshold_amount_usd?: number;
  taint_percent_threshold?: string;
  taint_percent_upper_threshold?: string;
  entity_subtypes?: number[];
  entity_names?: string[];
  risk_types_1?: number[];
  analysis_window?: string;
  multiplier_threshold?: string;
  transit_window?: string;
  payment_amount_percent?: string;
  counterparty_threshold?: string;
  inactivity_types?: string;
  dormancy_window?: string;
  transaction_amount_threshold?: string;
  address_age?: string;
  lower_bound?: string;
  upper_bound?: string;
  transaction_count_threshold?: string;
  analysis_window_2?: string;
  risk_types_2?: string;
  transaction_count_threshold_2?: string;
  indirect_risk_types?: string;
  sentiments?: number[];
  lower_transaction_count_threshold?: string;
  transaction_count_upper_threshold?: string;
}

export const defaultAddressConditionValues = [
  {
    rule_condition_type: 1,
    risk_types: [],
    entity_types: [],
    threshold_amount_usd: null,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 2,
    risk_types: [],
    entity_subtypes: [],
    threshold_amount_usd: null,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 3,
    risk_types: [],
    entity_names: [],
    threshold_amount_usd: null,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 4,
    risk_types: [3],
    entity_types: [],
  },
  {
    rule_condition_type: 5,
    risk_types: [3],
    entity_subtypes: [],
  },
  {
    rule_condition_type: 6,
    risk_types: [3],
    entity_names: [],
  },
  {
    rule_condition_type: 7,
    risk_types: [],
    analysis_window: null,
    multiplier_threshold: null,
  },
  {
    rule_condition_type: 8,
    transit_window: null,
    payment_amount_percent: null,
  },
  { rule_condition_type: 9, risk_types: [], counterparty_threshold: null },
  {
    rule_condition_type: 10,
    risk_types: [],
    inactivity_types: [],
    dormancy_window: null,
  },
  { rule_condition_type: 11, transaction_amount_threshold: null },
  { rule_condition_type: 12, risk_types: [], address_age: null },
  { rule_condition_type: 13, risk_types: [] },
  {
    rule_condition_type: 14,
    risk_types: [],
    lower_bound: null,
    upper_bound: null,
  },
  {
    rule_condition_type: 15,
    risk_types: [],
    analysis_window: null,
    transaction_count_threshold: null,
  },
  {
    rule_condition_type: 16,
    risk_types: [],
    transaction_amount_threshold: null,
    analysis_window: null,
  },
  {
    rule_condition_type: 17,
    risk_types: [],
    transaction_count_threshold: null,
    transaction_amount_threshold: null,
    analysis_window: null,
  },
  {
    rule_condition_type: 18,
    risk_types: [],
    entity_types: [],
    threshold_amount_usd: 0.01,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 19,
    risk_types: [],
    entity_subtypes: [],
    threshold_amount_usd: 0.01,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 20,
    risk_types: [],
    entity_names: [],
    threshold_amount_usd: 0.01,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 21,
    risk_types: [4],
    sentiments: [],
  },
  {
    rule_condition_type: 22,
    risk_types: [],
    entity_types: [],
    threshold_amount_usd: null,
    upper_threshold_amount_usd: null,
    transaction_count_threshold: null,
    transaction_count_upper_threshold: null,
  },
  {
    rule_condition_type: 23,
    risk_types: [],
    entity_subtypes: [],
    threshold_amount_usd: null,
    upper_threshold_amount_usd: null,
    transaction_count_threshold: null,
    transaction_count_upper_threshold: null,
  },
  {
    rule_condition_type: 24,
    risk_types: [],
    entity_names: [],
    threshold_amount_usd: null,
    upper_threshold_amount_usd: null,
    transaction_count_threshold: null,
    transaction_count_upper_threshold: null,
  },
];

export const defaultTransactionConditionValues = [
  {
    rule_condition_type: 1,
    risk_types: [],
    entity_types: [],
    threshold_amount_usd: null,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 2,
    risk_types: [],
    entity_subtypes: [],
    threshold_amount_usd: null,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 3,
    risk_types: [],
    entity_names: [],
    threshold_amount_usd: null,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 18,
    risk_types: [],
    entity_types: [],
    threshold_amount_usd: 0.01,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 19,
    risk_types: [],
    entity_subtypes: [],
    threshold_amount_usd: 0.01,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
  {
    rule_condition_type: 20,
    risk_types: [],
    entity_names: [],
    threshold_amount_usd: 0.01,
    taint_percent_threshold: null,
    taint_percent_upper_threshold: null,
    upper_threshold_amount_usd: null,
  },
];

export const conditionVariables = {
  indirect_risk_types: {
    placeholder: 'Indirect Payment Types',
    tooltip: 'Choose if you would like to analyze only incoming payments, outgoing payments or both.',
    options: {
      '1': 'received payments from',
      '2': 'sent payments to',
      '12': 'sent payments to or received payments from',
    },
  },
  risk_types: {
    placeholder: 'Payment Types',
    tooltip: 'Choose if you would like to analyze only incoming payments, outgoing payments or both.',
    options: {
      '1': 'received payments from',
      '2': 'sent payments to',
      '12': 'sent payments to or received payments from',
    },
  },
  risk_types_1: {
    placeholder: 'Payment Types',
    tooltip: 'Choose if you would like to analyze only incoming payments, outgoing payments or both.',
    options: {
      '1': 'received payments',
      '2': 'sent payments',
      '12': 'sent or received payments',
    },
  },
  risk_types_2: {
    placeholder: 'Payment Types',
    tooltip: 'Choose if you would like to analyze only incoming payments, outgoing payments or both.',
    options: {
      '1': 'received',
      '2': 'sent',
      '12': 'sent or received',
    },
  },
  inactivity_types: {
    placeholder: 'Inactivity Type',
    tooltip: 'Choose if you would like to analyze only incoming payments, outgoing payments or both.',
    options: {
      '1': 'sent any payments',
      '2': 'received any payments',
      '12': 'sent or received any payments',
    },
  },
  entity_types: {
    placeholder: 'Entity Types',
    tooltip:
      'An entity type represents broad verticals we classify cryptocurrency actors into. Visit Helpdesk for more information.',
  },
  entity_subtypes: {
    placeholder: 'Entity Sub-Types',
    tooltip:
      'An entity subtype represents granular verticals we classify cryptocurrency actors into. We categorize actors into over 50+ entity subtypes. Visit Helpdesk for more information.',
  },
  entity_names: {
    placeholder: 'Entity Name',
    tooltip: 'An entity name represents how an actor is generally identified',
  },
  web_sentiments: {
    placeholder: 'Sentiments',
    tooltip:
      'An entity type represents broad verticals we classify cryptocurrency actors into. Visit Helpdesk for more information.',
  },
  threshold_amount_usd: {
    placeholder: 'Threshold Amount (USD)',
    tooltip:
      'Choose at least amount in USD that an address must have sent or received from / to an entity-type / entity-subtype',
    text: 'for at least US${val}',
    zero: 'for at least any USD amount',
    one: 'for at least US$0.01',
    errorOne: 'Threshold Amount must be greater than 0.01',
    checkError: (val, val2) => {
      if (val === 0 || val2 === 0) {
        return true;
      } else if (val < val2) {
        return true;
      }
      return false;
    },
    error: 'Threshold Amount must be less than Upper Threshold Amount',
  },
  upper_threshold_amount_usd: {
    placeholder: 'Upper Threshold Amount (USD)',
    tooltip:
      'Choose at most amount in USD that an address must have sent or received from / to an entity-type / entity-subtype',
    text: 'at most US${val}',
    zero: 'up to any USD amount',
    checkError: (val, val2) => {
      if (val === 0 || val2 === 0) {
        return true;
      } else if (val > val2) {
        return true;
      }
      return false;
    },
    error: 'Upper Threshold Amount must be greater than Threshold Amount',
  },
  taint_percent_threshold: {
    placeholder: 'Taint Lower Threshold (%)',
    tooltip:
      'Choose % of total incoming funds or outgoing funds that an address must have sent or received from / to an entity-type / entity-subtype',
    text: 'has at least {val}% taint',
    checkError: (val, val2) => {
      if (val < 100) {
        if (val2 === 0) {
          return true;
        } else if (val < val2) {
          return true;
        }
      }
      return false;
    },
    error: 'Please enter valid Taint Percent between 0 to {val}',
  },
  taint_percent_upper_threshold: {
    placeholder: 'Taint Upper Threshold (%)',
    tooltip:
      'Choose % of total incoming funds or outgoing funds that an address must have sent or received from / to an entity-type / entity-subtype',
    text: 'has at most {val}% taint',
    zero: 'up to any taint %',
    checkError: (val, val2) => {
      if (val < 100) {
        if (val === 0) {
          return true;
        }
        if (val > val2) {
          return true;
        }
      }
      return false;
    },
    error: 'Please enter valid Taint Percent between {val} to 100',
  },
  analysis_window_2: {
    placeholder: 'Analysis Window',
    tooltip: 'Enter minutes for Analysis Window',
    text: '{val} minutes',
    error: 'Please enter valid value in number',
  },
  analysis_window: {
    placeholder: 'Analysis Window',
    tooltip: 'Enter days for Analysis Window',
    text: '{val} days',
    error: 'Please enter valid value in number',
  },
  transit_window: {
    placeholder: 'Transit Window',
    tooltip: 'Enter value for Transit Window',
    text: '{val} minutes',
    error: 'Please enter valid value in number',
  },
  payment_amount_percent: {
    placeholder: 'Transit Threshold',
    tooltip: 'Enter value for Transit Threshold',
    text: 'at least {val}%',
    error: 'Please enter valid value in number',
  },
  counterparty_threshold: {
    placeholder: 'Counterparty Count',
    tooltip: 'Enter value for Counterparty Count',
    text: 'at least {val}',
    error: 'Please enter valid value in number',
  },
  dormancy_window: {
    placeholder: 'Dormancy Window',
    tooltip: 'Enter value for Dormancy Window',
    text: '{val} days',
    error: 'Please enter valid value in number',
  },
  multiplier_threshold: {
    placeholder: 'Threshold',
    tooltip: 'Enter value for Threshold',
    text: '{val}',
    error: 'Please enter valid value in number',
  },
  transaction_amount_threshold: {
    placeholder: 'Amount Threshold',
    tooltip: 'Enter value for Amount Threshold',
    text: 'at least US$ {val}',
    error: 'Please enter valid value in number',
  },
  address_age: {
    placeholder: 'Address Age',
    tooltip: 'Enter value for Address Age',
    text: '{val} days',
    error: 'Please enter valid value in number',
  },
  lower_bound: {
    placeholder: 'Lower Amount Limit',
    tooltip: 'Enter value for Lower Amount Limit',
    text: 'US$ {val}',
    checkError: (val, val2) => {
      if (val === 0 || val2 === 0) {
        return true;
      } else if (val < val2) {
        return true;
      }
      return false;
    },
    error: 'Lower Bound must be less than Upper Bound',
  },
  upper_bound: {
    placeholder: 'Upper Amount Limit',
    tooltip: 'Enter value for Upper Amount Limit',
    text: 'US$ {val}',
    checkError: (val, val2) => {
      if (val === 0 || val2 === 0) {
        return true;
      } else if (val > val2) {
        return true;
      }
      return false;
    },
    error: 'Upper Bound must be greater than Lower Bound',
  },
  transaction_count_threshold: {
    placeholder: 'Transaction Count Threshold',
    tooltip: 'Enter value for Transaction Count Threshold',
    text: '{val} times',
    error: 'Please enter valid value in number',
  },
  transaction_count_threshold_2: {
    placeholder: 'Transaction Count Threshold',
    tooltip: 'Enter value for Transaction Count Threshold',
    text: '{val}',
    error: 'Please enter valid value in number',
  },
  lower_transaction_count_threshold: {
    placeholder: 'Transaction Count Threshold',
    tooltip: 'Enter value for Transaction Count Threshold',
    text: 'at least {val}',
    error: 'Please enter valid value in number',
    zero: 'at least any',
  },
  transaction_count_upper_threshold: {
    placeholder: 'Upper Transaction Count Threshold',
    tooltip: 'Enter value for Transaction Count Threshold',
    text: 'at most {val}',
    error: 'Please enter valid value in number',
    zero: 'up to any',
  },
};
