import { FC, useState, useRef } from 'react';
import { debounce } from 'lodash';
import Select from 'react-select';
import { useSearchWithCustomers } from '../../../api/search';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
interface ExistingCustomerDropdownProps {
  minimumInputLength?: number;
  control: Control;
}

const ExistingCustomerDropdown: FC<ExistingCustomerDropdownProps> = (props) => {
  const { minimumInputLength = 5, control } = props;
  const [inputText, setInputText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');

  const { isLoading, data } = useSearchWithCustomers(
    { query: searchText },
    { enabled: !!(searchText && searchText.length >= minimumInputLength) }
  );

  const optionsList = data?.pages?.[0]?.data?.customers?.results?.map((c) => ({
    label: c.customer_id,
    value: c.customer_id,
  }));

  const debouncedSetSearchText = useRef(
    debounce((nextValue: string) => setSearchText(nextValue), 500)
  ).current;

  const handleInputChange = (value: string) => {
    setInputText(value);
    debouncedSetSearchText(value);
  };

  const noOptionsMessage = () => {
    if (isLoading) {
      return 'Loading...';
    }
    if (searchText.length < minimumInputLength)
      return `Enter min ${minimumInputLength} characters to search...`;
    return 'No results found';
  };

  return (
    <div>
      <Controller
        name='customer_id'
        control={control}
        render={({ field, fieldState }) => (
          <>
            <Select
              options={optionsList || []}
              // Removes input border on focus
              styles={{
                input: (baseStyle) => ({ ...baseStyle, 'input:focus': { boxShadow: 'none' } }),
              }}
              classNames={{
                option: ({ isFocused }) => classNames(isFocused && '!bg-blue-500 !text-white'),
                control: () => 'border border-gray-200',
              }}
              value={field.value ? { label: field.value, value: field.value } : null}
              isClearable={true}
              inputValue={inputText}
              onInputChange={handleInputChange}
              isLoading={!!searchText && isLoading}
              filterOption={null}
              noOptionsMessage={noOptionsMessage}
              onChange={(value) => {
                field.onChange(value?.value);
              }}
            />
            {fieldState.error && (
              <p className='mt-2 text-sm text-red-600' id='email-error'>
                {fieldState.error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default ExistingCustomerDropdown;
