import { FC } from 'react';
import TeamMembersTable from '../../../../components/Settings/Team/TeamMembersTable';
import { TabHeader } from '../../../../components/ui/components/Header';
import { useWorkspace } from '../../../../utils/helpers/common';

const WorkspaceMembers: FC = () => {
  const workspace = useWorkspace();
  return (
    <div className='h-full'>
      <TabHeader
        heading={'Workspace Members - ' + workspace.name}
        subheading='You can add and view  members of your workspace'
      />
      <div className='mt-5'>
        <TeamMembersTable type='workspace' />
      </div>
    </div>
  );
};

export default WorkspaceMembers;
