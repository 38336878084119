import { isEqual } from 'lodash';

export const getAlertType = (type: number[]) => {
  if (type.length > 1 && isEqual(type, [1, 2])) return 'Incoming/Outgoing';
  if (type.length === 0) {
    if (isEqual(type, [1])) return 'Incoming';
    if (isEqual(type, [2])) return 'Outgoing';
    if (isEqual(type, [3])) return 'Direct';
  }
};
