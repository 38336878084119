import { FC, Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { X } from '@phosphor-icons/react';
import classNames from 'classnames';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  filter?: React.ReactNode;
  maxWidthClass?: string;
  titleClass?: string;
  childrenClass?: string;
  footerClass?: string;
}

const Sidebar: FC<SidebarProps> = ({
  isOpen,
  onClose,
  title,
  children,
  filter,
  maxWidthClass = 'max-w-3xl',
  titleClass,
  childrenClass,
  footer,
  footerClass,
}) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-[1090] overflow-hidden' onClose={onClose}>
        <div className='absolute inset-0 h-full overflow-hidden'>
          <TransitionChild
            as='div'
            enter='ease-in-out duration-500'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            {isOpen ? (
              <>
                <div className='absolute inset-0 bg-gray-900 opacity-75 transition-opacity' />
                <DialogPanel />
              </>
            ) : (
              <>
                <div className='absolute inset-0 bg-gray-900 opacity-0 transition-opacity' />
                <DialogPanel />
              </>
            )}
          </TransitionChild>

          <div className='fixed inset-y-0 right-0 flex max-w-full'>
            <TransitionChild
              as={Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'>
              <DialogPanel className={`relative w-screen ${maxWidthClass}`}>
                <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                  <div className={classNames('bg-gray-100 p-4 sm:px-6', titleClass)}>
                    <div className='flex items-center justify-between'>
                      <DialogTitle as='h2' className='mb-0 text-sm font-normal text-gray-600'>
                        {title}
                      </DialogTitle>
                      <div className='flex'>
                        {filter}
                        {!filter && (
                          <div
                            className='ml-2 cursor-pointer rounded border bg-white px-2 py-1'
                            onClick={onClose}>
                            <X size={18} className='my-auto text-gray-500 hover:text-gray-800' />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className='relative flex-1 py-6 px-4 sm:px-6'>
                    <div className='absolute inset-0 px-4 sm:px-6'>{children}</div>
                  </div> */}
                  <div className={classNames('flex-grow overflow-auto p-4', childrenClass)}>{children}</div>
                  {footer && <div className={classNames(footerClass)}>{footer}</div>}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Sidebar;
