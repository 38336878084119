import { FC } from 'react';
import { BasicModal, CopyBtn } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { useFireblocksLogsGet } from '../../../api/integration';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { formatDateTime } from '../../../utils/helpers/date';
import { useAuth } from '../../../modules/auth';
import { ellipsis } from '../../../utils/helpers/helperFunctions';

interface FireblocksLogsProps {
  modal: boolean;
  setModal: (value: boolean) => void;
}

const FireblocksLogs: FC<FireblocksLogsProps> = ({ modal, setModal }) => {
  const fireblocksLogsQuery = useFireblocksLogsGet({ enabled: modal });

  const { state } = useAuth();
  const headerData = ['Payload', 'Status', 'Created at', 'Processed at'];
  const [, fireblocksLogs] = flattenInfiniteQueryResult(fireblocksLogsQuery?.data);
  const rows = fireblocksLogs?.map((w, i) => ({
    id: i,
    data: [
      <div className='flex' key={i}>
        {ellipsis(JSON.stringify(w.payload), 60)}
        <CopyBtn text={JSON.stringify(w.payload)} />
      </div>,
      w.status_verbose,
      formatDateTime(w.created_at, state.userProfile.timezone),
      formatDateTime(w.processed_time, state.userProfile.timezone),
    ],
  }));
  return (
    <BasicModal
      open={modal}
      onClose={() => setModal(false)}
      modalTitle='Fireblocks Logs'
      customBodyClass=' '
      modalBody={
        <Table
          rows={rows}
          headerData={headerData}
          isHeaderVisible={false}
          headerWidths={[40, 13, 22, 22]}
          {...buildInfiniteQueryTableProps(fireblocksLogsQuery)}
        />
      }
    />
  );
};

export default FireblocksLogs;
