import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../FilterPanel';
import CalendarFilter, { IDateRangeString } from '../../Filters/CalendarFilter';
import CaseTypeFilter from '../../Filters/CaseTypeFilter';
interface FilterAuditTrailProps {
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
  type: 'address' | 'transaction' | 'case' | 'customer';
}

export interface IFilters {
  start_date?: string;
  end_date?: string;
  type: string[];
}

export const defaultStateFilters: IFilters = {
  start_date: '',
  end_date: '',
  type: [],
};

const FilterAuditTrail: FC<FilterAuditTrailProps> = ({ filters, onApply, type: auditTrailType }) => {
  const [type, setType] = useState<string[]>(filters?.type || []);
  const [dateRange, setDateRange] = useState<IDateRangeString>();

  const handleApply = () => {
    const newFilters = {
      type,
      start_date: dateRange?.from,
      end_date: dateRange?.to,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setType([]);
    setDateRange({ from: null, to: null });
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    setType(filters?.type);
    setDateRange({
      from: filters?.start_date,
      to: filters?.end_date,
    });
  }, [filters]);

  return (
    <FilterPanel onApply={handleApply} onReset={onReset}>
      <CaseTypeFilter value={type} setValue={setType} type={auditTrailType} />
      <CalendarFilter value={dateRange} setValue={setDateRange} label='Date Range' />
    </FilterPanel>
  );
};

export default FilterAuditTrail;
