import { FC } from 'react';
import { transactionDirectionOptions } from '../../utils/helpers/helperFunctions';
import SingleSelect from '../ui/components/Select/SingleSelect';

interface TransactionDirectionFilterProps {
  value: string;
  setValue: (type: string) => void;
}

const TransactionDirectionFilter: FC<TransactionDirectionFilterProps> = (props) => {
  const { value, setValue } = props;

  const currentOption = transactionDirectionOptions.find((option) => option.value === value);

  return (
    <>
      <SingleSelect
        options={transactionDirectionOptions}
        handleChange={(value) => setValue(value?.value)}
        value={currentOption}
        label='Transaction Direction'
        labelClassName='text-sm'
      />
    </>
  );
};

export default TransactionDirectionFilter;
