import { FC } from 'react';
import { AppliedFiltersBadge } from '../../ui';
import { riskTypesOptions } from '../Filters/RiskTypeFilter';

interface RiskTypeAppliedFilterProps {
  riskTypes: number[];
  setRiskTypes: (risk: number[]) => void;
}

const RiskTypeAppliedFilter: FC<RiskTypeAppliedFilterProps> = (props) => {
  const { riskTypes, setRiskTypes } = props;
  if (!riskTypes) return null;

  const handleRemove = (type: number) => {
    setRiskTypes(riskTypes.filter((t) => t !== type));
  };

  const getBadgeLabel = (type: number) => riskTypesOptions.find((r) => r.value === type.toString()).label;

  return (
    <>
      {riskTypes.map((r) => (
        <AppliedFiltersBadge key={r} onRemove={() => handleRemove(r)}>
          Risk Type: {getBadgeLabel(r)}
        </AppliedFiltersBadge>
      ))}
    </>
  );
};

export default RiskTypeAppliedFilter;
