import { createContext, useContext } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useScreenApi } from '../../utils/helpers/apiHelpers';

export interface IOnClickIdentifier {
  identifier: string;
  currency: number;
  entityType: 'addresses' | 'transactions';
  type?: number;
  address?: string;
  customer_id?: string;
}

export interface IError {
  status: number;
  message?: string;
}

interface RouterContextType {
  navigate: (path: string, params?: Record<string, string>) => void;
  pathname: string;
  getQueryParams: () => Record<string, string>;
  setQueryParams: (params: Record<string, string>) => void;
  getParams: () => Record<string, string>;
  onClickIdentifier: (params: IOnClickIdentifier) => void;
  showErrorPage: (error: IError) => void;
}

interface RouterContextProviderProps {
  children: React.ReactNode;
  value?: Partial<RouterContextType>;
}

function useSafeNavigate() {
  try {
    return useNavigate();
  } catch {
    return null;
  }
}

export const RouterContext = createContext<RouterContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  navigate: () => {},
  pathname: '',
  getQueryParams: () => ({}),
  getParams: () => ({}),
  setQueryParams: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClickIdentifier: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showErrorPage: () => {},
});

export function RouterContextProvider(props: RouterContextProviderProps) {
  const {
    navigate: propNavigate,
    pathname: propPathname,
    getQueryParams: propGetQueryParams,
    setQueryParams: propSetQueryParams,
    getParams: propGetParams,
    onClickIdentifier: propOnClickIdentifier,
    showErrorPage: propShowErrorPage,
  } = props?.value || {};
  const navigate = useSafeNavigate();

  let queryParams = {} as URLSearchParams;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let setSearchParams = (_params: URLSearchParams) => {};
  try {
    [queryParams, setSearchParams] = useSearchParams();
  } catch {
    // do nothing
  }
  const { pathname } = useLocation();
  // navigate wrapper for adding workspace to all the routes
  const navigateWrapper = (path: string, params?: Record<string, string>) => {
    const workspaceId = queryParams.get('workspace');
    const currentParams = new URLSearchParams(params);
    const hasWorkspacePath = currentParams.has('workspace');

    if (!hasWorkspacePath && workspaceId) {
      currentParams.set('workspace', workspaceId);
    }
    const isReplace = !path || path === pathname;

    navigate!((path || pathname) + '?' + currentParams.toString(), { replace: isReplace });
  };
  const getQueryParams = () => {
    return Object.fromEntries(queryParams);
  };

  const setQueryParams = (params: Record<string, string>) => {
    const newParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      newParams.set(key, value);
    });
    setSearchParams(newParams);
  };

  const screenApi = useScreenApi;

  const contextValue: RouterContextType = {
    navigate: propNavigate || navigateWrapper!,
    pathname: propPathname || pathname,
    getQueryParams: propGetQueryParams || getQueryParams,
    setQueryParams: propSetQueryParams || setQueryParams,
    getParams: propGetParams || useParams,
    onClickIdentifier: propOnClickIdentifier || screenApi,
    showErrorPage: propShowErrorPage || null,
  };

  return <RouterContext.Provider value={contextValue}>{props.children}</RouterContext.Provider>;
}

export function useRouter(): RouterContextType {
  return useContext(RouterContext);
}
