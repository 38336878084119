import { FC, useState, useEffect } from 'react';
import Select, { ActionMeta, MenuListProps, OptionProps, components, GroupBase } from 'react-select';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { useTeamMembersSearch } from '../../../api/settings/teamMembers';
import { Plus, X } from '@phosphor-icons/react';
import { BasicBadge } from '../../../ui';
import { PlusCircle } from '@phosphor-icons/react/dist/ssr';
import { useWorkspace } from '../../../utils/helpers/common';
import WorkspaceBadge from '../../ui/components/Badge/WorkspaceBadge';
import { useDebounce } from 'use-debounce';
import Popover from '../../../ui/components/Popover/Popover';

interface TeamMemberEmailControllerProps {
  minimumInputLength?: number;
  control: Control;
  inviteType: 'single' | 'bulk';
  type: 'organisation' | 'workspace';
}
declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    type?: 'organisation' | 'workspace';
  }
}
interface IOption {
  label: string;
  value: string;
  workspace?: string;
  workspaces?: string[];
  i?: number;
}

const Option = (props: OptionProps<IOption>) => {
  const workspace = useWorkspace();
  const isOptionDisabled =
    props.selectProps.type === 'organisation' || props.data.workspaces?.includes(workspace.name);
  const workspaces = props.data.workspaces?.sort((a, b) =>
    a === workspace.name ? -1 : b === workspace.name ? 1 : 0
  );

  return (
    <>
      <components.Option
        className={classNames({
          '!pointer-events-none': isOptionDisabled,
        })}
        {...props}>
        {props.data.workspace && (
          <div className='flex flex-row items-center justify-between gap-2 border-b p-3 text-gray-400'>
            <div>{props.data.label}</div>
            <WorkspaceBadge label={props.data.workspace} i={props.data.i} />
          </div>
        )}
        {workspaces && (
          <div
            className={classNames(
              'flex flex-row items-center justify-between gap-2 border-b p-3',
              isOptionDisabled ? 'text-gray-400' : 'cursor-pointer text-gray-800 hover:text-blue-500'
            )}>
            <div className='flex'>
              {!isOptionDisabled && <PlusCircle size={20} weight='fill' className='mr-3 text-blue-600' />}
              <div>{props.data.label}</div>
            </div>
            <div className='flex'>
              {workspaces.slice(0, 1).map((w, i) => (
                <WorkspaceBadge key={i} label={w} i={i} className='mr-1' />
              ))}
              {workspaces.length > 1 && (
                <Popover
                  referenceContent={
                    <BasicBadge className='bg-gray-100'>+ {workspaces.length - 1} workspaces</BasicBadge>
                  }
                  className='flex w-64 max-w-sm flex-wrap rounded-md bg-white p-2 shadow-lg'
                  popoverContent={workspaces.slice(1).map((w, i) => (
                    <WorkspaceBadge key={i} label={w} i={i} className='mr-1' />
                  ))}
                />
              )}
            </div>
          </div>
        )}
      </components.Option>
    </>
  );
};

const MenuList = (props: MenuListProps<IOption, true>) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <div className='flex px-4 py-2'>
        <div className='rounded-full bg-gray-100 p-2.5'>
          <Plus size={20} className='text-gray-800' />
        </div>
        <div
          className='ml-3 cursor-pointer hover:text-blue-500'
          onClick={() =>
            props.selectOption({
              label: props.selectProps.inputValue,
              value: props.selectProps.inputValue,
              workspace: '',
            })
          }>
          <div>{props.selectProps.inputValue}</div>
          <div>Invite to organization via email</div>
        </div>
      </div>
    </components.MenuList>
  );
};

const TeamMemberEmailController: FC<TeamMemberEmailControllerProps> = (props) => {
  const { minimumInputLength = 0, control, inviteType, type } = props;
  const [inputText, setInputText] = useState<string>('');
  const [debouncedInput] = useDebounce(inputText, 300);
  const [optionsList, setOptionsList] = useState<IOption[]>([]);
  const workspace = useWorkspace();

  const { data, isLoading, isFetching } = useTeamMembersSearch(debouncedInput, {
    enabled: !!(debouncedInput && debouncedInput.length >= minimumInputLength),
  });

  useEffect(() => {
    let newOptions = [];
    if (data && !isFetching) {
      newOptions = data.data.results.map((t) => ({
        label: t.email,
        value: t.email,
        workspaces: t.workspaces,
      }));
      setOptionsList([{ label: inputText, value: inputText }, ...newOptions]);
    }
  }, [data, inputText, isFetching, type]);

  const handleInputChange = (value: string) => {
    setInputText(value);
  };

  const noOptionsMessage = () => {
    if (isLoading) {
      return 'Loading...';
    }
    if (inputText.length < minimumInputLength)
      return `Enter min ${minimumInputLength} characters to search...`;
    return null;
  };

  const onChange = async (
    value: IOption[],
    action: ActionMeta<IOption>,
    callback: (value: IOption[]) => void
  ) => {
    if (action.action !== 'select-option') {
      callback(value);
      return;
    }
    const handleSelectOption = () => {
      if (type === 'organisation' && optionsList.slice(1).some((o) => o.value === value.at(-1).value)) {
        control.setError('emails', {
          type: 'custom',
          message: 'Email already exists in organisation',
        });
      } else if (
        type === 'workspace' &&
        optionsList.some((o) => o.value === value.at(-1).value && o.workspaces?.includes(workspace.name))
      ) {
        control.setError('emails', {
          type: 'custom',
          message: 'Email already exists in workspace',
        });
      } else {
        control.setError('emails', {
          type: 'custom',
          message: '',
        });
        callback(value);
      }
    };
    if (inputText === value.at(-1).value && !isFetching) {
      handleSelectOption();
    } else {
      setTimeout(handleSelectOption, 300);
    }
  };

  return (
    <div>
      <Controller
        name='emails'
        control={control}
        render={({ field, fieldState }) => (
          <>
            <Select
              options={optionsList || []}
              // Removes input border on focus
              styles={{
                input: (baseStyle) => ({ ...baseStyle, 'input:focus': { boxShadow: 'none' } }),
              }}
              classNames={{
                option: ({ isFocused }) =>
                  classNames(isFocused && '!bg-white', ' !py-0', {
                    '!pointer-events-none': type === 'organisation',
                  }),
                control: () => 'border border-gray-200',
                multiValue: () => '!bg-gray-100 !rounded-xl',
                multiValueRemove: () => 'hover:!bg-transparent',
              }}
              isMulti
              components={{
                Option,
                MenuList,
                MultiValueRemove: (props) => (
                  <components.MultiValueRemove {...props}>
                    <X className='my-auto mr-1 cursor-pointer hover:text-red-500' />
                  </components.MultiValueRemove>
                ),
              }}
              menuIsOpen={inviteType === 'single' && inputText.length > 0 ? undefined : false}
              isSearchable={inviteType === 'single'}
              placeholder='Enter an email to invite'
              isClearable={true}
              inputValue={inputText}
              onInputChange={handleInputChange}
              isLoading={!!inputText && isLoading}
              filterOption={null}
              noOptionsMessage={noOptionsMessage}
              onChange={(value, action) => onChange(value, action, field.onChange)}
              value={field.value}
              type={type}
            />
            {fieldState.error && (
              <p className='mt-2 text-xs text-red-600' id='email-error'>
                {fieldState.error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default TeamMemberEmailController;
