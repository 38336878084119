import { FC, Fragment, useEffect, useState } from 'react';
import { FunnelSimple } from '@phosphor-icons/react';
import { Button, RadioButton } from '@ui/index';
import { Popover, PopoverButton, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { startCase } from 'lodash';

// import styles from '../styles.module.scss';
// TODO: use react-hook-form, use different filter components for different modules https://github.com/merklescience/compass-frontend/pull/5/files/c4034fcced218eaed074a508b346083642e0aed6#r1024791915
interface FilterBtnProps {
  disabled?: boolean;
}

interface ISortOptions {
  label: string[];
  sort_by: string;
}
interface SortPanelProps {
  onReset?: () => void;
  onApply: (value: string) => void;
  disabled?: boolean;
  value: string;
  options: ISortOptions[];
  panelClass?: string;
}

const FilterBtn: FC<FilterBtnProps> = ({ disabled }) => {
  return (
    <Button
      className='py-2.5'
      variant='tertiary'
      iconStart={<FunnelSimple size={16} />}
      disabled={disabled}></Button>
  );
};

const SortPanel: FC<SortPanelProps> = (props) => {
  const { onReset, onApply, disabled, panelClass, value, options } = props;
  const [sort, setSort] = useState<ISortOptions>();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>();

  useEffect(() => {
    setSort(options.find((option) => option.sort_by === value.replace('-', '')));
    setSortOrder(value[0] === '-' ? 'desc' : 'asc');
  }, [options, value]);

  const handleApply = () => {
    onApply((sortOrder === 'desc' ? '-' : '') + sort?.sort_by);
  };

  return (
    <>
      <Popover className='relative mx-1'>
        {({ open }) => (
          <>
            <PopoverButton as='div' className={`${open ? '' : 'text-opacity-90'}`}>
              <FilterBtn disabled={disabled} />
            </PopoverButton>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'>
              <Popover.Panel
                className={classNames(
                  'lg:max-w-l absolute right-1 z-30 mt-3 w-screen max-w-sm px-4 pb-3 sm:px-0',
                  panelClass
                )}>
                <div className='rounded bg-white shadow-xl'>
                  <div className='relative bg-gray-100 lg:grid-cols-2'>
                    <div className='flex size-full flex-col items-start justify-between gap-3'>
                      <div className='flex w-full flex-col'>
                        <div className='relative h-auto rounded-lg'>
                          <div className='text-xs'>
                            {options.map((o) => (
                              <div key={o.sort_by}>
                                <div>
                                  <div className='border-b px-3 py-2 uppercase text-gray-500'>
                                    {startCase(o.sort_by)}
                                  </div>
                                  <div className='flex bg-white p-2 px-3'>
                                    <RadioButton
                                      labelText={o.label[0]}
                                      labelClassName='w-1/2'
                                      selected={sort?.sort_by === o.sort_by && sortOrder === 'asc'}
                                      onClick={() => {
                                        setSort(o);
                                        setSortOrder('asc');
                                      }}
                                    />
                                    <RadioButton
                                      labelText={o.label[1]}
                                      selected={sort?.sort_by === o.sort_by && sortOrder === 'desc'}
                                      onClick={() => {
                                        setSort(o);
                                        setSortOrder('desc');
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className='bottom-0 flex w-full flex-row items-center justify-between gap-2 px-4 py-2 font-medium'>
                        <PopoverButton
                          as={Button}
                          variant='tertiary'
                          className='px-[11px] py-[7px] text-sm leading-4'
                          onClick={onReset}>
                          Reset
                        </PopoverButton>
                        <PopoverButton
                          as={Button}
                          className='px-[11px] py-[7px] text-sm leading-4'
                          onClick={handleApply}>
                          Apply
                        </PopoverButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default SortPanel;
