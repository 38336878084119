import { FC, useState } from 'react';
import CustomSelect, { IOption } from './CustomSelect';
import EntityBadge from '../ui/components/Badge/EntityBadge';
import { entitySubTypeIds, getEntityTypeBySubType } from '../../utils/helpers/entity';
interface EntitySubtypeFilterProps {
  value: string[];
  setValue: (entities: string[]) => void;
  noLabel?: boolean;
  limit?: number;
  noBorder?: boolean;
  className?: string;
}

const EntitySubtypeFilter: FC<EntitySubtypeFilterProps> = (props) => {
  const { value, setValue, noLabel = false, limit, noBorder, className } = props;
  const entitySubTypeOptions = Object.values(entitySubTypeIds).map((entity) => ({
    value: entity,
    label: entity,
  }));
  let selectedEntities = [];
  if (value) {
    selectedEntities = entitySubTypeOptions.filter((option) => value.includes(option.value));
  }
  const [entities, setEntities] = useState<IOption[]>(selectedEntities);

  const getLabel = (option: IOption) => {
    return (
      <EntityBadge
        entity={{
          tag_type_verbose: getEntityTypeBySubType(option.value),
          tag_name_verbose: `${getEntityTypeBySubType(option.value)} -> ${option.value}`,
        }}
      />
    );
  };
  const handleChange = (entities: IOption[]) => {
    setEntities(entities);
    const entityValues = entities.map((entity) => entity.value);
    setValue(entityValues);
  };

  return (
    <CustomSelect
      label={noLabel ? null : 'Entity subtypes'}
      placeholder='Select Entity Subtype'
      options={entitySubTypeOptions}
      getCustomLabel={getLabel}
      getCustomOptionLabel={getLabel}
      handleChange={handleChange}
      value={entities}
      limit={limit}
      className={className}
      noBorder={noBorder}
    />
  );
};

export default EntitySubtypeFilter;
