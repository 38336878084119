import { FC } from 'react';
import { getCurrencyShortValue } from '../../utils/helpers/currency';
import CurrencyValue from '../ui/components/Currency/CurrencyValue';
import { ITransactionResponseShort } from '../../api/dtos/transaction';

interface ITransactionValueProps {
  transaction: ITransactionResponseShort;
  type?: 'all' | 'value' | 'value_usd';
}

const TransactionValue: FC<ITransactionValueProps> = ({ transaction, type = 'all' }) => {
  const showDash = transaction.digital_assets.length > 1;
  const value =
    transaction.digital_assets.length === 0 ? transaction.value : transaction.digital_assets[0].value;
  const currency =
    transaction.digital_assets.length === 0
      ? getCurrencyShortValue(transaction.currency_short)
      : getCurrencyShortValue(transaction.digital_assets[0].token);
  const valueUsd =
    transaction.digital_assets.length === 0 ? transaction.value_usd : transaction.digital_assets[0].value_usd;
  if (type === 'all')
    return (
      <div className='flex'>
        <CurrencyValue value={showDash ? null : value} currency={currency} />
        <span className='mx-3 border border-r-2 border-gray-200' />
        <CurrencyValue value={showDash ? null : valueUsd} currency='USD' />
      </div>
    );
  else if (type === 'value')
    return <CurrencyValue value={showDash ? null : value} currency={currency} dualTone />;
  else return <CurrencyValue value={showDash ? null : valueUsd} currency='USD' dualTone />;
};

export default TransactionValue;
