import React from 'react';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import CustomSelect, { CustomSelectProps, IOption } from '../../../Filters/CustomSelect';

interface CustomSelectControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<CustomSelectProps, 'handleChange'> {
  name: TName;
  control: ControllerProps<TFieldValues>['control'];
  defaultValue?: IOption | null;
}

const CustomSelectController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: CustomSelectControllerProps<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState }) => (
        <div>
          <CustomSelect
            label={props.placeholder}
            options={props.options}
            placeholder={props.placeholder}
            handleChange={field.onChange}
            getCustomLabel={props.getCustomLabel}
            getCustomOptionLabel={props.getCustomOptionLabel}
            value={field.value}
            {...props}
          />
          {fieldState?.error?.message && (
            <p className='ml-1 mt-1 text-xs text-red-600'>{fieldState?.error?.message}</p>
          )}
        </div>
      )}
    />
  );
};

export default CustomSelectController;
