import { FC } from 'react';
import SettingsTabs from '../../../../components/Settings/Tabs';

const UserSettings: FC = () => {
  const tabs = [
    {
      type: 'User Settings',
      tabs: [
        {
          name: 'profile',
          label: 'Profile',
        },
        {
          name: 'notifications',
          label: 'Notifications',
        },
      ],
    },
  ];

  return <SettingsTabs tabs={tabs} type='user' />;
};

export default UserSettings;
