import { FC } from 'react';
import SingleSelect, { IOption } from '../../ui/components/Select/SingleSelect';
import { BasicBadge } from '../../../ui';
import { useMutation, useQueryClient } from 'react-query';
import { caseApi } from '../../../api/case';
import { toast } from 'react-toastify';
import { ICaseResponse } from '../../../api/dtos/case';
import { useUserCaseUserGet } from '../../../api/profile';

interface IAssignedToDropdownProps {
  caseData: ICaseResponse;
}

const AssignedToDropdown: FC<IAssignedToDropdownProps> = ({ caseData }) => {
  const queryClient = useQueryClient();

  const { data } = useUserCaseUserGet({ case_role: '1,2' });
  const { mutate, isLoading } = useMutation(caseApi.updateCase, {
    onSuccess: () => {
      queryClient.invalidateQueries(['caseApi', caseData.id, 'getCase']);
      toast.success('Assignee has been updated');
    },
  });

  const caseUsersOptions = data?.data?.results?.map((user) => ({
    value: user.id.toString(),
    label: user.full_name,
  }));

  const currentUser = caseUsersOptions?.find((user) => Number(user.value) === caseData?.assigned_to) || {
    label: 'Unassigned',
    value: null,
  };

  const getCustomLabel = (option: IOption) => {
    return (
      <BasicBadge className={option.value == null ? 'bg-orange-50' : 'bg-gray-100'}>
        {option.label}
      </BasicBadge>
    );
  };

  const handleChange = (option: IOption) => {
    mutate({ caseId: caseData.id, assigned_to: Number(option.value), caseName: caseData.name });
  };

  return (
    <div>
      <SingleSelect
        value={currentUser}
        options={caseUsersOptions}
        getCustomLabel={getCustomLabel}
        handleChange={handleChange}
        noOptionsMessage='Case agents list is empty. Go to Settings -> Case Preference -> Case Agents, to add agents'
        className={isLoading ? 'pointer-events-none opacity-50' : ''}
        isCloseHidden
      />
    </div>
  );
};

export default AssignedToDropdown;
