import { FC } from 'react';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import { getCurrencyShortValue } from '../../../utils/helpers/currency';

interface IAddressBalanceWrapperProps {
  balance: number;
  balance_usd: number;
  currency_short: string;
}

const AddressBalanceWrapper: FC<IAddressBalanceWrapperProps> = (props) => {
  const { balance, balance_usd, currency_short } = props;
  return (
    <div className='flex gap-1'>
      <CurrencyValue value={balance} currency={getCurrencyShortValue(currency_short)} />
      <span className='flex'>
        (<CurrencyValue value={balance_usd} currency='USD' />)
      </span>
    </div>
  );
};

export default AddressBalanceWrapper;
