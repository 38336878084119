import classNames from 'classnames';
import { CaretDown, X, RadioButton, Circle } from '@phosphor-icons/react';
import { FC, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import Loader from '@/assets/loader.gif';
import Select, {
  components,
  StylesConfig,
  MenuListProps,
  OptionProps,
  ControlProps,
  GroupBase,
} from 'react-select';
import { Input } from '../../../../ui';
import useIntersectionObserver from '../../../../hooks/useInteractionObserver';
import { cx } from '../../../../utils/helpers/common';
declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    onToggleMenu?: (isOpen?: boolean) => void;
    getCustomLabel?: (option: IOption) => ReactNode;
    getCustomOptionLabel?: (option: IOption) => ReactNode;
    isCloseHidden?: boolean;
    controlRef?: MutableRefObject<HTMLDivElement>;
    showRadioButton?: boolean;
    fetchNextPage?: () => void;
    hasNextPage?: boolean;
    isFetchingNextPage?: boolean;
    setSearch?: (value: string) => void;
    asyncPaginate?: boolean;
    noBorder?: boolean;
    searchLabel?: string;
    stateCondition?: string;
    setStateCondition?: (value: string) => void;
    controlClassPaddingY?: string;
    customDropdownArrow?: ReactNode;
  }
}
export interface SingleSelectProps {
  label?: string;
  options: IOption[];
  value?: IOption;
  placeholder?: string | ReactNode;
  name?: string;
  getCustomLabel?: (option: IOption) => ReactNode;
  getCustomOptionLabel?: (option: IOption) => ReactNode;
  handleChange: (value: IOption) => void;
  isLoading?: boolean;
  className?: string;
  isCloseHidden?: boolean;
  noOptionsMessage?: string;
  showRadioButton?: boolean;
  parentClassName?: string;
  isSearchable?: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  setSearch?: (value: string) => void;
  filterOptions?: (value: IOption, input: string) => boolean;
  asyncPaginate?: boolean;
  noBorder?: boolean;
  labelClassName?: string;
  disabled?: boolean;
  searchLabel?: string;
  stateCondition?: string;
  setStateCondition?: (value: string) => void;
  controlClassPaddingY?: string;
  customDropdownArrow?: ReactNode;
}
export interface IOption {
  value: string;
  label: string;
}

const MenuList = (props: MenuListProps<IOption, true>) => {
  const menuListRef = useRef(null);
  const inputRef = useRef(null);
  const paginationID = useRef<HTMLDivElement>();

  useIntersectionObserver({
    target: paginationID,
    onIntersect: props.selectProps.fetchNextPage || null,
    enabled: !!props.selectProps.hasNextPage,
  });
  // Check if clicked outside of menu list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuListRef.current &&
        !menuListRef.current.contains(event.target) &&
        !props.selectProps.controlRef.current.contains(event.target)
      ) {
        props.selectProps.onToggleMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuListRef, props.selectProps]);

  const onChange = (e) => {
    if (props.selectProps.setSearch) {
      props.selectProps.setSearch(e.currentTarget.value);
      if (props.selectProps.setStateCondition) {
        props.selectProps.setStateCondition(props.selectProps.stateCondition);
      }
    }
    props.selectProps.onInputChange(e.currentTarget.value, {
      action: 'set-value',
      prevInputValue: props.selectProps.inputValue,
    });
  };

  return (
    <components.MenuList {...props}>
      {/* Select all and clear */}
      <div ref={menuListRef} className='z-[100]'>
        {props.selectProps.isSearchable && (
          <div className='sticky top-0 bg-white'>
            <Input
              ref={inputRef}
              type='text'
              className='w-full p-1'
              id='filter-search'
              value={props.selectProps.inputValue}
              onChange={onChange}
              placeholder={props.selectProps.searchLabel}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.currentTarget.focus();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                e.currentTarget.focus();
              }}
            />
          </div>
        )}
        {props.children}
        {props.selectProps.asyncPaginate &&
          !props.selectProps.isFetchingNextPage &&
          !props.selectProps.isLoading && (
            <div ref={paginationID} className={`p-2 ${!props.selectProps.hasNextPage ? 'hidden' : ''}`}></div>
          )}
        {props.selectProps.asyncPaginate && props.selectProps.isFetchingNextPage && (
          <img src={Loader} alt='Loader' className='mx-auto h-16' />
        )}
      </div>
    </components.MenuList>
  );
};

const Option = (props: OptionProps<IOption>) => {
  return (
    <components.Option {...props}>
      <div
        className={classNames('flex flex-row items-center gap-2 p-3 text-gray-700', {
          'bg-blue-600 text-white': props.isSelected && !props.selectProps.showRadioButton,
        })}
        onClick={() => props.selectProps.onToggleMenu(false)}>
        {props.selectProps.showRadioButton &&
          (props.isSelected ? (
            <RadioButton size={20} color='#2563EB' weight='fill' />
          ) : (
            <Circle size={20} color='#D1D5DB' />
          ))}
        {props.selectProps.getCustomOptionLabel
          ? props.selectProps.getCustomOptionLabel(props.data)
          : props.data.label}
      </div>
    </components.Option>
  );
};

const Control = (props: ControlProps<IOption>) => {
  const values = props.getValue();
  const onClick = () => {
    props.selectProps.onToggleMenu();
  };
  const renderValue = () => {
    if (values.length === 0) {
      return <div className='text-sm text-gray-500'>{props.selectProps.placeholder || 'Select'}</div>;
    }
    const limit = 2;
    const el = values.slice(0, 2).map((item) =>
      props.selectProps.getCustomLabel ? (
        <div key={item.label} className='mr-2'>
          {props.selectProps.getCustomLabel(item)}
        </div>
      ) : (
        item.label
      )
    );

    if (values.length > limit) {
      el.push(<span className='ml-auto text-blue-600'>{`+${values.length - limit} more`}</span>);
    }
    return el;
  };
  return (
    <components.Control {...props}>
      <div
        className={classNames(
          'flex h-full w-full cursor-pointer flex-row items-center justify-between rounded-md border-gray-300 pl-2 pr-3',
          { border: !props.selectProps.noBorder },
          props.selectProps.controlClassPaddingY || 'py-2'
        )}
        ref={props.selectProps.controlRef}
        onClick={onClick}>
        <div className='flex w-full'>{renderValue()}</div>
        {values?.length > 0 && !props.selectProps.isCloseHidden && (
          <X
            weight='bold'
            size={18}
            className={classNames('ml-1 mr-0.5 cursor-pointer border-r pr-1 text-red-300 hover:text-red-400')}
            onClick={(e) => {
              e.stopPropagation();
              props.selectProps.onChange(null, null);
            }}
          />
        )}
        {props.selectProps.customDropdownArrow ? (
          props.selectProps.customDropdownArrow
        ) : (
          <CaretDown weight='bold' size={16} className={classNames('mr-0.5')} />
        )}
      </div>
    </components.Control>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles: StylesConfig<any, false> = {
  control: (css) => ({
    ...css,
    border: 'none',
  }),
  menu: (css) => ({
    ...css,
    zIndex: 100,
  }),
  menuList: (css) => ({
    ...css,
    maxHeight: '300px',
    paddingTop: 0,
  }),
  multiValue: (css) => ({
    ...css,
    backgroundColor: '#fff',
  }),
  multiValueRemove: (css) => ({
    ...css,
    display: 'none',
  }),
  option: (css) => ({
    ...css,
    padding: '0',
    backgroundColor: 'none',
    cursor: 'pointer',
    border: 0,
    outline: 'none',
    background: 'none',
    ':hover': {
      backgroundColor: '#F3F4F6',
    },
  }),
};
const SingleSelect: FC<SingleSelectProps> = (props) => {
  const {
    options,
    placeholder,
    isLoading = false,
    className,
    isCloseHidden = false,
    showRadioButton = false,
    parentClassName,
    isSearchable = false,
    asyncPaginate = false,
    noBorder,
    labelClassName,
    disabled = false,
    searchLabel = 'Search',
  } = props;
  const [open, setOpen] = useState(false);
  const controlRef = useRef(null);

  return (
    <div className={parentClassName && classNames(parentClassName)}>
      {props.label && (
        <label className={cx('text-sm font-medium text-gray-500', labelClassName)}>{props.label}</label>
      )}
      <Select
        name={props.name}
        className={cx('mt-1 text-sm', className)}
        styles={styles}
        menuIsOpen={open}
        components={{ MenuList, Option, Control }}
        options={options}
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        placeholder={placeholder}
        onToggleMenu={(isOpen) => setOpen(isOpen || !open)}
        getCustomLabel={props.getCustomLabel}
        getCustomOptionLabel={props.getCustomOptionLabel}
        isCloseHidden={isCloseHidden}
        onChange={props.handleChange}
        value={props.value}
        menuPlacement='auto'
        isLoading={isLoading}
        controlRef={controlRef}
        noOptionsMessage={() => props.noOptionsMessage || 'No options'}
        showRadioButton={showRadioButton}
        isSearchable={isSearchable}
        hasNextPage={props?.hasNextPage}
        fetchNextPage={props?.fetchNextPage}
        isFetchingNextPage={props?.isFetchingNextPage}
        setSearch={props?.setSearch}
        filterOption={props?.filterOptions}
        asyncPaginate={asyncPaginate}
        noBorder={noBorder}
        isDisabled={disabled}
        searchLabel={searchLabel}
        stateCondition={props.stateCondition}
        setStateCondition={props.setStateCondition}
        controlClassPaddingY={props.controlClassPaddingY}
        customDropdownArrow={props.customDropdownArrow}
      />
    </div>
  );
};

export default SingleSelect;
