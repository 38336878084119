import * as React from 'react';
import AssociatedCases from '../../../components/AddressTransaction/AssociatedCases';
import CompassLoader from '../../../components/ui/components/Loader/CompassLoader';
import DetailPage from '../../../layouts/DetailPage';

import Tab, { ITabValue } from '../../../components/ui/components/Tab/Tab';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useCustomerGetCustomer, useCustomerGetDepositAddresses } from '../../../api/customer';
import CustomerMetadata from '../../../components/Customer/CustomerMetadata/CustomerMetadata';
import AuditLogs from '../../../components/common/AuditLogs';
import ProofOfReserves from '../../../components/Customer/ProofOfReserves';
import { useAuth } from '../../../modules/auth';
import Alerts from '../../../components/common/Alerts';
import RecordedTransactions from '../../../components/Customer/RecordedTransactions';
import CustomerAddresses from '../../../components/Customer/CustomerAddresses';

interface CustomerDetailsProps {
  id?: string;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = () => {
  const { getParams } = useRouter();
  const { state } = useAuth();
  const customerId = encodeURIComponent(getParams().id || getParams().customerId);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const { data: response, isLoading: detailLoading } = useCustomerGetCustomer(customerId, {
    useErrorBoundary: true,
  });

  const depositAddressesQuery = useCustomerGetDepositAddresses(
    {
      customerId: encodeURIComponent(response?.data?.customer_id),
    },
    {
      enabled: !!response?.data?.customer_id,
    }
  );

  const [alerts, setAlerts] = React.useState(0);

  if (detailLoading) {
    return <CompassLoader />;
  }
  const tabs: ITabValue[] = [
    { label: 'Customer Alerts' },
    { label: 'Recorded Transactions' },
    { label: 'Customer Addresses' },
    { label: 'Proof of Reserves', hidden: !state.userProfile?.is_proof_of_reserve_enabled },
    {
      label: 'Associated Cases',
      count: response?.data?.associated_cases?.length,
      hidden: !response?.data?.associated_cases?.length,
    },
    { label: 'Audit Logs' },
  ];

  return (
    <DetailPage>
      <CustomerMetadata customer={response.data} alerts={alerts} />
      <div className=''>
        <Tab
          values={tabs}
          changeTab={selectedTab}
          type='secondary'
          listClassName='border-b border-gray-200 py-2 px-4 sticky z-30 top-0'
          panelClassName='p-4'>
          <Alerts
            id={encodeURIComponent(response?.data?.customer_id)}
            custId={response?.data?.id}
            type='customer'
            setAlerts={setAlerts}
            entity_type='customer'
            showLoading
            showFullHeight
            noDataAfterLoad={() => setSelectedTab(1)}
          />
          <RecordedTransactions
            customer={response.data}
            depositAddressesQuery={depositAddressesQuery}
            setAlerts={setAlerts}
          />
          <CustomerAddresses
            customer={response.data}
            depositAddressesQuery={depositAddressesQuery}
            setAlerts={setAlerts}
            customerId={customerId}
          />
          <ProofOfReserves customerId={response.data.customer_id} />
          <AssociatedCases associatedCases={response.data.associated_cases} />
          <AuditLogs type='customer' id={response.data.customer_id} />
        </Tab>
      </div>
    </DetailPage>
  );
};

export default CustomerDetails;
