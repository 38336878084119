import classNames from 'classnames';
import { FC } from 'react';

interface MetaDataProps {
  title: string;
  className?: string;
  preHeader?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  metaDetails?: {
    label: React.ReactNode;
    value: React.ReactNode;
    isHidden?: boolean;
  }[];
}

const MetaData: FC<MetaDataProps> = (props) => {
  const { title, className, header, footer } = props;
  return (
    <div className={classNames('metadata-section p-6', className)}>
      {props.preHeader}
      <div className='text-xs font-medium uppercase text-gray-500'>{title}</div>
      {header}
      {props.children && <hr className='my-4' />}
      {props.children}
      {props.metaDetails?.length > 0 && <hr className='my-4' />}
      {props.metaDetails?.map((metaDetail, i) =>
        metaDetail.isHidden ? null : (
          <div key={i} className='my-5 grid grid-cols-3'>
            <span className='my-auto text-2xs uppercase text-gray-500'>{metaDetail.label}</span>
            <span className='col-span-2 my-auto text-xs font-semibold'>{metaDetail.value}</span>
          </div>
        )
      )}
      {footer && <hr className='my-4' />} {footer}
    </div>
  );
};

export default MetaData;
