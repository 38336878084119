import { useMutation, useQueryClient } from 'react-query';
import { DownloadSimple } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { useAuth } from '../../../modules/auth';

import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { getCurrencyFromCode } from '../../../utils/helpers/currency';
import { getRiskOptions } from '../../../utils/helpers/common';
import { RiskLevel } from '../../../utils/constants/constants';
import { formatDateTime } from '../../../utils/helpers/date';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';

import { customListApi, useCustomListGetAddresses } from '../../../api/settings/customList';
import { ICustomAddressResult } from '../../../api/dtos/settings/customList';
import { ICustomTagResult } from '../../../api/dtos/settings/customTags';
import { customTagsApi } from '../../../api/settings/customTags';

import SingleSelect, { IOption } from '../../../components/ui/components/Select/SingleSelect';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import DigitalAssets from '../../../components/ui/components/Currency/DigitalAssets';
import { Button, ConfirmationModal, Input, RiskBadge, Textarea } from '../../../ui';
import CompassLoader from '../../../components/ui/components/Loader/CompassLoader';
import { Search } from '../../../components/ui/components/Search';
import Table from '../../../ui/components/Table/Table';

interface EditCustomListProps {
  isEdit: boolean;
  setPageKey: (val: string) => void;
  customListData?: ICustomTagResult;
}

const EditCustomList: React.FC<EditCustomListProps> = ({ isEdit, customListData, setPageKey }) => {
  const [addAddressesList, setAddAddressesList] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [addAddressesError, setAddAddressesError] = useState<string[]>([]);
  const [customData, setCustomData] = useState<ICustomTagResult>(customListData);
  const [customListName, setCustomListName] = useState<string>(customListData?.name ?? '');
  const [customListDesc, setCustomListDesc] = useState<string>(customListData?.description ?? '');
  const [removeAddress, setRemoveAddress] = useState<ICustomAddressResult>(null);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState({
    label: customListData?.risk_level
      ? RiskLevel.find((item) => item.value === customListData?.risk_level).label
      : RiskLevel[0].label,
    value: customListData?.risk_level?.toString() ?? RiskLevel[0].value?.toString(),
  });
  const riskOptions = getRiskOptions();

  const { state } = useAuth();
  const customListAddressesQuery = useCustomListGetAddresses(
    { custom_tag_id: customData?.id, q: search },
    { enabled: isEdit }
  );

  const [count, results] = flattenInfiniteQueryResult(customListAddressesQuery.data);
  const queryClient = useQueryClient();
  const onSuccess = (data) => {
    toast.success(data?.data?.message);
    queryClient.invalidateQueries(customListAddressesQuery.key);
  };
  const onSuccessCreate = (data) => {
    setCustomData(data?.data);
    toast.success('Custom List Created');
    setPageKey('edit');
  };
  const onSuccessSave = (data) => {
    setCustomData(data?.data);
    toast.success('Custom List Saved');
  };

  const onError = (error) => {
    toast.error(getErrorMessage(error));
  };
  const { mutate: addAddresses, isLoading: isAddAddressLoading } = useMutation(customListApi.postAddresses, {
    onSuccess: () => {
      toast.success('Added Addresses Successfully');
      queryClient.invalidateQueries(customListAddressesQuery.key);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: AxiosError<any>) => {
      queryClient.invalidateQueries(customListAddressesQuery.key);

      const errorObject = error.response.data.error;
      const allFailedAddresses = errorObject.map((error) => Object.keys(error)[0]);

      const throwErrorToast = () => {
        const errorMap = {};

        errorObject.forEach((error) => {
          for (const addressId in error) {
            let errorMessage = error[addressId];

            if (Array.isArray(errorMessage)) {
              errorMessage = errorMessage[0];
            }

            if (errorMessage in errorMap) {
              errorMap[errorMessage].push(addressId);
            } else {
              errorMap[errorMessage] = [addressId];
            }
          }
        });

        for (const errorMessage in errorMap) {
          const addresses = errorMap[errorMessage];
          const addressCount = addresses.length;
          const addressList = addresses.slice(0, 3).join(', ');
          const moreAddresses = addressCount > 3 ? ` +${addressCount - 3} more` : '';
          const message = `${addressList}${moreAddresses} ${errorMessage}`;

          toast.error(message);
        }
      };

      if (allFailedAddresses.length) {
        throwErrorToast();
        setAddAddressesError(allFailedAddresses);
        const inputAddresses = addAddressesList;
        const inputAddressArray = inputAddresses.split('\n');
        const clearedAddresses = inputAddressArray.filter((address) => allFailedAddresses.includes(address));
        const clearedInput = clearedAddresses.join('\n');
        setAddAddressesList(clearedInput);
      }
    },
  });

  const { mutate: exportAddress } = useMutation(customListApi.exportAddresses, {
    onSuccess,
    onError,
  });

  const { mutate: archiveAddress } = useMutation(customListApi.archiveAddress, {
    onSuccess,
    onError,
  });
  const { mutate: createCustomList } = useMutation(customTagsApi.createCustomTag, {
    onSuccess: onSuccessCreate,
    onError,
  });
  const { mutate: editCustomList } = useMutation(customTagsApi.updateCustomTag, {
    onSuccess: onSuccessSave,
    onError,
  });

  const onAddAddresses = () => {
    if (!addAddressesList) {
      toast.error('Addresses list is empty');
      return;
    }
    addAddresses(
      {
        addresses: addAddressesList.split(/[\n,]+/),
        custom_tag_id: customData?.id,
      },
      {
        onSuccess: () => {
          toast.success('Existing alerts will not be closed or resolved or reopened');
          setAddAddressesList('');
          setAddAddressesError([]);
        },
      }
    );
  };

  const onExport = () => {
    exportAddress({ custom_tag_id: customData?.id });
  };

  const rows = count
    ? results?.map((i) => {
        const digitalAssets = i.currencies.map((c) => ({
          currency: c,
          token: getCurrencyFromCode(c).currency_short,
        }));
        return {
          id: i.id,
          data: [
            <IdentifierEllipsis key={i.id} identifier={i.identifier} copyable />,
            i.added_by,
            formatDateTime(i.created_at, state.userProfile.timezone),
            <DigitalAssets digitalAssets={digitalAssets} key={i.id} />,
            <Button variant='irreversible' key={i.id} onClick={() => setRemoveAddress(i)}>
              Remove
            </Button>,
          ],
        };
      })
    : [];

  const getLabel = (option: IOption) => {
    return <RiskBadge risk={Number(option.value)} />;
  };

  const handleSaveOrCreate = () => {
    if (isEdit) {
      editCustomList({
        id: customData?.id,
        payload: {
          name: customListName,
          risk_level: selectedRiskLevel.value,
          description: customListDesc,
        },
      });
    } else {
      createCustomList({
        name: customListName,
        risk_level: selectedRiskLevel.value,
        description: customListDesc,
        is_in_custom_list: true,
      });
    }
  };

  const headerData = ['Addresses', 'Added By', 'Date Added', 'Blockchains', 'Action '];

  return (
    <div>
      <div className='mb-2 flex justify-between rounded-md bg-gray-100 p-2 py-3 text-[13px] font-medium uppercase'>
        <h2 className='self-center'>{isEdit ? 'Edit' : 'Create'} Custom List</h2>
        <span
          className='cursor-pointer text-blue-600'
          onClick={() => {
            queryClient.invalidateQueries('customTagsApi.getCustomLists');
            rows.splice(0, rows.length);
            setPageKey('home');
          }}>
          &lt;- Back to Custom List
        </span>
      </div>
      <div className='mt-3 h-full min-h-[400px] max-w-3xl px-1 py-2.5'>
        <div className='flex gap-3'>
          <Input
            id={''}
            type='text'
            labelText='Name'
            value={customListName}
            placeholder='Enter name'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomListName(e.target.value)}
            className='mt-1 flex w-1/2 flex-col justify-between'
            inputClassNames={`${[1, 2].includes(customData?.type) ? 'py-[11px]' : 'py-2.5'} text-xs`}
            labelClassNames='font-normal'
            disabled={[1, 2].includes(customData?.type)}
          />
          <Input
            id={''}
            type='text'
            labelText='Description'
            value={customListDesc}
            placeholder='Enter description'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomListDesc(e.target.value)}
            className='mt-1 flex w-1/2 flex-col justify-between'
            inputClassNames={`${[1, 2].includes(customData?.type) ? 'py-[11px]' : 'py-2.5'} text-xs`}
            labelClassNames='font-normal'
            disabled={[1, 2].includes(customData?.type)}
          />
          <SingleSelect
            options={riskOptions}
            //TODO: change to mt-1 after react migration
            className='mt-2'
            parentClassName='w-1/2 overflow-visible'
            handleChange={(value) => {
              setSelectedRiskLevel(value);
            }}
            value={selectedRiskLevel}
            label='Risk Level'
            labelClassName='font-normal text-gray-800'
            getCustomLabel={getLabel}
            getCustomOptionLabel={getLabel}
            isCloseHidden={true}
            disabled={[1, 2].includes(customData?.type)}
          />
          <Button
            className='mt-3 h-fit self-end py-2.5'
            onClick={handleSaveOrCreate}
            disabled={[1, 2].includes(customData?.type)}>
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </div>

        {isEdit && (
          <>
            {isAddAddressLoading ? (
              <CompassLoader position='left' />
            ) : (
              <>
                <div className='mt-3 text-sm'>Add Addresses</div>
                <Textarea
                  id='addresses'
                  value={addAddressesList}
                  onChange={setAddAddressesList}
                  className='mt-2'
                  rows={5}
                  error={addAddressesError.length > 0 ? ' ' : ''}
                  placeholder='Add multiple addresses separated by “,” or add each address in new line'
                />
                <div className='flex items-center justify-between pt-2'>
                  <div className='mt-1 text-xs text-gray-500'>Max 1000 address</div>
                  <Button className='mt-1' onClick={onAddAddresses}>
                    Add Addresses
                  </Button>
                </div>
              </>
            )}
            <div className='mt-3 flex justify-between rounded-t-md border border-gray-200 p-2'>
              <Search
                className='bg-gray-50'
                placeholder='Search for Address'
                minChars={3}
                value={search}
                setValue={setSearch}
              />
              <Button iconStart={<DownloadSimple size={16} />} onClick={onExport}>
                Export Custom List
              </Button>
            </div>
            <ConfirmationModal
              open={removeAddress != null}
              data={removeAddress}
              onCancel={() => setRemoveAddress(null)}
              onConfirm={(address: ICustomAddressResult) => {
                setRemoveAddress(null);
                archiveAddress({ id: address.id });
              }}
              title={`Remove Address`}
              body={
                <>
                  <p>
                    Are you sure you want to remove address <strong>{removeAddress?.identifier}</strong>?
                  </p>
                </>
              }
            />
            <Table
              className='rounded-b-md border border-gray-200'
              height={300}
              isHeaderVisible={false}
              headerData={headerData}
              rows={rows}
              isLoading={customListAddressesQuery.isLoading || customListAddressesQuery.isRefetching}
              {...buildInfiniteQueryTableProps(customListAddressesQuery)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EditCustomList;
