import { startCase } from 'lodash';
import { BasicModal, Button, Input } from '../../../ui';
import { Currencies } from '../../../utils/constants/constants';
import { getCurrencyFromCode } from '../../../utils/helpers/currency';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import { IMonitoringAddress } from './MonitoringTable';
import { useMutation, useQueryClient } from 'react-query';
import {
  monitoringAddressesApi,
  tokenMonitoringAddressesApi,
} from '../../../api/settings/monitoringAddresses';
import { toast } from 'react-toastify';
import { useState } from 'react';
import SingleSelect, { IOption } from '../../ui/components/Select/SingleSelect';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';

interface AddMonitoringAddressModalProps extends IMonitoringAddress {
  type: 'add' | 'remove';
  show: boolean;
  onClose: () => void;
  isToken?: boolean;
  isIgnored?: boolean;
}

const AddMonitoringAddressModal: React.FC<AddMonitoringAddressModalProps> = (props) => {
  const { type, identifier: address, currency, show, onClose, isIgnored, isToken } = props;

  const [identifier, setIdentifier] = useState('');
  const [blockchain, setBlockchain] = useState<IOption>(null);
  const [error, setError] = useState('');

  const queryClient = useQueryClient();

  const resetForm = () => {
    setIdentifier('');
    setBlockchain(null);
    setError('');
  };

  const monitoringApi = isToken ? tokenMonitoringAddressesApi : monitoringAddressesApi;
  const monitoringKey = isToken
    ? 'TokenMonitoringAddressesApi.getMonitoredAddresses'
    : 'MonitoringAddressesApi.getMonitoredAddresses';
  const { mutate: addAddress, isLoading } = useMutation(monitoringApi.addMonitoredAddress, {
    onSuccess: () => {
      resetForm();
      toast.success('Address added successfully');
      queryClient.invalidateQueries(monitoringKey);
      onClose();
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });

  const { mutate: archiveAddress, isLoading: isLoadingArchive } = useMutation(
    monitoringApi.archiveMonitoredAddress,
    {
      onSuccess: () => {
        toast.success('Address archived successfully');
        queryClient.invalidateQueries(monitoringKey);
        onClose();
      },
      onError: (error: AxiosError) => {
        toast.error(getErrorMessage(error));
      },
    }
  );

  const onSubmit = () => {
    if (type === 'add') {
      if (!identifier || !blockchain) {
        setError('Please fill all fields');
        return;
      }
    }
    addAddress({
      identifier: identifier || address,
      currency: blockchain?.value != null ? Number(blockchain?.value) : currency,
      is_ignored: isToken ? null : isIgnored,
    });
  };

  const onRemove = () => {
    archiveAddress({
      identifier: address,
      currency,
      is_ignored: isIgnored,
    });
  };

  const blockchainOptions = Currencies.map((currency) => ({
    label: currency.label,
    value: currency.value.toString(),
  }));
  const getBlockchainLabel = (currency: IOption) => {
    return (
      <span className='flex'>
        <CurrencyBadge currency={Number(currency.value)} transparent label='' />
        {currency.label}
      </span>
    );
  };

  return (
    <BasicModal
      modalTitle={`${startCase(type)} Address`}
      open={show}
      onClose={onClose}
      modalBody={
        <div className=''>
          {type === 'add' ? (
            <>
              <label className='mb-2 inline-block text-sm font-medium text-gray-800'>
                Enter address {!isIgnored && 'to monitor'}
              </label>
              <Input
                name='identifier'
                label='Address'
                id={'address'}
                type={'text'}
                placeholder='Enter address'
                value={identifier}
                onChange={(e) => setIdentifier((e.target as HTMLInputElement).value)}
              />
            </>
          ) : (
            <>
              <label className='mb-2 inline-block text-sm font-medium text-gray-800'>
                Currently {isIgnored && 'Not '} Monitoring Address
              </label>
              <IdentifierEllipsis className='mb-2' identifier={address} />
            </>
          )}
          {/* {type === 'remove' && <MonitoringBadge isEnabled={is_active} />} */}
          {type === 'add' ? (
            <>
              <label className='mb-2 mt-4 inline-block text-sm font-medium text-gray-800'>
                Select Blockchain
              </label>
              <SingleSelect
                options={blockchainOptions}
                handleChange={(value: IOption) => {
                  setBlockchain(value);
                }}
                value={blockchain}
                getCustomLabel={getBlockchainLabel}
                getCustomOptionLabel={getBlockchainLabel}
                placeholder='Select Blockchain'
              />{' '}
            </>
          ) : (
            <>
              <label className='mb-2 mt-4 inline-block text-sm font-medium text-gray-800'>
                Blockchain Selected
              </label>
              <div className='text-gray-500'>{getCurrencyFromCode(currency).label}</div>
            </>
          )}

          {error && (
            <p className='mt-2 text-sm text-red-600' id='email-error'>
              {error}
            </p>
          )}
        </div>
      }
      modalFooter={
        <div className='mt-4 flex justify-between'>
          <Button variant='tertiary' onClick={onClose}>
            Cancel
          </Button>
          {type === 'add' ? (
            <Button disabled={isLoading} type='submit' variant='primary' className='ml-2' onClick={onSubmit}>
              Add
            </Button>
          ) : (
            <div>
              <Button
                disabled={isLoadingArchive}
                type='submit'
                variant='primary'
                className='ml-2'
                onClick={onRemove}>
                Remove
              </Button>
            </div>
          )}
        </div>
      }
    />
  );
};

export default AddMonitoringAddressModal;
