import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';

import './pieWidget.scss';

import DashboardWidget from './DashboardWidget';
import EmptyState from '../../ui/components/States/Empty';
import CustomLink from '../ui/components/CustomLink';
import { RiskLevel } from '../../utils/constants/constants';

interface IPieWidget3Props {
  title: string;
  data: (string | number)[][];
  isLoading?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
  isPdf?: boolean;
  isRisk?: boolean;
  widgetHeader?: React.ReactNode;
  link?: {
    to?: string;
    params?: Record<string, string>;
  };
}

const PieWidget3: React.FC<IPieWidget3Props> = ({
  title,
  data,
  isLoading,
  tooltipTitle,
  tooltipDescription,
  isPdf,
  isRisk,
  widgetHeader,
  link,
}) => {
  const [tooltip, setTooltip] = useState<{ x: number; y: number; content: ReactNode } | null>(null);

  const transformedData = isRisk
    ? data.map((row) => ({
        label: RiskLevel.find((r) => r.value === Number(row[0]))?.label || 'Unknown',
        value: row[1] as number,
      }))
    : data.map((row) => ({ label: row[0] as string, value: row[1] as number }));

  const total = transformedData.reduce((sum, row) => sum + row.value, 0);

  const emptySpacePercentage = 0.1;
  const emptySize = (total * emptySpacePercentage) / (transformedData.length * (1 + emptySpacePercentage));

  const colors = isRisk
    ? ['#fde68a', '#fbbf24', '#f87171', '#ef4444', '#b91c1c']
    : ['#0891B2', '#06B6D4', '#67E8F9'];

  const noData = transformedData.every((row) => row.value === 0);

  const stackedData = transformedData.reduce(
    (acc, { label, value }, index) => {
      acc[label] = value;
      if (index !== transformedData.length - 1 && value !== 0) acc[`empty${index}`] = emptySize;
      return acc;
    },
    {} as { [key: string]: number }
  );

  // Finding the original index of the keys which don't start with 'empty' in the stackedData object for colors
  const actualStackedDataIndexes = Object.keys(stackedData)
    .filter((key) => !key.startsWith('empty'))
    .reduce((acc, key, index) => {
      const originalIndex = Object.keys(stackedData).indexOf(key);
      acc[originalIndex] = index;
      return acc;
    }, {});

  const handleMouseOver = (dataKey: string, e) => {
    if (!dataKey.startsWith('empty')) {
      setTooltip({
        x: e.clientX + 10,
        y: e.clientY - 20,
        content: (
          <div className='flex flex-col items-start justify-center text-start'>
            <span className='text-gray-400'>{dataKey}</span>
            <span>{stackedData[dataKey]}</span>
          </div>
        ),
      });
    }
  };

  const handleMouseOut = () => {
    setTooltip(null);
  };

  return (
    <CustomLink
      to={link?.to}
      params={link?.params}
      className={classNames('group', {
        'col-span-4': isPdf,
        'col-span-1': !isPdf,
      })}>
      <DashboardWidget
        title={title}
        subtitle='By Risk Level'
        className={classNames('cursor-pointer !px-0 py-0', {
          '!pb-5': !isLoading && noData,
          '!pb-1': !(!isLoading && noData),
        })}
        headerClassName={classNames('!p-5 !pt-5', {
          '!pb-3': !isLoading && noData,
          '!pb-0': !(!isLoading && noData),
        })}
        titleClassName='text-sm group-hover:!text-blue-600 group-hover:!underline'
        showBorder={false}
        widgetHeader={widgetHeader}
        tooltipTitle={tooltipTitle}
        tooltipDescription={tooltipDescription}>
        {!isLoading && noData ? (
          <EmptyState isRow customHeight={75} />
        ) : (
          <div className='flex flex-col justify-center'>
            {tooltip && (
              <div
                className='absolute z-max rounded-md border border-gray-300 bg-white px-3 py-1 text-2xs'
                style={{ left: tooltip.x, top: tooltip.y }}>
                {tooltip.content}
              </div>
            )}
            {isLoading ? (
              <Skeleton width={100} height={100} className='m-2.5' />
            ) : (
              <>
                <ResponsiveContainer width='100%' height={60}>
                  <BarChart
                    layout='vertical'
                    data={[stackedData]}
                    reverseStackOrder
                    style={{ cursor: 'pointer' }}
                    margin={{ top: 20, right: 15, left: 15, bottom: 5 }}>
                    <XAxis type='number' domain={[0, total]} hide />
                    <YAxis type='category' dataKey='name' hide />
                    {Object.keys(stackedData).map((key, index) => (
                      <Bar
                        key={key}
                        dataKey={key}
                        stackId='a'
                        fill={
                          !key.startsWith('empty')
                            ? colors[actualStackedDataIndexes[index] % colors.length]
                            : '#fff'
                        }
                        radius={[3, 3, 3, 3]}
                        onMouseOver={(data, i, e) => handleMouseOver(key, e)}
                        onMouseOut={handleMouseOut}></Bar>
                    ))}
                  </BarChart>
                </ResponsiveContainer>
                <div className='mx-4 mb-2 mt-1 flex items-center justify-between pr-2'>
                  <span className='text-sm'>Total Pending</span>
                  <span className='text-[20px] font-bold'>{total}</span>
                </div>
              </>
            )}
          </div>
        )}
      </DashboardWidget>
    </CustomLink>
  );
};

export default PieWidget3;
