import { FC } from 'react';
import { formatDateTime } from '../../../utils/helpers/date';
import ReportTable from '../ReportTable';
import { ICustomerDepositWithdrawalResult } from '../../../api/dtos/customer';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { useRouter } from '../../../modules/router/RouterProvider';
import { RiskBadge } from '../../../ui';
import { getCurrencyById } from '../../../utils/helpers/currency';
import TransactionValue from '../../Transaction/TransactionValue';

interface ICustomerDepositWithdrawalReportProps {
  transactions: ICustomerDepositWithdrawalResult[];
  type: 'deposit' | 'withdrawal';
  part?: number;
}

const CustomerDepositWithdrawalReport: FC<ICustomerDepositWithdrawalReportProps> = ({
  transactions,
  type,
  part,
}) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;

  if (!transactions) return null;

  const tableData = transactions.map((txn) => {
    const originator =
      type === 'deposit'
        ? txn.from_addresses[0]?.tag_name_verbose || 'Unknown'
        : txn.to_addresses[0]?.address || 'Unknown';
    const beneficiary =
      type === 'deposit'
        ? txn.to_addresses[0]?.address || 'Unknown'
        : txn.from_addresses[0]?.tag_name_verbose || 'Unknown';
    return {
      label: <div className='break-words'>{txn.identifier}</div>,
      value: [
        {
          label: 'Transaction',
          value: (
            <CurrencyBadge currency={txn.currency} label={getCurrencyById(txn.currency).label} transparent />
          ),
        },
        ...(txn.from_addresses ? [{ label: 'Originator', value: originator }] : []),
        ...(txn.to_addresses ? [{ label: 'Beneficiary', value: beneficiary }] : []),
        {
          label: 'Date',
          value: formatDateTime(txn.transaction_time, timezone),
        },
        {
          label: 'Risk Level',
          value: <RiskBadge risk={txn.risk_level} />,
        },
        {
          label: 'Value',
          value: <TransactionValue transaction={txn} type='all' />,
        },
      ],
    };
  });

  return (
    <ReportTable
      tableData={tableData}
      title={`Part ${part}: ${type === 'deposit' ? 'Deposits' : 'Withdrawals'}`}
    />
  );
};

export default CustomerDepositWithdrawalReport;
