import { FC } from 'react';
import SettingsTabs from '../../../../components/Settings/Tabs';
import { useCurrentRole } from '../../../../utils/helpers/common';
import { Navigate } from 'react-router-dom';

const OrganizationSettings: FC = () => {
  // const { state } = useAuth();
  // const canEnableMonitoring = state?.userProfile?.can_enable_continuous_monitoring;

  const { isOrgAdmin } = useCurrentRole();

  if (!isOrgAdmin) {
    return <Navigate to='/dashboard' replace={true} />;
  }

  const tabs = [
    {
      type: 'Organisation Settings',
      tabs: [
        {
          name: 'organisation',
          label: 'Organisation Members',
          tabs: [
            {
              name: 'members',
              label: 'Members',
            },
            {
              name: 'workspaces',
              label: 'Workspaces',
            },
          ],
        },
        {
          name: 'billing',
          label: 'Billing',
          tabs: [
            { name: 'current-plan', label: 'Current Plan' },
            { name: 'usage', label: 'Usage' },
          ],
        },
      ],
    },
  ];

  return <SettingsTabs tabs={tabs} type='organisation' />;
};

export default OrganizationSettings;
