import { Circle } from '@phosphor-icons/react';
import { FC } from 'react';
import BasicBadge from '../BasicBadge/BasicBadge';

export interface StatusBadgeProps {
  isActive: boolean;
  enableLabel?: string;
  disableLabel?: string;
  className?: string;
}

const StatusBadge: FC<StatusBadgeProps> = (props) => {
  const { enableLabel = 'Enabled', disableLabel = 'Disabled' } = props;

  const className = props.isActive ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100';
  return (
    <BasicBadge
      iconStart={
        <Circle
          size={8}
          weight='fill'
          className={'mr-1 ' + (props.isActive ? 'text-green-400' : 'text-red-500')}
        />
      }
      className={className + ' ' + props.className}>
      {props.isActive ? enableLabel : disableLabel}
    </BasicBadge>
  );
};

export default StatusBadge;
