import { useState, useCallback } from 'react';
import { useToggle } from 'react-use';
export const useModalData = <T>(defaultVal?: T) => {
  const [state, setState] = useState<T>(defaultVal ?? undefined);
  const [isOpen, setOpen] = useToggle(defaultVal !== undefined ? true : false);

  const open = useCallback(
    (val: T | null) => {
      setState(val);
      setOpen(true);
    },
    [setOpen, setState]
  );

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return {
    isOpen,
    state,
    open,
    close,
  };
};
