import { FC } from 'react';
import { IAggregatedResult } from '../../../api/dtos/address';
import { startCase } from 'lodash';
import EntityBadge from '../../ui/components/Badge/EntityBadge';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import React from 'react';
import classNames from 'classnames';
import EmptyState from '../../../ui/components/States/Empty';

interface CustomerAssociatedEntitiesTableReportProps {
  associatedEntities: IAggregatedResult[];
  type: 'deposit' | 'withdrawal';
}

const CustomerAssociatedEntitiesTableReport: FC<CustomerAssociatedEntitiesTableReportProps> = ({
  associatedEntities,
  type,
}) => {
  if (!associatedEntities) return null;
  const tableData = associatedEntities
    .filter((a) => a.tag_name_verbose)
    .map((a) => {
      return {
        label: <div className='break-words'>{a.tag_name_verbose}</div>,
        value: [
          {
            label: <EntityBadge entity={{ tag_type_verbose: a.tag_type_verbose }} />,
            value: <CurrencyValue value={a.total_value_usd} currency={'USD'} />,
          },
        ],
      };
    });
  return (
    <div
      className={classNames('mt- 2 flex border', {
        '[page-break-after:always]': type === 'deposit',
      })}>
      <div className='w-1/4 p-2 text-gray-500'>
        <div className='text-xs font-semibold'> Entities {startCase(type)}ing Funds</div>
        <div className='text-3xs font-normal'>
          Associated risks with entities {type}ing funds with total transaction volume.
        </div>
      </div>
      <div className='w-full'>
        {tableData?.length ? (
          tableData.map((item, index) => (
            <div
              key={index}
              className='grid break-inside-avoid grid-cols-3 text-2xs text-gray-500 [&>*]:px-2 [&>*]:py-1'>
              <div className='col-start-1 my-auto'>{item.label}</div>
              {Array.isArray(item.value)
                ? item.value.map((subItem, subIndex) => (
                    <React.Fragment key={subIndex}>
                      <div className='col-start-2 my-auto text-gray-500'>{subItem.label}</div>
                      <div className='my-auto font-semibold text-gray-900'>{subItem.value}</div>
                    </React.Fragment>
                  ))
                : item.value}
            </div>
          ))
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default CustomerAssociatedEntitiesTableReport;
