import { FC } from 'react';
import { IClassifierResult } from '../../api/dtos/classifier';
import { RiskBadge } from '../../ui';
import Rule from '../Risks/Rule';

interface IClassifiersReportProps {
  classifiers: IClassifierResult[];
  type: 'address' | 'transaction';
}

const ClassifiersReport: FC<IClassifiersReportProps> = ({ classifiers, type }) => {
  if (!classifiers) return null;
  return (
    <div>
      <div className='mb-4 border-b-2 pb-4'>
        <div className='text-[26px] font-extrabold'>Appendix A</div>
        <div className='text-2xs'>Risk Classifiers: An list of risks which have been checked for.</div>
      </div>
      <div className='mb-4 rounded-b-sm'>
        <div className='rounded-t-sm border border-gray-200 bg-gray-200 px-3 py-2.5 text-xs font-bold'>
          List of Rules
          <div className='text-3xs font-normal text-gray-500'>
            The list of rules which have been run against this address
          </div>
        </div>
      </div>
      {classifiers.map((classifier, index) => (
        <div key={index} className='break-inside-avoid border pl-2 pr-4 text-2xs'>
          <div className='my-2 flex'>
            <RiskBadge risk={classifier.alert_level} />
            <div className='my-auto ml-2 flex text-xs font-bold'>{classifier.rule_name}</div>
          </div>
          {classifier.rule_conditions.map((r, i) => (
            <div key={i} className='my-2 text-2xs leading-6 text-gray-500'>
              <Rule ruleType={r.rule_condition_type} type={type} {...r} />
              {i !== classifier.rule_conditions.length - 1 && <div className='text-gray-500'>AND</div>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ClassifiersReport;
