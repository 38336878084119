import { Api } from './api';
import {
  IUpdateStageorAssigneeRequest,
  IGetWorkspaceAssignmentCountsParams,
  IGetWorkspaceAssignmentCountsResponse,
  IUpdateStageorAssigneePayload,
} from './dtos/workflows';
import { useQuery } from 'react-query';

export class WorkflowsApi extends Api {
  readonly baseUrl: string = '/risk/api/v4';

  readonly updateStageorAssignee = (params: IUpdateStageorAssigneeRequest) => {
    const { type, id, payload } = params;
    return this.http.patch<IUpdateStageorAssigneePayload>(this.route(`/${type}/${id}/`), payload);
  };

  readonly getWorkspaceAssignmentCount = (params: IGetWorkspaceAssignmentCountsParams) => {
    const { type } = params;
    return this.http.get<IGetWorkspaceAssignmentCountsResponse>(
      this.route(`/${type}/workspace-assignment-counts/`)
    );
  };
}

export const workflowsApi = new WorkflowsApi();

export const useWorkspaceAssignmentCountGet = (params: IGetWorkspaceAssignmentCountsParams) => {
  const key = ['StagesApi.getWorkspaceAssignmentCount', params.type];
  const result = useQuery(key, () => workflowsApi.getWorkspaceAssignmentCount({ ...params }));

  return {
    key,
    ...result,
  };
};
