/* eslint-disable react/jsx-pascal-case */
import './integrations/instrument.js';
import { FC } from 'react';
import SPA from './containers/SPA';
import './styles/index.scss';

const App: FC = () => {
  return <SPA />;
};

export default App;
