import { ArrowDownRight, ArrowUpRight } from '@phosphor-icons/react';
import { FC } from 'react';
import { ellipsis } from '../../../../utils/helpers/helperFunctions';

interface CustomerAddressProps {
  type: number;
  customer_id: string;
}

const CustomerAddress: FC<CustomerAddressProps> = ({ customer_id, type }) => {
  if (!customer_id) return null;
  return (
    <div className='flex' title={customer_id}>
      {type === 1 ? (
        <ArrowDownRight className='mr-1 text-amber-600' size={16} />
      ) : (
        <ArrowUpRight className='mr-1 text-red-600' size={16} />
      )}
      {ellipsis(customer_id, 8)}
    </div>
  );
};

export default CustomerAddress;
