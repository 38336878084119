import Slack1 from '@/assets/integrations/slack-1.jpg';
import Slack2 from '@/assets/integrations/slack-2.jpg';
import Slack3 from '@/assets/integrations/slack-3.jpg';

const SlackHow = () => {
  // const data = [
  //   'Get notified in Slack with alerts about addresses, transactions, customers and cases.',
  //   'Also receive important notifications like when your file upload is successful or has failed.',
  //   'Connect Slack to Merkle Science to get weekly and monthy reports.',
  // ];

  const data = [
    {
      text: 'Get notified in Slack with alerts about addresses, transactions, customers and cases.',
      image: Slack1,
    },
    {
      text: 'Also receive important notifications like when your file upload is successful or has failed.',
      image: Slack2,
    },
    {
      text: 'Connect Slack to Merkle Science to get weekly and monthy reports.',
      image: Slack3,
    },
  ];

  return (
    <div className='my-4 border-t pt-6'>
      <div className='mb-4 font-bold'>How does it work?</div>
      <div className='grid grid-cols-3 gap-6 text-sm text-gray-500'>
        {data.map((item, i) => (
          <div key={i}>
            <div className='mb-4 flex h-[255px] border'>
              <img src={item.image} className='my-auto' />
            </div>
            <div>{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlackHow;
