import classNames from 'classnames';
import { clone } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import CheckboxController from '../../../ui/components/Form/CheckboxController';
import { Button, CopyBtn, DropdownOption } from '../../../ui';
import InputController from '../../../ui/components/Form/InputController';
import { v4 as uuid } from 'uuid';
import { CaretDown } from '@phosphor-icons/react';
import SimpleDropdown from '../../ui/components/Dropdown/SimpleDropdown';
import { useMutation } from 'react-query';
import { integrationApi } from '../../../api/integration';
import { toast } from 'react-toastify';
import { IIntegrationProps } from '../../../views/dashboard/settings/Integrations/IntegrationDetails';
import { Tooltip } from 'react-tooltip';

const initialData = {
  key: '',
  url: '',
  riskDetectionNotifications: null,
  alertItemNotifications: null,
  alertResolutionNotifications: false,
  riskLevels: {
    '1': null,
    '2': null,
    '3': null,
    '4': null,
    '5': null,
  },
};

const WebhookForm: FC<IIntegrationProps> = ({ data, onSubmit, onCancel }) => {
  const {
    control,
    handleSubmit,
    setValue,
    resetField,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: initialData,
  });

  const [isWebhookTested, setIsWebhookTested] = useState(false);
  const [loading, setLoading] = useState(false);

  const riskDetectionNotifications = useWatch({ control, name: 'riskDetectionNotifications' });
  const webhookUrl = useWatch({ control, name: 'url' });
  const webhookKey = useWatch({ control, name: 'key' });

  const canSave = isWebhookTested
    ? !dirtyFields.url
    : Object.keys(dirtyFields).length > 0 && !dirtyFields.url;
  useEffect(() => {
    if (data == null) {
      return;
    }
    const riskLevels = clone(initialData.riskLevels);
    Object.keys(riskLevels).forEach((key) => {
      riskLevels[key] = data.config.risk?.includes(key);
    });

    setValue('url', data.config.url);
    setValue('key', data.config.key || uuid());
    setValue('riskDetectionNotifications', data.config.alert_item || !!data.config?.risk?.length);
    setValue('riskLevels', riskLevels);
    setValue('alertItemNotifications', data.config.alert_item);
    setValue('alertResolutionNotifications', data.config.alert_resolution_notifications);
  }, [setValue, data]);

  const onSave = async (form: typeof initialData) => {
    const config = {
      url: form.url,
      key: form.key,
      risk: form.riskDetectionNotifications
        ? Object.keys(form.riskLevels).filter((key) => form.riskLevels[key])
        : [],
      alert_item: form.alertItemNotifications && form.riskDetectionNotifications,
      alert_resolution_notifications: !!form.alertResolutionNotifications,
    };
    setLoading(true);
    await onSubmit({
      id: data?.id,
      is_active: true,
      provider: 'webhook',
      config: config,
    });
    reset(undefined, { keepValues: true });
    setLoading(false);
  };

  const testOptions = [
    {
      label: 'Test Address',
      value: 'address',
    },
    {
      label: 'Test Transaction',
      value: 'transaction',
    },
    {
      label: 'Test Customer',
      value: 'customer',
    },
  ];

  const { mutate: testWebhook } = useMutation(integrationApi.testWebhook, {
    onSuccess: () => {
      toast.success('Validated successfully');
      setIsWebhookTested(true);
      resetField('url', { defaultValue: webhookUrl });
    },
    onError: () => {
      toast.error('Failed to validate. Please check the inputs.');
    },
  });

  const onChangeTestWebhook = (option: DropdownOption) => {
    testWebhook({
      endpoint: webhookUrl,
      entity_type: option.value.toString(),
      secret_key: webhookKey,
    });
  };

  return (
    <div className='mt-8 bg-gray-50 px-10 py-7'>
      <div className='font-bold'>Configure Integration</div>
      <form onSubmit={handleSubmit(onSave)}>
        <div className='mt-2 flex py-2'>
          <InputController
            control={control}
            name='url'
            label='URL'
            className='mr-6 w-1/2'
            id='url'
            labelText='Webhook URL'
            type={'text'}
          />
          <InputController
            iconEnd={<CopyBtn text={data?.config?.key} />}
            control={control}
            name='key'
            label='Key'
            className='w-1/2'
            id={'key'}
            labelText='Webhook Key'
            type={'text'}
          />
        </div>
        <div className='py-2'>
          <CheckboxController
            control={control}
            name='riskDetectionNotifications'
            className='!items-start justify-start'
            inputClassName='mt-1'
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                <div className={classNames('text-sm font-medium')}>Risk Detection Notifications</div>
                <div className='text-sm font-normal text-gray-500'>
                  Notification sent when any entity (address, transaction or customer) of the following risks
                  is detected by Merkle Science. The entity can be submitted through APIs or through the
                  manual upload.
                </div>
              </div>
            }
          />
          <div
            className={classNames('flex gap-4 pl-6 pt-3', {
              hidden: !riskDetectionNotifications,
            })}>
            <CheckboxController control={control} name='riskLevels.5' labelText='Critical' />
            <CheckboxController control={control} name='riskLevels.4' labelText='High' />
            <CheckboxController control={control} name='riskLevels.3' labelText='Medium' />
            <CheckboxController control={control} name='riskLevels.2' labelText='Caution' />
            <CheckboxController control={control} name='riskLevels.1' labelText='Info' />
          </div>
          <div
            className={classNames('py-4 pl-6', {
              hidden: !riskDetectionNotifications,
            })}>
            <CheckboxController
              control={control}
              name='alertItemNotifications'
              className='!items-start justify-start'
              inputClassName='mt-1'
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                  <div className={classNames('text-sm font-medium')}>Exposure change detection</div>
                  <div className='text-sm font-normal text-gray-500'>
                    Notifies when there is a change in exposure values or new sub item in Alert is created
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <div className='py-2'>
          <CheckboxController
            control={control}
            name='alertResolutionNotifications'
            className='!items-start justify-start'
            inputClassName='mt-1'
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                <div className={classNames('text-sm font-medium')}>Alert Resolution Notifications</div>
                <div className='text-sm font-normal text-gray-500'>
                  Notification sent when any alert is resolved.
                </div>
              </div>
            }
          />
        </div>
        <div className='mt-2 flex justify-between'>
          <Button onClick={onCancel} variant='tertiary'>
            Cancel
          </Button>
          <div className='flex'>
            <SimpleDropdown
              onChange={onChangeTestWebhook}
              options={testOptions}
              placeholder={
                <Button variant='secondary'>
                  Test Webhook
                  <CaretDown weight='bold' size={16} className='ml-1' />
                </Button>
              }
            />
            <div
              data-tooltip-id={`webhook-tooltip`}
              data-tooltip-html={!canSave ? 'Please test webhook before saving the configuration' : ''}>
              <Button type='submit' className='ml-3' disabled={loading || !canSave}>
                Save Configuration
              </Button>
            </div>
            <Tooltip variant={'dark'} place={'right'} id={`webhook-tooltip`} className='z-10 shadow' />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WebhookForm;
