import { FC, PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../modules/auth';
import { useSearchParams } from 'react-router-dom';
import { useAnalyticsGet } from '../api/analytics';
import { IAnalyticsResponse } from '../api/dtos/analytics';
import { AxiosResponse } from 'axios';
import { initLogRocket } from '../integrations';

type GuestContainerProps = PropsWithChildren & {
  fallbackPath?: string;
};

export const GuestContainer: FC<GuestContainerProps> = ({ children, fallbackPath }) => {
  const [searchParams] = useSearchParams();

  const { isAuthenticated } = useAuth();

  useAnalyticsGet({
    onSuccess: (data: AxiosResponse<IAnalyticsResponse>) => {
      initLogRocket(data.data, null);
    },
  });

  if (isAuthenticated) {
    return <Navigate to={fallbackPath || searchParams.get('redirect') || '/'} />;
  }

  return <>{children}</>;
};

export function inGuestContainer(Node: ReactNode) {
  return <GuestContainer>{Node}</GuestContainer>;
}

export default GuestContainer;
