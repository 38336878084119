import { Check, Copy } from '@phosphor-icons/react';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export interface CopyBtnProps {
  text: string;
  size?: number;
  className?: string;
}

const CopyToClipboardBtn = (props: CopyBtnProps) => {
  const { text, size, className } = props;
  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className='my-auto flex'>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <button type='button' className={`inline ${className || ''} border-0`}>
          {copied ? (
            <Check size={size || 20} className='text-green-500' />
          ) : (
            <Copy size={size || 20} className='text-gray-500' />
          )}
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default CopyToClipboardBtn;
