import { DigitalAsset } from '../../../api/dtos/address';
import { uniq } from 'lodash';
import { Currencies } from '../../../utils/constants/constants';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import classNames from 'classnames';
import { ICurrencyType } from '../../../utils/constants/types';

interface ICurrencyButtonsProps {
  currency: number;
  setCurrency: (currency: number) => void;
  digitalAssets: DigitalAsset[];
}

const CurrenciesButtons: React.FC<ICurrencyButtonsProps> = ({ currency, setCurrency, digitalAssets }) => {
  const customerCurrencies = ((): ICurrencyType[] => {
    if (digitalAssets?.length) {
      return uniq(digitalAssets.map((item) => Currencies.find((c) => c.value === item.currency)));
    }
    return [] as ICurrencyType[];
  })();

  const onClickCurrency = (c: number) => {
    if (currency === c) {
      setCurrency(null);
      return;
    }
    setCurrency(c);
  };

  return (
    <div className='flex flex-wrap'>
      {customerCurrencies.map((c) => (
        <div
          key={c.value}
          className={classNames(
            'mx-2 my-auto mb-2 flex cursor-pointer rounded-xl border px-2 py-px hover:border-gray-400',
            { 'bg-blue-600 text-white': currency === c.value }
          )}
          onClick={() => onClickCurrency(c.value)}>
          <CurrencyBadge currency={c.value} label={c.currency_short} transparent />
        </div>
      ))}
    </div>
  );
};

export default CurrenciesButtons;
