import { FC } from 'react';
import { AppliedFiltersBadge } from '../../ui';
import { getCurrencyFromCode } from '../../utils/helpers/currency';

interface CurrencyAppliedFiltersProps {
  currencies: number[];
  setCurrencies: (currencies: number[]) => void;
}

const CurrencyAppliedFilters: FC<CurrencyAppliedFiltersProps> = (props) => {
  const { currencies, setCurrencies } = props;
  if (!currencies) return null;

  const handleRemove = (currency: number) => {
    setCurrencies(currencies.filter((c) => c !== currency));
  };

  return (
    <>
      {currencies.map((c) => (
        <AppliedFiltersBadge key={c} onRemove={() => handleRemove(c)}>
          Blockchain: {getCurrencyFromCode(c).label}
        </AppliedFiltersBadge>
      ))}
    </>
  );
};

export default CurrencyAppliedFilters;
