import classNames from 'classnames';
import React from 'react';
import { FC } from 'react';

export interface IReportTableProps {
  tableData: {
    label: React.ReactNode;
    value: React.ReactNode | { label: React.ReactNode; value: React.ReactNode }[];
  }[];
  title: string;
}

const ReportTable: FC<IReportTableProps> = ({ tableData, title }) => {
  return (
    <div className='mb-4 rounded-b-sm'>
      <div className='rounded-t-sm border border-gray-200 bg-gray-200 px-3 py-2.5 text-2xs font-bold'>
        {title}
      </div>

      {tableData.map((item, index) => (
        <div
          key={index}
          className='grid break-inside-avoid grid-cols-3 border-x border-gray-300 text-2xs text-gray-500 [&>*]:px-2 [&>*]:py-1'>
          <div className='col-span-3 col-start-1 border-b border-gray-200 !p-0' />
          <div className={classNames('col-start-1', Array.isArray(item.value) ? 'mb-auto' : 'my-auto')}>
            {item.label}
          </div>
          {Array.isArray(item.value)
            ? item.value.map((subItem, subIndex) => (
                <React.Fragment key={subIndex}>
                  <div className='col-start-2 my-auto text-gray-500'>{subItem.label}</div>
                  <div className='my-auto font-semibold text-gray-900'>{subItem.value}</div>
                </React.Fragment>
              ))
            : item.value}
          <div className='col-span-3 col-start-1 border-b border-gray-200 !p-0' />
        </div>
      ))}
    </div>
  );
};

export default ReportTable;
