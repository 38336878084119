import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../../FilterPanel';
import EntityFilter from '../../../Filters/EntityFilter';
import EntitySubtypeFilter from '../../../Filters/EntitySubtypeFilter';
import { Search } from '../../../ui/components/Search';
interface CounterpartyFilterProps {
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
}

export interface IFilters {
  entities: string[];
  entitySubtypes?: string[];
  entityName?: string;
}

export const defaultStateFilters: IFilters = {
  entities: [],
  entitySubtypes: [],
  entityName: '',
};

const CounterpartyFilter: FC<CounterpartyFilterProps> = ({ filters, onApply }) => {
  const [entities, setEntities] = useState<string[]>([]);
  const [entitySubtypes, setEntitySubtypes] = useState<string[]>([]);
  const [entityName, setEntityName] = useState<string>('');

  const handleApply = () => {
    const newFilters = {
      entities,
      entitySubtypes,
      entityName,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setEntities([]);
    setEntitySubtypes([]);
    setEntityName('');
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    setEntities(filters.entities);
    setEntitySubtypes(filters.entitySubtypes);
    setEntityName(filters.entityName);
  }, [filters]);

  return (
    <FilterPanel onApply={handleApply} onReset={onReset}>
      <EntityFilter value={entities} setValue={setEntities} />
      <EntitySubtypeFilter value={entitySubtypes} setValue={setEntitySubtypes} />
      <Search
        label='Entity name'
        placeholder='Enter Entity Name ...'
        value={entityName}
        setValue={setEntityName}
      />
    </FilterPanel>
  );
};

export default CounterpartyFilter;
