import { FC } from 'react';
import { AppliedFiltersBadge } from '../../ui';
import { useQuery } from 'react-query';
import { profileApi } from '../../api/profile';

interface UserAppliedFiltersProps {
  users: number[];
  setUsers: (currencies: number[]) => void;
}

const UserAppliedFilters: FC<UserAppliedFiltersProps> = (props) => {
  const { users, setUsers } = props;
  const { data: usersData } = useQuery(['userFilter.getUsers'], () => profileApi.getCaseUsers(), {
    enabled: !!users?.length,
  });

  const getUser = (id: number) => {
    return usersData?.data?.results?.find((user) => user.id === id);
  };

  if (!users || !users.length) return null;

  const handleRemove = (user: number) => {
    setUsers(users.filter((c) => c !== user));
  };

  return (
    <>
      {users.map((c) => (
        <AppliedFiltersBadge key={c} onRemove={() => handleRemove(c)}>
          User: {getUser(c).full_name}
        </AppliedFiltersBadge>
      ))}
    </>
  );
};

export default UserAppliedFilters;
