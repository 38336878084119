import * as React from 'react';

import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import { isCurrencyDeprecated } from '../../../utils/helpers/currency';

import { useRouter } from '../../../modules/router/RouterProvider';

import { Checkbox } from '../../../ui';
import CurrencyBadge from '../../../components/ui/components/Badge/CurrencyBadge';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import DigitalAssets from '../../../components/ui/components/Currency/DigitalAssets';
import RisksDetected from '../../../components/common/RisksDetected';
import TransactionValue from '../../../components/Transaction/TransactionValue';

import OriginatorBeneficiary from '../../../components/common/OriginatorBeneficiary';
import { ITransactionResponse } from '../../../../src/api/dtos/transaction';
import { Date } from '../../../components/ui/components/Date';

interface ITransactionData {
  transactions: ITransactionResponse[];
  selectAllTransactions?: (force?: boolean) => void;
  selectedTransactions?: number[];
  selectTransaction?: (id: number) => void;
  type?: string;
}

export const useGetTransactionData = ({
  transactions,
  selectAllTransactions,
  selectedTransactions,
  selectTransaction,
  type = 'page',
}: ITransactionData) => {
  const screenApi = useScreenApi();
  const { navigate } = useRouter();

  return {
    headerData: [
      ...(type !== 'search'
        ? [
            <Checkbox
              key={0}
              className='w-0 rounded'
              onChange={() => selectAllTransactions()}
              checked={selectedTransactions.length === transactions.length && transactions.length !== 0}
            />,
          ]
        : []),
      'Transaction',
      'Digital Assets',
      'Originator',
      'Beneficiary',
      'Date Added',
      'Transaction Date',
      ...(type === 'search' ? ['Last Screened At'] : []),
      <div key={8} className='flex justify-end'>
        Volume
      </div>,
      <div key={9} className='flex justify-end'>
        Value USD
      </div>,
      'Risk Level',
      <div key={10} id='open-alerts' className='flex justify-center'>
        Open Alerts
      </div>,
    ],
    rowData: transactions
      ? transactions.map((transaction, index) => {
          return {
            id: transaction.identifier,
            currency: transaction.currency,
            customerId: transaction.customer_id,
            data: [
              ...(type !== 'search'
                ? [
                    <Checkbox
                      key={0}
                      className='w-0 rounded'
                      onChange={(e) => {
                        e.stopPropagation();
                        selectTransaction(transaction.id);
                      }}
                      checked={selectedTransactions.includes(transaction.id)}
                    />,
                  ]
                : []),
              <IdentifierEllipsis
                key={1}
                identifier={transaction.identifier}
                limit={16}
                className='text-blue-500'
                includedInCase={transaction.is_included_in_case}
                clickable
                copyable
              />,
              <div key={2} className='flex cursor-pointer gap-1'>
                {transaction.digital_assets.length ? (
                  <DigitalAssets digitalAssets={transaction.digital_assets} />
                ) : (
                  <CurrencyBadge
                    key={index}
                    currency={transaction.currency}
                    label={transaction.currency_short}
                  />
                )}
              </div>,
              <OriginatorBeneficiary key={3} transaction={transaction} type={'originator'} />,
              <OriginatorBeneficiary key={4} transaction={transaction} type={'beneficiary'} />,
              <Date key={transaction.id} date={transaction.created_at} type='duration' />,
              <Date key={transaction.id} date={transaction.block_date_time} type='duration' />,
              ...(type === 'search'
                ? [<Date key={transaction.id} date={transaction.last_screened_time} type='duration' />]
                : []),
              <div key={8} className='flex justify-end'>
                <TransactionValue transaction={transaction} type='value' />
              </div>,
              <div key={9} className='flex justify-end'>
                <TransactionValue transaction={transaction} type='value_usd' />
              </div>,
              <RisksDetected
                key={7}
                risk={transaction.risk_level}
                risksDetected={transaction.risks_detected}
                isAddress={false}
                grayScale={type === 'search'}
              />,
              <div key={10} id='open-alerts' className='flex justify-center'>
                {transaction.open_alerts}
              </div>,
            ],
            onClick: () => {
              isCurrencyDeprecated(transaction.currency)
                ? navigate(`/transactions/${transaction.id}`)
                : screenApi({
                    identifier: transaction.identifier,
                    currency: transaction.currency,
                    customer_id: transaction.customer_id,
                    type: transaction.type,
                    address: transaction.address,
                    entityType: 'transactions',
                  });
            },
          };
        })
      : [],
  };
};
