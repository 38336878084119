import { FC } from 'react';
import { conditionVariables, getRule } from '../../utils/constants/rules';
import capitalize from 'lodash/capitalize';
import { entitySubtypeWithType, entityTypes } from '../../utils/helpers/entity';
import { sentiments } from '../../ui/utils/constants';
import { sanitizeHtml } from '../../utils/helpers/common';
import { IRuleCondition } from '../../api/dtos/policies';

interface IRuleProps extends IRuleCondition {
  type: 'address' | 'transaction';
  ruleType: number;
  setLabel?: boolean;
}

const Rule: FC<IRuleProps> = (props) => {
  const { type, ruleType, setLabel = false, ...ruleProps } = props;
  const updatedRuleProps = {};
  // Update rule props

  const entityType = Object.keys(entityTypes);

  // riskTypes
  const riskTypesKeys = [
    'risk_types',
    'risk_types_1',
    'risk_types_2',
    'indirect_risk_types',
    'inactivity_types',
  ];
  riskTypesKeys.forEach((key) => {
    let updated_key = key;
    if ([7, 10, 11, 13, 14, 15, 16].includes(ruleType) && key !== 'inactivity_types') {
      updated_key = 'risk_types_1';
    } else if (ruleType === 17) {
      updated_key = 'risk_types_2';
    } else if ([18, 19, 20].includes(ruleType)) {
      updated_key = 'indirect_risk_types';
    }
    if (ruleProps[key]) {
      if (ruleProps[key].length > 1) {
        updatedRuleProps[updated_key] = conditionVariables[updated_key].options['12'];
      } else if (ruleProps[key][0] === 1) {
        updatedRuleProps[updated_key] = conditionVariables[updated_key].options['1'];
      } else if (ruleProps[key][0] === 2) {
        updatedRuleProps[updated_key] = conditionVariables[updated_key].options['2'];
      }
    }
  });
  if (ruleProps['entity_names']) {
    updatedRuleProps['entity_names'] = ruleProps['entity_names'].join(', ');
  }
  if (ruleProps['entity_types']) {
    updatedRuleProps['entity_types'] = ruleProps['entity_types']
      .map((type) => entityType[parseInt(type) - 1])
      .join(', ');
  }
  if (ruleProps.sentiments) {
    updatedRuleProps['sentiments'] = ruleProps.sentiments
      .map((sentiment) => sentiments.find((s) => s.value === parseInt(sentiment))?.content)
      .join(', ');
  }
  if (ruleProps['entity_subtypes']) {
    updatedRuleProps['entity_subtypes'] = ruleProps['entity_subtypes']
      .map((subtype) => entitySubtypeWithType[subtype])
      .join(', ');
  }
  if (ruleProps['threshold_amount_usd']) {
    if (Number(ruleProps['threshold_amount_usd']) === 0) {
      if ([18, 19].includes(ruleType)) {
        updatedRuleProps['threshold_amount_usd'] = 'for at least US$1';
      } else {
        updatedRuleProps['threshold_amount_usd'] = 'for at least any USD amount';
      }
    } else {
      updatedRuleProps['threshold_amount_usd'] =
        `for at least US$${Number(ruleProps['threshold_amount_usd'])?.toFixed(2)}`;
    }
  }
  if (!ruleProps['upper_threshold_amount_usd']) {
    updatedRuleProps['upper_threshold_amount_usd'] = 'up to any USD amount';
  } else {
    updatedRuleProps['upper_threshold_amount_usd'] = `at most US$${ruleProps['upper_threshold_amount_usd']}`;
  }
  if (ruleProps['taint_percent_threshold']?.toString()) {
    updatedRuleProps['taint_percent_threshold'] =
      `has at least ${ruleProps['taint_percent_threshold']}% taint`;
  }
  if (ruleProps['taint_percent_upper_threshold']) {
    updatedRuleProps['taint_percent_upper_threshold'] =
      `has at most ${ruleProps['taint_percent_upper_threshold']}% taint`;
  } else {
    updatedRuleProps['taint_percent_upper_threshold'] = 'up to any taint %';
  }
  const daysKeys = ['analysis_window', 'dormancy_window', 'address_age'];
  daysKeys.forEach((key) => {
    if (ruleProps[key]) {
      updatedRuleProps[key] = `${ruleProps[key]} days`;
    }
  });
  const minutesKeys = ['analysis_window_2', 'transit_window'];
  minutesKeys.forEach((key) => {
    if (ruleProps[key] || ruleProps['analysis_window']) {
      updatedRuleProps[key] =
        `${key === 'analysis_window_2' ? ruleProps['analysis_window'] : ruleProps[key]} minutes`;
    }
  });

  if (ruleProps['payment_amount_percent']) {
    updatedRuleProps['payment_amount_percent'] = `at least ${ruleProps['payment_amount_percent']}%`;
  }
  if (ruleProps['counterparty_threshold']) {
    updatedRuleProps['counterparty_threshold'] = `at least ${ruleProps['counterparty_threshold']}`;
  }
  if (ruleProps['transaction_amount_threshold']) {
    updatedRuleProps['transaction_amount_threshold'] =
      `at least US$ ${ruleProps['transaction_amount_threshold']}%`;
  }
  if (ruleProps['transaction_count_threshold']) {
    updatedRuleProps['transaction_count_threshold'] =
      `${ruleProps['transaction_count_threshold']} time${ruleProps['transaction_count_threshold'] === 1 ? '' : 's'}`;

    // mapping the key to other key present in conditionsList
    updatedRuleProps['lower_transaction_count_threshold'] =
      `at least ${ruleProps['transaction_count_threshold']}`;
    updatedRuleProps['transaction_count_threshold_2'] = `${ruleProps['transaction_count_threshold']}`;
  }
  if (ruleProps['lower_transaction_count_threshold']) {
    updatedRuleProps['lower_transaction_count_threshold'] =
      `at least ${ruleProps['lower_transaction_count_threshold']}`;
  }
  if (ruleProps['transaction_count_upper_threshold']) {
    updatedRuleProps['transaction_count_upper_threshold'] =
      `at most ${ruleProps['transaction_count_upper_threshold']}`;
  }
  const usdValKeys = ['lower_bound', 'upper_bound'];
  usdValKeys.forEach((key) => {
    if (ruleProps[key]) {
      updatedRuleProps[key] = `US$ ${ruleProps[key]}`;
    }
  });
  const otherKeys = ['multiplier_threshold'];
  otherKeys.forEach((key) => {
    updatedRuleProps[key] = ruleProps[key];
  });

  return (
    <>
      <div
        className='my-1 text-xs leading-6'
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(
            getRule(ruleType, setLabel, { item_type: capitalize(type), ...updatedRuleProps })
          ),
        }}></div>
    </>
  );
};

export default Rule;
