import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { Api } from './api';
import {
  IWorkspaceInviteRequest,
  IWorkspaceMembersRequest,
  IWorkspaceMembersResponse,
  IWorkspaceMembersResult,
  IWorkspaceUpdateMemberRequest,
  IWorkspacesLiteResponse,
  IWorkspacesResponse,
  IStageResponse,
  IWorkFlowStageRequest,
  IStageResultResponse,
  IWorkspaceMembersRequestLite,
} from './dtos/workspaces';
import { getNextPageParam } from '../utils/helpers/helperFunctions';

export class WorkspacesApi extends Api {
  readonly baseUrl: string = '/profile/api/v2/workspaces/';

  readonly getWorkspaces = () => {
    return this.http.get<IWorkspacesResponse>(this.route(''));
  };

  readonly getWorkspacesLite = () => {
    return this.http.get<IWorkspacesLiteResponse>(this.route('lite/'));
  };

  readonly getWorkspaceMembers = (params: IWorkspaceMembersRequest) => {
    return this.http.get<IWorkspaceMembersResponse>(this.route(`members/`), {
      params,
    });
  };

  readonly getStageOptions = () => {
    return this.http.get<IStageResponse>(this.route(`/workflow-stages/`));
  };

  readonly getWorkFlowStages = (params: IWorkFlowStageRequest) => {
    const { workspace_id, stage_id } = params;
    return this.http.get<IStageResultResponse>(this.route(`/workflow-stages/${stage_id}/`), {
      params: { workspace_id },
    });
  };

  readonly getWorkSpacesMembers = (params: IWorkspaceMembersRequestLite) => {
    const { offset, q, is_active } = params;
    return this.http.get(this.route('members/lite/'), { params: { offset, q, is_active } });
  };

  readonly updateWorkspaceMember = (params: IWorkspaceUpdateMemberRequest) => {
    return this.http.put<IWorkspaceMembersResult>(this.route(`members/${params.id}/`), params);
  };

  readonly inviteWorkspaceMembers = (params: IWorkspaceInviteRequest) => {
    return this.http.post(this.route(`members/`), params);
  };
}

export const workspacesApi = new WorkspacesApi();

export const useWorkspaceMembersGet = (
  params: IWorkspaceMembersRequest,
  options?: UseInfiniteQueryOptions
) => {
  const key = ['WorkspacesApi', 'getWorkspaceMembers', params];
  return useInfiniteQuery(
    key,
    ({ pageParam }) => workspacesApi.getWorkspaceMembers({ ...params, offset: pageParam }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
};

export const useWorkspacesGet = (options?: UseQueryOptions) => {
  const key = ['WorkspacesApi', 'getWorkspaces'];
  return useQuery(key, () => workspacesApi.getWorkspaces(), options as unknown);
};

export const useWorkspaceLiteGet = (options?: UseQueryOptions) => {
  const key = ['WorkspacesApi', 'getWorkspacesLite'];
  return useQuery(key, () => workspacesApi.getWorkspacesLite(), options as unknown);
};

export const useWorkSpacesGetStageOptions = (options?: UseQueryOptions) => {
  const key = ['WorkspacesApi', 'getStageOptions'];
  const { data: result, isLoading: isLoading } = useQuery(key, () => workspacesApi.getStageOptions(), {
    ...options,
  } as unknown);

  return { result, isLoading };
};

export const useWorkSpaceGetWorkFlowStages = (params: IWorkFlowStageRequest, options?: UseQueryOptions) => {
  const key = ['WorkspacesApi', params, 'getWorkFlowStages'];
  const result = useQuery(key, () => workspacesApi.getWorkFlowStages({ ...params }), {
    ...options,
  } as unknown);
  return result;
};

export const useWorkSpaceGetWorkFlowMembers = (
  params: IWorkspaceMembersRequestLite,
  options?: UseQueryOptions
) => {
  const key = [
    'WorkspacesApi',
    'getWorkSpacesMembers',
    params?.id,
    params.q,
    params.offset,
    params.is_active,
  ];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => workspacesApi.getWorkSpacesMembers({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};
