import { FC } from 'react';
import AppliedFilters, { BasicAppliedFilter } from '../AppliedFilters';
import { IFilters, defaultStateFilters } from './PolicyListFilter';
import { isEmpty, every, startCase } from 'lodash';
import { policyCategoryOptions, policyTypeOptions } from '../../utils/constants/rules';

interface PolicyListAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  ordering: string;
  setOrdering: (ordering: string) => void;
}

const PolicyListAppliedFilters: FC<PolicyListAppliedFiltersProps> = (props) => {
  const { filters, setFilters, ordering, setOrdering } = props;
  if (every(filters, (v) => isEmpty(v)) && !ordering) return null;
  const getOrderingLabel = (ordering: string) => {
    const field = ordering.replace(/^-/, '');
    if (field.includes('name')) return `${startCase(field)} - ${ordering.startsWith('-') ? 'Z->A' : 'A->Z'}`;
    return `${startCase(field)} - ${ordering.startsWith('-') ? 'Oldest' : 'Latest'}`;
  };
  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters(defaultStateFilters);
      }}>
      <BasicAppliedFilter
        label='Policy Status'
        single
        value={[startCase(filters.policyStatus)]}
        setValue={(v) => {
          setFilters({ ...filters, policyStatus: v[0] });
        }}
      />
      <BasicAppliedFilter
        label='Policy Type'
        single
        value={[policyTypeOptions.find((o) => o.value === filters.policyType)?.label]}
        setValue={(v) => {
          setFilters({ ...filters, policyType: v[0] });
        }}
      />
      <BasicAppliedFilter
        label='Category'
        single
        value={[policyCategoryOptions.find((o) => o.value === filters.category)?.label]}
        setValue={(v) => {
          setFilters({ ...filters, category: v[0] });
        }}
      />
      {ordering && (
        <BasicAppliedFilter
          label='Sort By'
          single
          value={[getOrderingLabel(ordering)]}
          setValue={() => setOrdering('')}
        />
      )}
    </AppliedFilters>
  );
};

export default PolicyListAppliedFilters;
