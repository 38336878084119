import { useRouter } from '../../../modules/router/RouterProvider';
import { Button, RiskBadge } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { flattenInfiniteQueryResult } from '../../../utils/helpers/react-query.helper';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { Plus } from '@phosphor-icons/react';
import { ellipsis } from '../../../utils/helpers/helperFunctions';
import CaseAddEntitiesModal from '../CaseAddEntitiesModal';
import { useState } from 'react';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import { ICaseResponse, ICaseTransactionResponse } from '../../../api/dtos/case';
import DeleteCaseEntity from './DeleteCaseEntity';
import { AxiosResponse } from 'axios';
import { UseInfiniteQueryResult } from 'react-query';

interface EntityTableProps {
  caseData: ICaseResponse;
  transactionsQuery?: UseInfiniteQueryResult<AxiosResponse<ICaseTransactionResponse>>;
}

const TransactionsTable: React.FC<EntityTableProps> = ({ caseData, transactionsQuery }) => {
  const { navigate } = useRouter();
  const screenApi = useScreenApi();

  const [modal, setModal] = useState(false);

  const [count, transactions] = flattenInfiniteQueryResult(transactionsQuery?.data);

  if (!transactions?.length && !transactionsQuery.isFetching) return null;

  const headerData = ['Risk', 'Currency', 'Transaction', 'Customer ID', 'Type', 'Alerts', 'Added By', ''];

  const rows = transactions?.map((transaction) => {
    return {
      id: transaction.id,
      data: [
        <RiskBadge key={transaction.id} risk={transaction.risk_level} />,
        <CurrencyBadge transparent key={transaction.id} currency={transaction.currency} />,
        <IdentifierEllipsis key={transaction.id} identifier={transaction.identifier} limit={6} copyable />,
        ellipsis(transaction.customer_id || '', 8),
        transaction.type === 1 ? 'Deposit' : transaction.type === 2 ? 'Withdrawal' : '',
        transaction.alerts,
        transaction.added_by_name,
        <DeleteCaseEntity
          key={transaction.id}
          type='transaction'
          caseId={caseData.id}
          entityId={transaction.id}
          entityLabel={transaction.identifier}
        />,
      ],
      onClick: async () => {
        const result = await screenApi(
          {
            identifier: transaction.identifier,
            currency: transaction.currency,
            customer_id: transaction.customer_id,
            type: transaction.type,
            address: transaction.address,
            entityType: 'transactions',
          },
          false
        );
        navigate(`/transactions/${result.id}`, {
          caseId: caseData.case_id.toString(),
          entityId: transaction.id.toString(),
          refCase: caseData.id.toString(),
        });
      },
    };
  });

  return (
    <div className=''>
      <Table
        title='Transactions'
        headerActions={
          <Button variant='tertiary' onClick={() => setModal(true)} iconStart={<Plus size={16} />}>
            Add
          </Button>
        }
        count={count}
        headerData={headerData}
        rows={rows}
        border
        height={200}
        isLoading={transactionsQuery.isFetching}
      />
      <CaseAddEntitiesModal
        caseId={caseData.id}
        type='transaction'
        isOpen={modal}
        onClose={() => setModal(false)}
      />{' '}
    </div>
  );
};

export default TransactionsTable;
