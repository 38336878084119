import React from 'react';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import SingleSelect, { IOption } from './SingleSelect';

type SingleSelectControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
  control: ControllerProps<TFieldValues>['control'];
  defaultValue?: IOption | null;
  options: IOption[];
  placeholder?: string;
  getCustomLabel?: (option: IOption) => React.ReactNode;
  getCustomOptionLabel?: (option: IOption) => React.ReactNode;
  isCloseHidden?: boolean;
};

const SingleSelectController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: SingleSelectControllerProps<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <SingleSelect
          label={props.placeholder}
          options={props.options}
          placeholder={props.placeholder}
          handleChange={field.onChange}
          getCustomLabel={props.getCustomLabel}
          getCustomOptionLabel={props.getCustomOptionLabel}
          value={field.value}
          className='mt-1'
          {...props}
        />
      )}
    />
  );
};

export default SingleSelectController;
