import * as React from 'react';
import { CaretDown, CaretRight, MagnifyingGlass, Plus } from '@phosphor-icons/react';
import { unescape } from 'lodash';

import { useRouter } from '../../../modules/router/RouterProvider';

import { useCurrentRole } from '../../../utils/helpers/common';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { IClassifierResult } from '../../../api/dtos/classifier';
import { usePolicyGetPolicyRuleList } from '../../../api/policies';

import { BasicBadge, Button, RiskBadge } from '../../../ui';
import { Search } from '../../ui/components/Search';
import Table from '../../../ui/components/Table/Table';
import PolicyStatusBadge from '../../../ui/components/Badges/StatusBadge/PolicyStatusBadge';

import Rule from '../../Risks/Rule';
import PolicyRuleListAppliedFilters from './PolicyRuleListAppliedFilters';
import PolicyRuleListFilter, { IFilters, defaultStateFilters } from './PolicyRuleListFilter';

interface Props {}

const PolicyRuleList: React.FC<Props> = () => {
  const { navigate, getParams } = useRouter();
  const { isAdmin } = useCurrentRole();

  const [searchValue, setSearchValue] = React.useState('');
  const [query, setQuery] = React.useState('');
  const [filters, setFilters] = React.useState(defaultStateFilters as IFilters);

  const [isExpanded, setIsExpanded] = React.useState<string[]>([]);
  const params = getParams();
  const policyId = params.policyId;
  const rulesQuery = usePolicyGetPolicyRuleList({ ...filters, id: policyId, q: query });
  const headerData = ['Rule', 'Risk Level', 'Risk Type', 'Custom Tags', 'Status', ''];

  const data = flattenInfiniteQueryResult(rulesQuery?.data)[1];

  const onClickRule = (rule: IClassifierResult) => {
    navigate(`/settings/workspace/policies/${rule.policy}/rules/${rule.uuid}`);
  };

  const rows = data?.map((rule) => ({
    id: rule.uuid,
    data: [
      <div key={rule.uuid} className='flex gap-1'>
        <div className='mt-1'>{isExpanded.includes(rule.uuid) ? <CaretDown /> : <CaretRight />}</div>
        <div className='flex max-w-xl flex-col'>
          <div>{unescape(rule.rule_name)}</div>
          {isExpanded.includes(rule.uuid) && (
            <div>
              {rule.rule_conditions.map((r, i) => (
                <div key={i} className='my-1 text-xs font-normal leading-6 text-gray-500'>
                  <Rule
                    ruleType={r.rule_condition_type}
                    type={(rule.policy_type_verbose.toLowerCase() as 'address') || 'transaction'}
                    {...r}
                  />
                  {i !== rule.rule_conditions.length - 1 && (
                    <div className='font-medium text-gray-500'>AND</div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>,
      <RiskBadge key={rule.uuid} risk={rule.alert_level} className='mt-1' />,
      <div key={rule.uuid} className='flex gap-1'>
        {rule.risk_types.map((riskType, i) => (
          <BasicBadge key={i} className='bg-gray-100 text-xs'>
            {riskType.risk_type_verbose}
          </BasicBadge>
        ))}
      </div>,
      <BasicBadge
        key={rule.uuid}
        className={`${rule.custom_tag_name ? 'bg-purple-100' : ''} !text-2xs text-purple-700`}>
        {rule.custom_tag_name}
      </BasicBadge>,
      <PolicyStatusBadge key={rule.uuid} isActive={rule.is_enabled} />,
      isAdmin ? (
        <Button key={rule.uuid} onClick={() => onClickRule(rule)} variant='tertiary'>
          Edit
        </Button>
      ) : null,
    ],
    className: 'hover:bg-gray-100',
  }));

  const onClickRow = (id: string) => {
    const index = isExpanded.findIndex((i) => i === id);
    if (index === -1) {
      setIsExpanded([...isExpanded, id]);
    } else {
      const newExpanded = [...isExpanded];
      newExpanded.splice(index, 1);
      setIsExpanded(newExpanded);
    }
  };

  const [isAllExpanded, setIsAllExpanded] = React.useState(false);

  React.useEffect(() => {
    if (isAllExpanded) {
      setIsExpanded(data.map((d) => d.uuid));
    } else {
      setIsExpanded([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllExpanded]);

  React.useEffect(() => {
    if (rulesQuery.isFetchingNextPage === false) {
      if (isAllExpanded === true && rulesQuery.data && rulesQuery.data.pages) {
        setIsExpanded((prevItems) => [
          ...prevItems,
          ...rulesQuery.data.pages[rulesQuery.data.pages.length - 1].data.results.map((d) => d.uuid),
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rulesQuery.isFetchingNextPage]);

  const onSearchKeydown = (e) => {
    if (e.key === 'Enter') {
      setSearchValue(e.target.value);
      setQuery(e.target.value);
    }
  };

  const handleOnBlur = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div>
      <div className='flex items-center justify-between px-4 pt-4'>
        <p className='font-semibold'>Rules for this Policy</p>
        <div className='flex gap-2'>
          <Search
            value={searchValue}
            setValue={setSearchValue}
            showXButton={false}
            onKeyDown={onSearchKeydown}
            onBlur={handleOnBlur}
          />
          <Button type='button' variant='primary' onClick={() => setQuery(searchValue)} className='px-3'>
            <MagnifyingGlass size={18} />
          </Button>
          <PolicyRuleListFilter filters={filters} onApply={setFilters} />
          {rows?.length !== 0 && (
            <Button type='button' variant='tertiary' onClick={() => setIsAllExpanded(!isAllExpanded)}>
              {isAllExpanded ? 'Collapse' : 'Expand'} All
            </Button>
          )}
          <Button
            type='button'
            variant='tertiary'
            iconStart={<Plus />}
            onClick={() => navigate(`/settings/workspace/policies/${policyId}/rules/add/`)}>
            Add Rule
          </Button>
        </div>
      </div>
      <Table
        headerData={headerData}
        rows={rows}
        isCountVerbose
        heightOffset={120}
        onClickRow={onClickRow}
        headerWidths={[32, 8, 15, 10, 8, 6]}
        appliedFilters={<PolicyRuleListAppliedFilters filters={filters} setFilters={setFilters} />}
        {...buildInfiniteQueryTableProps(rulesQuery)}
      />
    </div>
  );
};

export default PolicyRuleList;
