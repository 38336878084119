export const tokenAccountError = 'Current address is a token account. Please screen owner account address';

export const errors = {
  no_active_account_found_with_the_given_credentials: 'Invalid email or password',
  user_is_blocked: 'User account is blocked. Please contact your administrator.',
  no_organization_found: 'No organization found for given email address',
  captcha_failed: 'Error while verifying reCAPATCHA. Please try again after {value} seconds',
  there_is_no_active_user_associated_with_this_e_mail_address_or_the_password_can_not_be_changed:
    'There is no active user associated with this e-mail address or the password can not be changed',
  internal_error: 'Internal Error!',
  screened_failed: 'Failed to rescreen the {value}. Please try again after some time.',
  currency_deprecated: 'This blockchain is no longer supported',
  cannot_edit_rules: 'Custom Rules feature is not enabled. Please contact support team to subscribe.',
  cannot_enable_behaviour_rules:
    'Behaviour Rules feature is not enabled. Please contact support team to subscribe.',
  something_went_wrong: 'Something went wrong. Please reach out to the support team.',
};
