import { FC, useRef, useState } from 'react';
import Sidebar from '../../ui/components/Sidebar';
import Tab from '../../ui/components/Tab/Tab';
import { digitalAssetsApi, useDigitalAssetsGet } from '../../../api/header/digitalAssets';
import Table from '../../../ui/components/Table/Table';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { BasicBadge, Button } from '../../../ui';
import TokenSvg from '../../../assets/currency/token.svg';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { isLiteCurrency } from '../../../utils/helpers/currency';
import { Currencies } from '../../../utils/constants/constants';
import SupportedAssets from './SupportedAssets';
import { Search } from '../../ui/components/Search';
import DigitalAssetsFilter, { IFilters, defaultStateFilters } from './DigitalAssetsFilter';
import { CaretUp, Download } from '@phosphor-icons/react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import saveBlobToFile from '../../../utils/helpers/saveBlobToFile';
import DigitalAssetsAppliedFilters from './DigitalAssetsAppliedFilters';
import { CaretDown } from '@phosphor-icons/react/dist/ssr';
interface SupportedDigitalAssetsProps {
  isOpen: boolean;
  onClose: () => void;
}

const SupportedDigitalAssets: FC<SupportedDigitalAssetsProps> = ({ isOpen, onClose }) => {
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [blockchainSearch, setBlockchainSearch] = useState<string>('');
  const [blockchainOrder, setBlockchainOrder] = useState<'asc' | 'desc'>('asc');

  const digitalAssetsQuery = useDigitalAssetsGet(
    { blockchains: filters.blockchains, q: searchQuery },
    { enabled: isOpen }
  );
  const [, tokenData] = flattenInfiniteQueryResult(digitalAssetsQuery?.data);

  const toastId = useRef(null);
  const { mutate, isLoading } = useMutation(digitalAssetsApi.downloadDigitalAssets, {
    onSuccess: (data) => {
      toast.update(toastId.current, {
        render: 'Exported transactions CSV successfully',
        type: 'success',
        autoClose: 3000,
        closeButton: true,
      });
      saveBlobToFile(data.data, 'supported-digital-assets.csv');
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'Download failed. Please try again later.',
        type: 'error',
        autoClose: 3000,
        closeButton: true,
      });
    },
  });

  const onExport = () => {
    toastId.current = toast.success('Your file is being prepared. Please wait for a few seconds', {
      autoClose: false,
      closeButton: false,
    });
    mutate();
  };

  const tokenRows = tokenData?.map((token) => ({
    id: token.name,
    data: [
      <div key={token.name} className='flex'>
        <BasicBadge className='bg-gray-100 text-gray-800'>
          <img className='mr-1' width={16} src={TokenSvg} />
          {token.symbol}
        </BasicBadge>
        <span className='my-auto ml-2 text-3xs text-gray-500'>{token.name}</span>
      </div>,
      <SupportedAssets key={token.name} blockchains={token.blockchains} />,
    ],
  }));

  const blockchainHeader = [
    'Sr. No.',
    'Blockchains',
    <span
      className='flex cursor-pointer'
      onClick={() => setBlockchainOrder(blockchainOrder === 'asc' ? 'desc' : 'asc')}
      key={'coverage'}>
      Coverage
      {blockchainOrder === 'asc' ? (
        <CaretUp size={16} className='my-auto ml-1' />
      ) : (
        <CaretDown size={16} className='my-auto ml-1' />
      )}
    </span>,
  ];
  const blockchainRows = Currencies.filter((currency) =>
    currency.label.toUpperCase().includes(blockchainSearch.toUpperCase())
  )
    .sort((a, b) => {
      if (isLiteCurrency(a.value) !== isLiteCurrency(b.value)) {
        return blockchainOrder === 'desc'
          ? isLiteCurrency(a.value)
            ? -1
            : 1
          : isLiteCurrency(a.value)
            ? 1
            : -1;
      }
      return a.value - b.value;
    })
    .map((currency, i) => ({
      id: currency.value,
      data: [
        i + 1,
        <CurrencyBadge key={currency.value} currency={currency.value} isLabelVerbose />,
        isLiteCurrency(currency.value) ? 'Lite Coverage' : 'Full Coverage',
      ],
    }));
  const tabs = [{ label: 'Tokens' }, { label: 'Blockchains' }];
  return (
    <Sidebar isOpen={isOpen} onClose={onClose} title='Supported Digital Assets'>
      <Tab values={tabs} type={'primary'}>
        <div>
          <Table
            headerData={['Token Data', 'Blockchains']}
            headerActions={
              <div className='mr-4 flex h-9'>
                <Search
                  value={searchQuery}
                  setValue={setSearchQuery}
                  className='ml-4 mr-2 w-[600px]'
                  placeholder='Search for a digital asset'
                />
                <DigitalAssetsFilter filters={filters} onApply={setFilters} />
                <Button
                  className='ml-2'
                  variant='tertiary'
                  buttonSize='sm'
                  onClick={onExport}
                  disabled={isLoading}>
                  <Download size={16} />
                </Button>
              </div>
            }
            appliedFilters={<DigitalAssetsAppliedFilters filters={filters} setFilters={setFilters} />}
            rows={tokenRows}
            className='-mx-4 h-[calc(100vh-116px)]'
            {...buildInfiniteQueryTableProps(digitalAssetsQuery)}
          />
        </div>
        <div>
          <Table
            isHeaderVisible={false}
            appliedFilters={
              <Search
                value={blockchainSearch}
                setValue={setBlockchainSearch}
                className='mx-6 my-3'
                placeholder='Search for a currency'
              />
            }
            headerData={blockchainHeader}
            className='-mx-4 h-[calc(100vh-116px)]'
            rows={blockchainRows}
          />
        </div>
      </Tab>
    </Sidebar>
  );
};

export default SupportedDigitalAssets;
