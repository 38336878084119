import { FC } from 'react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { formatDateTime } from '../../../utils/helpers/date';
import ReportTable from '../ReportTable';
import { RiskBadge } from '../../../ui';
import { ICustomerResponse } from '../../../api/dtos/customer';

interface ICustomerDetailsReportProps {
  customerData: ICustomerResponse;
  totalTransactions?: number;
  part?: number;
}

const CustomerDetailsReport: FC<ICustomerDetailsReportProps> = ({
  customerData,
  totalTransactions,
  part,
}) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  const currentTime = formatDateTime(new Date().toLocaleString('en-US', { timeZone: timezone }));

  if (!customerData) return null;

  const tableData = [
    {
      label: 'Customer ID',
      value: <div>{customerData.customer_id}</div>,
    },
    {
      label: 'Total Transactions',
      value: <div>{totalTransactions}</div>,
    },
    {
      label: 'Risk Level',
      value: (
        <div>
          <RiskBadge risk={customerData.risk_level} />
        </div>
      ),
    },
    {
      label: 'Last Txn.',
      value: (
        <span className='my-auto'>{formatDateTime(customerData.latest_transaction_time, timezone)}</span>
      ),
    },
    {
      label: 'Added On',
      value: <span className='my-auto'>{formatDateTime(customerData.created_at, timezone)}</span>,
    },
  ];

  return (
    <div>
      <h1 className='mb-1 text-[26px] font-extrabold'>Customer Report</h1>
      <code className='lead mb-3'>Customer ID: {customerData.customer_id}</code>
      <hr className='my-2' />

      <div className='text-2xs'>
        <span className='mr-5 inline-block text-gray-500'>Report Exported On</span>
        {currentTime}
      </div>
      <hr className='my-2' />

      <ReportTable title={`Part ${part}: Customer Details`} tableData={tableData} />
    </div>
  );
};

export default CustomerDetailsReport;
