import React from 'react';
import profile from '@/assets/icons/profile.svg';
import msProfile from '@/assets/icons/ms-profile.svg';
import { formatDateTime } from '../../../utils/helpers/date';
import { File } from '@phosphor-icons/react';
import { caseTypes } from './types';
import { downloadApi } from '../../../api/download';
import { useMutation } from 'react-query';
import saveBlobToFile from '../../../utils/helpers/saveBlobToFile';
import { toast } from '../../../ui/components/Toast';
import { useAuth } from '../../../modules/auth';
import styles from './Comment.module.scss';
import classNames from 'classnames';
import { sanitizeHtml } from '../../../utils/helpers/common';

interface CommentProps {
  comment: IComment;
  // allComment: IComment[];
  // index: number;
}

export interface IComment {
  type: number;
  body: string;
  comment_files: { file_key: string; file_name: string }[];
  commenter: string;
  created_at: string;
}

const Comment: React.FC<CommentProps> = ({ comment }) => {
  const isCaseAdmin = comment.commenter === 'Case Admin';
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();

  const { mutateAsync } = useMutation(downloadApi.downloadFile);

  const onClickFile = async (file: { file_key: string; file_name: string }) => {
    try {
      const result = await mutateAsync({ key: file.file_key });
      saveBlobToFile(result.data, file.file_name);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className='border-b-2 border-gray-100 px-4 pb-4'>
      <div
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment.body) }}
        className={classNames('mt-2 text-xs font-semibold', styles.container)}></div>
      <div className='mb-2 flex flex-row'>
        {comment.comment_files.map((file) => (
          <label
            key={file.file_key}
            className='mr-1 flex cursor-pointer rounded-lg border border-gray-300 px-1 text-sm'
            onClick={() => onClickFile(file)}>
            <File className='mx-1 my-auto text-gray-500' weight='fill' />
            {file.file_name}
          </label>
        ))}
      </div>
      <div className='flex justify-between text-sm'>
        <div className='flex'>
          {comment.type !== 6 && <div>{caseTypes[comment.type]}</div>}
          <img src={isCaseAdmin ? msProfile : profile} alt='profile' className='mx-2 size-6' />
          <div>{comment.commenter}</div>
        </div>
        <div className='text-gray-500'>{formatDateTime(comment.created_at, timezone)}</div>
      </div>
    </div>
  );
};

export default Comment;
