import { UseQueryOptions, useInfiniteQuery } from 'react-query';
import { Api } from '../api';
import { IDigitalAssetsRequest, IDigitalAssetsResponse } from '../dtos/header/digitalAssets';
import { getNextPageParam } from '../../utils/helpers/helperFunctions';

export class DigitalAssetsApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/digital-assets';

  readonly getDigitalAssets = (params: IDigitalAssetsRequest) => {
    return this.http.get<IDigitalAssetsResponse>(this.route(''), { params });
  };

  readonly downloadDigitalAssets = () => {
    return this.http.get(this.route('download/'));
  };
}

export const digitalAssetsApi = new DigitalAssetsApi();

export const useDigitalAssetsGet = (
  params: IDigitalAssetsRequest,
  options?: UseQueryOptions<IDigitalAssetsResponse>
) => {
  return useInfiniteQuery(
    ['DigitalAssetsApi', params],
    ({ pageParam = 0 }) => digitalAssetsApi.getDigitalAssets({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
};
