import { FC } from 'react';
import TeamMembersTable from '../../../../components/Settings/Team/TeamMembersTable';
import { TabHeader } from '../../../../components/ui/components/Header';

const OrganizationMembers: FC = () => {
  return (
    <div className='h-full'>
      <TabHeader
        heading='Organisation Members'
        subheading='You can add and view  members of your organisation'
      />
      <div className='mt-5'>
        <TeamMembersTable type='organisation' />
      </div>
    </div>
  );
};

export default OrganizationMembers;
