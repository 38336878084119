import { FC } from 'react';
import { IAggregatedResult } from '../../../api/dtos/address';
import ReportTable from '../ReportTable';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';

interface ICounterpartySummaryReportProps {
  incoming: IAggregatedResult[];
  outgoing: IAggregatedResult[];
  part?: number;
}

const CounterpartySummaryReport: FC<ICounterpartySummaryReportProps> = (props) => {
  const { incoming, outgoing, part } = props;
  if (!incoming || !outgoing) return null;

  interface ICounterpartyData {
    [tag_name_verbose: string]: {
      directIncoming?: IAggregatedResult;
      indirectIncoming?: IAggregatedResult;
      directOutgoing?: IAggregatedResult;
      indirectOutgoing?: IAggregatedResult;
    };
  }

  const counterpartyValues = (): ICounterpartyData => {
    const counterpartyData: ICounterpartyData = {};
    const indexArray = [];

    incoming.forEach((item) => {
      let temp = null;
      if (item.tag_name_verbose !== null) {
        temp = item.tag_name_verbose;
        if (!indexArray.includes(item.tag_name_verbose)) {
          counterpartyData[temp] = {};
        }
        if (item.exposure_type === 'direct') {
          counterpartyData[temp].directIncoming = item;
        } else if (item.exposure_type === 'indirect') {
          counterpartyData[temp].indirectIncoming = item;
        }
        indexArray.push(item.tag_name_verbose);
      }
    });

    outgoing.forEach((item) => {
      if (item.tag_name_verbose !== null) {
        if (!indexArray.includes(item.tag_name_verbose)) {
          counterpartyData[item.tag_name_verbose] = {};
        }
        if (item.exposure_type === 'direct') {
          counterpartyData[item.tag_name_verbose].directOutgoing = item;
        } else if (item.exposure_type === 'indirect') {
          counterpartyData[item.tag_name_verbose].indirectOutgoing = item;
        }
        indexArray.push(item.tag_name_verbose);
      }
    });

    Object.keys(counterpartyData).forEach(function (key) {
      if (Object.keys(counterpartyData[key]).length === 0) {
        delete counterpartyData[key];
      }
    });

    return counterpartyData;
  };

  const getEntityType = (counterparty: ICounterpartyData[keyof ICounterpartyData]) => {
    const incoming = counterparty.directIncoming || counterparty.indirectIncoming;
    const outgoing = counterparty.directOutgoing || counterparty.indirectOutgoing;
    // TODO: Handle for multihop true false?
    if (incoming) {
      return `${incoming.tag_type_verbose} > ${incoming.tag_subtype_verbose}`;
    } else if (outgoing) {
      return `${outgoing.tag_type_verbose} > ${outgoing.tag_subtype_verbose}`;
    }

    return '';
  };

  const tableData = Object.keys(counterpartyValues()).map((key) => {
    const counterparty = counterpartyValues()[key];
    const value = [
      {
        label: 'Entity Type',
        value: <span>{getEntityType(counterparty)}</span>,
      },
    ];

    if (counterparty.indirectIncoming) {
      value.push({
        label: 'Indirect Incoming Exposure',
        value: <CurrencyValue value={counterparty.indirectIncoming.total_value_usd} currency='USD' />,
      });
    }

    if (counterparty.directIncoming) {
      value.push({
        label: 'Direct Incoming Exposure',
        value: <CurrencyValue value={counterparty.directIncoming.total_value_usd} currency='USD' />,
      });
    }

    if (counterparty.indirectOutgoing) {
      value.push({
        label: 'Indirect Outgoing Exposure',
        value: <CurrencyValue value={counterparty.indirectOutgoing.total_value_usd} currency='USD' />,
      });
    }

    if (counterparty.directOutgoing) {
      value.push({
        label: 'Direct Outgoing Exposure',
        value: <CurrencyValue value={counterparty.directOutgoing.total_value_usd} currency='USD' />,
      });
    }

    return {
      label: key,
      value,
    };
  });

  return <ReportTable tableData={tableData} title={`Part ${part}: Counterparty Summary`} />;
};

export default CounterpartySummaryReport;
