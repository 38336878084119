import { FC } from 'react';

const CustomersDisabled: FC = () => {
  return (
    <div>
      <div className='font-medium text-gray-800'>
        Monitor multiple customer wallets and customer transactions in one place with the “Customers” module
      </div>
      <div className='mt-6 font-medium text-gray-500'>
        This feature is not available as part of your current plan.
        <br />
        Please reach to your Account Managers or mail us at{' '}
        <a href='mailto:support@merklescience.com'>support@merklescience.com</a> to get it activated
      </div>
    </div>
  );
};

export default CustomersDisabled;
