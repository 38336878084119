import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { IAnalyticsResponse } from '../api/dtos/analytics';
import { IAuthState } from '../modules/auth/types';
import { getGlobalScope } from '@sentry/react';
import axios from 'axios';

const isDev = process.env.NODE_ENV === 'development';

export const initLogRocket = (params: IAnalyticsResponse, state: IAuthState) => {
  if (!isDev && params.logrocket_enabled === true) {
    const proxyIngestionURL = 'https://logrocket-data.merklescience.com';
    const logRocketParams = {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().endsWith('api/v1/login/') && request.body) {
            const body = JSON.parse(request.body);
            body.password = '********';
            request.body = JSON.stringify(body);
          }
          return request;
        },
      },
      serverURL: null,
    };
    axios
      .get(proxyIngestionURL)
      .then((response) => {
        if (response.data === 'ok') {
          logRocketParams.serverURL = `${proxyIngestionURL}/i`;
        }
      })
      .finally(() => {
        if (logRocketParams.serverURL) LogRocket.init(params.logrocket_token, logRocketParams);

        if (state?.access) {
          LogRocket.identify(params.id.toString(), {
            name: params.name,
            username: params.username,
            email: params.email,
            role: params.role_verbose,
            server_env: params.server_env,
            organization: params.organization,
            can_access_customers: state.userProfile.can_access_customers,
          });
        }
        setupLogRocketReact(LogRocket);
      });

    LogRocket.getSessionURL((sessionURL) => {
      getGlobalScope().setExtras({
        sessionURL,
      });
    });
  }
};

export const getSessionRecodingUrl = () => {
  try {
    if (LogRocket.sessionURL === 'Must call LogRocket.init() before sessionURL becomes available.') {
      return '';
    } else {
      return LogRocket.sessionURL;
    }
  } catch {
    return '';
  }
};

export const initGTag = (params: IAnalyticsResponse) => {
  if (params.gtm_enabled) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s) as HTMLScriptElement;
      const dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', params.gtm_id);
  }
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj?: any;
    _hjSettings?: {
      hjid: number;
      hjsv: number;
    };
  }
}

export const initHotjar = (param: IAnalyticsResponse) => {
  if (param.hotjar_enabled === true) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function (...args) {
          (h.hj.q = h.hj.q || []).push(args);
        };
      h._hjSettings = { hjid: param.hotjar_id, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    if (param.is_authenticated) {
      window.hj('identify', param.email, {
        name: param.name,
        username: param.username,
        organization: param.organization,
        role_verbose: param.role_verbose,
        server_env: param.server_env,
      });
    }
  }
};
