import { FC } from 'react';
import { IAlert } from '../../../../../api/dtos/alert';
import { useAlertGetAlertItems } from '../../../../../api/alert';
// import { useParams } from 'react-router-dom';
import AlertInfoItem from './AlertInfoItem';
import Pagination from '../../../../../containers/Pagination';
import Sidebar from '../../../../ui/components/Sidebar';

interface IAlertInfoProps {
  alert: IAlert;
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const AlertInfo: FC<IAlertInfoProps> = ({ alert, type, id, open, setOpen }) => {
  // const { addressId } = useParams();
  // TODO: use react-router

  const alertItems = useAlertGetAlertItems(
    {
      id,
      alertId: alert.id,
      type,
    },
    {
      enabled: open,
    }
  );

  return (
    <Sidebar
      title={
        <div className='flex flex-row'>
          <div className='mr-2 text-black'>
            <div>Rule Name</div>
            <div>Risk Level</div>
          </div>
          <div>
            <div>: {alert.alert_body}</div>
            <div>: {alert.level_verbose}</div>
          </div>
        </div>
      }
      isOpen={open}
      onClose={() => setOpen(false)}>
      <div className='mt-4 border'>
        <Pagination query={alertItems}>
          {(item) => (
            <AlertInfoItem id={id} alert={alert} alertItem={item} key={item.identifier} type={type} />
          )}
        </Pagination>
      </div>
    </Sidebar>
  );
};

export default AlertInfo;
