import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { Api } from '../api';
import {
  ITeamMember,
  ITeamMemberAddRequest,
  ITeamMemberBulkInviteRequest,
  ITeamMemberRequest,
  ITeamMemberSearchResponse,
  ITeamMemberUpdateRequest,
  ITeamMembersResponse,
} from '../dtos/settings/teamMembers';
import { getNextPageParam } from '../../utils/helpers/helperFunctions';

class TeamMembersApi extends Api {
  readonly baseUrl: string = '/profile/api/v2/team-members/';

  readonly getTeamMembers = (request: ITeamMemberRequest) => {
    const { offset = 0, limit = 25, q, is_active } = request;
    return this.http.get<ITeamMembersResponse>(this.route(''), {
      params: {
        is_active,
        q,
        limit,
        offset,
      },
    });
  };

  readonly getTeamMember = (id: number) => {
    return this.http.get<ITeamMember>(this.route(`${id}/`));
  };

  readonly addTeamMember = (request: ITeamMemberAddRequest) => {
    return this.http.post(this.route(''), request);
  };

  readonly updateTeamMember = (request: ITeamMemberUpdateRequest) => {
    const { id, is_active } = request;
    return this.http.put<ITeamMember>(this.route(`${id}/`), { is_active: is_active });
  };

  readonly bulkInviteTeamMembers = (request: ITeamMemberBulkInviteRequest) => {
    return this.http.post<ITeamMember[]>(this.route('bulk-invite-workspace/'), request);
  };

  readonly searchTeamMembers = (query: string) => {
    return this.http.get<ITeamMemberSearchResponse>(this.route('suggest/'), {
      params: {
        q: query,
      },
    });
  };
}

export const teamMembersApi = new TeamMembersApi();

export const useTeamMembersGet = (params: ITeamMemberRequest, options?: UseInfiniteQueryOptions) => {
  const key = ['TeamMembersApi', 'getTeamMembers', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => teamMembersApi.getTeamMembers({ ...params, offset: pageParam }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );

  return result;
};

export const useGetTeamMemberQuery = (id: number, options?: UseQueryOptions) => {
  const key = ['TeamMembersApi', id, 'getTeamMember'];
  const result = useQuery(key, () => teamMembersApi.getTeamMember(id), {
    ...options,
  } as unknown);

  return result;
};

export const useTeamMembersSearch = (query: string, options?: UseQueryOptions) => {
  const key = ['TeamMembersApi', 'searchTeamMembers', query];
  const result = useQuery(key, () => teamMembersApi.searchTeamMembers(query), {
    ...options,
  } as unknown);

  return result;
};
